import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Fab from "@material-ui/core/Fab";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintIcon from "@material-ui/icons/Print";
import React, { Fragment, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Compete } from "../../../store/compete/compete.types";
import { RootState } from "../../../store/root-reducer";
import { rubricCreateBatch } from "../../../store/rubrics/rubric.action";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { TeamPublic } from "../../../store/teams/team.types";
import { FaHeader, FaText } from "../../atomic/FaTexts";
import CoreValuesRubric from "./CoreValuesRubric";
import InnovationProjectRubric from "./InnovationProjectRubric";
import RobotDesignRubric from "./RobotDesignRubric";
import {
  CoreQuestion,
  GeneralQuestion,
  ProjectQuestion,
  RobotQuestion,
} from "./rubrics";

type LocalProps = {
  team: TeamPublic;
  compete: Compete;
  rubrics: RubricAnswer[];
  judge?: boolean;
};

type Score = { value?: 1 | 2 | 3 | 4; comment?: string };

/**
 * @todo add an alert on modal close, in case they try to close the modal while changes exist
 * also add progress bar to the save button
 * Editing isn't working at the moment -- make sure it only updates on answers once
 * It's super fucking slow, which isn't great?
 * Rule out the CV checkboxes from "isCoreComplete"
 * Add judge advisor bullshit
 * Bulk update - don't create one at a time?
 */

type ChangeData = {
  [property in
    | CoreQuestion
    | ProjectQuestion
    | RobotQuestion
    | GeneralQuestion]: Score;
};

const RubricViewForm = ({
  team,
  compete,
  rubrics,
  rubricCreateBatch,
  judge = false,
}: Props) => {
  const [cvOpen, setCvOpen] = useState<boolean>(false);
  const [ipOpen, setIpOpen] = useState<boolean>(false);
  const [rdOpen, setRdOpen] = useState<boolean>(false);

  const onPrint: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setCvOpen(true);
    setIpOpen(true);
    setRdOpen(true);
    setTimeout(() => {
      if (!team) return;
      const t = document.title;
      if (team)
        document.title = `${team.team_number}-${team.team_name
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "_")}-rubric`;
      else document.title = "rubric";
      window.print();
      document.title = t;
    }, 1000);
  };

  // console.log(rubrics.find((r) => r.question_id.startsWith("gn")));

  const classes = useStyles();
  return (
    <div className={classes.rubricview}>
      <FaHeader size="md">
        {team.team_number} {team.team_name}
      </FaHeader>
      <Accordion
        className={classes.cvSection}
        expanded={cvOpen}
        onChange={() => setCvOpen(!cvOpen)}
      >
        <AccordionSummary
          className={classes.cvHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <FaText className={classes.bold}>Core Values</FaText>
        </AccordionSummary>
        <AccordionDetails>
          <CoreValuesRubric
            answers={rubrics
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .filter(
                (x, i, a) =>
                  a.findIndex((y) => y.question_id === x.question_id) === i
              )}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.ipSection}
        expanded={ipOpen}
        onChange={() => setIpOpen(!ipOpen)}
      >
        <AccordionSummary
          className={classes.ipHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <FaText className={classes.bold}>Innovation Project</FaText>
        </AccordionSummary>
        <AccordionDetails>
          <InnovationProjectRubric
            answers={rubrics
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .filter(
                (x, i, a) =>
                  a.findIndex((y) => y.question_id === x.question_id) === i
              )}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.rdSection}
        expanded={rdOpen}
        onChange={() => setRdOpen(!rdOpen)}
      >
        <AccordionSummary
          className={classes.rdHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <FaText className={classes.bold}>Robot Design</FaText>
        </AccordionSummary>
        <AccordionDetails>
          <RobotDesignRubric
            answers={rubrics
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .filter(
                (x, i, a) =>
                  a.findIndex((y) => y.question_id === x.question_id) === i
              )}
          />
        </AccordionDetails>
      </Accordion>
      <div className={classes.comments}>
        <FaText className={classes.commentBox}>
          <strong>"Great job" comment: </strong>
          {rubrics.find((g) => g.question_id === "gn:positive_comment")
            ?.comment ?? ""}
        </FaText>
        <FaText className={classes.commentBox}>
          <strong>"Think about" comment: </strong>
          {rubrics.find((g) => g.question_id === "gn:negative_comment")
            ?.comment ?? ""}
        </FaText>
        {judge && (
          <Fragment>
            <FaText className={classes.commentBox}>
              <strong>Judge-only private comment: </strong>
              {rubrics.find((g) => g.question_id === "gn:private_comment")
                ?.comment ?? ""}
            </FaText>
            <FaText className={classes.commentBox}>
              <strong>Award script: </strong>
              {rubrics.find((g) => g.question_id === "gn:award_script")
                ?.comment ?? ""}
            </FaText>
          </Fragment>
        )}
      </div>
      <Fab
        variant="extended"
        // size="small"
        color={"default"}
        aria-label="print"
        className={classes.printBubble}
        onClick={onPrint}
      >
        <PrintIcon />
        Print
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    rubricview: {},
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
      margin: theme.spacing(1, 0),
    },
    bold: { fontWeight: "bold" },
    cvSection: {
      border: `1px solid ${theme.palette.corevalues.main}`,
    },
    cvHeader: {
      backgroundColor: theme.palette.corevalues.dark,
    },
    ipSection: {
      border: `1px solid ${theme.palette.innovationproject.main}`,
    },
    ipHeader: {
      backgroundColor: theme.palette.innovationproject.dark,
    },
    rdSection: {
      border: `1px solid ${theme.palette.robotdesign.main}`,
    },
    rdHeader: {
      backgroundColor: theme.palette.robotdesign.dark,
    },
    comments: {
      display: "flex",
      flexDirection: "column",
    },
    commentBox: {
      marginTop: theme.spacing(2),
    },
    printBubble: {
      // position: "absolute",
      width: 120,
      justifyContent: "space-evenly",
      display: "flex",
      margin: theme.spacing(1),
      position: "fixed",
      bottom: theme.spacing(4),
      right: theme.spacing(2),
    },
  })
);

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { rubricCreateBatch });

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(RubricViewForm);
