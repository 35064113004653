import React, { FormEvent, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { paymentCreate } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import logger from "../../../utils/logger";
import SubmitButton from "../../atomic/SubmitButton";
import UserName from "../../atomic/UserName";

type LocalProps = {
  userId: IUser["_id"];
  onClose: () => void;
};

const AddOverrideView = ({ userId, user, onClose, paymentCreate }: Props) => {
  const [amount, setAmount] = useState<number>(0);
  const [comment, setComment] = useState<string>("");

  const onSubmit = async (
    e: FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (!comment)
      return logger.warn(
        "Please enter a comment to create an override",
        "AddOverrideView",
        true
      );
    const success = await paymentCreate({
      address: "",
      amount,
      admin_comment: comment,
      credit_card: false,
      payer: userId,
      comment: "",
    });
    if (success) {
      setComment("");
      setAmount(0);
      onClose();
    }
  };

  return !user ? (
    <div>Something went wrong...</div>
  ) : (
    <div>
      <h1>
        <UserName user={user} />
      </h1>

      <h2>Summary</h2>
      <form className="form" onSubmit={onSubmit}>
        <strong>Total owing:</strong>{" "}
        <span>${(user.up - user.down).toFixed(2)}</span>
        <strong>Amount to override:</strong>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number.parseFloat(e.target.value))}
        />
        <strong>Admin comment</strong>
        <input
          type="text"
          value={comment}
          required
          onChange={(e) => setComment(e.target.value)}
        />
        <SubmitButton value="Create" />
      </form>
    </div>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState, props: LocalProps) => ({
    user: selectUser(state, props),
  });
};

const connector = connect(makeMapStateToProps, {
  paymentCreate,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(AddOverrideView);
