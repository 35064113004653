import { FIRSTSeason } from '../common/backend.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrentSeason } from '../api/globals';

export interface SettingsState {
  season: FIRSTSeason;
}


const initialState:SettingsState = {
  season: CurrentSeason
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSeason: (state, action: PayloadAction<FIRSTSeason>) => {
      state.season = action.payload;
    } 
  }
})

export const { setSeason } = settingsSlice.actions

export default settingsSlice.reducer
