import { IScoresheet } from "../../api/api.types";

/** Basic types */

export type Scoresheet = IScoresheet & {};

export type ScoresheetState = {
  all: Scoresheet[];
  mine: Scoresheet[];
  loading: boolean;
};

/** Action definitions */
export const SCORE_GET_ALL = "SCORE_GET_ALL";
export const SCORE_GET_MINE = "SCORE_GET_MINE";
export const SCORE_CREATE = "SCORE_CREATE";
export const SCORE_UPDATE = "SCORE_UPDATE";
export const SCORE_DELETE = "SCORE_DELETE";

/** Action types */
export interface ScoresheetGetAllActionType {
  type: typeof SCORE_GET_ALL;
  payload: Scoresheet[];
}
export interface ScoresheetGetMineActionType {
  type: typeof SCORE_GET_MINE;
  payload: Scoresheet[];
}

export interface ScoresheetUpdateActionType {
  type: typeof SCORE_UPDATE;
  payload: {
    id: Scoresheet["_id"];
    data: Scoresheet;
  };
}

export interface ScoresheetCreateActionType {
  type: typeof SCORE_CREATE;
  payload: Scoresheet;
}

export interface ScoresheetDeleteActionType {
  type: typeof SCORE_DELETE;
  payload: Scoresheet["_id"];
}

/** Final export */
export type ScoresheetActionTypes =
  | ScoresheetGetAllActionType
  | ScoresheetGetMineActionType
  | ScoresheetCreateActionType
  | ScoresheetUpdateActionType
  | ScoresheetDeleteActionType;
