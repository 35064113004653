import { resolve } from "dns";
import firebase from "firebase";
import logger from "./logger";

type Props = {
  /** File name to put on Firebase, including extension */
  name: string;
  /** Location in firebase (folder from root) */
  location?: string;
  /** File to upload */
  file: File;
  /** Callback when progress updates (pause and cancel not currently handled)  */
  onProgress?: (x: number) => void;
  /** Callback on upload error */
  onError?: (e: firebase.storage.FirebaseStorageError) => void;
  /** Callback on upload finish */
  onFinish?: () => void;
};

/**
 * Starts uploading a file to Firebase Storage
 */
const uploadFirebase = ({
  name,
  location = "",
  file,
  onProgress,
  onError,
  onFinish,
}: Props): Promise<boolean> => {
  return new Promise<boolean>((res, rej) => {
    // Create a root reference
    var storageRef = firebase.storage().ref(location);

    // Create a reference to the filename
    var itemRef = storageRef.child(name);

    var uploadTask = itemRef.put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes.
    // 2. Error observer, called on failure.
    // 3. Completion observer, called on successful completion.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        logger.debug(
          progress + "% uploaded",
          "firebase/" + (location ? location + "/" : "") + name,
          false,
          { snapshot }
        );
        if (onProgress) onProgress(progress);
      },
      (error) => {
        if (onError) onError(error);
        rej();
      },
      () => {
        if (onFinish) onFinish();
        res(true);
      }
    );
  });
};

export default uploadFirebase;
