type Labels = [string, string, string, string];
interface ILabels {
  desc: {
    [key: string]: string;
  };
  label: {
    [key: string]: Labels;
  };
}

const robotdesign: ILabels = {
  desc: {
    identify:
      "Team had a clearly defined mission strategy and explored building and coding skills they needed.",
    design:
      "Team produced innovative designs and a clear workplan, seeking guidance as needed.",
    create:
      "Team developed an effective robot and code solution matching their mission strategy.",
    iterate:
      "Team repeatedly tested their robot and code to identify areas for improvement and incorporated the findings into their current solution.",
    communicate:
      "Team's explanation of the robot design process was effective and showed how all team members have been involved.",
  },
  label: {
    identify_a: [
      "Unclear mission strategy",
      "Partially clear mission strategy",
      "Clear mission strategy",
      "",
    ],
    identify_b: [
      "Limited evidence of building and coding skills in all team members",
      "Inconsistent evidence of building and coding skills in all team members",
      "Consistent evidence of building and coding skills in all team members",
      "",
    ],
    design_a: [
      "Minimal evidence of an effective plan",
      "Partial evidence of an effective plan",
      "Clear evidence of an effective plan",
      "",
    ],
    design_b: [
      "Minimal explanation of robot and coded's innovative features",
      "Partial explanation of robot and code's innovative features",
      "Clear explanation of robot and code's innovative features",
      "",
    ],
    create_a: [
      "Limited explanation of their robot and its attachment and sensor functionality",
      "Simple explanation of their robot and its attachment and sensor functionality",
      "Detailed explanation of their robot and its attachment and sensor functionality",
      "",
    ],
    create_b: [
      "Unclear explanation of how code makes their robot act",
      "Partially clear explanation of how code makes their robot act",
      "Clear explanation of how code makes their robot act",
      "",
    ],
    iterate_a: [
      "Minimal evidence of testing their robot and code",
      "Partial evidence of testing their robot and code",
      "Clear evidence of testing their robot and code",
      "",
    ],
    iterate_b: [
      "Minimal evidence their robot and code was improved",
      "Partial evidence their robot and code was improved",
      "Clear evidence their robot and code was improved",
      "",
    ],
    communicate_a: [
      "Unclear explanation of robot design process",
      "Partially clear explanation of robot design process",
      "Clear explanation of robot design process",
      "",
    ],
    communicate_b: [
      "Minimal evidence that all team members were involved",
      "Partial evidence that all team members were involved",
      "Clear evidence that all team members were involved",
      "",
    ],
  },
};
export default robotdesign;
