import React from "react";
import { ThemeColour } from "../../common/theme.types";
import { LocalRoute } from "../Routes";
import LocalLink from "./LocalLink";

interface Props {
  className?: string;
  variant?: ThemeColour;
  to: LocalRoute;
  children: React.ReactNode;
}

const ButtonLink = ({ className, variant, to, children }: Props) => {
  return (
    <LocalLink
      to={to}
      className={`btn btn-${variant}${className ? " " + className : ""}`}
    >
      {children}
    </LocalLink>
  );
};

export default ButtonLink;
