import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import {
  CategoricalScoreResult,
  GameType,
  GIsNumericScore,
  NumericScoreResult,
  Score,
  ScoreError,
  ScoreResult,
} from "./games/types";
import { Question } from "./forms/Questions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Fab from "@material-ui/core/Fab";
import { ScoreAnswer } from "../../../api/api.types";
import { FaButton } from "../../atomic/FaButtons";
import TextField from "@material-ui/core/TextField";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import PrintIcon from "@material-ui/icons/Print";
import ToggleSwitch from "../../atomic/ToggleSwitch";
import { Box, Paper } from "@material-ui/core";
import { FaHeader, FaParagraph, FaText } from "../../atomic/FaTexts";

interface Props {
  game: GameType;
  referee?: boolean;
  team_name?: string;
  scoresheet: Scoresheet;
}

const ScoresheetView = ({
  game,
  team_name,
  scoresheet,
  referee: inputRef = false,
}: Props) => {
  const classes = useStyles();
  const [compact, setCompact] = useState<boolean>(true);
  const [referee, setReferee] = useState<boolean>(false);

  const onPrint: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (!scoresheet) return;
    const t = document.title;
    document.title = team_name
      ? `${team_name.toLowerCase().replace(" ", "_")}-round-${scoresheet.round}`
      : `round-${scoresheet.round}`;
    document.title = "scoresheet";
    window.print();
    document.title = t;
  };

  return (
    <Paper className={classes.root}>
      <ToggleSwitch
        onToggle={() => setCompact(!compact)}
        value={compact}
        name="Compact view?"
        rounded
      />
      {inputRef && (
        <ToggleSwitch
          onToggle={() => setReferee(!referee)}
          value={referee}
          name="Referee view?"
          rounded
        />
      )}
      {compact ? (
        <div className={classes.flexgrid}>
          {game.missions
            .filter((m) => m.prefix !== "gp" || referee)
            .map(({ title, prefix, image }, i) => (
              <Box className={classes.compactmission}>
                <div className="d-flex">
                  <img
                    src={
                      image ??
                      "https://www.firstlegoleague.org/sites/default/files/color/fll_theme-474df67b/fll-logo-horizontal.png"
                    }
                    className={classes.missionPic}
                  />
                  <h4>{title}</h4>
                </div>
                {game.scores
                  .filter((q) => q.id.startsWith(prefix))
                  .map((q) => (
                    <Question
                      key={q.id}
                      unanswered={false}
                      compact
                      question={q}
                      value={
                        scoresheet.answers.find((r) => r.id === q.id)?.answer
                      }
                      errors={[]}
                    />
                  ))}
              </Box>
            ))}
          <div className={classes.comments}>
            <FaHeader size="sm">Comment from the referee:</FaHeader>
            <FaParagraph>
              {scoresheet.public_comment ?? "No comment"}
            </FaParagraph>
            {referee && (
              <Fragment>
                <FaHeader size="sm">Comment for the referees:</FaHeader>
                <FaParagraph>
                  {scoresheet.private_comment ?? "No comment"}
                </FaParagraph>
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <Fragment>
          <Table>
            <TableBody>
              {game.missions
                .filter((m) => m.prefix !== "gp" || referee)
                .map(({ title, prefix, image }, i) => (
                  <Fragment key={prefix}>
                    <TableRow>
                      <TableCell
                        className={i > 0 ? classes.topborder : classes.noborder}
                        colSpan={2}
                        component="th"
                      >
                        <div className={classes.mission}>
                          <img
                            src={
                              image ??
                              "https://www.firstlegoleague.org/sites/default/files/color/fll_theme-474df67b/fll-logo-horizontal.png"
                            }
                            className={classes.missionPic}
                          />
                          <h4>{title}</h4>
                        </div>
                      </TableCell>
                    </TableRow>
                    {game.scores
                      .filter((q) => q.id.startsWith(prefix))
                      .map((q) => (
                        <Question
                          key={q.id}
                          unanswered={false}
                          question={q}
                          value={
                            scoresheet.answers.find((r) => r.id === q.id)
                              ?.answer
                          }
                        />
                      ))}
                  </Fragment>
                ))}
            </TableBody>
          </Table>
          <div className={classes.comments}>
            <FaHeader size="sm">Comment from the referee:</FaHeader>
            <FaParagraph>
              {scoresheet.public_comment ?? "No comment"}
            </FaParagraph>
            {referee && (
              <Fragment>
                <FaHeader size="sm">Comment for the referees:</FaHeader>
                <FaParagraph>
                  {scoresheet.private_comment ?? "No comment"}
                </FaParagraph>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}

      <FaButton
        // @todo
        onClick={onPrint}
        startIcon={<PrintIcon />}
      >
        Print!
      </FaButton>

      <Fab
        variant="extended"
        // size="small"
        color={"primary"}
        className={classes.scoreBubble}
      >
        {game.score(scoresheet.answers)} Points
      </Fab>
    </Paper>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    questions: {},
    topborder: {
      borderBottom: "none",
      borderTop: "1px solid " + theme.palette.grey[300],
    },
    noborder: {
      borderBottom: "none",
    },
    flexgrid: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: ""
      flexWrap: "wrap",
    },
    compactmission: {
      flexGrow: 1,
      padding: 3,
      margin: 4,

      border: "1px solid #cccccc",
      display: "block",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      // border: "1px grey solid",
    },
    mission: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    missionPic: {
      marginRight: 16,
      height: 64,
      width: "auto",
    },
    scoreBubble: {
      // position: "absolute",
      width: 120,
      margin: theme.spacing(1),
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    comments: {
      display: "flex",
      flexDirection: "column",
    },
    comment: {
      marginBottom: 16,
    },
    root: {
      width: "100%",
      padding: 4,
      "@media print": {
        width: "100%",
      },
    },
  })
);

export default ScoresheetView;
