import React from "react";
import { ThemeColour } from "../../common/theme.types";
import Icon from "./Icon";

interface Props {
  className?: string;
  value?: number;
  toggle: () => void;
  variant?: ThemeColour;
  id?: string;
}

const NumberBox = ({
  className,
  variant = "primary",
  value,
  toggle,
  id,
}: Props) => {
  return (
    <div
      id={id}
      onClick={toggle}
      className={`checkbox${className ? " " + className : ""}`}
    >
      <span className={`text-${variant}`}>{value}</span>
    </div>
  );
};

export default NumberBox;
