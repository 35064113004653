import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ProgramToClass, ProgramToLabel } from "../../../common/backend.types";
import { addressGetAll } from "../../../store/address/address.action";
import { competeGetAll } from "../../../store/compete/compete.action";
import { exportTeams } from "../../../store/exports.action";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import {
  teamDelete,
  teamGetAll,
  teamUndelete,
} from "../../../store/teams/team.action";
import {
  selectAllTeams,
  selectDeletedTeams,
} from "../../../store/teams/team.selector";
import { Team } from "../../../store/teams/team.types";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { userGetAll } from "../../../store/users/user.action";
import Button from "../../atomic/Button";
import { FaHeader } from "../../atomic/FaTexts";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import TeamNumber from "../../atomic/TeamNumber";
import RegisterCompeteForm from "../../forms/RegisterCompeteForm";
import TeamTransferForm from "../../forms/TeamTransferForm";
import AdminTeamRow from "./AdminTeamRow";

const AdminTeamsView = ({
  loading,
  teams,
  delTeams,
  teamDelete,
  teamUndelete,
  exportTeams,

  teamGetAll,
  userGetAll,
  addressGetAll,
  paymentGetAll,
  competeGetAll,
  tournamentGetAll,
}: Props) => {
  const [filter, setFilter] = useState<string>("");
  const [transfer, setTransfer] = useState<Team | null>(null);
  const [moveTeam, setMoveTeam] = useState<Team | null>(null);

  useEffect(() => {
    teamGetAll();
    userGetAll();
    addressGetAll();
    paymentGetAll();
    competeGetAll();
    tournamentGetAll();
  }, []);

  return (
    <div id="admin-teams-view">
      <span className="d-flex">
        <h1 className="mr-auto">Teams</h1>
        <Button onClick={() => exportTeams()}>Download</Button>
      </span>
      <input
        type="text"
        className="mb-1"
        placeholder="Filter..."
        value={filter}
        onChange={(e) => {
          e.preventDefault();
          setFilter(e.target.value);
        }}
      />

      {loading ? (
        <Spinner variant="admin" />
      ) : (
        <div className="teams">
          <table className="table">
            <thead>
              <tr>
                <th>Program</th>
                <th>Number</th>
                <th>Name</th>
                <th>Affiliation</th>
                <th>Events</th>
                <th>Coaches</th>
                <th>Timestamp</th>
                <th>Pre?</th>
                <th>Paid up?</th>
                <th>Transfer</th>
                <th>Move tournament</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className={"ta-center"}>
              {teams
                .filter(
                  (t) =>
                    t.team_name.toLowerCase().includes(filter.toLowerCase()) ||
                    t.team_number.toString().includes(filter) ||
                    t.affiliation?.toLowerCase().includes(filter.toLowerCase())
                )
                .map((t) => (
                  <AdminTeamRow
                    key={t._id}
                    team={t}
                    onTransfer={() => setTransfer(t)}
                    onMoveTeam={() => setMoveTeam(t)}
                  />
                ))}
            </tbody>
          </table>
          <h2>Deleted teams</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Program</th>
                <th>Number</th>
                <th>Name</th>
                <th>Affiliation</th>
                <th>Restore?</th>
              </tr>
            </thead>
            <tbody className={"ta-center"}>
              {delTeams
                .filter(
                  (t) =>
                    t.team_name.toLowerCase().includes(filter.toLowerCase()) ||
                    t.team_number.toString().includes(filter)
                )
                .map((t) => (
                  <tr key={t._id}>
                    <td
                      className={"text-bold text-" + ProgramToClass(t.program)}
                    >
                      {ProgramToLabel(t.program)}
                    </td>
                    <td>
                      <TeamNumber number={t.team_number} program={t.program} />
                    </td>
                    <td>{t.team_name}</td>
                    <td>{t.affiliation}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => teamUndelete(t._id)}
                      >
                        <Icon icon="check" />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal open={transfer !== null} onClose={() => setTransfer(null)}>
        {transfer ? (
          <TeamTransferForm
            team={transfer}
            onFinish={() => setTransfer(null)}
          />
        ) : (
          <div />
        )}
      </Modal>
      <Modal open={moveTeam !== null} onClose={() => setMoveTeam(null)}>
        {moveTeam ? (
          <RegisterCompeteForm
            teams={[moveTeam]}
            program={moveTeam.program}
            admin
            onSubmit={() => setMoveTeam(null)}
          />
        ) : (
          <div />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.team.loading,
  teams: selectAllTeams(state),
  delTeams: selectDeletedTeams(state),
});

const connector = connect(mapStateToProps, {
  teamDelete,
  teamUndelete,
  exportTeams,

  teamGetAll,
  userGetAll,
  addressGetAll,
  paymentGetAll,
  competeGetAll,
  tournamentGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(AdminTeamsView);
