import {
  Email,
  FIRSTProgram,
  FIRSTSeason,
  Programs,
  Seasons,
  State,
  TournamentType,
} from "../../common/backend.types";
import { Tournament } from "../../store/tournament/tournament.types";
import {
  GCheckFields,
  GDef,
  IAddress,
  IGuard,
  ITournament,
} from "../api.types";

export interface DTOTournamentGet {
  _id: ITournament["_id"];
  name: string;
  program: FIRSTProgram;
  season: FIRSTSeason;
  start: Date;
  n_days: number;
  type: TournamentType;
  next_tourn?: ITournament["_id"];
  cap: number;
  remote: boolean;
  company: string;
  street1: string;
  street2?: string;
  street3?: string;
  postcode: string;
  suburb: string;
  state: State;
  directors: Email[];
  volunteer_coordinators: Email[];
  locked: boolean;
  vol_locked?: boolean;
  judge_locked?: boolean;
  ship_address: IAddress["_id"];
  ship_kits: number;
  dummy?: boolean;
  released?: boolean;
  timestamp: Date;
}

export const TournamentFromDTOGet = (x: DTOTournamentGet): Tournament => ({
  _id: x._id,
  name: x.name,
  program: x.program,
  season: x.season,
  start: x.start,
  n_days: x.n_days,
  type: x.type,
  next_tourn: x.next_tourn,
  cap: x.cap,
  remote: x.remote,
  company: x.company,
  street1: x.street1,
  street2: x.street2 ? x.street2 : "",
  street3: x.street3 ? x.street3 : "",
  postcode: x.postcode,
  suburb: x.suburb,
  state: x.state,
  directors: x.directors,
  volunteer_coordinators: x.volunteer_coordinators,
  locked: x.locked,
  vol_locked: x.vol_locked ? x.vol_locked : false,
  judge_locked: x.judge_locked ? x.judge_locked : false,
  ship_kits: x.ship_kits,
  ship_address: x.ship_address ? x.ship_address : "",
  dummy: x.dummy,
  released: x.released,
  timestamp: x.timestamp,
});

export interface DTOTournamentCreate {
  name: string;
  program: FIRSTProgram;
  season: FIRSTSeason;
  start: Date;
  n_days: number;
  type: TournamentType;
  next_tourn?: ITournament["_id"];
  cap: number;
  remote: boolean;
  company: string;
  street1: string;
  street2?: string;
  street3?: string;
  postcode: string;
  suburb: string;
  state: State;
  directors: Email[];
  volunteer_coordinators: Email[];
  locked: boolean;
  ship_address?: IAddress["_id"];
  ship_kits: number;
  released?: boolean;
}

export interface DTOTournamentUpdate {
  name?: string;
  // Can't change program, season or event type after creating
  /** [Admin] What date/time does the event start? */
  start?: Date;
  /** [Admin] How many days does the event run for? */
  n_days?: number;
  /** To which event does this qualify you for? */
  next_tourn?: ITournament["_id"];
  /** How many teams are allowed in this event*/
  cap?: number;

  /** [Admin] Is this event fully online? */
  remote?: boolean;
  company?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  postcode?: string;
  suburb?: string;
  state?: State;

  /** No more changes - documents are printed! */
  locked?: boolean;
  vol_locked?: boolean;
  judge_locked?: boolean;
  ship_address?: IAddress["_id"];
  /** How many kits do they need shipped? */
  ship_kits?: number;
  released?: boolean;
}

export interface DTODummyTournamentCreate {
  // Basic info
  name: string;
  program: FIRSTProgram;
  season: FIRSTSeason;
  /** What date/time does the event start? */
  start: Date;
  /** How many days does the event run for? */
  n_days: number;
  /** Event type */
  type: TournamentType;
  /** To which event does this qualify you for? */
  next_tourn?: string;
  /** How many teams are allowed in this event*/
  cap: number;
  /** [Admin] Is this event fully online? */
  remote?: boolean;
}

export const GIsDTOTournamentGet: IGuard<DTOTournamentGet> = (x) => {
  const T = GCheckFields<DTOTournamentGet>(GDef(x), [
    "_id",
    "name",
    "program",
    "season",
    "start",
    "n_days",
    "type",
    "cap",
    "remote",
    "company",
    "street1",
    "postcode",
    "suburb",
    "state",
    "locked",
    // "vol_locked",
    // "judge_locked",
    "ship_kits",
    "timestamp",
  ]);
  return T;
};
