import { IShipment } from "../../api/api.types";
import { Address, Company, Contact } from "../../common/backend.types";

export const SHIPMENT_GET_MINE = "SHIPMENT_GET_MINE";
export const SHIPMENT_GET_ALL = "SHIPMENT_GET_ALL";
export const SHIPMENT_CREATE = "SHIPMENT_CREATE";
export const SHIPMENT_UPDATE = "SHIPMENT_UPDATE";
export const SHIPMENT_DELETE = "SHIPMENT_DELETE";
export const SHIPMENT_CLEAR = "SHIPMENT_CLEAR";



export type ShipmentId = string;

/** A "shipping address" contains all the data required to create a TNT shipment */
export type ShippingAddress = {
  contact: Contact;
  street: Address;
  company: string | Company;
};

export interface ShipmentData {
  address: ShippingAddress;
  items: string[];
  shipped: boolean;
  ship_date: Date;
  consignment_number?: string;
}

export interface Shipment extends IShipment {
  _id: ShipmentId;
}

export interface ShipmentState {
  mine: Shipment[];
  all: Shipment[];
  loading: boolean;
}

/** Action types */
interface ShipmentGetMineActionType {
  type: typeof SHIPMENT_GET_MINE;
  payload: Shipment[];
}

interface ShipmentGetAllActionType {
  type: typeof SHIPMENT_GET_ALL;
  payload: Shipment[];
}

interface ShipmentUpdateActionType {
  type: typeof SHIPMENT_UPDATE;
  payload: Shipment;
}

interface ShipmentCreateActionType {
  type: typeof SHIPMENT_CREATE;
  payload: Shipment;
}

interface ShipmentDeleteActionType {
  type: typeof SHIPMENT_DELETE;
  payload: Shipment["_id"];
}

interface ShipmentClearActionType {
  type: typeof SHIPMENT_CLEAR;
  payload: null;
}

/** Final export */
export type ShipmentActionTypes =
  | ShipmentGetMineActionType
  | ShipmentGetAllActionType
  | ShipmentCreateActionType
  | ShipmentUpdateActionType
  | ShipmentDeleteActionType
  | ShipmentClearActionType;
