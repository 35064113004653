import { IUser } from "../../api/api.types";

/**
 * Type defintions
 */
export const USER_LOADED = "USER_LOADED";
export const UPDATE_USER = "UPDATE_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const SET_EMAIL_SENT = "SET_EMAIL_SENT";

export type UserId = string;

/**
 * Types
 */

export interface UserObject extends IUser {
  given_name: string;
  family_name: string;
  phone: string;
  email_sent?: boolean;
}

// If I need to add fields, add to AO's
export type AuthPayload = {
  user: UserObject;
  token: string;
};

// AuthState is just an array of UserObjects
export interface AuthState {
  token: string;
  user: UserObject | null;
  loading: boolean;
  error: Error | null;
}

export interface ValidatedAuthState {
  token: string;
  expires: Date;
  loading: boolean;
  user: UserObject;
  error: null;
}

// /**
//  * Action type interfaces
//  */
export interface LoadUserAction {
  type: typeof USER_LOADED;
  payload: AuthPayload;
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: UserObject;
}

export interface LogoutAction {
  type: typeof LOGOUT;
  payload: string;
}

export interface SetEmailSentAction {
  type: typeof SET_EMAIL_SENT;
  payload: boolean;
}

export interface AuthErrorAction {
  type: typeof AUTH_ERROR;
  payload: Error;
}

// /**
//  * Final export
//  */
export type AuthActionTypes =
  | LoadUserAction
  | UpdateUserAction
  | LogoutAction
  | SetEmailSentAction
  | AuthErrorAction;
