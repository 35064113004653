import { Fragment, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { UserRole } from "../../common/backend.types";
import { RootState } from "../../store/root-reducer";
import { teamGetMine } from "../../store/teams/team.action";
import { validateAuth } from "../../utils/auth.validate";
import logger from "../../utils/logger";
import Icon from "../atomic/Icon";
import LocalLink from "../atomic/LocalLink";
import { Game, Games } from "../views/Scoring/games/types";

const Sidebar = ({ auth, teams, teamGetMine, coach, season }: Props) => {
  const [collapse, setCollapse] = useState<boolean>(true);

  // These are needed almost everywhere, so pick it up here.
  // Anywhere else is just extra (and updates)
  useEffect(() => {
    logger.info("Updating stuff");
    if (auth.user) {
      teamGetMine();
    }
  }, []);
  //     addressGetMine();
  //     paymentGetMine();
  //     volleyGetMine();
  //     shipmentGetMine();
  //     // Everyone gets "all"
  //     tournamentGetAll();
  //     competeGetAll();
  //     userGetAll();
  //     eventGetAll();
  //   }
  //   const R = auth.user?.roles;
  //   let [getVolley, getTeams] = [false, false];

  //   if (
  //     R?.includes(UserRole.Admin) ||
  //     R?.includes(UserRole.Financier) ||
  //     R?.includes(UserRole.Shipper)
  //   ) {
  //     addressGetAll();
  //     paymentGetAll();
  //     shipmentGetAll();
  //     getVolley = true;
  //     getTeams = true;
  //   }
  //   if (R?.includes(UserRole.Director)) {
  //     tournamentGetMine();

  //     getVolley = true;
  //     getTeams = true;
  //   }
  //   if (getVolley) volleyGetAll();
  //   if (getTeams) teamGetAll();
  // }, [auth.user]);

  const game = useMemo<Game | undefined>(
    () => Games.find((gme) => gme.season === season),
    []
  );
  const sanitise = (s: string): string => s.toLowerCase().replace(" ", "-");

  return validateAuth(auth) ? (
    <div className={`sidebar${collapse ? " collapse" : ""}`}>
      <div className="sidebar-toggler" onClick={() => setCollapse(!collapse)}>
        <Icon icon="chevron-right" className={collapse ? "open" : "close"} />
      </div>
      <div className="sidebar-links h-100">
        <span className="sidebar-link">
          <LocalLink to="/">Dashboard</LocalLink>
        </span>
        {teams.length ? (
          <Fragment>
            <span className="sidebar-link">
              <LocalLink to="/my-teams">My Teams</LocalLink>
            </span>
            <span className="sidebar-link-inset">
              <LocalLink to="/shipping">My Shipping</LocalLink>
            </span>
            <span className="sidebar-link-inset">
              <LocalLink to="/finance">My Finance</LocalLink>
            </span>
          </Fragment>
        ) : coach.length ? (
          <span className="sidebar-link">
            <LocalLink to="/my-teams">My Teams</LocalLink>
          </span>
        ) : (
          <span className="sidebar-link">
            <LocalLink to="/register-teams">Register teams</LocalLink>
          </span>
        )}
        {(auth.user.roles.includes(UserRole.Financier) ||
          auth.user.roles.includes(UserRole.Admin)) && (
          <span className="sidebar-link">
            <LocalLink to="/finance-view">Finance</LocalLink>
          </span>
        )}
        {(auth.user.roles.includes(UserRole.Shipper) ||
          auth.user.roles.includes(UserRole.Admin)) && (
          <span className="sidebar-link">
            <LocalLink to="/shipping-view">Shipping</LocalLink>
          </span>
        )}
        {auth.user.roles.includes(UserRole.Director) && (
          <span className="sidebar-link">
            <LocalLink to="/my-tournaments">Tournaments</LocalLink>
          </span>
        )}

        {auth.user.roles.includes(UserRole.Admin) && (
          <Fragment>
            <span className="sidebar-link">
              <LocalLink to="/admin">Admin</LocalLink>
            </span>
            <span className="sidebar-link-inset">
              <LocalLink to="/admin-users">Users</LocalLink>
            </span>
            <span className="sidebar-link-inset">
              <LocalLink to="/admin-volunteers">Volunteers</LocalLink>
            </span>
            <span className="sidebar-link-inset">
              <LocalLink to="/admin-teams">Teams</LocalLink>
            </span>
            <span className="sidebar-link-inset">
              <LocalLink to="/all-tournaments">Tournaments</LocalLink>
            </span>
          </Fragment>
        )}
        {game ? (
          <div className="mt-auto mb-2">
            <span className="sidebar-link">
              <LocalLink to={`/calculator/${sanitise(game.name)}`}>
                {game.name} calculator
              </LocalLink>
            </span>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  ) : (
    <div className="sidebar hidden"></div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  teams: state.team.mine,
  coach: state.team.coach,
  season: state.settings.season,
});

const connector = connect(mapStateToProps, {
  teamGetMine,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(Sidebar);
