import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ReactComponent as FaBird } from "./birdlogo16_plain.svg";
import { FaIcon, IsFaIcon } from "./faLibrary";

const Svg = (x: FwIconTypes) => {
  switch (x) {
    case "fw-bird":
      return <FaBird />;
    default:
      return <div></div>;
  }
};

const StyleToFA = (s: IconStyle) => {
  switch (s) {
    case "brand":
      return "fab";
    case "solid":
      return "fas";
    case "regular":
      return "far";
    //     case "light":
    //     return "fal";
    default:
      return "fas";
  }
};

interface Props {
  /** Name of icon; custom icons have a "bv-" prefix */
  icon: IconTypes;
  /** Additional classnames to add to icon */
  className?: string;
  /** Size modifier */
  size?: "lg" | "sm";
  /** Style of icon */
  style?: IconStyle;
  /** OnClick event handler */
  onClick?: (e: React.MouseEvent<any>) => void;
}

/**
 * Represents an icon, either provided by FontAwesome or a custom SVG.
 */
const Icon = ({ icon, className, style, onClick, size }: Props) => (
  <div
    className={`icon${className ? ` ${className}` : ""}${
      size ? ` size-${size}` : " "
    }`}
    onClick={onClick}
  >
    {IsFaIcon(icon) ? (
      <FontAwesomeIcon
        icon={style ? [StyleToFA(style), icon] : icon}
        size={size ? size : undefined}
      />
    ) : (
      Svg(icon)
    )}
  </div>
);

export default Icon;

type FwIconTypes = "fw-bird";

type IconTypes = FaIcon | FwIconTypes;

// Light and Regular are pro-only atm
type IconStyle = "brand" | "solid" | "regular"; //| "light" ;
