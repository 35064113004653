import { GCheckFields, GDef, IGuard, IRubricAnswer } from "../api.types";

export interface DTORubricAnswerCreate {
  /** What team is this? */
  team_id: string;
  /** What tournament is this */
  tournament_id: string;
  question_id: string;
  value: number;
  comment: string;
}

export interface DTORubricAnswerBatchCreate {
  /** What team is this? */
  team_id: string;
  /** What tournament is this */
  tournament_id: string;
  answers: { question_id: string; value: number; comment: string }[];
}

export type DTORubricAnswerGet = {
  _id: string;
  compete_id: string;
  user_id: string;
  question_id: string;
  value: number;
  comment: string;
  timestamp: Date;
};

export const GIsDTORubricAnswerGet: IGuard<DTORubricAnswerGet> = (x) => {
  return GCheckFields<DTORubricAnswerGet>(x, [
    "_id",
    "compete_id",
    "user_id",
    "question_id",
    "value",
    "comment",
    "timestamp",
  ]);
};

export const RubricAnswerGetDtoToRubricAnswer = (
  x: DTORubricAnswerGet
): IRubricAnswer => x;
