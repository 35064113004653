import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Compete } from "../../../store/compete/compete.types";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import { RootState } from "../../../store/root-reducer";
import { ScoreAnswer } from "../../../api/api.types";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Team, TeamPublic } from "../../../store/teams/team.types";
import UserName from "../../atomic/UserName";
import { FaButton } from "../../atomic/FaButtons";
import { FaText } from "../../atomic/FaTexts";
import { alpha } from "@material-ui/core/styles";
import { ScoresheetWithTeam } from "./ScoresheetHistory";

type LocalProps = {
  compete: Compete;
  scoresheets: Scoresheet[];
  score: (answers: ScoreAnswer[]) => number;
  onClickView: (s: ScoresheetWithTeam) => void;
  onClickAdd: (round: 1 | 2 | 3, team?: TeamPublic) => void;
};

const toLabel = (t?: TeamPublic): string =>
  !t ? "?" : `${t.team_number}: ${t.team_name}`;

const ScoresheetHistoryRow = ({
  compete,
  scoresheets,
  teams,
  users,
  score,
  onClickView,
  onClickAdd,
}: Props) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.scoresheethistoryrow}>
      <TableCell>
        {toLabel(teams.find((t) => t._id === compete.team_id))}
      </TableCell>
      {([1, 2, 3] as (1 | 2 | 3)[]).map((round) => (
        <TableCell
          align="center"
          className={
            scoresheets.filter((s) => s.round === round).length > 0
              ? classes.complete
              : classes.incomplete
          }
        >
          {scoresheets
            .filter((s) => s.round === round)
            .map((scoresheet, i) => (
              <FaText
                className={[
                  classes.clickable,
                  i === 0 ? classes.first : classes.second,
                ].join(" ")}
                onClick={() =>
                  onClickView({
                    ...scoresheet,
                    team: teams.find((t) => t._id === compete.team_id),
                  })
                }
              >
                <UserName
                  user={users.find((u) => u._id === scoresheet.user_id)}
                />
                &nbsp; ({score(scoresheet.answers)})
              </FaText>
            ))}
          {scoresheets.filter((s) => s.round === round).length > 0 ? (
            <FaButton
              onClick={() => {
                // console.log({
                //   round,
                //   team: teams.find((t) => t._id === compete.team_id),
                // });
                onClickAdd(
                  round,
                  teams.find((t) => t._id === compete.team_id)
                );
              }}
              variant="outlined"
            >
              Revise
            </FaButton>
          ) : (
            <FaButton
              onClick={() =>
                onClickAdd(
                  round,
                  teams.find((t) => t._id === compete.team_id)
                )
              }
              variant="contained"
              color="primary"
            >
              Add
            </FaButton>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    scoresheethistoryrow: {
      // cursor: "pointer",
      // transition: "all 0.2s ease-in-out",
      // "&:hover": {
      //   background: theme.palette.grey[200],
      // },
    },
    complete: {
      background: alpha(theme.palette.primary.light, 0.2),
    },
    incomplete: {},
    clickable: {
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      color: "blue",
      textDecoration: "underline",
    },
    first: {},
    second: {
      color: alpha(theme.palette.primary.main, 0.5),
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  teams: [...state.team.tournament, ...state.team.all].filter(
    (x, i, a) => a.findIndex((y) => y._id === x._id) === i
  ),
  users: state.users.all,
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(ScoresheetHistoryRow);
