/**
 * Action types
 */

import { DTOVolleyUpdate } from "../../api/dtos/volley.dto";
import VolleyService from "../../api/services/volley.service";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import { Tournament } from "../tournament/tournament.types";
import {
  Volley,
  VolleyActionTypes,
  VOLLEY_CREATE,
  VOLLEY_DELETE,
  VOLLEY_GET_ALL,
  VOLLEY_GET_MINE,
  VOLLEY_UPDATE,
} from "./volley.types";

export const VolleyGetAllAction = (data: Volley[]): VolleyActionTypes => ({
  type: VOLLEY_GET_ALL,
  payload: data,
});
export const VolleyGetMineAction = (data: Volley[]): VolleyActionTypes => ({
  type: VOLLEY_GET_MINE,
  payload: data,
});

export const VolleyUpdateAction = (
  id: Volley["_id"],
  data: Volley
): VolleyActionTypes => ({
  type: VOLLEY_UPDATE,
  payload: { id, data },
});

export const VolleyCreateAction = (data: Volley): VolleyActionTypes => ({
  type: VOLLEY_CREATE,
  payload: data,
});

export const VolleyDeleteAction = (id: Volley["_id"]): VolleyActionTypes => ({
  type: VOLLEY_DELETE,
  payload: id,
});

/**
 * Actions
 */

export const volleyGetAll = (): AppThunk => async (dispatch) => {
  try {
    const t = await VolleyService.getAll();
    dispatch(VolleyGetAllAction(t));
  } catch (error: any) {
    logger.logError(error, logger.warn, "volley.action :: getAll", true);
  }
};

export const volleyGetMine = (): AppThunk => async (dispatch) => {
  try {
    const t = await VolleyService.getMine();
    dispatch(VolleyGetMineAction(t));
  } catch (error: any) {
    logger.logError(error, logger.warn, "volley.action :: getAll", true);
  }
};

export const volleyCreate =
  (tournament_id: Tournament["_id"]): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = await VolleyService.create(tournament_id);
      dispatch(VolleyCreateAction(t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "volley.action :: volleyCreate",
        true
      );
      return false;
    }
  };

export const volleyUpdate =
  (id: Volley["_id"], data: DTOVolleyUpdate): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = await VolleyService.update(id, data);
      dispatch(VolleyUpdateAction(id, t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "volley.action :: volleyUpdate",
        true
      );
      return false;
    }
  };

export const volleyToggleDay =
  (id: Volley["_id"], day: number): AppThunk =>
  async (dispatch) => {
    try {
      const t = await VolleyService.toggleDay(id, day);
      dispatch(VolleyUpdateAction(id, t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "volley.action :: volleyToggleDay",
        true
      );
      return false;
    }
  };

export const volleyDelete =
  (id: Volley["_id"]): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      await VolleyService.remove(id);
      dispatch(VolleyDeleteAction(id));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "volley.action :: volleyDelete",
        true
      );
      return false;
    }
  };
