import { UserRole } from "../common/backend.types";
import { AuthState, ValidatedAuthState } from "../store/auth/auth.types";

export const validateAuth = (
  auth: AuthState,
  roles?: UserRole[]
): auth is ValidatedAuthState => {
  const { user, error } = auth;
  if (error) return false;
  if (!user) return false;
  if (!user.roles) return false;
  if (!user.roles.includes(UserRole.User)) return false;

  if (roles) {
    const roleMatch = roles.reduce(
      (p, c) => user.roles.includes(c) || p,
      false
    );
    if (!roleMatch) return false;
  }

  return true;
};
