import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError } from "../api/api.types";
import UserService from "../api/services/user.service";
import firebase from "../firebase";
import store from "../store";
import { LoadUserAction } from "../store/auth/auth.action";
import logger from "./logger";

export const setAuthToken = (token: String) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = token;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const AxiosToApiErr = (e: AxiosError): ApiError => {
  logger.debug("AxiosToApiErr", "AxiosToApiErr", false, e);
  return e.response && e.response.data
    ? {
        name: e.name,
        message: e.response.data.message
          ? e.response.data.message
          : typeof e.response.data === "string"
          ? e.response.data
          : JSON.stringify(e.response.data),
        status: e.response.status,
        statusText: e.response.statusText,
        errors: [
          e.response.data.error,
          ...(Array.isArray(e.response.data.errors)
            ? e.response.data.errors
            : []),
        ]
          .map((e) => (typeof e === "string" ? e : e ? JSON.stringify(e) : ""))
          .filter((e) => e !== ""),
      }
    : e.response
    ? {
        name: e.name,
        status: e.response.status,
        statusText: e.response.statusText,
        message: e.message,
      }
    : {
        name: e.name,
        status: 500,
        statusText: "Malformed error packet",
        message: e.message,
      };
};

const api = axios.create({
  // baseURL:
  //   window.location.hostname.includes("herokuapp") ||
  //   window.location.hostname.includes("localhost")
  //     ? "/api/"
  //     : "https://ausfirst.herokuapp.com/api/",
  baseURL: "/api/",
  headers: {
    key: window.location.hostname.startsWith("test") ? "erin" : undefined,
  },
});

api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const CONTEXT = `api.instance :: REQ ${config.method}`;
    logger.debug({ url: config.url as string, config }, CONTEXT, false, config);
    return config;
  }
);

api.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse => {
    const CONTEXT = `api.instance :: RES ${res.config.method} /${res.config.url}`;
    logger.debug(res.data, CONTEXT, false, res);
    return res;
  },
  async (err: AxiosError) => {
    const CONTEXT = `api.instance :: RES ${err.config.method} /${err.config.url}`;
    logger.warn(err.message, CONTEXT, false, err);
    const origReq = err.config;
    // If no response, malformed return or cancel; pass through
    if (!err.response) {
      logger.debug("Err has no response", CONTEXT, true, {
        err,
        res: err.response,
      });
      return Promise.reject(AxiosToApiErr(err));
    }
    // If not a 401, refreshing won't help.
    if (err.response.status !== 401) {
      // logger.debug("Not a 401", CONTEXT);
      return Promise.reject(AxiosToApiErr(err));
    }

    // If a 401 on auth, I probably just failed to fetch a token.
    if (origReq.url === "auth") {
      logger.debug("401 on auth; assuming I just tried to refresh", CONTEXT);
      return Promise.reject(AxiosToApiErr(err));
    }
    // If a 401 and I've already tried, don't do it again
    if (origReq.headers.retry === "true")
      return Promise.reject(AxiosToApiErr(err));

    // If a 401, my token might have expired; try refreshing it

    logger.success("Attempting refresh", CONTEXT);
    const u = firebase.auth().currentUser;
    if (!u) {
      logger.warn("No current user", CONTEXT);
      return Promise.reject(AxiosToApiErr(err));
    }
    logger.debug({ u }, CONTEXT + " // currentUser");
    try {
      const token = await u.getIdToken(/*forceRefresh */ true);
      logger.success(token, CONTEXT + " // token");
      setAuthToken(token);
      const api_user = await UserService.getMe();
      logger.success({ api_user }, CONTEXT + " // api_user");
      store.dispatch(LoadUserAction(api_user, token));
      // Set auth token
      origReq.headers["Authorization"] = token;
      // Set retry
      origReq.headers.retry = "true";

      return api(origReq);
    } catch (error: any) {
      logger.error(error, CONTEXT, false, error);
      return Promise.reject(AxiosToApiErr(err));
    }
  }
);

export default api;
