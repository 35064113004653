import { IUser } from "../../api/api.types";

export type UserState = {
  all: IUser[];
  loading: boolean;
};

/** Action definitions */
export const USERS_GET_ALL = "USERS_GET_ALL";
export const USERS_UPDATE = "USERS_UPDATE";
export const USERS_CLEAR = "USERS_CLEAR";

/** Action types */
export interface UsersGetAllActionType {
  type: typeof USERS_GET_ALL;
  payload: IUser[];
}

export interface UsersUpdateActionType {
  type: typeof USERS_UPDATE;
  payload: IUser;
}

export interface UsersClearActionType {
  type: typeof USERS_CLEAR;
  payload: null;
}

/** Final export */
export type UsersActionTypes =
  | UsersGetAllActionType
  | UsersUpdateActionType
  | UsersClearActionType;
