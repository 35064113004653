import {
  UsersActionTypes,
  UserState,
  USERS_CLEAR,
  USERS_GET_ALL,
  USERS_UPDATE,
} from "./user.types";

const initialState: UserState = {
  all: [],
  loading: true,
};

const userReducer = function (
  state: UserState = initialState,
  action: UsersActionTypes
): UserState {
  switch (action.type) {
    case USERS_GET_ALL:
      return {
        ...state,
        all: [...action.payload, ...state.all].filter(
          (x, i, a) => a.findIndex((y) => y._id === x._id) === i
        ),
        loading: false,
      };
    case USERS_UPDATE:
      const p = action.payload;
      return {
        ...state,
        all: state.all.map((t) => (t._id === p._id ? p : t)),
        loading: false,
      };
    case USERS_CLEAR:
      return { ...state, all: [] };
    default:
      return state;
  }
};

export default userReducer;
