import React, { useEffect } from "react";
import { AnalyticsTrack } from "../../../api/analytics";
import { sortByVersion } from "../../../utils/Version";
import releases from "./releases";
import Version from "./VersionChanges";

interface Props {}

const WhatsNewView = (props: Props) => {
  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "What's New",
      },
    });
  }, []);

  return (
    <div id="version-view" className="mb-5">
      {releases
        .sort((a, b) => sortByVersion(a.version, b.version))
        .map((v, i) => (
          <Version src={v} key={i} />
        ))}
    </div>
  );
};

export default WhatsNewView;
