import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../../store/root-reducer";
import { FaHeader } from "../../../atomic/FaTexts";
import { FaButtonExtLink } from "../../../atomic/FaButtons";
import { FIRSTProgram } from "../../../../common/backend.types";
import Colours from "../../../../styles/colours";
import Link from "@material-ui/core/Link";
import firebaseLinks from "../../../../common/firebase.links";
import {
  selectCoachTeams,
  selectCurrentTeamsICoach,
  selectMyTeams,
} from "../../../../store/teams/team.selector";

type LocalProps = {};

const TeamResources = ({ teams, season }: Props) => {
  const classes = useStyles();
  return teams.length === 0 ? (
    <div />
  ) : (
    <div className={classes.teamresources}>
      <FaHeader size="sm">Resources</FaHeader>
      {teams.filter((t) => t.program !== FIRSTProgram.FTC).length ? (
        <div className={classes.programrow}>
          <Link href={firebaseLinks.thinkscape_instructions} target="_blank">
            Thinkscape instructions
          </Link>
        </div>
      ) : (
        <div />
      )}
      {teams.filter((t) => t.program === FIRSTProgram.FLL_DISCOVER).length ? (
        <div className={[classes.programrow, classes.discoverrow].join(" ")}>
          <span>Discover:</span>
          <Link
            href={(firebaseLinks.en_discover as any)[season] ?? ""}
            target="_blank"
          >
            Engineering Notebook
          </Link>
          <Link
            href={(firebaseLinks.tmg_discover as any)[season] ?? ""}
            target="_blank"
          >
            Team Meeting Guide
          </Link>
        </div>
      ) : (
        <div />
      )}
      {teams.filter((t) => t.program === FIRSTProgram.FLL_EXPLORE).length ? (
        <div className={[classes.programrow, classes.explorerow].join(" ")}>
          <span>Explore:</span>
          <Link
            href="https://www.firstinspires.org/resource-library/fll/explore/challenge-and-resources"
            target="_blank"
          >
            Challenge information
          </Link>
          <Link
            href={(firebaseLinks.en_explore as any)[season] ?? ""}
            target="_blank"
          >
            Engineering Notebook
          </Link>
          <Link
            href={(firebaseLinks.tmg_explore as any)[season] ?? ""}
            target="_blank"
          >
            Team Meeting Guide
          </Link>
        </div>
      ) : (
        <div />
      )}
      {teams.filter((t) => t.program === FIRSTProgram.FLL_CHALLENGE).length ? (
        <div className={[classes.programrow, classes.challengerow].join(" ")}>
          <span>Challenge:</span>
          <Link
            href="https://www.firstinspires.org/resource-library/fll/challenge/challenge-and-resources"
            target="_blank"
          >
            Challenge information
          </Link>
          <Link
            href={(firebaseLinks.en_challenge as any)[season] ?? ""}
            target="_blank"
          >
            Engineering Notebook
          </Link>
          <Link
            href={(firebaseLinks.tmg_challenge as any)[season] ?? ""}
            target="_blank"
          >
            Team Meeting Guide
          </Link>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    teamresources: {},
    programrow: {
      display: "flex",
      alignItems: "center",
      marginBottom: 8,
      paddingLeft: 8,
      flexWrap: "wrap",
      "& span": {
        marginRight: 16,
        minWidth: 80,
      },
      "& a": {
        marginRight: 16,
      },
    },
    challengerow: {
      borderLeft: "solid 4px " + Colours.challenge,
    },
    explorerow: {
      borderLeft: "solid 4px " + Colours.explore,
    },
    discoverrow: {
      borderLeft: "solid 4px " + Colours.discover,
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  teams: selectCurrentTeamsICoach(state),
  season: state.settings.season,
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(TeamResources);
