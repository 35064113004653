import { IEvent } from "../../api/api.types";
import { FIRSTProgram } from "../../common/backend.types";

/** Basic types */

export type Event = IEvent & {};
export type EventUpdateData = {
  title?: string;
  date?: Date;
  program?: FIRSTProgram;
};

export type AdminState = {
  events: Event[];
  loading: boolean;
};

/** Action definitions */
export const EVENT_GET_ALL = "EVENT_GET_ALL";
export const EVENT_CREATE = "EVENT_CREATE";
export const EVENT_UPDATE = "EVENT_UPDATE";
export const EVENT_DELETE = "EVENT_DELETE";
export const EVENT_CLEAR = "EVENT_CLEAR";

/** Action types */
export interface EventGetAllActionType {
  type: typeof EVENT_GET_ALL;
  payload: Event[];
}

export interface EventUpdateActionType {
  type: typeof EVENT_UPDATE;
  payload: Event;
}

export interface EventCreateActionType {
  type: typeof EVENT_CREATE;
  payload: Event;
}

export interface EventDeleteActionType {
  type: typeof EVENT_DELETE;
  payload: Event["_id"];
}

export interface EventClearActionType {
  type: typeof EVENT_CLEAR;
  payload: null;
}

/** Final export */
export type EventActionTypes =
  | EventGetAllActionType
  | EventCreateActionType
  | EventUpdateActionType
  | EventDeleteActionType
  | EventClearActionType;

export type AdminActionTypes = EventActionTypes;
