import React, { FormEvent } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { shipmentUpdate } from "../../../store/shipping/shipment.action";
import { Shipment } from "../../../store/shipping/shipment.types";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import SubmitButton from "../../atomic/SubmitButton";

interface LocalProps {
  shipment: Shipment;
}

const ShipmentEdit = ({ shipment, shipmentUpdate }: Props) => {
  const [con, setCon] = useState<string>("");
  const [newComment, setNewComment] = useState<string>("");

  const deleteComments = () => {
    shipmentUpdate(shipment._id, { comment: "" });
  };

  const updateShipment = (
    e: FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    e.preventDefault();

    shipmentUpdate(shipment._id, {
      consignment_number: con,
      comment: shipment.comment + ";" + newComment,
    });
    setNewComment("");
  };
  useEffect(() => {
    setCon(shipment.consignment_number);
  }, [shipment.consignment_number]);

  return (
    <form className="d-flex mb-1" onSubmit={updateShipment}>
      <div className="d-flex-col">
        Consignment:
        <input
          autoComplete="off"
          value={con}
          onChange={(e) => setCon(e.target.value)}
        />
      </div>
      <div className="d-flex-col mx-1">
        <div className="d-flex">
          <span>Comments:</span>
          <span onClick={deleteComments} className="text-danger c-pointer ml-1">
            <Icon icon="trash-alt" />
          </span>
        </div>
        {shipment.comment.split(";").map((c, i) => (
          <span key={i}>{c}</span>
        ))}
        <input
          autoComplete="off"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </div>
      <SubmitButton value="Save" />
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { shipmentUpdate });
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(ShipmentEdit);
