import {
  Compete,
  CompeteActionTypes,
  CompeteState,
  COMPETE_CREATE,
  COMPETE_DELETE,
  COMPETE_GET_ALL,
  COMPETE_UPDATE,
} from "./compete.types";

const initialState: CompeteState = {
  all: [],
  loading: true,
};

const competeReducer = function (
  state: CompeteState = initialState,
  action: CompeteActionTypes
): CompeteState {
  switch (action.type) {
    case COMPETE_GET_ALL:
      return {
        ...state,
        all: action.payload.filter((x, i, a) => a.indexOf(x) === i),
        loading: false,
      };
    case COMPETE_CREATE:
      return {
        ...state,
        all: [...state.all, ...action.payload].filter(
          (x, i, a) => a.indexOf(x) === i
        ),
        loading: false,
      };
    case COMPETE_UPDATE:
      const { id, data } = action.payload;
      return {
        ...state,
        all: state.all.map((t) => (t._id === id ? { ...t, ...data } : t)),
        loading: false,
      };
    case COMPETE_DELETE:
      return {
        ...state,
        all: state.all.filter((t) => t._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};

export default competeReducer;
