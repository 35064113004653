import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/root-reducer";
import { IEvent } from "../../../api/api.types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import { FaIconButton } from "../../atomic/FaButtons";
import {
  FIRSTProgram,
  Programs,
  ProgramToLabel,
} from "../../../common/backend.types";
import { eventCreate, eventUpdate } from "../../../store/admin/admin.action";
import { DTOEventCreate, DTOEventUpdate } from "../../../api/dtos/admin.dto";
import TextField from "@material-ui/core/TextField";
import e from "express";
import Selector from "../../atomic/Selector";
import moment from "moment";
import logger from "../../../utils/logger";
import { SingleSelector } from "../../atomic/FaSelectors";

type LocalProps = { event?: IEvent };

const PublicEventRow = ({ event, eventCreate, eventUpdate }: Props) => {
  const [data, setData] = useState<Partial<IEvent>>(
    event ?? {
      title: "",
      date: undefined,
      program: undefined,
    }
  );

  const dataIsValid = (x: Partial<IEvent>): x is DTOEventCreate =>
    x.date !== undefined && x.title !== undefined;

  const handleUpdate = async () => {
    if (!dataIsValid(data)) return;
    if (event) {
      await eventUpdate(event._id, data);
      logger.success("Event updated!", "PublicEventRow", true);
    } else {
      await eventCreate(data);
      logger.success("Event created!", "PublicEventRow", true);
    }
  };

  const classes = useStyles();
  return (
    <TableRow
      className={[
        classes.publiceventrow,
        event === undefined ? classes.divider : "",
      ].join(" ")}
    >
      <TableCell>
        {event ? (
          <FaIconButton
            onClick={handleUpdate}
            variant="contained"
            rounded
            color="primary"
          >
            <SaveIcon />
          </FaIconButton>
        ) : (
          <FaIconButton
            onClick={handleUpdate}
            variant="contained"
            rounded
            color="success"
          >
            <AddIcon />
          </FaIconButton>
        )}
      </TableCell>
      <TableCell>
        <TextField
          id="date"
          // label="Date"
          type="date"
          value={data.date ? moment(data.date).format("yyyy-MM-DD") : undefined}
          onChange={(e) =>
            setData({
              ...data,
              date: new Date(e.target.value),
            })
          }
        />
      </TableCell>
      <TableCell>
        <TextField
          value={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />
      </TableCell>
      <TableCell>
        <SingleSelector
          options={Programs.map((p) => ({
            value: p,
            label: ProgramToLabel(p),
          }))}
          value={data.program}
          clearable
          onSelect={(p) => setData({ ...data, program: p })}
        />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    publiceventrow: {},
    divider: {
      borderBottom: "solid 2px black",
    },
  })
);

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { eventCreate, eventUpdate });

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(PublicEventRow);
