import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { Address } from "../../../store/address/address.types";
import { paymentDelete } from "../../../store/payment/payment.action";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import UserName from "../../atomic/UserName";

interface LocalProps {
  users: IUser[];
  payments: Payment[];
  onEdit: (x: Payment) => void;
  filter?: string;
}

const Overrides = ({ payments, users, onEdit, filter }: Props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Contact</th>
          <th>Amount</th>
          <th>Override comment</th>
          <th>Other comments</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody className="striped">
        {payments
          .filter((p) => p.admin_comment)
          .filter(
            (p) =>
              !filter ||
              users
                .find((u) => u._id === p.payer)
                ?.given_name?.toLowerCase()
                .includes(filter) ||
              users
                .find((u) => u._id === p.payer)
                ?.family_name?.toLowerCase()
                .includes(filter) ||
              p.reference.toString().includes(filter)
          )

          .map((p) => (
            <tr key={p._id}>
              <td>{p.reference}</td>
              <td>
                <UserName user={users.find((u) => u._id === p.payer)} />
              </td>
              <td>${p.amount.toFixed(2)}</td>
              <td>
                {p.admin_comment ? (
                  p.admin_comment
                ) : (
                  <span className="text-warn">Unpaid</span>
                )}
              </td>
              <td>{p.comment}</td>
              <td>
                <Button
                  onClick={() => onEdit(p)}
                  variant="clear"
                  className="text-success"
                >
                  <Icon icon="edit" />
                </Button>
              </td>
              <td>
                {p.payment_id ? (
                  <div></div>
                ) : (
                  <Button
                    onClick={() => paymentDelete(p._id)}
                    variant="clear"
                    className="text-danger"
                  >
                    <Icon icon="trash-alt" />
                  </Button>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { paymentDelete });
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(Overrides);
