import React, { useEffect } from "react";
import Moment from "react-moment";
import { connect, ConnectedProps } from "react-redux";
import { FIRSTProgram } from "../../common/backend.types";
import { RootState } from "../../store/root-reducer";
import { tournamentGetAll } from "../../store/tournament/tournament.action";
import { volleyCreate, volleyGetMine } from "../../store/volley/volley.action";
import Icon from "../atomic/Icon";
import ProgramLogo from "../atomic/ProgramLogo";
import Spinner from "../atomic/Spinner";
import TournamentVolleyRegoRow from "./TournamentVolleyRegoRow";

type LocalProps = {};

const RegisterVolleyForm = ({
  tournaments,
  volleys,
  loading,
  tournamentGetAll,
  volleyGetMine,
  volleyCreate,
}: Props) => {
  useEffect(() => {
    tournamentGetAll();
    volleyGetMine();
  }, []);

  return loading ? (
    <Spinner variant="volunteer" />
  ) : (
    <div id="register-form">
      <h1>Volunteer for a tournament</h1>
      <table className="table table-primary mt-1">
        <thead>
          <tr>
            <th>Name</th>
            <th>Program</th>
            <th>Start date</th>
            <th>Location</th>
            <th>Register?</th>
          </tr>
        </thead>
        <tbody className="striped">
          {tournaments
            .filter(
              (t) =>
                t.start.getTime() + t.n_days * 24 * 60 * 60 * 1000 >=
                new Date().getTime()
            )
            .sort((a, b) => a.start.getTime() - b.start.getTime())
            .map((tourn) => (
              <tr key={tourn._id}>
                <td className="d-flex-col flex-left">
                  <div className="d-flex j-space-between w-100">
                    {tourn.name}
                  </div>
                  {/* <small>{tourn.type}</small> */}
                </td>
                <td>
                  <ProgramLogo program={tourn.program} />
                </td>
                <td>
                  <Moment format="LL">{tourn.start}</Moment>
                  {tourn.n_days > 1 ? `(${tourn.n_days} days)` : ""}
                </td>
                <td>
                  {tourn.remote ? (
                    <span className="d-flex place-content-center">
                      Remote
                      <Icon icon="laptop-house" className="mx-1" />
                    </span>
                  ) : (
                    <div className="d-flex-col">
                      <span>{tourn.company}</span>
                      <span>{tourn.street1}</span>
                      <span>{tourn.street2}</span>
                      <span>{tourn.street3}</span>
                      <span>{tourn.suburb}</span>
                      <span>
                        {tourn.state} {tourn.postcode}
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  <TournamentVolleyRegoRow
                    tournament={tourn}
                    volley={volleys.find((v) => v.tournament_id === tourn._id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tournaments: state.tournament.all,
  volleys: state.volley.mine,
  loading: state.tournament.loading || state.volley.loading,
});

const connector = connect(mapStateToProps, {
  volleyCreate,
  tournamentGetAll,
  volleyGetMine,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(RegisterVolleyForm);
