import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AlertPayload } from "../../store/alert/alert.types";
import { RootState } from "../../store/root-reducer";

const AlertContainer = ({ alert }: Props) => {
  return (
    <div className="group-alert">
      <div>
        {alert.map((A: AlertPayload) => (
          <div role="alert" className={`alert alert-${A.alertType}`} key={A.id}>
            {A.msg}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  alert: state.alert,
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & {};

export default connector(AlertContainer);
