import {
  FIRSTProgram,
  FIRSTSeason,
  ShipmentContent,
} from "../common/backend.types";
import { Shipment } from "../store/shipping/shipment.types";
import { Team } from "../store/teams/team.types";
import { Tournament } from "../store/tournament/tournament.types";
import logger from "../utils/logger";

type TeamItem = {
  program: FIRSTProgram;
  post_registered?: boolean;
  ship_kit?: boolean;
  season: FIRSTSeason;
};

/**
 *  Given a list of teams, return an Inventory of items to ship
 *      This function doesn't group by address ID, so pre-filter the teams.
 */
export const getInventory = (t: TeamItem[]): ShipmentContent[] => {
  return compressManifest(
    t.map((i) => ({
      program: i.program,
      season: i.season,
      count: i.ship_kit ? 1 : 0,
    }))
  ).filter((i) => i.count);
};
/**
 *  Given a list of tournaments, return an Inventory of items to ship
 *      This function doesn't group by address ID, so pre-filter the tournaments.
 */
export const getInventoryTourn = (t: Tournament[]): ShipmentContent[] => {
  return compressManifest(
    t.map((i) => ({
      program: i.program,
      season: i.season,
      count: i.ship_kits,
    }))
  ).filter((i) => i.count);
};

/** Given a list of shipments, return an Inventory of things which have shipped */
export const getInventoryS = (t: Shipment[]): ShipmentContent[] => {
  return compressManifest(
    t.map((i) => i.contents).reduce((p, c) => [...c, ...p], [])
  );
};

/** Given a manifest, combine all items of the same content */
export const compressManifest = (s: ShipmentContent[]): ShipmentContent[] => {
  const combos = s
    .map((i) => ({ season: i.season, program: i.program }))
    .filter(
      (x, i, a) =>
        a.findIndex((I) => I.season === x.season && I.program === x.program) ===
        i
    );
  const r = combos.map((c) => ({
    ...c,
    count: s
      .filter((i) => i.season === c.season && i.program === c.program)
      .reduce((p, c) => p + c.count, 0),
  }));
  return r;
};

/**
 * Given two manifests, return those in A but not B
 * @param A Manifest (List of shipment contents)
 * @param B Manifest (List of shipment contents)
 */
export const diffManifest = (A: ShipmentContent[], B: ShipmentContent[]) => {
  return A.map((a) => ({
    ...a,
    count: Math.max(
      a.count -
        (B.find((b) => b.season === a.season && b.program === a.program)
          ?.count ?? 0),
      0
    ),
  })).filter((a) => a.count);
};

/**
 * Return all unshipped kits
 * @param A List of teams
 * @param B List of shipments
 */
export const diffShipped = (A: Team[], B: Shipment[]) => {
  return diffManifest(getInventory(A), getInventoryS(B));
};

/**
 * Return all unshipped tournament kits
 * @param A List of tournaments
 * @param B List of shipments
 */
export const diffShippedTourn = (A: Tournament[], B: Shipment[]) => {
  return diffManifest(getInventoryTourn(A), getInventoryS(B));
};

/**
 * Return all unshipped tournament kits
 * @param A List of tournaments
 * @param B List of shipments
 */
export const diffShippedBoth = (A: Tournament[], B: Team[], C: Shipment[]) => {
  // console.log("diffShippedBoth: ");
  // console.log({
  //   a: getInventoryTourn(A),
  //   b: getInventory(B),
  //   c: getInventoryS(C),
  //   diff: diffManifest(
  //     compressManifest([...getInventoryTourn(A), ...getInventory(B)]),
  //     getInventoryS(C)
  //   ),
  // });
  return diffManifest(
    compressManifest([...getInventoryTourn(A), ...getInventory(B)]),
    getInventoryS(C)
  );
};
