import {
  AddressActionTypes,
  AddressState,
  ADDRESS_CLEAR,
  ADDRESS_CREATE,
  ADDRESS_DELETE,
  ADDRESS_GET_ALL,
  ADDRESS_GET_MINE,
  ADDRESS_UPDATE,
} from "./address.types";

const initialState: AddressState = {
  mine: [],
  all: [],
  loading: true,
};

const addressReducer = function (
  state: AddressState = initialState,
  action: AddressActionTypes
): AddressState {
  switch (action.type) {
    case ADDRESS_GET_MINE:
      return { ...state, mine: action.payload, loading: false };
    case ADDRESS_GET_ALL:
      return { ...state, all: action.payload, loading: false };
    case ADDRESS_CREATE:
      return {
        ...state,
        mine: [...state.mine, action.payload],
        all: [...state.all, action.payload],
        loading: false,
      };
    case ADDRESS_UPDATE:
      const p = action.payload;
      return {
        ...state,
        mine: state.mine.map((t) => (t._id === p._id ? p : t)),
        all: state.all.map((t) => (t._id === p._id ? p : t)),
        loading: false,
      };
    case ADDRESS_DELETE:
      return {
        ...state,
        mine: state.mine.filter((t) => t._id !== action.payload),
        all: state.all.filter((t) => t._id !== action.payload),
        loading: false,
      };
    case ADDRESS_CLEAR:
      return { ...state, mine: [], all: [] };
    default:
      return state;
  }
};

export default addressReducer;
