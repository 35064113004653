class Version {
  release: number;
  major: number;
  minor: number;
  modifier?: string;

  constructor(
    release: number,
    major: number,
    minor: number,
    modifier?: string
  ) {
    this.release = release;
    this.major = major;
    this.minor = minor;
    this.modifier = modifier;
  }

  public toString = (): string => {
    return `v${this.release}.${this.major}.${this.minor}${
      this.modifier ? this.modifier : ""
    }`;
  };
}

export const sortByVersion = (a: Version, b: Version) => {
  if (a.release !== b.release) return b.release - a.release;
  if (a.major !== b.major) return b.major - a.major;
  if (a.minor !== b.minor) return b.minor - a.minor;
  if (a.modifier && b.modifier) return b.modifier.localeCompare(a.modifier);
  return 0;
};

export default Version;
