import firebase from "firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("firstaustralia-dev") ||
  window.location.hostname.includes("first-australia-staging")
    ? {
        apiKey: "AIzaSyBuhaRUL2HNA0TAQu3KuGxM7V6LJWlNJ9g",
        authDomain: "firstaustralia-dev.firebaseapp.com",
        projectId: "firstaustralia-dev",
        storageBucket: "firstaustralia-dev.appspot.com",
        messagingSenderId: "311231010090",
        appId: "1:311231010090:web:2ceb9b457f0ba990c2ba53",
        measurementId: "G-NHX040QJFB",
      }
    : {
        apiKey: "AIzaSyDhqrsZ4oAmq3IqMzH4e-AlhEOhMs9L2zM",
        authDomain: "firstaustralia-system.firebaseapp.com",
        projectId: "firstaustralia-system",
        storageBucket: "firstaustralia-system.appspot.com",
        messagingSenderId: "483439762889",
        appId: "1:483439762889:web:5477aed6ab5f0d78f25c18",
        measurementId: "G-B83HM7F524",
      };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const passwordProvider = new firebase.auth.EmailAuthProvider();
export const microsoftProvider = new firebase.auth.OAuthProvider(
  "microsoft.com"
);

export const auth = firebase.auth();
export default firebase;
