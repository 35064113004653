import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnalyticsTrack } from "../../../api/analytics";
import { Seasons } from "../../../common/backend.types";
import { addressGetMine } from "../../../store/address/address.action";
import { Address } from "../../../store/address/address.types";
import {
  getMyCosts,
  getMyPayments,
  getPayLink,
} from "../../../store/auth/auth.action";
import { paymentGetMine } from "../../../store/payment/payment.action";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetMine } from "../../../store/shipping/shipment.action";
import { teamGetMine } from "../../../store/teams/team.action";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import api from "../../../utils/api.instance";
import logger from "../../../utils/logger";
import ButtonLink from "../../atomic/ButtonLink";
import FinanceBreakdown from "../../reports/FinanceBreakdown";

const Finance = ({
  user,
  allTeams,
  credit,
  addresses,
  payments,
  season,
  teamGetMine,
  addressGetMine,
  getMyCosts,
  getMyPayments,
  paymentGetMine,
  getPayLink,
  shipmentGetMine,
}: Props) => {
  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "My Finance",
      },
    });
  }, []);

  useEffect(() => {
    teamGetMine();
    addressGetMine();
    shipmentGetMine();
    paymentGetMine();
  }, [teamGetMine, addressGetMine, paymentGetMine, shipmentGetMine]);

  const Label = (a: Address | undefined) =>
    a === undefined ? (
      <span></span>
    ) : (
      <div className="d-flex-col ta-center">
        <span>{a.company}</span>
        <span>{a.street1}</span>
        <span>{a.street2}</span>
        <span>{a.street3}</span>
        <span>
          {a.suburb} {a.postcode}
        </span>
        <span>
          {a.state} {a.country}
        </span>
      </div>
    );

  const devRetryPayment = (p: Payment) => {
    api.get("payments/xetta/", {
      params: {
        msg: "Approved",
        Rego_id: p.reference,
        AmountPaid: p.amount,
        Company: addresses.find((a) => a._id === p.address)?.company,
        AuthResult: "38r29mi9093rm0",
        Receipt: "FakeDevReceiptNumber",
        success: true,
        Hash: "FakeDevReceiptNumber",
      },
    });
    setTimeout(() => {
      getMyPayments();
    }, 1000);
  };

  return !user ? (
    <div>Something went wrong!</div>
  ) : (
    <div id="finance-view">
      <h1>My Finance</h1>
      <div className="d-flex">
        <div className="p-1">
          Total paid: <strong>${user.down.toFixed(2)}</strong>
        </div>
        <div className="p-1">
          Costs (other seasons, excl shipping & GST):{" "}
          <strong>${user.offseasonCostExclGst.toFixed(2)}</strong>
        </div>
        <div className="p-1">
          Costs (this season):{" "}
          <strong>${Math.max(user.currentSeasonExclGst, 0).toFixed(2)}</strong>
        </div>
        <div className="p-1">
          {user.down > user.up && (
            <ButtonLink variant="primary" to="/pay-now">
              Pay now!
            </ButtonLink>
          )}
        </div>
      </div>
      <FinanceBreakdown user={user} isMine />
    </div>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState) => ({
    user: selectUser(state, { userId: "me" }),
    allTeams: state.team.mine,
    addresses: state.address.mine,
    payments: state.payment.mine,
    season: state.settings.season,
    credit: state.auth.user?.credit ?? 0,
  });
};
const connector = connect(makeMapStateToProps, {
  teamGetMine,
  addressGetMine,
  getMyCosts,
  getMyPayments,
  paymentGetMine,
  getPayLink,
  shipmentGetMine,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(Finance);
