import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/root-reducer";
import { useLocation, useParams } from "react-router";
import { useEffect } from "react";
import { Game, Games } from "./games/types";
import Spinner from "../../atomic/Spinner";
import ScoreCalculator from "./forms/ScoreCalculator";

type LocalProps = {};

type Location = { game: string };

type State = {
  game?: Game;
  loading: boolean;
};

const Calculator = (props: Props) => {
  const params = useParams<Location>();
  const [state, setState] = useState<State>({
    game: undefined,
    loading: false,
  });

  const sanitise = (s: string): string => s.toLowerCase().replace(" ", "-");

  useEffect(() => {
    const g = Games.find((gme) => sanitise(gme.name) === sanitise(params.game));

    setState({
      loading: false,
      game: g,
    });
  }, [params]);

  // console.log(params);
  const classes = useStyles();
  return state.loading ? (
    <Spinner variant="challenge" />
  ) : (
    <div className={classes.calculator}>
      {!state.game ? (
        <span>Game not found</span>
      ) : (
        <ScoreCalculator game={state.game} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    calculator: {},
  })
);

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(Calculator);
