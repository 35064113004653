import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/root-reducer";
import Dropdown from "../../atomic/Dropdown";
import { useParams } from "react-router";
import { Tournament } from "../../../store/tournament/tournament.types";
import Spinner from "../../atomic/Spinner";
import {
  scoresheetCreate,
  scoresheetGetAll,
} from "../../../store/scoring/scoring.action";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import { Team, TeamPublic } from "../../../store/teams/team.types";
import Selector from "../../atomic/Selector";
import TextField from "@material-ui/core/TextField";
import { FaHeader, FaText } from "../../atomic/FaTexts";
import ScoreCalculator from "./forms/ScoreCalculator";
import { Games } from "./games/types";
import FaModal from "../../atomic/FaModal";
import CreateScoresheet from "./CreateScoresheet";
import { FaButton, FaIconButton } from "../../atomic/FaButtons";
import CreateIcon from "@material-ui/icons/Add";
import { userGetTourn } from "../../../store/users/user.action";
import ScoresheetHistory from "./ScoresheetHistory";
import Dialog from "@material-ui/core/Dialog";
import RefreshIcon from "@material-ui/icons/Sync";
import { competeGetAll } from "../../../store/compete/compete.action";
import { teamGetByTournament } from "../../../store/teams/team.action";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import moment from "moment";

type LocalProps = {
  route_tournament?: Tournament;
};

const RefereeView = ({
  scoresheets,
  teams,
  competes,
  loading,
  route_tournament,
  scoresheetCreate,
  scoresheetGetAll,
  competeGetAll,
  userGetTourn,
  teamGetByTournament,
}: Props) => {
  useEffect(() => {
    competeGetAll();
    // tournamentGetAll();
    // console.log(route_tournament);

    if (!route_tournament) return;
    teamGetByTournament(route_tournament._id);
    scoresheetGetAll(route_tournament._id);
    userGetTourn(route_tournament._id);
  }, [route_tournament?._id]);

  const [team, setTeam] = useState<boolean>(false);
  const [round, setRound] = useState<number>(1);

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());

  useEffect(() => {
    setLastUpdate(new Date());
    setRefreshing(false);
  }, [scoresheets]);

  /** debounce */
  const handleRefresh = () => {
    setRefreshing(true);
    if (route_tournament) scoresheetGetAll(route_tournament._id);
    competeGetAll();

    // setTimeout(() => setRefreshing(false), 10000);
  };

  const [open, setOpen] = useState<
    | {
        team?: TeamPublic;
        round: 1 | 2 | 3;
      }
    | undefined
  >();

  const classes = useStyles();
  return loading ? (
    <Spinner variant="challenge" />
  ) : (
    <div className={classes.refereeview}>
      {!route_tournament ? (
        <FaText>Tournament not found</FaText>
      ) : (
        <Fragment>
          {/* @todo rankings */}
          {/* @todo fix modal */}
          <FaHeader size="md">
            {route_tournament.name}{" "}
            <FaIconButton
              onClick={handleRefresh}
              variant="contained"
              rounded
              className={classes.refreshBtn}
              color={refreshing ? "default" : "success"}
              disabled={refreshing}
            >
              <RefreshIcon />
            </FaIconButton>
          </FaHeader>
          <small>Last updated {moment(lastUpdate).format("HH:mm:ss")}</small>
          <FaHeader size="sm">Scoresheets</FaHeader>
          <ScoresheetHistory
            competes={competes
              .filter((c) => c.tournament_id === route_tournament._id)
              .sort(
                (a, b) =>
                  (teams.find((t) => t._id === a.team_id)?.team_number ?? 0) -
                  (teams.find((t) => t._id === b.team_id)?.team_number ?? 0)
              )}
            scoresheets={scoresheets}
            game={Games.find((g) => g.season === route_tournament.season)}
            onClick={(round, team) => setOpen({ team, round })}
          />
          <Dialog
            open={!!open}
            onClose={() => {
              if (
                !team ||
                window.confirm(
                  "Are you sure? Any current changes will not be saved"
                )
              )
                setOpen(undefined);
            }}
            PaperProps={{ className: classes.dialog }}
          >
            {/* <div className={classes.dialog}> */}
            {!open ? (
              <div />
            ) : (
              <CreateScoresheet
                onTeamSet={setTeam}
                tournament={route_tournament}
                teams={teams.filter((t) =>
                  competes
                    .filter((c) => c.tournament_id === route_tournament._id)
                    .map((c) => c.team_id)
                    .includes(t._id)
                )}
                initTeam={open?.team}
                initRound={open?.round}
                scoresheets={scoresheets}
                competes={competes.filter(
                  (c) => c.tournament_id === route_tournament._id
                )}
              />
            )}
            {/* </div> */}
          </Dialog>
        </Fragment>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    refereeview: {},
    topbar: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    teamselector: { width: 200 },
    body: {
      display: "flex",
      flexDirection: "column",
    },
    dialog: {
      padding: 16,
      minWidth: "80%",
    },
    refreshBtn: {
      height: 32,
      width: 32,
      marginLeft: theme.spacing(1),
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  scoresheets: state.scoring.all,
  teams: [...state.team.all, ...state.team.tournament].filter(
    (x, i, a) => a.findIndex((y) => y._id === x._id) === i
  ),
  tournaments: state.tournament.all,
  competes: state.compete.all,
  users: state.users.all,
  loading:
    state.team.loading || state.tournament.loading || state.compete.loading,
});

const connector = connect(mapStateToProps, {
  scoresheetCreate,
  scoresheetGetAll,
  userGetTourn,
  competeGetAll,
  teamGetByTournament,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(RefereeView);
