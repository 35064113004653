import React, { Fragment, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import {
  loginWithPassword,
  registerPassword,
} from "../../store/auth/auth.action";
import UserService from "../../api/services/user.service";
import logger from "../../utils/logger";
import Spinner from "../atomic/Spinner";
import { ThemeColour } from "../../common/theme.types";
import background from "../../img/background3.jpg";
import GoogleSigninButton from "../atomic/GoogleSigninButton";
import SubmitButton from "../atomic/SubmitButton";
import { validateAuth } from "../../utils/auth.validate";
import { Redirect } from "react-router";
import Button from "../atomic/Button";
import firebase from "firebase";

type Fetch = { loading: boolean; result: boolean; google: boolean };
type Passwords = { pw: string; rpt?: string };
const Landing = ({ loginWithPassword, registerPassword, auth }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [userExists, setUserExists] = useState<Fetch>();
  const [pw, setPw] = useState<Passwords>({ pw: "", rpt: "" });
  const [loading, setLoading] = useState<boolean>(false);

  const [redir, setRedir] = useState<boolean>(false);

  useEffect(() => {
    setRedir(validateAuth(auth));
  }, [auth, setRedir]);

  useEffect(() => {
    setLoading(false);
  }, [auth, setLoading]);

  const sendResetEmail = () => {
    var auth = firebase.auth();
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        logger.success("Reset email sent to: " + email, "sendResetEmail", true);
      })
      .catch(function (error) {
        // An error happened.
        logger.warn(
          "Failed to send email: " + error.message,
          "sendResetEmail",
          true,
          { error }
        );
      });
  };

  const submitEmail = async (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (!email) return;
    try {
      setUserExists({ loading: true, result: false, google: false });
      const result = await UserService.checkEmail(email);
      if (result && result.length > 0 && result[0].providers)
        setUserExists({
          loading: false,
          result: result[0].providers.includes("password"),
          google: result[0].providers.includes("google.com"),
        });
      else setUserExists({ loading: false, result: false, google: false });
      setPw({ pw: "", rpt: result ? "" : undefined });
    } catch (error: any) {
      logger.logError(error, logger.info, "Landing :: submitEmail", true);
      setUserExists({ loading: false, result: false, google: false });
    }
  };

  const onLogin = (e: React.MouseEvent<any>): void => {
    e.preventDefault();
    if (!pw) return;
    loginWithPassword(email, pw.pw);
    setLoading(true);
  };

  const onRegister = (e: React.MouseEvent<any>): void => {
    e.preventDefault();
    if (!pw || !pw.rpt) return;
    if (pw.pw !== pw.rpt)
      return logger.warn(
        "Passwords do not match",
        "Landing :: onRegister",
        true
      );
    registerPassword(email, pw.pw);
    setLoading(true);
  };

  return redir ? (
    <Redirect to="/" />
  ) : (
    <div id="landing-view">
      <div className="background">
        <img src={background} alt="FIRST LEGO League fun pic" />
      </div>
      <div className="" id="landing-login">
        {loading ? (
          <Spinner variant={"primary"} />
        ) : (
          <Fragment>
            <form onSubmit={submitEmail} className="inline-form">
              <label htmlFor="login-email">Email: </label>
              {userExists === undefined ? (
                <input
                  type="text"
                  name="email"
                  id="login-email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <span>
                  {email}
                  <strong
                    className="ml-1 c-pointer"
                    onClick={() => {
                      setUserExists(undefined);
                      setPw({ pw: "", rpt: "" });
                    }}
                  >
                    Change
                  </strong>
                </span>
              )}
            </form>
            {!userExists ? (
              <Button variant={"success"} onClick={submitEmail}>
                Next
              </Button>
            ) : userExists.loading ? (
              <Spinner variant={"secondary"} />
            ) : userExists.google ? (
              <span className="py-1">
                User has a registered Google sign-on. Please use the button
                below to sign in.
              </span>
            ) : userExists.result ? (
              <div className="d-flex-col">
                {/* <h5>Log in</h5> */}
                <form onSubmit={onLogin} className="form">
                  <label htmlFor="login-password">Password: </label>
                  <input
                    required
                    type="password"
                    name="password"
                    id="login-password"
                    onChange={(e) => {
                      e.persist();
                      setPw((p) => ({ ...p, pw: e.target.value }));
                    }}
                  />
                  <br />
                  <span className="action-text" onClick={sendResetEmail}>
                    Forgot your password?
                  </span>
                  <br />
                  <SubmitButton variant={"success"} value="Login" />
                </form>
              </div>
            ) : (
              <div className="d-flex-col">
                {/* <h5>Register</h5> */}
                <form onSubmit={onRegister} className="form">
                  <label htmlFor="register-password">Password: </label>
                  <input
                    required
                    type="password"
                    name="password"
                    id="register-password"
                    onChange={(e) => {
                      e.persist();
                      setPw((p) => ({ ...p, pw: e.target.value }));
                    }}
                  />
                  <label htmlFor="register-password2">Confirm password: </label>
                  <input
                    required
                    type="password"
                    name="password2"
                    id="register-password2"
                    onChange={(e) => {
                      e.persist();
                      setPw({ pw: pw.pw, rpt: e.target.value });
                    }}
                  />
                  <SubmitButton variant={"success"} value="Register" />
                </form>
              </div>
            )}

            <hr />
            <GoogleSigninButton />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {
  loginWithPassword,
  registerPassword,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(Landing);
