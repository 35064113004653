import { IRubricAnswer } from "../../api/api.types";

/** Basic types */

export type RubricAnswer = IRubricAnswer & {};

export type RubricState = {
  all: RubricAnswer[];
  mine: RubricAnswer[];
  loading: boolean;
};

/** Action definitions */
export const RUBRIC_GET_ALL = "RUBRIC_GET_ALL";
export const RUBRIC_GET_MINE = "RUBRIC_GET_MINE";
export const RUBRIC_CREATE = "RUBRIC_CREATE";
export const RUBRICS_CREATE = "RUBRICS_CREATE";
export const RUBRIC_UPDATE = "RUBRIC_UPDATE";
export const RUBRIC_DELETE = "RUBRIC_DELETE";

/** Action types */
export interface RubricAnswerGetAllActionType {
  type: typeof RUBRIC_GET_ALL;
  payload: RubricAnswer[];
}
export interface RubricAnswerGetMineActionType {
  type: typeof RUBRIC_GET_MINE;
  payload: RubricAnswer[];
}

export interface RubricAnswerUpdateActionType {
  type: typeof RUBRIC_UPDATE;
  payload: {
    id: RubricAnswer["_id"];
    data: RubricAnswer;
  };
}

export interface RubricAnswerCreateActionType {
  type: typeof RUBRIC_CREATE;
  payload: RubricAnswer;
}

export interface RubricAnswerBatchCreateActionType {
  type: typeof RUBRICS_CREATE;
  payload: RubricAnswer[];
}

export interface RubricAnswerDeleteActionType {
  type: typeof RUBRIC_DELETE;
  payload: RubricAnswer["_id"];
}

/** Final export */
export type RubricAnswerActionTypes =
  | RubricAnswerGetAllActionType
  | RubricAnswerGetMineActionType
  | RubricAnswerCreateActionType
  | RubricAnswerBatchCreateActionType
  | RubricAnswerUpdateActionType
  | RubricAnswerDeleteActionType;
