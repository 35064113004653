import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnalyticsTrack } from "../../../api/analytics";
import { IUser } from "../../../api/api.types";
import { addressGetAll } from "../../../store/address/address.action";
import { Address } from "../../../store/address/address.types";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetAll } from "../../../store/shipping/shipment.action";
import { teamGetAll } from "../../../store/teams/team.action";
import { userGetAll } from "../../../store/users/user.action";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import UserName from "../../atomic/UserName";
import EditPayment from "../../forms/EditPayment";
import CreditCards from "./CreditCards";
import Invoices from "./Invoices";
import Outstanding from "./Outstanding";
import Overrides from "./Overrides";

const FinancierView = ({
  paymentGetAll,
  addressGetAll,
  userGetAll,
  shipmentGetAll,
  teamGetAll,
  users,
  address,
  payment: { all, loading },
}: Props) => {
  const [showInv, setShowInv] = useState<boolean>(true);
  const [showCc, setShowCc] = useState<boolean>(true);
  const [showAdmin, setShowAdmin] = useState<boolean>(true);
  const [showOut, setShowOut] = useState<boolean>(true);
  const [editPayment, setEditPayment] = useState<Payment | undefined>();
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "Financier",
      },
    });
  }, []);

  useEffect(() => {
    paymentGetAll();
    addressGetAll();
    shipmentGetAll();
    userGetAll();
    teamGetAll();
  }, [userGetAll, paymentGetAll, addressGetAll, teamGetAll, shipmentGetAll]);

  return (
    <div className="mb-5">
      <h1>Finance</h1>
      <input
        type="text"
        className="mb-1"
        placeholder="Filter by name, reference or company"
        value={filter}
        onChange={(e) => {
          e.preventDefault();
          setFilter(e.target.value);
        }}
      />

      {loading || users.loading || address.loading ? (
        <Spinner variant="financier" />
      ) : (
        <div className="payments">
          <h2 className="d-flex">
            <Button
              variant="light"
              className="mr-2"
              onClick={() => setShowInv(!showInv)}
            >
              <Icon icon={showInv ? "chevron-down" : "chevron-right"} />
            </Button>
            Invoice Payments
          </h2>
          {showInv && (
            <Invoices
              payments={all}
              users={users.all}
              addresses={address.all}
              onEdit={setEditPayment}
              filter={filter.toLowerCase()}
            />
          )}
          <h2 className="d-flex">
            <Button
              variant="light"
              className="mr-2"
              onClick={() => setShowCc(!showCc)}
            >
              <Icon icon={showCc ? "chevron-down" : "chevron-right"} />
            </Button>
            Credit Card Payments
          </h2>
          {showCc && (
            <CreditCards
              payments={all}
              users={users.all}
              addresses={address.all}
              onEdit={setEditPayment}
              filter={filter.toLowerCase()}
            />
          )}
          <h2 className="d-flex">
            <Button
              variant="light"
              className="mr-2"
              onClick={() => setShowAdmin(!showAdmin)}
            >
              <Icon icon={showAdmin ? "chevron-down" : "chevron-right"} />
            </Button>
            Override Payments
          </h2>
          {showAdmin && (
            <Overrides
              payments={all}
              users={users.all}
              onEdit={setEditPayment}
              filter={filter.toLowerCase()}
            />
          )}
          <h2 className="d-flex">
            <Button
              variant="light"
              className="mr-2 px-1"
              onClick={() => setShowOut(!showOut)}
            >
              <Icon icon={showOut ? "chevron-down" : "chevron-right"} />
            </Button>
            Outstanding Payments
          </h2>
          {showOut && (
            <Outstanding users={users.all} filter={filter.toLowerCase()} />
          )}
        </div>
      )}
      <Modal
        open={editPayment !== undefined}
        onClose={() => setEditPayment(undefined)}
      >
        {editPayment ? (
          <EditPayment
            users={users.all}
            payment={editPayment}
            onSubmit={() => setEditPayment(undefined)}
            onCancel={() => setEditPayment(undefined)}
          />
        ) : (
          <div></div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users,
  payment: state.payment,
  address: state.address,
});

const connector = connect(mapStateToProps, {
  userGetAll,
  paymentGetAll,
  addressGetAll,
  teamGetAll,
  shipmentGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(FinancierView);
