import { IAddress } from "../../api/api.types";
import { State } from "../../common/backend.types";

/** Basic types */

export type Address = IAddress & {};
export type AddressUpdateData = {
  name?: string;
  phone?: string;
  email?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  suburb?: string;
  postcode?: string;
  state?: State;
  country?: string;

  label?: string;
  company?: string;
  abn?: string;
};

export type AddressState = {
  mine: Address[];
  all: Address[];
  loading: boolean;
};

/** Action definitions */
export const ADDRESS_GET_MINE = "ADDRESS_GET_MINE";
export const ADDRESS_GET_ALL = "ADDRESS_GET_ALL";
export const ADDRESS_CREATE = "ADDRESS_CREATE";
export const ADDRESS_UPDATE = "ADDRESS_UPDATE";
export const ADDRESS_DELETE = "ADDRESS_DELETE";
export const ADDRESS_CLEAR = "ADDRESS_CLEAR";

/** Action types */
export interface AddressGetMineActionType {
  type: typeof ADDRESS_GET_MINE;
  payload: Address[];
}

export interface AddressGetAllActionType {
  type: typeof ADDRESS_GET_ALL;
  payload: Address[];
}

export interface AddressUpdateActionType {
  type: typeof ADDRESS_UPDATE;
  payload: Address;
}

export interface AddressCreateActionType {
  type: typeof ADDRESS_CREATE;
  payload: Address;
}

export interface AddressDeleteActionType {
  type: typeof ADDRESS_DELETE;
  payload: Address["_id"];
}

export interface AddressClearActionType {
  type: typeof ADDRESS_CLEAR;
  payload: null;
}

/** Final export */
export type AddressActionTypes =
  | AddressGetMineActionType
  | AddressGetAllActionType
  | AddressCreateActionType
  | AddressUpdateActionType
  | AddressDeleteActionType
  | AddressClearActionType;
