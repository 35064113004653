import { Dialog } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ExportIcon from "@material-ui/icons/CloudDownload";
import LockedIcon from "@material-ui/icons/Lock";
import UnlockedIcon from "@material-ui/icons/LockOpen";
import RefreshIcon from "@material-ui/icons/Sync";
import ShowIcon from "@material-ui/icons/Visibility";
import HideIcon from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  competeGetAll,
  competeUpdate,
} from "../../../store/compete/compete.action";
import { Compete } from "../../../store/compete/compete.types";
import { RootState } from "../../../store/root-reducer";
import { rubricGetAll } from "../../../store/rubrics/rubric.action";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { scoresheetGetAll } from "../../../store/scoring/scoring.action";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import {
  teamGetAll,
  teamGetByTournament,
} from "../../../store/teams/team.action";
import { TeamPublic } from "../../../store/teams/team.types";
import {
  tournamentGetAll,
  tournamentUpdate,
} from "../../../store/tournament/tournament.action";
import { Tournament } from "../../../store/tournament/tournament.types";
import { userGetTourn } from "../../../store/users/user.action";
import logger from "../../../utils/logger";
import { FaButton, FaIconButton } from "../../atomic/FaButtons";
import FaModal from "../../atomic/FaModal";
import { FaText } from "../../atomic/FaTexts";
import Spinner from "../../atomic/Spinner";
import { Games, GameType } from "../Scoring/games/types";
import ScoresheetView from "../Scoring/ScoresheetView";
import judgeAdvisorCalcs from "./judgeAdvisorCalcs";
import JudgeAdvisorRow from "./JudgeAdvisorRow";
import RubricViewForm from "./RubricViewForm";
import { JudgeDataTeam } from "./types";

interface RowData {
  r1?: number;
  r2?: number;
  r3?: number;
}

// type JudgeRankData = {
//   [key: string]: any;
//   cv?: number;
//   ip?: number;
//   rd?: number;
//   cvr?: number;
//   rpr?: number;
//   rdr?: number;
// };

const UNDEFINED = -2000;
const MIN_SCORE = 5;

const max = (x: RowData, round: 1 | 2 | 3 = 1) => {
  let raw = [x.r1, x.r2, x.r3].filter((r) => r !== undefined) as number[];
  if (raw.length < round) return UNDEFINED;
  let m = Math.max(...raw);
  if (round === 1) return m;
  let idx = raw.indexOf(m);
  raw = raw.filter((r, i) => i !== idx);
  m = raw.length ? Math.max(...raw) : UNDEFINED;
  if (round === 2) return m;
  idx = raw.indexOf(m);
  raw = raw.filter((r, i) => i !== idx);
  return raw.length ? Math.max(...raw) : UNDEFINED;
};

export type JaTeamData = JudgeDataTeam & {
  r1s?: Scoresheet;
  r2s?: Scoresheet;
  r3s?: Scoresheet;
  team: TeamPublic;
  compete: Compete;
  cve: number;
  ipe: number;
  rde: number;
  cvr: RubricAnswer[];
  ipr: RubricAnswer[];
  rdr: RubricAnswer[];
};

type ViewRubric = {
  team: TeamPublic;
  compete: Compete;
  rubrics: RubricAnswer[];
};

type LocalProps = {
  route_tournament: Tournament;
};

const JudgeAdvisorView = ({
  route_tournament,
  allteams,
  competes,
  scoresheetGetAll,
  rubricGetAll,
  userGetTourn,
  scoresheets,
  rubrics,
  tournamentUpdate,
  loading,
  competeUpdate,
  competeGetAll,
  teamGetAll,
  tournamentGetAll,
  teamGetByTournament,
  users,
}: Props) => {
  const [teams, setTeams] = useState<JaTeamData[]>([]);
  const [hidden, setHidden] = useState<boolean>(false);
  const [viewSc, setViewSc] = useState<Scoresheet | undefined>();
  const [viewRb, setViewRb] = useState<ViewRubric | undefined>();
  const [game, setGame] = useState<GameType>();

  useEffect(() => {
    // teamGetAll();
    competeGetAll();
    tournamentGetAll();
    if (!route_tournament) return;
    teamGetByTournament(route_tournament._id);
    scoresheetGetAll(route_tournament._id);
    rubricGetAll(route_tournament._id);
    userGetTourn(route_tournament._id);
  }, [route_tournament?._id]);

  useEffect(() => {
    setGame(Games.find((g) => g.season === route_tournament?.season));
  }, [route_tournament]);

  useEffect(() => {
    if (!game) return;
    setTeams(
      judgeAdvisorCalcs.calcTeams(
        route_tournament,
        game,
        allteams,
        competes,
        scoresheets,
        rubrics
      )
    );
  }, [game, route_tournament, allteams, competes, scoresheets, rubrics]);

  const classes = useStyles();

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  useEffect(() => {
    setLastUpdate(new Date());
    setRefreshing(false);
  }, [scoresheets, rubrics]);

  /** debounce */
  const handleRefresh = () => {
    setRefreshing(true);
    scoresheetGetAll(route_tournament._id);
    rubricGetAll(route_tournament._id);
    competeGetAll();

    // setTimeout(() => setRefreshing(false), 5000);
  };

  return loading ? (
    <Spinner variant="challenge" />
  ) : !route_tournament ? (
    <span>Tournament not found</span>
  ) : (
    <div className={classes.judgeadvisorview}>
      <div className={classes.controls}>
        <FaText className={classes.label}>Controls:</FaText>

        <FaIconButton
          tooltip="Refresh data"
          onClick={handleRefresh}
          variant="contained"
          rounded
          className={classes.refreshBtn}
          color={refreshing ? "default" : "success"}
          disabled={refreshing}
        >
          <RefreshIcon />
        </FaIconButton>
        <FaButton
          onClick={() =>
            tournamentUpdate(route_tournament._id, {
              judge_locked: !route_tournament.judge_locked,
            })
          }
          variant="outlined"
          className={classes.exportBtn}
          startIcon={
            route_tournament.judge_locked ? <LockedIcon /> : <UnlockedIcon />
          }
          tooltip={"Locked events can no longer edit their rubrics"}
          color={route_tournament.judge_locked ? "danger" : "primary"}
        >
          {route_tournament.judge_locked ? "Locked" : "Unlocked"}
        </FaButton>
        <FaButton
          variant="contained"
          color={route_tournament.released ? "danger" : "success"}
          onClick={() => {
            if (
              route_tournament.released ||
              window.confirm(
                "This will allow all teams to view their rubrics.  DO NOT RELEASE RESULTS if anything is still up in the air.  Are you sure you want to release results?"
              )
            ) {
              tournamentUpdate(route_tournament._id, {
                released: !route_tournament.released,
              });
            }
          }}
        >
          {route_tournament.released ? "Unrelease" : "Release"} results
        </FaButton>
      </div>
      <small>Last updated {moment(lastUpdate).format("HH:mm:ss")}</small>

      <div className={classes.controls}>
        <FaText className={classes.label}>Exports:</FaText>
        <FaButton
          variant="contained"
          startIcon={<ExportIcon />}
          className={classes.exportBtn}
          onClick={() =>
            judgeAdvisorCalcs.exportTeams(teams, route_tournament, users)
          }
        >
          Teams
        </FaButton>
        <FaButton
          variant="contained"
          startIcon={<ExportIcon />}
          className={classes.exportBtn}
          onClick={() =>
            judgeAdvisorCalcs.exportScores(teams, route_tournament)
          }
        >
          Robot game
        </FaButton>
        <FaButton
          variant="contained"
          startIcon={<ExportIcon />}
          className={classes.exportBtn}
          onClick={() => judgeAdvisorCalcs.exportCore(teams, route_tournament)}
        >
          Core values
        </FaButton>
        <FaButton
          variant="contained"
          startIcon={<ExportIcon />}
          className={classes.exportBtn}
          onClick={() =>
            judgeAdvisorCalcs.exportProject(teams, route_tournament)
          }
        >
          Innovation project
        </FaButton>
        <FaButton
          variant="contained"
          startIcon={<ExportIcon />}
          className={classes.exportBtn}
          onClick={() => judgeAdvisorCalcs.exportRobot(teams, route_tournament)}
        >
          Robot design
        </FaButton>
      </div>
      <Table
        size="small"
        className={classes.table}
        aria-labelledby="tableTitle"
        // size={dense ? "small" : "medium"}
        aria-label="enhanced table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Team number</TableCell>
            <TableCell>
              Team name
              <IconButton onClick={() => setHidden(!hidden)}>
                {hidden ? <ShowIcon /> : <HideIcon />}
              </IconButton>
            </TableCell>
            {hidden || (
              <Fragment>
                <TableCell>Judge lane</TableCell>
                <TableCell>R1</TableCell>
                <TableCell>R2</TableCell>
                <TableCell>R3</TableCell>
                <TableCell>Max</TableCell>
                <TableCell>Robot game rank</TableCell>
                <TableCell>Core Values rank</TableCell>
                <TableCell>Innovation Project rank</TableCell>
                <TableCell>Robot Design rank</TableCell>
              </Fragment>
            )}
            <TableCell>Champion's Score</TableCell>
            <TableCell>Champion's Rank</TableCell>
            <TableCell>Breakthrough</TableCell>
            <TableCell>Rising All-star</TableCell>
            <TableCell>Motivate</TableCell>
            <TableCell>Awards</TableCell>
            {/* <TableCell>Place</TableCell>
            <TableCell>Advance?</TableCell>
            <TableCell>Comments</TableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {teams.map((row, index) => (
            <JudgeAdvisorRow
              key={row.team_number}
              row={row}
              onViewRubric={setViewRb}
              onViewScoresheet={setViewSc}
              hidden={hidden}
            />
          ))}
        </TableBody>
      </Table>

      {game && (
        <FaModal open={!!viewSc} onClose={() => setViewSc(undefined)}>
          {viewSc ? (
            <ScoresheetView scoresheet={viewSc} game={game} referee />
          ) : (
            <div />
          )}
        </FaModal>
      )}

      <Dialog open={!!viewRb} onClose={() => setViewRb(undefined)}         PaperProps={{ className: classes.dialog }}
>
        {viewRb ? <RubricViewForm judge {...viewRb} /> : <div />}
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    judgeadvisorview: { height: "100%" },
    paper: {
      width: "100%",
      height: "100%",
      marginBottom: theme.spacing(2),
    },

    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    clickable: {
      textDecoration: "underline",
      color: "blue",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    dialog: {
      padding: 16,
      minWidth: "80%",
    },

    controls: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
    exportBtn: {
      marginRight: theme.spacing(1),
    },
    refreshBtn: {
      height: 32,
      width: 32,
      marginRight: theme.spacing(1),
    },
    selector: {
      width: 240,
    },
    label: {
      width: 80,
      margin: "auto 0",
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  allteams: [
    ...state.team.all,
    ...state.team.tournament,
    ...state.team.dummy,
  ].filter((x, i, a) => a.findIndex((y) => y._id === x._id) === i),
  competes: state.compete.all,
  tournaments: state.tournament.all,
  users: state.users.all,
  scoresheets: state.scoring.all,
  rubrics: state.rubrics.all,
  loading:
    state.team.loading ||
    state.tournament.loading ||
    state.compete.loading ||
    state.rubrics.loading ||
    state.scoring.loading,
});

const connector = connect(mapStateToProps, {
  scoresheetGetAll,
  rubricGetAll,
  userGetTourn,
  tournamentUpdate,
  competeUpdate,
  teamGetByTournament,
  competeGetAll,
  teamGetAll,
  tournamentGetAll,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(JudgeAdvisorView);
