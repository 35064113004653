import React, { useEffect } from "react";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FIRSTProgram, ShipmentContent } from "../../../common/backend.types";
import {
  compressManifest,
  diffShipped,
  diffShippedTourn,
  getInventory,
  getInventoryTourn,
} from "../../../inventory/getInventory";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetAll } from "../../../store/shipping/shipment.action";
import { teamGetAll } from "../../../store/teams/team.action";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { userGetAll } from "../../../store/users/user.action";
const ShipperTab = ({ users, teams, tournaments, shipments }: Props) => {
  const [todoTeam, setTodoTeam] = useState<ShipmentContent[]>([]);
  const [todoTourn, setTodoTourn] = useState<ShipmentContent[]>([]);

  useEffect(() => {
    setTodoTeam(diffShipped(teams, shipments));
    setTodoTourn(diffShippedTourn(tournaments, shipments));
  }, [teams, tournaments, shipments]);

  useEffect(() => {
    userGetAll();
    teamGetAll();
    tournamentGetAll();
    shipmentGetAll();
  }, []);

  return (
    <div>
      <h1>Shipper</h1>
      <div className="tab-grid-4">
        <strong>Users</strong>
        <span>{users}</span>
        <hr className="col-span-4" />
        <strong>Team kits</strong>
        <span>{teams.filter((t) => t.ship_kit).length}</span>
        <span className="col-span-4">&nbsp;</span>
        <span className="text-challenge">Challenge</span>
        <span>
          {
            teams.filter(
              (t) => t.ship_kit && t.program === FIRSTProgram.FLL_CHALLENGE
            ).length
          }
        </span>
        <span className="text-explore">Explore</span>
        <span>
          {
            teams.filter(
              (t) => t.ship_kit && t.program === FIRSTProgram.FLL_EXPLORE
            ).length
          }
        </span>
        <span className="text-discover">Discover</span>
        <span>
          {
            teams.filter(
              (t) => t.ship_kit && t.program === FIRSTProgram.FLL_DISCOVER
            ).length
          }
        </span>
        <span className="text-ftc">FTC</span>
        <span>
          {
            teams.filter((t) => t.ship_kit && t.program === FIRSTProgram.FTC)
              .length
          }
        </span>
        <hr className="col-span-4" />

        <strong>Tournament kits</strong>
        <span>{tournaments.reduce((p, c) => p + c.ship_kits, 0)}</span>
        <span className="col-span-4">&nbsp;</span>
        <span className="text-challenge">Challenge</span>
        <span>
          {tournaments
            .filter((t) => t.program === FIRSTProgram.FLL_CHALLENGE)
            .reduce((p, c) => p + c.ship_kits, 0)}
        </span>
        <span className="text-explore">Explore</span>
        <span>
          {tournaments
            .filter((t) => t.program === FIRSTProgram.FLL_EXPLORE)
            .reduce((p, c) => p + c.ship_kits, 0)}
        </span>
        <span className="text-discover">Discover</span>
        <span>
          {tournaments
            .filter((t) => t.program === FIRSTProgram.FLL_DISCOVER)
            .reduce((p, c) => p + c.ship_kits, 0)}
        </span>
        <span className="text-ftc">FTC</span>
        <span>
          {tournaments
            .filter((t) => t.program === FIRSTProgram.FTC)
            .reduce((p, c) => p + c.ship_kits, 0)}
        </span>
        <hr className="col-span-4" />

        <strong>Total kits to ship:</strong>
        <span className="col-span-3">
          {compressManifest([
            ...getInventory(teams),
            ...getInventoryTourn(tournaments),
          ]).reduce((p, c) => p + c.count, 0)}
        </span>
        <strong>Shipped:</strong>
        <span className="col-span-3">
          {compressManifest(
            shipments
              .filter((s) => s.consignment_number)
              .map((s) => s.contents)
              .flat()
          ).reduce((p, c) => p + c.count, 0)}
        </span>
        <strong>Shipments completed:</strong>
        <span className="col-span-3">
          {shipments.filter((s) => s.consignment_number).length}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.all.length,
  teams: state.team.all,
  tournaments: state.tournament.all,
  shipments: state.shipment.all,
});

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector> & {};

export default connector(ShipperTab);
