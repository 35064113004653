import React from "react";
import { FIRSTProgram, VolParticipation } from "../../common/backend.types";
import ProgramLogo from "../atomic/ProgramLogo";
import { RoleMap } from "../../common/vol_roles";
import CheckBox from "../atomic/CheckBox";
import NumberBox from "../atomic/NumberBox";

const VolPrograms = [
  FIRSTProgram.FLL_EXPLORE,
  FIRSTProgram.FLL_CHALLENGE,
  FIRSTProgram.FTC,
  FIRSTProgram.FRC,
];

interface Props {
  /** List (ordered or unordered) of roles */
  checked: VolParticipation[];
  /** toggle whether something is checked*/
  toggleChecked: (part: VolParticipation) => void;
  /** Should this be an ordered list? */
  ordered?: boolean;
}

const VolunteerRoleList = ({ ordered, checked, toggleChecked }: Props) => {
  // TODO ordered
  return (
    <div className="d-flex">
      {VolPrograms.map((program) => (
        <div className="d-flex-col  mb-auto">
          <ProgramLogo program={program} full />
          {RoleMap[program]
            .sort((a, b) => a.localeCompare(b))
            .map((role, idx) => (
              <div className="d-flex mr-auto" key={idx}>
                {ordered ? (
                  <NumberBox
                    toggle={() => toggleChecked({ program, role })}
                    value={
                      checked
                        .filter((c) => c.program === program)
                        .findIndex((c) => c.role === role) === -1
                        ? undefined
                        : checked
                            .filter((c) => c.program === program)
                            .findIndex((c) => c.role === role) + 1
                    }
                  />
                ) : (
                  <CheckBox
                    toggle={() => toggleChecked({ program, role })}
                    checked={checked
                      .map((c) => JSON.stringify(c))
                      .includes(JSON.stringify({ program, role }))}
                  />
                )}
                <span
                  className={`ml-1 c-pointer${
                    checked
                      .map((c) => JSON.stringify(c))
                      .includes(JSON.stringify({ program, role }))
                      ? " text-primary"
                      : ""
                  }`}
                  onClick={() => toggleChecked({ program, role })}
                >
                  {role}
                </span>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default VolunteerRoleList;
