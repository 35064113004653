import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { RootState } from "../../../store/root-reducer";
import { getUserCosts } from "../../../store/users/user.action";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import UserName from "../../atomic/UserName";

type LocalProps = {
  userId: IUser["_id"];
  onView: (u: IUser) => void;
  onAdd: (u: IUser) => void;
};

const OutstandingUserRow = ({
  user,
  onView,
  onAdd,
  addresses,
  payments,
  teams,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  return !user ? (
    <tr>
      <td>Something went wrong...</td>
    </tr>
  ) : user.up > user.down && (user.up - user.down).toFixed(2.0) !== "0.00" ? (
    <tr>
      <td>
        <UserName user={user} />
      </td>
      <td>{user.email}</td>
      <td>${(user.up - user.down).toFixed(2.0)}</td>
      <td>
        <Button variant="explore" onClick={() => onView(user)}>
          <Icon icon="eye" />
        </Button>
      </td>
      <td>
        <Button variant="financier" onClick={() => onAdd(user)}>
          <Icon icon="plus" />
        </Button>
      </td>
    </tr>
  ) : (
    <tr></tr>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState, props: LocalProps) => ({
    user: selectUser(state, props),
    addresses: state.address.all,
    payments: state.payment.all,
    teams: state.team.all,
  });
};

const connector = connect(makeMapStateToProps, {});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(OutstandingUserRow);
