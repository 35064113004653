import { AppThunk } from "../app-thunk";
import {
  Event,
  EventActionTypes,
  EVENT_UPDATE,
  EVENT_CLEAR,
  EVENT_GET_ALL,
  EVENT_DELETE,
  EVENT_CREATE,
} from "./admin.types";
import { EventService } from "../../api/services/admin.service";
import logger from "../../utils/logger";
import { DTOEventCreate, DTOEventUpdate } from "../../api/dtos/admin.dto";

/**
 * Action types
 */
export const EventGetAllAction = (data: Event[]): EventActionTypes => ({
  type: EVENT_GET_ALL,
  payload: data,
});

export const EventCreateAction = (data: Event): EventActionTypes => ({
  type: EVENT_CREATE,
  payload: data,
});

export const EventUpdateAction = (data: Event): EventActionTypes => ({
  type: EVENT_UPDATE,
  payload: data,
});

export const EventDeleteAction = (data: Event["_id"]): EventActionTypes => ({
  type: EVENT_DELETE,
  payload: data,
});

export const EventClearAction = (): EventActionTypes => ({
  type: EVENT_CLEAR,
  payload: null,
});

/**
 * Actions
 */
export const eventGetAll = (): AppThunk => async (dispatch) => {
  try {
    const a = await EventService.getAll();
    dispatch(EventGetAllAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "event.action :: getAll", true);
  }
};

export const eventCreate =
  (data: DTOEventCreate): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const a = await EventService.create(data);
      dispatch(EventCreateAction(a));
      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "event.action :: eventCreate", true);
      return false;
    }
  };

export const eventUpdate =
  (aid: Event["_id"], data: DTOEventUpdate): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const a = await EventService.update(aid, data);
      dispatch(EventUpdateAction(a));
      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "event.action :: eventUpdate", true);
      return false;
    }
  };

export const eventDelete =
  (aid: Event["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await EventService.remove(aid);
      dispatch(EventDeleteAction(aid));
    } catch (error: any) {
      logger.logError(error, logger.warn, "event.action :: eventDelete", true);
    }
  };
export const eventClear = (): AppThunk => (dispatch) => {
  dispatch(EventClearAction());
};
