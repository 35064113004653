import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { v4 as uuid } from "uuid";
import logger from "../../utils/logger";
import Icon from "./Icon";

type Opt<T> = {
  label: string;
  value: T;
};

interface Props<T> {
  /** Placeholder string or component */
  placeholder?: React.ReactNode;
  /** Label, to be visible even when options are selected */
  label?: React.ReactNode;
  /** Element ID */
  id?: string;
  /** Options in selector */
  options: Opt<T>[];
  /** Class/prefix to be added to root container */
  className?: string;
  /** Current selected value */
  value?: T | undefined;
  /** Function to be run on change of selection */
  onSelect: (value: T | undefined) => void;
  /** Whether or not to include an input text element */
  searchable?: boolean;
  /** Close dropdown on click */
  hold?: boolean;
  /** Allow undefined input */
  clearable?: boolean;
  /** Allow typing of a new value */
  // allowNewValue?: boolean
  /** Show the dropdown caret */
  showCaret?: boolean;
  /** Clear background? */
  light?: boolean;
  /** Right-align content */
  right?: boolean;
  dropup?: boolean;
}

function Selector<T>({
  placeholder = "Select...",
  options,
  id,
  className,
  value,
  searchable = false,
  hold = false,
  clearable = false,
  light = false,
  showCaret = false,
  dropup = false,
  right = false,
  onSelect,
  label,
}: Props<T>) {
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [ID] = useState<string>(uuid());
  const [idx, setIdx] = useState<number>(-1);

  const inRef = useRef<HTMLInputElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  const { styles, attributes, update, state } = usePopper(
    btnRef.current,
    inRef.current,
    {
      placement: `${dropup ? "top" : "bottom"}-${right ? "end" : "start"}` as
        | "top-start"
        | "top-end"
        | "bottom-start"
        | "bottom-end",
    }
  );

  const [selectedOpt, setSelectedOpt] = useState<Opt<T>>();
  useEffect(() => {
    setSelectedOpt(options.find((o) => o.value === value));
  }, [options, value]);
  const [opts, setOpts] = useState<Opt<T>[]>(options.filter((o) => o.label));

  useEffect(() => {
    const O = options
      .filter((f) => f.label)
      .filter((x, i, a) => a.indexOf(x) === i)
      .filter(
        ({ label }) =>
          search === "" || label.toLowerCase().includes(search.toLowerCase())
      );
    setOpts(O);
    // if (O[0]?.label.toLowerCase() === search.toLowerCase()) setIdx(0);
  }, [search, options, value]);
  useEffect(() => {
    const handleClick = (e: MouseEvent, toggle: (b: boolean) => void) => {
      const h = e.target as HTMLElement;
      if (!container.current?.contains(h) && !inRef.current?.contains(h))
        toggle(false);
    };

    document.addEventListener("mousedown", (e) => handleClick(e, setOpen));
    return () => {
      document.removeEventListener("mousedown", (e) => handleClick(e, setOpen));
    };
  }, [setOpen]);

  const clickItem = (o: Opt<T>, holdNow?: boolean) => {
    onSelect(o.value);
    if (!hold && !holdNow) setOpen(false);
    setSearch("");
  };

  const clear = () => {
    setOpen(false);
    if (search) setSearch("");
    else onSelect(undefined);
  };

  useEffect(() => {
    if (open && update) update();
  }, [update, state, open]);

  return (
    <div
      id={id}
      ref={container}
      className={`selector${label ? " has-label" : ""}${
        className ? " " + className : ""
      }${light ? " selector-light" : ""}`}
    >
      {label && <label htmlFor={ID}>{label}</label>}
      <div
        tabIndex={0}
        className="selector-clicker"
        id={ID}
        ref={btnRef}
        onMouseDown={(e) => {
          setOpen(!open);
          if (inRef && inRef.current && searchable) inRef.current.focus();
        }}
        onFocus={(e) => {
          setOpen(true);
          if (inRef && inRef.current && searchable) inRef.current.focus();
        }}
        onBlur={() => setOpen(false)}
        onKeyDown={(e) => {
          if (searchable) return;
          // logger.info("Key pressed!", "Selector", false, { e });
          if (/^[a-zA-Z0-9]$/.test(e.key)) {
            e.preventDefault();
            const getOpt = options.find((o) =>
              o.label.toLowerCase().startsWith(e.key.toLowerCase())
            );
            if (getOpt) clickItem(getOpt);
          } else {
            switch (e.key) {
              case "ArrowDown":
                e.preventDefault();
                if (idx < opts.length - 1) {
                  clickItem(opts[idx + 1], true);
                  setIdx(idx + 1);
                }
                break;
              case "ArrowUp":
                e.preventDefault();
                if (idx > 0) {
                  clickItem(opts[idx - 1], true);
                  setIdx(idx - 1);
                }
                if (idx === -1) {
                  clickItem(opts[opts.length - 1], true);
                  setIdx(opts.length - 1);
                }
                break;
              case "Enter":
                e.preventDefault();
                if (idx > -1 && idx < opts.length && open) clickItem(opts[idx]);
                break;
              default:
                if (idx !== -1) setIdx(-1);
            }
          }
        }}
      >
        <div
          className={`selector-placeholder${clearable ? " clearable" : ""}${
            showCaret ? " has-caret" : ""
          }`}
        >
          <div className="selector-options">
            <span className="option">
              {selectedOpt ? selectedOpt.label : placeholder}
            </span>
          </div>

          {searchable && (
            <input
              type="text"
              className="selector-search-input"
              value={search}
              ref={inRef}
              onKeyDown={(e) => {
                // logger.info("Key pressed!", "Selector", false, { e });
                switch (e.key) {
                  case "ArrowDown":
                    e.preventDefault();
                    if (idx < opts.length - 1) setIdx(idx + 1);

                    break;
                  case "ArrowUp":
                    e.preventDefault();
                    if (idx > 0) setIdx(idx - 1);

                    break;
                  case "Enter":
                    e.preventDefault();

                    if (idx > -1 && idx < opts.length && open)
                      clickItem(opts[idx]);
                    break;
                  default:
                    if (idx !== -1) setIdx(-1);
                }
              }}
              // onFocus={() => setOpen(true)}
              // onFocusCapture={() => setOpen(true)}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
        </div>
      </div>
      {((value && clearable) || (search && searchable)) && (
        <span className="selector-clear" onClick={clear}>
          <Icon icon="times" />
        </span>
      )}
      {showCaret && (
        <span
          className="selector-caret" /**onClick={() => setOpen(!open)}*/
          onMouseDown={(e) => {
            setOpen(!open);
            if (inRef && inRef.current && searchable) inRef.current.focus();
          }}
        >
          {open ? <Icon icon="caret-up" /> : <Icon icon="caret-down" />}
        </span>
      )}
      <div
        className={`selector-content${dropup ? " dropup" : ""}  ${
          open ? "show" : "hide"
        }`}
        ref={inRef}
        style={styles.popper}
        {...attributes.popper}
        aria-labelledby={ID}
      >
        {opts.map((opt, id) => (
          <span
            className={`dropdown-item${
              opt.value === value ? " selected" : ""
            } ${id === idx ? " hovered" : ""}`}
            key={id}
            onMouseDown={() => {
              setIdx(-1);
              clickItem(opt);
            }}
          >
            {opt.label}
          </span>
        ))}
        {/* {allowNewValue && value && selectedOpt === undefined && (
          <span className="dropdown-item selected">{value}</span>
        )}
        {allowNewValue && (
          <Fragment>
            <span className={`dropdown-item`} onClick={createItem}>
              Create new...
            </span>
          </Fragment>
        )} */}
      </div>
    </div>
  );
}

export default Selector;
