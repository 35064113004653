import { AppThunk } from "../app-thunk";
import {
  Payment,
  PaymentActionTypes,
  PAYMENT_UPDATE,
  PAYMENT_CLEAR,
  PAYMENT_GET_ALL,
  PAYMENT_GET_MINE,
  PAYMENT_DELETE,
  PAYMENT_CREATE,
} from "./payment.types";
import PaymentService from "../../api/services/payment.service";
import logger from "../../utils/logger";
import {
  DTOPaymentCreate,
  DTOPaymentGet,
  DTOPaymentUpdate,
} from "../../api/dtos/payment.dto";
import { AnalyticsTrack } from "../../api/analytics";

/**
 * Action types
 */

export const PaymentGetMineAction = (data: Payment[]): PaymentActionTypes => ({
  type: PAYMENT_GET_MINE,
  payload: data,
});

export const PaymentGetAllAction = (data: Payment[]): PaymentActionTypes => ({
  type: PAYMENT_GET_ALL,
  payload: data,
});

export const PaymentCreateAction = (data: Payment): PaymentActionTypes => ({
  type: PAYMENT_CREATE,
  payload: data,
});

export const PaymentUpdateAction = (data: Payment): PaymentActionTypes => ({
  type: PAYMENT_UPDATE,
  payload: data,
});

export const PaymentDeleteAction = (
  data: Payment["_id"]
): PaymentActionTypes => ({
  type: PAYMENT_DELETE,
  payload: data,
});

export const PaymentClearAction = (): PaymentActionTypes => ({
  type: PAYMENT_CLEAR,
  payload: null,
});

/**
 * Actions
 */

export const paymentGetMine = (): AppThunk => async (dispatch) => {
  try {
    const a = await PaymentService.getMine();
    dispatch(PaymentGetMineAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "payment.action :: getMine", true);
  }
};

export const paymentGetAll = (): AppThunk => async (dispatch) => {
  try {
    const a = await PaymentService.getAll();
    dispatch(PaymentGetAllAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "payment.action :: getAll", true);
  }
};

export const paymentCreate =
  (data: DTOPaymentCreate): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const a = await PaymentService.create(data);
      dispatch(PaymentCreateAction(a));
      if (!data.admin_comment)
        AnalyticsTrack({
          name: "Start Payment",
          data: {
            method: data.credit_card ? "credit_card" : "invoice",
            amount: data.amount,
          },
        });
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "payment.action :: paymentCreate",
        true
      );
      return false;
    }
  };

export const paymentAddCreated =
  (data: DTOPaymentGet): AppThunk =>
  (dispatch) => {
    dispatch(PaymentCreateAction(data));
  };

export const paymentUpdate =
  (aid: Payment["_id"], data: DTOPaymentUpdate): AppThunk =>
  async (dispatch) => {
    try {
      const a = await PaymentService.update(aid, data);
      dispatch(PaymentUpdateAction(a));
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "payment.action :: paymentUpdate",
        true
      );
    }
  };

export const paymentDelete =
  (aid: Payment["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await PaymentService.remove(aid);
      dispatch(PaymentDeleteAction(aid));
      logger.success("Payment deleted", "paymentDelete", true, { aid });
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "payment.action :: paymentDelete",
        true
      );
    }
  };

export const paymentClear = (): AppThunk => (dispatch) => {
  dispatch(PaymentClearAction());
};
