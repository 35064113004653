import {
  AuthActionTypes,
  AuthState,
  AUTH_ERROR,
  LOGOUT,
  SET_EMAIL_SENT,
  UPDATE_USER,
  USER_LOADED,
} from "./auth.types";

const initialState: AuthState = {
  token: "",
  user: null,
  loading: true,
  error: null,
};

const authReducer = function (
  state: AuthState = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case USER_LOADED:
      const token = action.payload.token;
      localStorage.setItem("token", token);
      return {
        ...state,
        token,
        user: action.payload.user,
        loading: false,
        error: null,
      };
    case UPDATE_USER:
      return { ...state, user: action.payload, loading: false };
    case SET_EMAIL_SENT:
      return {
        ...state,
        user: state.user ? { ...state.user, email_sent: action.payload } : null,
        loading: false,
      };
    case LOGOUT:
      return { ...state, token: "", user: null, loading: false };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        token: "",
        user: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
