import { IVolley } from "../../api/api.types";
import { DTOVolleyUpdate } from "../../api/dtos/volley.dto";

/** Basic types */

export type Volley = IVolley & {};

export type VolleyState = {
  all: Volley[];
  mine: Volley[];
  loading: boolean;
};

/** Action definitions */
export const VOLLEY_GET_ALL = "VOLLEY_GET_ALL";
export const VOLLEY_GET_MINE = "VOLLEY_GET_MINE";
export const VOLLEY_CREATE = "VOLLEY_CREATE";
export const VOLLEY_UPDATE = "VOLLEY_UPDATE";
export const VOLLEY_DELETE = "VOLLEY_DELETE";

/** Action types */
export interface VolleyGetAllActionType {
  type: typeof VOLLEY_GET_ALL;
  payload: Volley[];
}
export interface VolleyGetMineActionType {
  type: typeof VOLLEY_GET_MINE;
  payload: Volley[];
}

export interface VolleyUpdateActionType {
  type: typeof VOLLEY_UPDATE;
  payload: {
    id: Volley["_id"];
    data: Volley;
  };
}

export interface VolleyCreateActionType {
  type: typeof VOLLEY_CREATE;
  payload: Volley;
}

export interface VolleyDeleteActionType {
  type: typeof VOLLEY_DELETE;
  payload: Volley["_id"];
}

/** Final export */
export type VolleyActionTypes =
  | VolleyGetAllActionType
  | VolleyGetMineActionType
  | VolleyCreateActionType
  | VolleyUpdateActionType
  | VolleyDeleteActionType;
