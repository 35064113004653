import React, { Fragment, useState } from "react";
import { IUser } from "../../../api/api.types";
import Modal from "../../atomic/Modal";
import AddOverrideView from "./AddOverrideView";
import OutstandingUserRow from "./OutstandingUserRow";
import OutstandingView from "./OutstandingView";

interface Props {
  users: IUser[];
  filter?: string;
}

const Outstanding = ({ users, filter }: Props) => {
  const [user, setUser] = useState<IUser | undefined>();
  const [addUser, setAddUser] = useState<IUser | undefined>();

  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Contact</th>
            <th>Email</th>
            <th>Amount</th>
            <th>View details</th>
            <th>Override payment</th>
          </tr>
        </thead>
        <tbody className="striped">
          {users
            .filter(
              (u) =>
                !filter ||
                u.given_name?.toLowerCase().includes(filter) ||
                u.family_name?.toLowerCase().includes(filter)
            )
            .map((u) => (
              <OutstandingUserRow
                key={u._id}
                onView={setUser}
                userId={u._id}
                onAdd={setAddUser}
              />
            ))}
        </tbody>
      </table>
      <Modal open={user !== undefined} onClose={() => setUser(undefined)}>
        {user ? <OutstandingView userId={user._id} /> : <div></div>}
      </Modal>
      <Modal open={addUser !== undefined} onClose={() => setAddUser(undefined)}>
        {addUser ? (
          <AddOverrideView
            userId={addUser._id}
            onClose={() => setAddUser(undefined)}
          />
        ) : (
          <div></div>
        )}
      </Modal>
    </Fragment>
  );
};

export default Outstanding;
