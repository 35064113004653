import { IPayment } from "../../api/api.types";

/**
 * Type defintions
 */
export const PAYMENT_GET_MINE = "PAYMENT_GET_MINE";
export const PAYMENT_GET_ALL = "PAYMENT_GET_ALL";
export const PAYMENT_CREATE = "PAYMENT_CREATE";
export const PAYMENT_UPDATE = "PAYMENT_UPDATE";
export const PAYMENT_DELETE = "PAYMENT_DELETE";
export const PAYMENT_CLEAR = "PAYMENT_CLEAR";

/**
 * Types
 */

export interface Payment extends IPayment {}

export interface PaymentState {
  mine: Payment[];
  all: Payment[];
  loading: boolean;
}

/** Action types */
interface PaymentGetMineActionType {
  type: typeof PAYMENT_GET_MINE;
  payload: Payment[];
}

interface PaymentGetAllActionType {
  type: typeof PAYMENT_GET_ALL;
  payload: Payment[];
}

interface PaymentUpdateActionType {
  type: typeof PAYMENT_UPDATE;
  payload: Payment;
}

interface PaymentCreateActionType {
  type: typeof PAYMENT_CREATE;
  payload: Payment;
}

interface PaymentDeleteActionType {
  type: typeof PAYMENT_DELETE;
  payload: Payment["_id"];
}

interface PaymentClearActionType {
  type: typeof PAYMENT_CLEAR;
  payload: null;
}

/** Final export */
export type PaymentActionTypes =
  | PaymentGetMineActionType
  | PaymentGetAllActionType
  | PaymentCreateActionType
  | PaymentUpdateActionType
  | PaymentDeleteActionType
  | PaymentClearActionType;
