import { RootState } from "../root-reducer";
import { createSelector } from "reselect";

/// Selector functions
const selectTeamMine = (state: RootState) => ({
  teams: state.team.mine,
  season: state.settings.season,
});
const selectTeamCoach = (state: RootState) => ({
  teams: state.team.coach,
  season: state.settings.season,
});
const selectTeamMineAndCoach = (state: RootState) => ({
  mine: state.team.mine,
  coach: state.team.coach,
  season: state.settings.season,
});
const selectTeamAll = (state: RootState) => ({
  teams: state.team.all,
  season: state.settings.season,
});
const selectTeamDeleted = (state: RootState) => ({
  teams: state.team.deleted_all,
  season: state.settings.season,
});

///

export const selectMyTeams = createSelector(selectTeamMine, (s) =>
  s.teams.filter((t) => t.season === s.season)
);
export const selectCoachTeams = createSelector(selectTeamCoach, (s) =>
  s.teams.filter((t) => t.season === s.season)
);
export const selectAllTeams = createSelector(selectTeamAll, (s) =>
  s.teams.filter((t) => t.season === s.season)
);

export const selectDeletedTeams = createSelector(selectTeamDeleted, (s) =>
  s.teams.filter((t) => t.season === s.season)
);

export const selectAllTeamsICoach = createSelector(
  selectTeamMineAndCoach,
  (s) =>
    [...s.mine, ...s.coach].filter(
      (x, i, a) => a.findIndex((y) => y._id === x._id) === i
    )
);
export const selectAllTeamsIOwn = createSelector(selectTeamMine, (s) =>
  s.teams.filter((x, i, a) => a.findIndex((y) => y._id === x._id) === i)
);
export const selectCurrentTeamsICoach = createSelector(
  selectTeamMineAndCoach,
  (s) =>
    [...s.mine, ...s.coach]
      .filter((x, i, a) => a.findIndex((y) => y._id === x._id) === i)
      .filter((t) => t.season === s.season)
);
