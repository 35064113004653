import { createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaText } from "../../atomic/FaTexts";
import NotCheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckedIcon from "@material-ui/icons/RadioButtonChecked";
import TextField from "@material-ui/core/TextField";
import { PaletteColor } from "@material-ui/core/styles/createPalette";

type Props = {
  value: ScoreLabel[];
  onChange?: (v: Score, i: number) => void;
  color: PaletteColor;
  label: string;
  description: string;
};

type ScoreLabel = Score & { labels: [string, string, string, string] };

type Score = { value?: 1 | 2 | 3 | 4; comment?: string };

const RubricQuestionRow = ({
  value,
  onChange,
  label,
  description,
  color,
}: Props) => {
  const [comments, setComments] = useState<string[]>(
    value.map((v) => v.comment ?? "")
  );

  useEffect(() => {
    setComments(value.map((v) => v.comment ?? ""));
  }, [value]);

  const classes = useStyles(color, onChange === undefined)();
  return (
    <Fragment>
      <TableRow>
        <TableCell colSpan={4} className={classes.rubricquestionrow}>
          <FaText variant="button">{label}</FaText>{" "}
          <FaText variant="caption">- {description}</FaText>
        </TableCell>
      </TableRow>
      {value.map((sc, i) => (
        <TableRow>
          <TableCell
            className={[
              classes.cell,
              sc.value === 1 ? classes.highlighted : "",
            ].join(" ")}
            onClick={() =>
              onChange === undefined
                ? {}
                : onChange({ value: 1, comment: sc.comment ?? "" }, i)
            }
          >
            <div className={classes.row}>
              {sc.value === 1 ? <CheckedIcon /> : <NotCheckedIcon />}
              {sc.labels[0] && (
                <FaText variant="caption">{sc.labels[0]}</FaText>
              )}
            </div>
          </TableCell>
          <TableCell
            className={[
              classes.cell,
              sc.value === 2 ? classes.highlighted : "",
            ].join(" ")}
            onClick={() =>
              onChange === undefined
                ? {}
                : onChange({ value: 2, comment: sc.comment ?? "" }, i)
            }
          >
            <div className={classes.row}>
              {sc.value === 2 ? <CheckedIcon /> : <NotCheckedIcon />}
              {sc.labels[1] && (
                <FaText variant="caption">{sc.labels[1]}</FaText>
              )}
            </div>
          </TableCell>
          <TableCell
            className={[
              classes.cell,
              sc.value === 3 ? classes.highlighted : "",
            ].join(" ")}
            onClick={() =>
              onChange === undefined
                ? {}
                : onChange({ value: 3, comment: sc.comment ?? "" }, i)
            }
          >
            <div className={classes.row}>
              {sc.value === 3 ? <CheckedIcon /> : <NotCheckedIcon />}
              {sc.labels[2] && (
                <FaText variant="caption">{sc.labels[2]}</FaText>
              )}
            </div>
          </TableCell>
          <TableCell
            className={[
              classes.cell,
              sc.value === 4 ? classes.highlighted : "",
            ].join(" ")}
            onClick={() =>
              onChange === undefined
                ? {}
                : onChange({ value: 4, comment: sc.comment ?? "" }, i)
            }
          >
            <div className={classes.row}>
              {sc.value === 4 ? <CheckedIcon /> : <NotCheckedIcon />}
              {sc.labels[3] && (
                <FaText variant="caption">{sc.labels[3]}</FaText>
              )}
            </div>
          </TableCell>
          <TableCell>
            {onChange ? (
              <TextField
                multiline
                value={comments[i] ?? ""}
                onChange={(e) =>
                  setComments(
                    comments.map((s, idx) => (i === idx ? e.target.value : s))
                  )
                }
                error={sc.value === 4 && !Boolean(sc.comment)}
                helperText={
                  sc.value === 4 && !Boolean(sc.comment)
                    ? "Please explain how the team exceeded"
                    : ""
                }
                onBlur={(e) =>
                  onChange === undefined
                    ? {}
                    : onChange(
                        { comment: comments[i], value: sc.value ?? 4 },
                        i
                      )
                }
              />
            ) : (
              <FaText>{comments[i]}</FaText>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

const useStyles = (color: PaletteColor, restricted: boolean) =>
  makeStyles((theme) =>
    createStyles({
      rubricquestionrow: {
        backgroundColor: color.main,
      },
      focusable: {
        // "&:focus-within": {
        //   backgroundColor: color.light,
        // },
      },
      highlighted: {
        backgroundColor: `${color.dark} !important`,
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      },
      cell: {
        alignItems: "center",
        textAlign: "center",
        width: "20%",
        cursor: restricted ? "default" : "pointer",
        height: 86,
        "&:hover": {
          backgroundColor: color.light,
        },
      },
    })
  );

export default RubricQuestionRow;
