import {
  AlertActionTypes,
  AlertObject,
  AlertState,
  REMOVE_ALERT,
  SET_ALERT,
} from "./alert.types";

const initialState: AlertState = [];

const alertReducer = function (
  state: AlertState = initialState,
  action: AlertActionTypes
): AlertState {
  switch (action.type) {
    case SET_ALERT:
      return [...state, action.payload];
    case REMOVE_ALERT:
      return state.filter((a: AlertObject) => a.id !== action.payload);
    default:
      return state;
  }
};

export default alertReducer;
