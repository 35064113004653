import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { AnalyticsInitialise } from "./api/analytics";
import { ThemeColour } from "./common/theme.types";
import AlertContainer from "./components/layout/AlertContainer";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import Routes from "./components/Routes";
import Lockdown from "./components/views/Lockdown";
import releases from "./components/views/WhatsNew/releases";
import "./css/index.min.css";
import store from "./store";
import { setAlert } from "./store/alert/alert.actions";
import Logger, { setAlertFunc } from "./utils/logger";
import Version, { sortByVersion } from "./utils/Version";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./styles/theme";

const LOCKDOWN: boolean = false;
const KEYS = ["test.firstaustralia"];

declare global {
  interface Window {
    Logger: any;
    Version: Version;
  }
}

window.Logger = window.Logger || {};
window.Version = releases.sort((a, b) =>
  sortByVersion(a.version, b.version)
)[0].version;

const App = () => {
  const KEYED = KEYS.reduce(
    (p: boolean, c) => p || window.location.hostname.startsWith(c),
    false
  );
  const unlocked =
    !LOCKDOWN || window.location.hostname.startsWith("localhost") || KEYED;

  useEffect(() => {
    AnalyticsInitialise();
    setAlertFunc((m: string | string[], a?: ThemeColour, t?: number) =>
      store.dispatch(setAlert(m, a, t) as any)
    );
    window.Logger = Logger;
    // Once lockdown is lifted, no longer allow test.firstaustralia.org
    if (!LOCKDOWN && KEYED)
      window.location.replace("https://firstaustralia.systems");
    // store.dispatch(loadUser() as any);
    // setAuthToken(localStorage.token);
  }, []);

  return unlocked ? (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Navbar title="FIRST Australia" />
          <main id="main">
            <Sidebar />
            <div id="content">
              <Routes />
            </div>
          </main>
          <AlertContainer />
          {/* <Footer /> */}
        </Router>
      </Provider>
    </ThemeProvider>
  ) : (
    <Lockdown />
  );
};

export default App;
