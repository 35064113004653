import { createSelector } from "reselect";
import { IUser } from "../../api/api.types";
import { FIRSTSeason } from "../../common/backend.types";
import {
  computeKitCost,
  computePayments,
  computeShippingCost,
  computeTeamCost,
  computeTotalCost,
} from "../../utils/getCost";
import { Address } from "../address/address.types";
import { Payment } from "../payment/payment.types";
import { RootState } from "../root-reducer";
import { Shipment } from "../shipping/shipment.types";
import { Team } from "../teams/team.types";

interface UserProps {
  userId: IUser["_id"] | "me";
  [x: string]: any;
}

type UserDetails = {
  user: IUser;
  teams: Team[];
  addresses: Address[];
  shipments: Shipment[];
  payments: Payment[];
  season: FIRSTSeason;
};
type MaybeUserDetails = Omit<UserDetails, "user"> & {
  user?: IUser;
};
/// Selector functions
const selectUserDetails = (
  state: RootState,
  props: UserProps
): MaybeUserDetails => {
  // logger.info(
  //   {
  //     user: state.users.all.find((u) => u._id === props.userId),
  //     userId: props.userId,
  //   },
  //   "selectUserDetails"
  // );
  return props.userId === "me"
    ? {
        user: state.auth?.user ?? undefined,
        teams: state.team.mine,
        addresses: state.address.mine,
        shipments: state.shipment.mine,
        payments: state.payment.mine,
        season: state.settings.season,
      }
    : {
        user: state.users.all.find((u) => u._id === props.userId),
        teams: state.team.all.filter((t) => t.user === props.userId),
        addresses: state.address.all.filter((a) => a.user === props.userId),
        shipments: state.shipment.all.filter(
          (s) => s.receiver === props.userId
        ),
        payments: state.payment.all.filter((t) => t.payer === props.userId),
        season: state.settings.season,
      };
};

const UserIsDefined = (s: MaybeUserDetails): s is UserDetails =>
  s.user !== undefined;

const constructUser = (s: MaybeUserDetails) =>
  !UserIsDefined(s)
    ? undefined
    : {
        ...s.user,
        /** Total amount user has accrued */
        up: computeTotalCost(s),
        /** Total amount user has paid */
        down: computePayments(s) + (s.user.credit ?? 0),
        shippingCost: computeShippingCost(s),
        teamCost: computeTeamCost(s),
        kitCost: computeKitCost(s),
        currentSeasonExclGst:
          computeTotalCost({
            ...s,
            teams: s.teams.filter((t) => t.season === s.season && !t.deleted),
            incl_gst: false,
          }) -
          computeShippingCost({
            ...s,
            teams: s.teams.filter((t) => t.season === s.season && !t.deleted),
          }),
        offseasonCostExclGst: 0,
        // computeTotalCost({
        //   ...s,
        //   teams: s.teams.filter((t) => t.season !== s.season && !t.deleted),
        //   incl_gst: false,
        // }) -
        // computeShippingCost({
        //   ...s,
        //   teams: s.teams.filter((t) => t.season !== s.season && !t.deleted),
        // }),
      };

export const makeUserWithMoneys = () =>
  createSelector(selectUserDetails, constructUser);

export type UserWithMoneys = NonNullable<ReturnType<typeof constructUser>>;
