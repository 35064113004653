import { FIRSTProgram, FIRSTSeason } from "../common/backend.types";
import { Address } from "../store/address/address.types";
import { Payment } from "../store/payment/payment.types";

type TeamItem = {
  program: FIRSTProgram;
  season: FIRSTSeason;
  post_registered?: boolean;
  ship_kit?: boolean;
};

export const costOfShipping = (s: Address["state"]): number => {
  switch (s) {
    case "VIC":
      return 20;
    case "ACT":
      return 20;
    case "NT":
      return 55;
    case "QLD":
      return 40;
    case "SA":
      return 30;
    case "NSW":
      return 20;
    case "WA":
      return 45;
    case "TAS":
      return 30;
    default:
      return 0;
  }
};

/**
 * @param p Program
 * @param pr Pre-registered?
 */
export const costOfProgramTeam = (
  p: FIRSTProgram,
  s: FIRSTSeason,
  pr?: boolean
): number => {
  switch (s) {
    case FIRSTSeason.Energize:
      switch (p) {
        case FIRSTProgram.FLL_CHALLENGE:
          return pr ? 100 : 100;
        case FIRSTProgram.FLL_DISCOVER:
          return pr ? 25 : 25;
        case FIRSTProgram.FLL_EXPLORE:
          return pr ? 25 : 25;
        case FIRSTProgram.FTC:
          return pr ? 295 : 295;
        default:
          return 0;
      }
    default:
      switch (p) {
        case FIRSTProgram.FLL_CHALLENGE:
          return pr ? 100 : 100;
        case FIRSTProgram.FLL_DISCOVER:
          return pr ? 25 : 25;
        case FIRSTProgram.FLL_EXPLORE:
          return pr ? 25 : 25;
        case FIRSTProgram.FTC:
          return pr ? 295 : 295;
        default:
          return 0;
      }
  }
};

/**
 *
 * @param p Program of team
 * @param pr Should apply a pre-registration discount?
 * @returns
 */
export const costOfProgramKit = (
  p: FIRSTProgram,
  s: FIRSTSeason,
  pr?: boolean
): number => {
  switch (s) {
    case FIRSTSeason.Energize:
      switch (p) {
        case FIRSTProgram.FLL_CHALLENGE:
          return pr ? 375 : 400;
        case FIRSTProgram.FLL_EXPLORE:
          return pr ? 50 : 50;
        case FIRSTProgram.FLL_DISCOVER:
          return pr ? 50 : 50;
        default:
          return pr ? 0 : 0;
      }
    default:
      switch (p) {
        case FIRSTProgram.FLL_CHALLENGE:
          return pr ? 375 : 400;
        case FIRSTProgram.FLL_EXPLORE:
          return pr ? 50 : 50;
        case FIRSTProgram.FLL_DISCOVER:
          return pr ? 50 : 50;
        default:
          return pr ? 0 : 0;
      }
  }
};

export const teamCost = (team: TeamItem): number => {
  return (
    costOfProgramTeam(team.program, team.season) +
    (team.ship_kit
      ? costOfProgramKit(team.program, team.season, !team.post_registered)
      : 0)
  );
};

export const getCost = (teams: TeamItem[], discount: number = 0) =>
  Math.max(
    teams.reduce((p, c) => p + teamCost(c), -discount),
    0
  );
