import api from "../../utils/api.instance";
import { GIsArray, IAddress, IUser } from "../api.types";
import {
  DTOAddressCreate,
  DTOAddressGet,
  DTOAddressUpdate,
  GIsDTOAddressGet,
} from "../dtos/address.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<IAddress[]> => {
  const a = (await api.get("address/mine")).data;
  return GIsArray<DTOAddressGet>(a).map((ad) => GIsDTOAddressGet(ad));
};

const getAll = () => Service.run(_getAll, {}, "ADDRESS_GET_ALL");

const _getAll = async (): Promise<IAddress[]> => {
  const a = (await api.get("address")).data;
  return GIsArray<DTOAddressGet>(a).map((ad) => GIsDTOAddressGet(ad));
};

const getByUser = async (user_id: IUser["_id"]): Promise<IAddress[]> => {
  const a = (await api.get(`address/user/${user_id}`)).data;
  return GIsArray<DTOAddressGet>(a).map((ad) => GIsDTOAddressGet(ad));
};

const create = async (data: DTOAddressCreate): Promise<IAddress> => {
  return GIsDTOAddressGet((await api.post(`address`, data)).data);
};

const update = async (
  id: IAddress["_id"],
  data: DTOAddressUpdate
): Promise<IAddress> => {
  return GIsDTOAddressGet((await api.put(`address/${id}`, data)).data);
};

const remove = async (id: IAddress["_id"]): Promise<boolean> => {
  await api.delete(`address/${id}`);
  return true;
};

const AddressService = {
  getMine,
  getAll,
  getByUser,
  create,
  update,
  remove,
};

export default AddressService;
