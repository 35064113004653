import moment from "moment";
import { IUser } from "../api/api.types";
import {
  ProgramToLabel,
  ShipmentContent,
  UserRole,
} from "../common/backend.types";
import { teamNumber } from "../components/atomic/TeamNumber";
import { Compete } from "../store/compete/compete.types";
import { Team } from "../store/teams/team.types";
import { Tournament } from "../store/tournament/tournament.types";
import { Volley } from "../store/volley/volley.types";
import downloadCSV from "../utils/download_csv";
import { computePayments, computeTotalCost } from "../utils/getCost";
import { AppThunk } from "./app-thunk";

type TeamWithCoaches = Team & {
  coachUsers: IUser[];
};
type TeamWithCoachesAndTourns = TeamWithCoaches & {
  tournaments: Tournament[];
};

export const exportTournamentTeams =
  (tournament: Tournament): AppThunk =>
  async (dispatch, getState) => {
    const header = [
      "team_number",
      "team_name",
      "rookie_year",
      "affiliation",
      "coach_names",
      "coach_emails",
      "phone_numbers",
    ];

    const { team, compete, users } = getState();

    const teams = team.all
      .filter((t) =>
        compete.all
          .filter((c) => c.tournament_id === tournament._id)
          .map((c) => c.team_id)
          .includes(t._id)
      )
      .filter((t) => !t.deleted);

    const twcs: TeamWithCoaches[] = teams.map((t) => ({
      ...t,
      coachUsers: t.coaches
        .map((c) => users.all.find((u) => u._id === c))
        .filter((c) => c) as IUser[],
    }));

    const data = twcs.map((t) => [
      teamNumber(t),
      (t.team_name ?? "").replaceAll(/,/g, "_"),
      t.rookie_year.toString(),
      t.affiliation ?? "",
      `${t.coachUsers
        .map((c) => `${c.given_name} ${c.family_name}`)
        .join(";")}`,
      `${t.coachUsers.map((c) => c.email).join(";")}`,
      `${t.coachUsers.map((c) => c.phone).join(";")}`,
    ]);

    downloadCSV(tournament.name + "_teams.csv", data, header);
  };

export const exportTournamentVolunteers =
  (tournament: Tournament): AppThunk =>
  (dispatch, getState) => {
    const header = [
      "Email",
      "Given name",
      "Family name",
      "Phone",
      "Role",
      "Age range",
      "Emergency name",
      "Emergency phone",
      "WWCC",
      "affiliation",
      "dietary",
      "Previous roles",
      "Preferences",
    ];
    const { volley, users } = getState();

    const volleys = volley.all.filter(
      (v) => v.tournament_id === tournament._id
    );

    const fUsers = users.all
      .filter((t) => volleys.map((c) => c.user_id).includes(t._id))
      .map((u) => ({
        ...u,
        volleys: volleys.filter((v) => v.user_id === u._id),
      }));

    const data = fUsers.map((u) => [
      u.email,
      u.given_name ?? "",
      u.family_name ?? "",
      u.phone ?? "",
      u.volleys.map((v) => v.job?.role ?? "TBA").join("; "),
      ...(u.volunteer_profile
        ? [
            u.volunteer_profile.age_range,
            u.volunteer_profile.emergency_name?.replaceAll(/,/g, "_"),
            u.volunteer_profile.emergency_phone,
            u.volunteer_profile.wwcc?.replaceAll(/,/g, "_"),
            u.volunteer_profile.affiliation?.replaceAll(/,/g, "_") ?? "",
            u.volunteer_profile.dietary?.replaceAll(/,/g, "_") ?? "",
            u.volunteer_profile.history
              .filter((h) => h.program === tournament.program)
              .map((h) => h.role)
              .join(";"),
            u.volunteer_profile.preferences
              .filter((h) => h.program === tournament.program)
              .map((h, i) => `${i + 1}:${h.role}`)
              .join(";"),
          ]
        : []),
    ]);

    downloadCSV(tournament.name + "_volunteers.csv", data, header);
  };

export const exportTournaments =
  (mine: boolean = false): AppThunk =>
  (dispatch, getState) => {
    const { tournament, users, compete, volley } = getState();

    const twu = (mine ? tournament.mine : tournament.all).map((t) => ({
      ...t,
      Directors: t.directors.map((d) => users.all.find((u) => u._id === d)),
    }));

    const header = [
      "Program",
      "Tournament Name",
      "Type",
      "Start Date",
      "Length",
      "Teams",
      "Volunteers",
      "Locked?",
      "Street",
      "Suburb",
      "Postcode",
    ];

    const data: string[][] = twu.map((t) => [
      ProgramToLabel(t.program),
      t.name,
      (t.remote ? "Remote " : "") + t.type,
      moment(t.start).format("L"),
      `${t.n_days === 1 ? "1 day" : `${t.n_days} days`}`,
      compete.all.filter((c) => c.tournament_id === t._id).length +
        " of " +
        t.cap,
      `${volley.all.filter((v) => v.tournament_id === t._id).length}`,
      t.locked ? "Yes" : "No",
      [t.street1, t.street2, t.street3].join("; "),
      t.suburb,
      t.postcode,
    ]);

    downloadCSV(mine ? "tournaments.csv" : "all_tournaments.csv", data, header);
  };

export const exportTeams =
  (mine: boolean = false): AppThunk =>
  (dispatch, getState) => {
    const { tournament, users, settings, compete, team } = getState();

    const teams = team.all.filter(
      (t) => !t.deleted && t.season === settings.season
    );
    const d_teams = team.deleted_all.filter(
      (t) => t.deleted && t.season === settings.season
    );
    const header = [
      "Program",
      "Number",
      "Name",
      "Affiliation",
      "Coach names",
      "Coach emails",
      "Coach phones",
      "Pre-registered?",
      "Events",
      "Rookie year",
      "Ship kit?",
      "Deleted?",
    ];

    const twcs: TeamWithCoachesAndTourns[] = [...teams, ...d_teams].map(
      (t) => ({
        ...t,
        coachUsers: t.coaches
          .map((c) => users.all.find((u) => u._id === c))
          .filter((c) => c) as IUser[],
        tournaments: compete.all
          .filter((c) => c.team_id === t._id)
          .map((c) =>
            tournament.all.find(
              (t) => t._id === c.tournament_id && t.season === settings.season
            )
          )
          .filter((t) => t) as Tournament[],
      })
    );

    const data = twcs.map((t) => [
      ProgramToLabel(t.program),
      teamNumber(t),
      (t.team_name ?? "").replaceAll(/,/g, "_"),
      t.affiliation ?? "",
      `${t.coachUsers
        .map((c) => `${c.given_name} ${c.family_name}`)
        .join(";")}`,
      `${t.coachUsers.map((c) => c.email).join(";")}`,
      `${t.coachUsers.map((c) => c.phone).join(";")}`,
      t.post_registered ? "No" : "Yes",
      t.tournaments.map((t) => t?.name ?? "???").join("; "),
      t.rookie_year.toString(),
      t.ship_kit ? "Yes" : "No",
      t.deleted ? "Yes" : "No",
    ]);

    downloadCSV("all_teams.csv", data, header);
  };

type UsersMax = IUser & {
  teams: Team[];
  vols: Tournament[];
  up: number;
  down: number;
};

export const exportUsers =
  (mine: boolean = false): AppThunk =>
  (dispatch, getState) => {
    const {
      tournament,
      users,
      team,
      shipment,
      volley,
      address,
      payment,
      compete,
      settings,
    } = getState();

    const uwt = users.all
      .filter((u) => u.email_verified)
      .map((u) => ({
        ...u,
        teams: team.all
          .filter(
            (t) =>
              (t.user === u._id || t.coaches.includes(u._id)) &&
              t.season === settings.season
          )
          .map((t) => ({
            ...t,
            comps: compete.all
              .filter((c) => c.team_id === t._id)
              .map((c) => c.tournament_id),
          }))
          .map((t) => ({
            ...t,
            tourns: tournament.all
              .filter(
                (tourn) =>
                  tourn.season === settings.season &&
                  t.comps.includes(tourn._id)
              )
              .map((tourn) => tourn.name),
          })),
        vols: volley.all
          .filter((v) => v.user_id === u._id)
          .map((v) => ({
            tourn: tournament.all.find((t) => t._id === v.tournament_id),
            role: v.job?.role ?? "TBD",
          }))
          .filter((t) => t && t.tourn && t.tourn.season === settings.season),
        address: address.all.find((a) => a.user === u._id),
        shipments: shipment.all.filter(
          (s) =>
            s.receiver === u._id &&
            new Date(s.timestamp ?? 0).getFullYear() === 2022
        ),
        up: computeTotalCost({
          user: u,
          addresses: address.all.filter((a) => a.user === u._id),
          teams: team.all.filter((t) => t.user === u._id && !t.deleted),
          shipments: shipment.all.filter((s) => s.receiver === u._id),
          incl_gst: true,
        }),
        down: computePayments({
          user: u,
          payments: payment.all.filter((a) => a.payer === u._id),
        }),
      }));

    const header = [
      "Given name",
      "Family name",
      "Phone",
      "Email",
      "State",
      "Suburb",
      "Credit",
      "Team limit",
      "Roles",
      "Is coach?",
      "Teams",
      "Registered Events",
      "Money owing",
      "Money paid",
      "Balance",
      "2022 Shipments",
      "Is volunteer?",
      "Volunteer events",
      "Age range",
      "Emergency name",
      "Emergency phone",
      "WWCC",
      "Volunteer history",
      "Volunteer Preferences",
    ];

    const data: string[][] = uwt.map((u) => [
      u.given_name ?? "?",
      u.family_name ?? "?",
      u.phone ?? "?",
      u.email,
      u.address?.state ?? "?",
      u.address?.suburb ?? "?",
      "$" + u.credit.toFixed(2),
      u.team_limit.toString(),
      u.roles
        .map((r) => {
          switch (r) {
            case UserRole.Admin:
              return "Admin";
            case UserRole.Director:
              return "Director";
            case UserRole.Financier:
              return "Financier";
            case UserRole.Shipper:
              return "Shipper";
            default:
              return "User";
          }
        })
        .join("; "),
      u.teams.length ? "Yes" : "No",
      u.teams.map((t) => (t.team_name ?? "").replaceAll(/,/g, "_")).join("; "),
      u.teams
        .filter((t) => t.tourns.length)
        .map((t) => t.tourns.join(" and "))
        .join("; "),
      "$" + u.up.toFixed(2),
      "$" + u.down.toFixed(2),
      "$" + (u.up - u.down).toFixed(2),
      u.shipments
        .map(
          (s) =>
            s.consignment_number +
            "(" +
            s.contents.map(contentToString).join(" + ") +
            ")"
        )
        .join("; "),
      u.vols.length ? "Yes" : "No",
      u.vols.map((v) => `${v.tourn?.name ?? "???"} (${v.role})`).join("; "),
      u.volunteer_profile?.age_range ?? "",
      u.volunteer_profile?.emergency_name ?? "",
      u.volunteer_profile?.emergency_phone ?? "",
      u.volunteer_profile?.wwcc ?? "",
      u.volunteer_profile?.history
        .map((h) => `${ProgramToLabel(h.program)} ${h.role}`)
        .join("; ") ?? "",
      u.volunteer_profile?.preferences
        .map(
          (h, i, a) =>
            `${
              a.filter((x) => x.program === h.program).indexOf(h) + 1
            }: ${ProgramToLabel(h.program)} ${h.role}`
        )
        .join(": ") ?? "",
    ]);

    downloadCSV("all_users.csv", data, header);
  };

const contentToString = (x: ShipmentContent): string =>
  `${x.count}x${x.season.toString().substring(0, 4)} ${ProgramToLabel(
    x.program
  )}`;

export const exportVols =
  (mine: boolean = false): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const {
      tournament,
      settings,
      users,
      team,
      shipment,
      volley,
      address,
      payment,
    } = getState();

    const uwt = users.all
      .filter((u) => u.email_verified)
      .filter((u) => u.volunteer_profile)
      .map((u) => ({
        ...u,
        teams: team.all.filter(
          (t) => t.user === u._id || t.coaches.includes(u._id)
        ),
        vols: volley.all
          .filter((v) => v.user_id === u._id)
          .map((v) => ({
            tourn: tournament.all.find((t) => t._id === v.tournament_id),
            role: v.job?.role ?? "TBD",
          }))
          .filter((t) => t && t.tourn && t.tourn.season === settings.season),
        up: computeTotalCost({
          user: u,
          addresses: address.all.filter((a) => a.user === u._id),
          shipments: shipment.all.filter((s) => s.receiver === u._id),
          teams: team.all.filter((t) => t.user === u._id && !t.deleted),
          incl_gst: true,
        }),
        down: computePayments({
          user: u,
          payments: payment.all.filter((a) => a.payer === u._id),
        }),
      }))
      .filter((u) => u.vols.length > 0);
    const header = [
      "Email",
      "Given name",
      "Family name",
      "Phone",
      "Credit",
      "Team limit",
      "Roles",
      "Is coach?",
      "Teams",
      "Money owing",
      "Money paid",
      "Balance",
      "Is volunteer?",
      "Volunteer events",
      "Age range",
      "Emergency name",
      "Emergency phone",
      "WWCC",
      "Volunteer history",
      "Volunteer Preferences",
    ];

    const data: string[][] = uwt.map((u) => [
      u.email,
      u.given_name ?? "?",
      u.family_name ?? "?",
      u.phone ?? "?",
      "$" + u.credit.toFixed(2),
      u.team_limit.toString(),
      u.roles
        .map((r) => {
          switch (r) {
            case UserRole.Admin:
              return "Admin";
            case UserRole.Director:
              return "Director";
            case UserRole.Financier:
              return "Financier";
            case UserRole.Shipper:
              return "Shipper";
            default:
              return "User";
          }
        })
        .join("; "),
      u.teams.length ? "Yes" : "No",
      u.teams.map((t) => (t.team_name ?? "").replaceAll(/,/g, "_")).join("; "),
      "$" + u.up.toFixed(2),
      "$" + u.down.toFixed(2),
      "$" + (u.up - u.down).toFixed(2),
      u.vols.length ? "Yes" : "No",
      u.vols.map((v) => `${v.tourn?.name ?? "???"} (${v.role})`).join("; "),
      u.volunteer_profile?.age_range ?? "",
      u.volunteer_profile?.emergency_name ?? "",
      u.volunteer_profile?.emergency_phone ?? "",
      u.volunteer_profile?.wwcc ?? "",
      u.volunteer_profile?.history
        .map((h) => `${ProgramToLabel(h.program)} ${h.role}`)
        .join("; ") ?? "",
      u.volunteer_profile?.preferences
        .map(
          (h, i, a) =>
            `${
              a.filter((x) => x.program === h.program).indexOf(h) + 1
            }: ${ProgramToLabel(h.program)} ${h.role}`
        )
        .join(": ") ?? "",
    ]);

    downloadCSV("all_vols.csv", data, header);
  };
