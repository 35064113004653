/**
 * Action types
 */

import { DTOCompeteCreate, DTOCompeteUpdate } from "../../api/dtos/compete.dto";
import CompeteService from "../../api/services/compete.service";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import { Tournament } from "../tournament/tournament.types";
import {
  Compete,
  CompeteActionTypes,
  COMPETE_CREATE,
  COMPETE_DELETE,
  COMPETE_GET_ALL,
  COMPETE_UPDATE,
} from "./compete.types";

export const CompeteGetAllAction = (data: Compete[]): CompeteActionTypes => ({
  type: COMPETE_GET_ALL,
  payload: data,
});

export const CompeteUpdateAction = (
  id: Compete["_id"],
  data: Compete
): CompeteActionTypes => ({
  type: COMPETE_UPDATE,
  payload: { id, data },
});

export const CompeteCreateAction = (data: Compete[]): CompeteActionTypes => ({
  type: COMPETE_CREATE,
  payload: data,
});

export const CompeteDeleteAction = (
  id: Compete["_id"]
): CompeteActionTypes => ({
  type: COMPETE_DELETE,
  payload: id,
});

/**
 * Actions
 */

export const competeGetAll = (): AppThunk => async (dispatch) => {
  try {
    const t = await CompeteService.getAll();
    dispatch(CompeteGetAllAction(t));
  } catch (error: any) {
    logger.logError(error, logger.warn, "compete.action :: getAll", true);
  }
};

export const competeCreate =
  (data: DTOCompeteCreate, admin?: boolean): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = admin
        ? await CompeteService.createAdmin(data.tournament_id, data.team_ids)
        : await CompeteService.create(data.tournament_id, data.team_ids);
      dispatch(CompeteCreateAction(t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "compete.action :: competeCreate",
        true
      );
      return false;
    }
  };

export const competeUpdate =
  (id: Compete["_id"], data: DTOCompeteUpdate): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = await CompeteService.update(id, data);
      dispatch(CompeteUpdateAction(id, t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "compete.action :: competeUpdate",
        true
      );
      return false;
    }
  };

export const competeMove =
  (
    id: Compete["_id"],
    to: Tournament["_id"],
    admin?: boolean
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = admin
        ? await CompeteService.moveTeamAdmin(id, to)
        : await CompeteService.moveTeam(id, to);
      dispatch(CompeteUpdateAction(id, t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "compete.action :: competeUpdate",
        true
      );
      return false;
    }
  };

export const competeDelete =
  (id: Compete["_id"], admin?: boolean): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      if (admin) await CompeteService.removeAdmin(id);
      else await CompeteService.remove(id);
      dispatch(CompeteDeleteAction(id));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "compete.action :: competeDelete",
        true
      );
      return false;
    }
  };

export const competeAdvance =
  (id: Compete["_id"]): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const c = await CompeteService.advanceTeam(id);
      dispatch(CompeteCreateAction([c]));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "compete.action :: competeAdvance",
        true
      );
      return false;
    }
  };

export const createScoresheet = () => {};

export const getScoreTeams = (t: Tournament["_id"]) => {};
