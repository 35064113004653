import api from "../../utils/api.instance";
import { GIsArray, ICompete, ITeam, ITournament } from "../api.types";
import {
  DTOCompeteGet,
  DTOCompeteUpdate,
  GIsDTOCompeteGet,
} from "../dtos/compete.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<ICompete[]> => {
  const a = (await api.get("competes/mine")).data;
  return GIsArray<DTOCompeteGet>(a).map((ad) => GIsDTOCompeteGet(ad));
};

const getAll = () => Service.run(_getAll, {}, "COMPETE_GET_ALL");

const _getAll = async (): Promise<ICompete[]> => {
  const a = (await api.get("competes")).data;
  return GIsArray<DTOCompeteGet>(a).map((ad) => GIsDTOCompeteGet(ad));
};

const create = async (
  tournament: ITournament["_id"],
  teams: ITeam["_id"][]
): Promise<ICompete[]> => {
  const a = (await api.post(`competes/${tournament}`, teams)).data;
  return GIsArray<DTOCompeteGet>(a).map((ad) => GIsDTOCompeteGet(ad));
};

const createAdmin = async (
  tournament: ITournament["_id"],
  teams: ITeam["_id"][]
): Promise<ICompete[]> => {
  const a = (await api.post(`competes/admin/${tournament}`, teams)).data;
  return GIsArray<DTOCompeteGet>(a).map((ad) => GIsDTOCompeteGet(ad));
};

const advanceTeam = async (compete_id: ICompete["_id"]): Promise<ICompete> => {
  const a = (await api.post(`competes/promote/${compete_id}`)).data;
  return GIsDTOCompeteGet(a);
};

const moveTeam = async (
  compete_id: ICompete["_id"],
  to: ITournament["_id"]
): Promise<ICompete> => {
  const a = (await api.put(`competes`, { compete_id, to })).data;
  return GIsDTOCompeteGet(a);
};

const moveTeamAdmin = async (
  compete_id: ICompete["_id"],
  to: ITournament["_id"]
): Promise<ICompete> => {
  const a = (await api.put(`competes/admin`, { compete_id, to })).data;
  return GIsDTOCompeteGet(a);
};

const update = async (compete_id: ICompete["_id"], data: DTOCompeteUpdate) => {
  const a = (await api.put(`competes/update/${compete_id}`, data)).data;
  return GIsDTOCompeteGet(a);
};

const remove = async (compete_id: ICompete["_id"]): Promise<boolean> => {
  await api.delete(`competes/${compete_id}`);
  return true;
};

const removeAdmin = async (compete_id: ICompete["_id"]): Promise<boolean> => {
  await api.delete(`competes/admin/${compete_id}`);
  return true;
};

const CompeteService = {
  getMine,
  getAll,
  create,
  createAdmin,
  advanceTeam,
  moveTeam,
  moveTeamAdmin,
  update,
  remove,
  removeAdmin,
};

export default CompeteService;
