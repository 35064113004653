import React from "react";
import { ThemeColour } from "../../common/theme.types";

const Spinner = ({ variant, className = "" }: Props) => {
  return (
    <div className={`spinner ${className}`}>
      <div className={`rect1  bg-${variant}`}></div>
      <div className={`rect2  bg-${variant}`}></div>
      <div className={`rect3  bg-${variant}`}></div>
      <div className={`rect4  bg-${variant}`}></div>
      <div className={`rect5  bg-${variant}`}></div>
    </div>
  );
};

type Props = {
  variant: ThemeColour;
  className?: string;
};

export default Spinner;
