/**
 * Action types
 */

import { AnalyticsTrack } from "../../api/analytics";
import { IUser } from "../../api/api.types";
import {
  DTOTeamCopy,
  DTOTeamCreate,
  DTOTeamDummyCreate,
  DTOTeamUpdate,
} from "../../api/dtos/team.dto";
import TeamService from "../../api/services/team.service";
import { getCost } from "../../inventory/getCost";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import { UserObject } from "../auth/auth.types";
import { Tournament } from "../tournament/tournament.types";
import {
  Team,
  TeamActionTypes,
  TeamPublic,
  TEAM_CREATE,
  TEAM_DELETE,
  TEAM_GET_ALL,
  TEAM_GET_MINE,
  TEAM_GET_MINE_COACH,
  TEAM_GET_TOURN,
  TEAM_UNDELETE,
  TEAM_UPDATE,
} from "./team.types";

export const TeamGetMineAction = (data: Team[]): TeamActionTypes => ({
  type: TEAM_GET_MINE,
  payload: data,
});

export const TeamGetTournAction = (data: TeamPublic[]): TeamActionTypes => ({
  type: TEAM_GET_TOURN,
  payload: data,
});

export const TeamGetMineCoachAction = (data: Team[]): TeamActionTypes => ({
  type: TEAM_GET_MINE_COACH,
  payload: data,
});

export const TeamGetAllAction = (data: Team[]): TeamActionTypes => ({
  type: TEAM_GET_ALL,
  payload: data,
});

export const TeamUpdateAction = (
  id: Team["_id"],
  data: Team
): TeamActionTypes => ({
  type: TEAM_UPDATE,
  payload: { id, data },
});

export const TeamCreateAction = (data: Team): TeamActionTypes => ({
  type: TEAM_CREATE,
  payload: data,
});

export const TeamDeleteAction = (id: Team["_id"]): TeamActionTypes => ({
  type: TEAM_DELETE,
  payload: id,
});

export const TeamUndeleteAction = (id: Team["_id"]): TeamActionTypes => ({
  type: TEAM_UNDELETE,
  payload: id,
});

/**
 * Actions
 */

export const teamGetMine = (): AppThunk => async (dispatch, getState) => {
  try {
    const t = await TeamService.getMine();
    const { auth } = getState();
    const U = auth.user; // Shouldn't have to do this, linter :-/
    if (U) {
      const M = t.filter((team) => team.user === U._id);
      const C = t.filter(
        (team) => team.user !== U._id && team.coaches.includes(U._id)
      );
      dispatch(TeamGetMineAction(M));
      if (C.length) dispatch(TeamGetMineCoachAction(C));
    }
  } catch (error: any) {
    logger.logError(error, logger.warn, "team.action :: getMine", true);
  }
};

export const teamGetAll = (): AppThunk => async (dispatch) => {
  try {
    const t = await TeamService.getAll();
    dispatch(TeamGetAllAction(t));
  } catch (error: any) {
    logger.logError(error, logger.warn, "team.action :: getAll", true);
  }
};
export const teamGetByTournament =
  (tournament_id: Tournament["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      const t = await TeamService.getTournament(tournament_id);
      dispatch(TeamGetTournAction(t));
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: getTourn", true);
    }
  };

export const teamCreate =
  (data: DTOTeamCreate, canCopy: boolean = false): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = await TeamService.create(data);
      dispatch(TeamCreateAction(t));
      const address = getState().address.mine.find(
        (a) => a._id === data.shipping_address
      );
      AnalyticsTrack({
        name: "Register Team",
        data: {
          program: data.program,
          post_registered: data.post_registered ? data.post_registered : false,
          ship_kit: data.ship_kit,
          postcode: address ? address.postcode : "unknown",
          state: address ? address.state : "unknown",
          suburb: address ? address.suburb : "unknown",
          copied: false,
          canCopy,
        },
      });
      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamCreate", true);
      return false;
    }
  };

export const teamCopy =
  (data: DTOTeamCopy): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = await TeamService.copy(data);
      dispatch(TeamCreateAction(t));
      const address = getState().address.mine.find(
        (a) => a._id === t.shipping_address
      );
      AnalyticsTrack({
        name: "Register Team",
        data: {
          program: t.program,
          post_registered: data.post_registered ? data.post_registered : false,
          ship_kit: data.ship_kit,
          postcode: address ? address.postcode : "unknown",
          state: address ? address.state : "unknown",
          suburb: address ? address.suburb : "unknown",
          copied: false,
          canCopy: true,
        },
      });

      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamCreate", true);
      return false;
    }
  };
export const teamDummyCreate =
  (data: DTOTeamDummyCreate): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = await TeamService.createDummy(data);
      dispatch(TeamCreateAction(t));
      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamCreate", true);
      return false;
    }
  };

export const teamUpdate =
  (id: Team["_id"], data: DTOTeamUpdate): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = await TeamService.update(id, data);
      dispatch(TeamUpdateAction(id, t));
      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamUpdate", true);
      return false;
    }
  };

export const teamAddCoach =
  (id: Team["_id"], email: string): AppThunk<Promise<IUser["_id"] | null>> =>
  async (dispatch) => {
    try {
      const t = await TeamService.addCoach(id, email);
      dispatch(TeamUpdateAction(id, t));
      logger.success(`${email} added as a coach`, "team.action", true);
      return t.coaches[t.coaches.length - 1];
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamAddCoach", true);
      return null;
    }
  };
export const teamRmCoach =
  (id: Team["_id"], email: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = await TeamService.rmCoach(id, email);
      dispatch(TeamUpdateAction(id, t));
      logger.success(`${email} removed as a coach`, "team.action", true);
      return true;
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamRmCoach", true);
      return false;
    }
  };

export const teamDelete =
  (id: Team["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await TeamService.remove(id);
      dispatch(TeamDeleteAction(id));
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamDelete", true);
    }
  };

export const teamUndelete =
  (id: Team["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await TeamService.unremove(id);
      dispatch(TeamUndeleteAction(id));
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamUnDelete", true);
    }
  };

export const teamTransfer =
  (teamId: Team["_id"], userId: UserObject["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      const t = await TeamService.transfer(teamId, userId);
      dispatch(TeamUpdateAction(teamId, t));
    } catch (error: any) {
      logger.logError(error, logger.warn, "team.action :: teamUnDelete", true);
    }
  };

export const myTeamsCost = (): AppThunk => async (dispatch, getState) => {
  const { team, auth } = getState();
  return getCost(team.mine, auth.user?.credit ? auth.user.credit : 0);
};
