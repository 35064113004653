import {
  AdminActionTypes,
  AdminState,
  EVENT_CLEAR,
  EVENT_CREATE,
  EVENT_DELETE,
  EVENT_GET_ALL,
  EVENT_UPDATE,
} from "./admin.types";

const initialState: AdminState = {
  events: [],
  loading: true,
};

const adminReducer = function (
  state: AdminState = initialState,
  action: AdminActionTypes
): AdminState {
  switch (action.type) {
    case EVENT_GET_ALL:
      return { ...state, events: action.payload, loading: false };
    case EVENT_CREATE:
      return {
        ...state,
        events: [...state.events, action.payload],
        loading: false,
      };
    case EVENT_UPDATE:
      const p = action.payload;
      return {
        ...state,
        events: state.events.map((t) => (t._id === p._id ? p : t)),
        loading: false,
      };
    case EVENT_DELETE:
      return {
        ...state,
        events: state.events.filter((t) => t._id !== action.payload),
        loading: false,
      };
    case EVENT_CLEAR:
      return { ...state, events: [] };
    default:
      return state;
  }
};

export default adminReducer;
