import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { loginWithGoogle } from "../../../store/auth/auth.action";

import gLogo from "./g-logo.png";
import "./index.css";

const GoogleSigninButton = ({ loginWithGoogle, align = "center" }: Props) => {
  return (
    <button
      className="google-login-button"
      onClick={loginWithGoogle}
      style={{
        margin:
          align === "center"
            ? "auto"
            : align === "left"
            ? "0 auto 0 0"
            : "0 0 0 auto",
      }}
    >
      <div className="google-logo">
        <img src={gLogo} alt="Google Logo" />
      </div>
      Sign in with Google
    </button>
  );
};

const connector = connect(null, { loginWithGoogle });
type Props = ConnectedProps<typeof connector> & {
  align?: "center" | "left" | "right";
};

export default connector(GoogleSigninButton);
