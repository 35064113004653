import { createTheme } from "@material-ui/core/styles";
import Colours from "./colours";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    // status: {
    //   danger: React.CSSProperties['color'],
    // }
  }
  interface ThemeOptions {
    // status: {
    //   danger: React.CSSProperties['color']
    // }
  }
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    corevalues: Palette["primary"];
    innovationproject: Palette["primary"];
    robotdesign: Palette["primary"];
  }
  interface PaletteOptions {
    corevalues: PaletteOptions["primary"];
    innovationproject: PaletteOptions["primary"];
    robotdesign: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0066b3",
    },
    secondary: {
      main: "#ed1c24",
    },
    corevalues: {
      main: Colours.corevalues,
      light: "#fdece9",
      dark: "#f18e88",
    },
    innovationproject: {
      main: Colours.innovationproject,
      light: "#e9ecf8",
      dark: "#5384c4",
    },
    robotdesign: {
      main: Colours.robotdesign,
      light: "#ebf4eb",
      dark: "#45b16f",
    },
  },
  typography: {
    h5: {
      marginBottom: 16,
    },
  },
});

export default theme;
