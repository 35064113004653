import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faCaretDown,
  faCaretUp,
  faTimes,
  faSignOutAlt,
  faBars,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faCheck,
  faTrashAlt,
  faPlus,
  faExternalLinkAlt,
  faEdit,
  faUpload,
  faEye,
  faLaptopHouse,
  faUnlock,
  faLock,
  faDirections,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser as any,
  faCaretDown as any,
  faCaretUp as any,
  faTimes as any,
  faSignOutAlt as any,
  faBars as any,
  faChevronUp as any,
  faChevronRight as any,
  faChevronDown as any,
  faChevronLeft as any,
  faCheck as any,
  faTrashAlt as any,
  faPlus as any,
  faExternalLinkAlt as any,
  faEdit as any,
  faEye as any,
  faLaptopHouse as any,
  faUnlock as any,
  faLock as any,
  faUpload as any,
  faDirections as any,
  faExpandArrowsAlt as any
);

const icons = [
  "angle-down",
  "caret-down",
  "caret-up",
  "chevron-up",
  "chevron-right",
  "chevron-down",
  "chevron-left",
  "times",
  "sign-out-alt",
  "bars",
  "check",
  "plus",
  "eye",
  "trash-alt",
  "external-link-alt",
  "edit",
  "upload",
  "laptop-house",
  "lock",
  "unlock",
  "directions",
  "expand-arrows-alt",
] as const;

export type FaIcon = typeof icons[number];

export const IsFaIcon = (x: unknown): x is FaIcon =>
  typeof x === "string" && icons.includes(x as FaIcon);
