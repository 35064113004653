import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/root-reducer";
import { Team, TeamPublic } from "../../../store/teams/team.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { Compete } from "../../../store/compete/compete.types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import YesIcon from "@material-ui/icons/Check";
import NoIcon from "@material-ui/icons/Cancel";
import Colours from "../../../styles/colours";
import Rubrics from "./rubrics";

type LocalProps = {
  team: TeamPublic;
  compete: Compete["_id"];
  onClick: () => void;
};

const RubricTableRow = ({ auth, team, compete, rubrics, onClick }: Props) => {
  const [myRubrics, setMyRubrics] = useState<RubricAnswer[]>([]);

  useEffect(() => {
    setMyRubrics(rubrics.filter((r) => r.compete_id === compete));
  }, [compete, rubrics]);

  const classes = useStyles();
  return (
    <TableRow
      className={[
        classes.rubrictablerow,
        myRubrics
          .map((r) => r.user_id === auth.user?._id)
          .reduce((p, c) => c || p, false)
          ? classes.mytablerow
          : "",
      ].join(" ")}
      onClick={onClick}
    >
      <TableCell>{team.team_number}</TableCell>
      <TableCell>{team.team_name}</TableCell>
      <TableCell align="center">
        {Rubrics.isCoreComplete(myRubrics.map((r) => r.question_id)) ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="error" />
        )}
      </TableCell>
      <TableCell align="center">
        {Rubrics.isProjectComplete(myRubrics.map((r) => r.question_id)) ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="error" />
        )}
      </TableCell>
      <TableCell align="center">
        {Rubrics.isRobotComplete(myRubrics.map((r) => r.question_id)) ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="error" />
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    rubrictablerow: {
      cursor: "pointer",
      transition: "0.2s ease-in-out all",
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    mytablerow: {
      backgroundColor: theme.palette.grey[200],
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  rubrics: state.rubrics.all,
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(RubricTableRow);
