import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { Tournament } from "../../../store/tournament/tournament.types";
import CheckBox from "../../atomic/CheckBox";
import Spinner from "../../atomic/Spinner";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import { FaHeader } from "../../atomic/FaTexts";
import BasicAssignView from "./BasicAssignView";
import { IUser } from "../../../api/api.types";
import { Volley } from "../../../store/volley/volley.types";
import { userGetAll } from "../../../store/users/user.action";
import { volleyGetAll } from "../../../store/volley/volley.action";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { AnalyticsTrack } from "../../../api/analytics";

interface LocalProps {
  tournament?: Tournament;
  admin?: boolean;
}

export interface VolUser
  extends Volley,
    Partial<Omit<IUser, "_id" | "timestamp">> {
  volleyId: Volley["_id"];
}

const ManageVolunteerView = ({
  users,
  volleys,
  tournament: inTournament,
  allTournaments,
  userGetAll,
  volleyGetAll,
  tournamentGetAll,
}: Props) => {
  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "Manage Volunteers",
      },
    });
  }, []);

  const [tournament, setTournament] = useState<Tournament | undefined>(
    inTournament
  );
  const [volunteers, setVolunteers] = useState<VolUser[]>([]);

  const params = useParams<{ id?: string }>();

  useEffect(() => {
    userGetAll();
    volleyGetAll();
    tournamentGetAll();
  }, []);

  useEffect(() => {
    if (!tournament)
      setTournament(allTournaments.find((t) => t._id === params.id));
  }, [allTournaments, params]);

  useEffect(() => {
    if (!tournament) return;
    const vls = volleys.filter((v) => v.tournament_id === tournament._id);
    setVolunteers(
      vls.map((v) => ({
        ...users.find((u) => u._id === v.user_id),
        ...v,
        volleyId: v._id,
      }))
    );
  }, [tournament, volleys, users]);

  const classes = useStyles();
  return !tournament ? (
    <Spinner variant="primary" />
  ) : (
    <div className={classes.managevolunteerview}>
      <FaHeader size="md">{tournament.name}</FaHeader>
      <BasicAssignView tournament={tournament} volunteers={volunteers} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  managevolunteerview: {
    marginBottom: theme.spacing(5),
  },
}));

const mapStateToProps = (state: RootState) => ({
  volleys: state.volley.all,
  users: state.users.all,
  allTournaments: state.tournament.all,
});

const connector = connect(mapStateToProps, {
  userGetAll,
  volleyGetAll,
  tournamentGetAll,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(ManageVolunteerView);
