import React from "react";
import { ITeam } from "../../api/api.types";
import { FIRSTProgram } from "../../common/backend.types";

interface Props {
  number: number;
  program: FIRSTProgram;
  className?: string;
}
const pToLetter = (p: FIRSTProgram) => {
  switch (p) {
    case FIRSTProgram.FLL_CHALLENGE:
      return "C";
    case FIRSTProgram.FLL_DISCOVER:
      return "D";
    case FIRSTProgram.FLL_EXPLORE:
      return "E";
    default:
      return "";
  }
};

const TeamNumber = ({ number, program, className }: Props) => {
  return [FIRSTProgram.FTC, FIRSTProgram.FRC].includes(program) ? (
    <span className={`team-number ${className}`}>{number}</span>
  ) : (
    <span className={`team-number ${className}`}>
      AU-<strong>{number}</strong>
      {pToLetter(program)}
    </span>
  );
};

export const teamNumber = (team: ITeam) =>
  `AU-${team.team_number}${pToLetter(team.program)}`;

export default TeamNumber;
