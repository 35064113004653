import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/root-reducer";
import Dropdown from "../../atomic/Dropdown";
import { useParams } from "react-router";
import { Tournament } from "../../../store/tournament/tournament.types";
import Spinner from "../../atomic/Spinner";
import {
  scoresheetCreate,
  scoresheetGetAll,
  scoresheetGetMine,
} from "../../../store/scoring/scoring.action";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import { Team, TeamPublic } from "../../../store/teams/team.types";
import Selector from "../../atomic/Selector";
import TextField from "@material-ui/core/TextField";
import { FaHeader, FaText } from "../../atomic/FaTexts";
import ScoreCalculator from "./forms/ScoreCalculator";
import { Games } from "./games/types";
import { ScoreAnswer } from "../../../api/api.types";
import FaModal from "../../atomic/FaModal";
import CreateScoresheet from "./CreateScoresheet";
import { FaButton, FaIconButton } from "../../atomic/FaButtons";
import CreateIcon from "@material-ui/icons/Add";
import { userGetTourn } from "../../../store/users/user.action";
import ScoresheetHistory from "./ScoresheetHistory";
import logger from "../../../utils/logger";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { competeGetAll } from "../../../store/compete/compete.action";
import { eventGetAll } from "../../../store/admin/admin.action";
import { teamGetByTournament } from "../../../store/teams/team.action";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { validateAuth } from "../../../utils/auth.validate";
import ScoresheetView from "./ScoresheetView";
import RefreshIcon from "@material-ui/icons/Sync";
import moment from "moment";
import useInterval from "../../../utils/hooks/useInterval";
import { selectAllTournaments } from "../../../store/tournament/tournament.selector";

interface RowData extends TeamPublic {
  r1?: number;
  r2?: number;
  r3?: number;
}

const UNDEFINED = -2000;
const MIN_SCORE = 5;

type LocalProps = {};

type Params = { tournament_id: string };

const sanitise = (x: string): string =>
  x
    .replaceAll(" ", "-")
    .replaceAll(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();

const datesort = (a: Scoresheet, b: Scoresheet) =>
  new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

const max = (x: RowData, round: 1 | 2 | 3 = 1) => {
  let raw = [x.r1, x.r2, x.r3].filter((r) => r !== undefined) as number[];
  if (raw.length < round) return UNDEFINED;
  let m = Math.max(...raw);
  if (round === 1) return m;
  let idx = raw.indexOf(m);
  raw = raw.filter((r, i) => i !== idx);
  m = raw.length ? Math.max(...raw) : UNDEFINED;
  if (round === 2) return m;
  idx = raw.indexOf(m);
  raw = raw.filter((r, i) => i !== idx);
  return raw.length ? Math.max(...raw) : UNDEFINED;
};

const teamsort = (a: RowData, b: RowData) => {
  // First round
  let ma = max(a, 1);
  let mb = max(b, 1);
  if (ma !== mb) return mb - ma;
  // Second round
  ma = max(a, 2);
  mb = max(b, 2);
  if (ma !== mb) return mb - ma;
  // Final round
  return max(b, 3) - max(a, 3);
};

const RankingView = ({
  auth,
  scoresheets,
  teams,
  tournaments,
  competes: allCompetes,
  users,
  loading,
  myScores,
  scoresheetGetAll,
  tournamentGetAll,
  competeGetAll,
  teamGetByTournament,
  userGetTourn,
  scoresheetGetMine,
}: Props) => {
  const { tournament_id } = useParams<Params>();
  const [view, setView] = useState<Scoresheet | undefined>();
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());

  const [tournament, setTournament] = useState<Tournament>();
  const competes = allCompetes.filter(
    (c) => c.tournament_id === tournament?._id
  );

  const [data, setData] = useState<RowData[]>([]);

  useEffect(() => {
    setLastUpdate(new Date());
    setRefreshing(false);
  }, [scoresheets]);

  useEffect(() => {
    tournamentGetAll();
    competeGetAll();
    // eventGetAll();
  }, []);

  useEffect(() => {
    if (validateAuth(auth) && tournament) {
      scoresheetGetMine(tournament._id);
    }
  }, [auth, tournament]);

  useEffect(() => {
    if (tournament) return;
    const tournByName = tournaments.find(
      (t) => sanitise(t.name) === sanitise(tournament_id)
    );
    if (tournByName) setTournament(tournByName);
    else setTournament(tournaments.find((t) => t._id === tournament_id));
  }, [tournaments, tournament_id, tournament]);

  useEffect(() => {
    if (!tournament?._id) return;
    scoresheetGetAll(tournament._id);
    teamGetByTournament(tournament._id);
  }, [tournament?._id]);

  useEffect(() => {
    const game = Games.find((g) => g.season === tournament?.season);
    if (!game) return setData(teams.map((t) => t as RowData));
    setData(
      teams
        .map((t) => {
          const compete = competes.find((c) => c.team_id === t._id);
          if (!compete) return { ...t } as RowData;
          const r1s = scoresheets
            .filter((s) => s.compete_id === compete._id && s.round === 1)
            .sort(datesort)[0];
          const r2s = scoresheets
            .filter((s) => s.compete_id === compete._id && s.round === 2)
            .sort(datesort)[0];
          const r3s = scoresheets
            .filter((s) => s.compete_id === compete._id && s.round === 3)
            .sort(datesort)[0];
          return {
            ...t,
            r1: r1s ? game.score(r1s.answers) : undefined,
            r2: r2s ? game.score(r2s.answers) : undefined,
            r3: r3s ? game.score(r3s.answers) : undefined,
          } as RowData;
        })
        .sort(teamsort)
    );
  }, [tournament?.season, teams, scoresheets]);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  /** debounce */
  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    if (tournament) {
      scoresheetGetAll(tournament._id);
      scoresheetGetMine(tournament._id);
    } else setRefreshing(false);

    // setTimeout(() => setRefreshing(false), 20000);
  }, [tournament]);

  // To make sure it loads immediately
  useEffect(handleRefresh, [handleRefresh]);

  useInterval(
    useCallback(() => {
      handleRefresh();
    }, [handleRefresh]),
    5 * 30 * 1000
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [resetCount, setResetCount] = useState<number>(0);

  useEffect(() => {
    if (!containerRef.current) return;
    if (resetCount > 10)
      setTimeout(() => {
        if (!containerRef.current) return;

        // containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        containerRef.current.scrollBy({ top: -99999999 });
        setTimeout(() => setResetCount(0), 2000);
      }, 2000);
  }, [containerRef.current, resetCount]);

  useInterval(
    useCallback(() => {
      if (resetCount > 10) return;
      // console.log(containerRef);
      const div = containerRef.current;
      if (div) {
        // console.log(
        //   div.scrollTop + div.clientHeight + " =?= " + div.scrollHeight
        // );
        if (div.scrollTop + div.clientHeight >= div.scrollHeight - 4) {
          setResetCount(resetCount + 1);
        } else {
          div.scrollBy({
            top: 5,
            behavior: "smooth",
          });
        }
      }
    }, [containerRef, resetCount]),
    100
  );

  const classes = useStyles();
  return loading ? (
    <Spinner variant="challenge" />
  ) : (
    <div className={classes.refereeview} ref={containerRef}>
      {!tournament ? (
        <FaText>Tournament not found</FaText>
      ) : (
        <Fragment>
          <div className={classes.topbar}>
            <div className={classes.centered}>
              <FaHeader size="md">
                {tournament.name}{" "}
                {/* <FaIconButton
              onClick={handleRefresh}
              variant="contained"
              rounded
              className={classes.refreshBtn}
              color={refreshing ? "default" : "success"}
              disabled={refreshing}
            >
              <RefreshIcon />
            </FaIconButton> */}
              </FaHeader>
              <small>
                Last updated {moment(lastUpdate).format("HH:mm:ss")}
              </small>
            </div>
          </div>
          {/* @todo rankings */}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Team number</TableCell>
                <TableCell align="center">Team name</TableCell>
                <TableCell align="center">Rank</TableCell>
                <TableCell align="center">Round 1</TableCell>
                <TableCell align="center">Round 2</TableCell>
                <TableCell align="center">Round 3</TableCell>
                <TableCell align="center">Highest score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
              {data.map((t, i) => (
                <TableRow key={t._id}>
                  <TableCell align="center">{t.team_number}</TableCell>
                  <TableCell align="center">{t.team_name}</TableCell>
                  <TableCell align="center">
                    {max(t) === UNDEFINED ? "-" : i + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      const compete = competes.find((c) => c.team_id === t._id);
                      if (compete)
                        setView(
                          myScores
                            .filter(
                              (s) =>
                                s.compete_id === compete._id && s.round === 1
                            )
                            .sort(datesort)[0]
                        );
                    }}
                    className={
                      myScores
                        .filter(
                          (s) =>
                            s.compete_id ===
                              competes.find((c) => c.team_id === t._id)?._id &&
                            s.round === 1
                        )
                        .sort(datesort).length
                        ? classes.link
                        : ""
                    }
                  >
                    {t.r1 === undefined ? "-" : Math.max(t.r1, MIN_SCORE)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      const compete = competes.find((c) => c.team_id === t._id);
                      if (compete)
                        setView(
                          myScores
                            .filter(
                              (s) =>
                                s.compete_id === compete._id && s.round === 2
                            )
                            .sort(datesort)[0]
                        );
                    }}
                    className={
                      myScores
                        .filter(
                          (s) =>
                            s.compete_id ===
                              competes.find((c) => c.team_id === t._id)?._id &&
                            s.round === 2
                        )
                        .sort(datesort).length
                        ? classes.link
                        : ""
                    }
                  >
                    {t.r2 === undefined ? "-" : Math.max(t.r2, MIN_SCORE)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      const compete = competes.find((c) => c.team_id === t._id);
                      if (compete)
                        setView(
                          myScores
                            .filter(
                              (s) =>
                                s.compete_id === compete._id && s.round === 3
                            )
                            .sort(datesort)[0]
                        );
                    }}
                    className={
                      myScores
                        .filter(
                          (s) =>
                            s.compete_id ===
                              competes.find((c) => c.team_id === t._id)?._id &&
                            s.round === 3
                        )
                        .sort(datesort).length
                        ? classes.link
                        : ""
                    }
                  >
                    {t.r3 === undefined ? "-" : Math.max(t.r3, MIN_SCORE)}
                  </TableCell>
                  <TableCell align="center">
                    {max(t) === UNDEFINED ? "-" : Math.max(max(t), MIN_SCORE)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Fragment>
      )}
      {Games.find((g) => g.season === tournament?.season) !== undefined && (
        <FaModal open={Boolean(view)} onClose={() => setView(undefined)}>
          {view ? (
            <ScoresheetView
              scoresheet={view}
              game={Games.find((g) => g.season === tournament?.season)!}
            />
          ) : (
            <div />
          )}
        </FaModal>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    refereeview: { maxHeight: "100%", overflow: "auto" },
    topbar: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    centered: {
      margin: "auto",
    },
    teamselector: { width: 200 },
    body: {
      display: "flex",
      flexDirection: "column",
    },
    tablebody: {
      // overflowY: "scroll",
    },
    link: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
      color: "blue",
      textDecoration: "underline",
    },
    refreshBtn: {
      height: 32,
      width: 32,
      marginLeft: theme.spacing(1),
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  scoresheets: state.scoring.all,
  myScores: state.scoring.mine,
  teams: state.team.tournament,
  tournaments: selectAllTournaments(state),
  competes: state.compete.all,
  users: state.users.all,
  loading:
    state.team.loading || state.tournament.loading || state.compete.loading,
});

const connector = connect(mapStateToProps, {
  scoresheetGetAll,
  userGetTourn,
  tournamentGetAll,
  competeGetAll,
  teamGetByTournament,
  scoresheetGetMine,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(RankingView);
