/** Here we define all the verisons as a list of version changes */

import Version from "../../../utils/Version";

const releases: VersionChangeObject[] = [
  {
    version: new Version(22, 0, 16),
    intro: ["Added tokens for scoresheet creation"],
    sections: [],
  },
  {
    version: new Version(22, 0, 15),
    intro: ["Updated admin user export"],
    sections: [],
  },
  {
    version: new Version(22, 0, 14),
    intro: [
      "Fixed bug in shipping calculation for FTC.",
      "Upgraded some views to include date stamps",
    ],
    sections: [],
  },
  {
    version: new Version(22, 0, 13),
    intro: ["Added SuperPowered score calculator."],
    sections: [],
  },
  {
    version: new Version(22, 0, 12),
    intro: ["Updated FLL resources for the 2022 ENERGIZE SuperPowered season."],
    sections: [],
  },
  {
    version: new Version(22, 0, 11),
    intro: ["Fixed bug in shipper system, and admin volunteer export."],
    sections: [],
  },
  {
    version: new Version(22, 0, 10),
    intro: ["Updated terms and conditions."],
    sections: [],
  },
  {
    version: new Version(22, 0, 9),
    intro: ["Improved scoresheet printing."],
    sections: [],
  },
  {
    version: new Version(22, 0, 8),
    intro: ["Updated scoring rule to allow train + circle in Cargo Connect."],
    sections: [],
  },
  {
    version: new Version(22, 0, 7),
    intro: ["Added error message on invoice upload if no billing address."],
    sections: [],
  },
  {
    version: new Version(22, 0, 6),
    intro: ["Fixed issue with volunteer export"],
    sections: [],
  },
  {
    version: new Version(22, 0, 5),
    intro: [
      "Fixed error in Pay Now screen (total to pay not affected).",
      "Removed erroneous note describing an old team limit.",
    ],
    sections: [],
  },
  {
    version: new Version(22, 0, 4),
    intro: ["Fixed broken export buttons."],
    sections: [],
  },
  {
    version: new Version(22, 0, 3),
    intro: ["Updated Xetta payment link."],
    sections: [],
  },
  {
    version: new Version(22, 0, 2),
    intro: ["Adjusted financial calculations."],
    sections: [],
  },
  {
    version: new Version(22, 0, 1),
    intro: ["Updated name of 2022 season."],
    sections: [],
  },
  {
    version: new Version(22, 0, 0),
    intro: [
      "New year, new season, new robots!  Welcome to the 2022 registration system.",
      "This year, the system is largely the same except for a few key areas.",
    ],
    sections: [
      {
        title: "Season selector",
        changes: [
          "We needed to support several seasons happening concurrently as the FIRST Forward events continue to take place, so you can now select which season you're looking at from the navbar.",
          "When in the context of a particular season, you'll only see registrations and tournaments relevant to that season; when you create teams, they're specifically for that season only.",
          "To support recurring users, we (for the first time ever!) now allow you to copy your registration from last year to this year!  Look for the blue button on your Coach Dashboard.",
        ],
      },
      {
        title: "Shipping prices",
        changes: [
          "The way we calculate shipping has been tweaked to allow more people to pay by credit card.  Last year, you had to pay by invoice if you needed more than 10 kits shipped to any location, but this year we've set it up so that you can pay by CC, though you will be charged more for every additional 10 kits involved (due to increased shipping prices).",
          "You'll note as well that shipping prices are NOT seasonal -- so, your shipments and payments from all seasons will be displayed (so that any outstanding payments are handled correctly).  We've tried to make it as simple as possible.",
          "Since the payment calculation has been tweaked, please double check that your numbers are correct - we don't want to accidentally overcharge anyone.",
        ],
      },
      {
        title: "Team limits",
        changes: [
          "We've removed the old limit of 2 teams per person;  there's still a limit on kits (1 between 2 for Challenge, 1:1 for Discover and Explore) and a maximum number of kits we can distribute (based on how many we ordered from LEGO), but outside that you can register as many teams as you need. ",
        ],
      },
    ],
  },
  {
    version: new Version(21, 4, 9),
    intro: ["Enabled FRC volunteering history"],
    sections: [],
  },
  {
    version: new Version(21, 4, 8),
    intro: ["Enabled FRC tournaments & volunteering (though not teams)."],
    sections: [],
  },
  {
    version: new Version(21, 4, 7),
    intro: [
      "Created table view and scoresheet revisions for referees - should make things much easier.",
    ],
    sections: [],
  },
  {
    version: new Version(21, 4, 6),
    intro: ["Created a compact view for referees - slightly easier to fill in"],
    sections: [],
  },
  {
    version: new Version(21, 4, 5),
    intro: [
      "Fixed bug where users with multiple teams saw duplicated rubrics",
      "Fixed the coach rubric print action - it's not very good yet, but at least it's something.",
    ],
    sections: [],
  },
  {
    version: new Version(21, 4, 4),
    intro: [
      "Added event team handling",
      "Secondary coaches can now move teams and see rubrics",
    ],
    sections: [],
  },
  {
    version: new Version(21, 4, 3),
    intro: [
      "Added event team handling",
      "Secondary coaches can now move teams and see rubrics",
    ],
    sections: [],
  },
  {
    version: new Version(21, 4, 2),
    intro: [
      "Fixed error for coach rubric access",
      "Fixed duplicated labels on rubrics",
      "Fixed bug in judge advisor exports",
    ],
    sections: [],
  },
  {
    version: new Version(21, 4, 1),
    intro: [
      "A whole bunch of minor updates just in time for tournament season",
      "Projector view for rankings - so you can now put the rankings on a screen and just leave it there",
    ],
    sections: [],
  },
  {
    version: new Version(21, 4, 0),
    intro: ["Improved site performance"],
    sections: [],
  },
  {
    version: new Version(21, 3, 1),
    intro: [
      "Updated admin volunteer exports",
      "The system now prevents volunteers and teams from doubling up to events.",
      "Admins can delete tournament volunteers",
    ],
    sections: [],
  },
  {
    version: new Version(21, 3, 1),
    intro: ["Added tournament shipping"],
    sections: [],
  },
  {
    version: new Version(21, 3, 0),
    intro: ["Added referee, judge, judge advisor and ranking views"],
    sections: [],
  },
  {
    version: new Version(21, 2, 5),
    intro: [
      "Added admin volunteer view",
      "Fixed issue with tournaments registering deleted teams",
    ],
    sections: [],
  },
  {
    version: new Version(21, 2, 4),
    intro: [
      "Added dummy teams and tournaments for private testing",
      "Added rubric view for judging",
      "Added 'PDF verified' status to invoices to make sure we check things manually",
    ],
    sections: [],
  },
  {
    version: new Version(21, 2, 3),
    intro: ["Added thinkscape resource"],
    sections: [],
  },
  {
    version: new Version(21, 2, 2),
    intro: [
      "The number of containers on the Cargo Connect score calculator has been fixed.",
      "Secondary coaches have been given the ability to view their teams and see team resources; no editing power though",
      "Added a link to the Cargo Connect score calculator in the sidebar",
    ],
    sections: [],
  },
  {
    version: new Version(21, 2, 1),
    intro: [
      "Publicly available score calculators have been added for City Shaper, RePlay and Cargo Connect",
    ],
    sections: [],
  },
  {
    version: new Version(21, 2, 0),
    intro: [
      "Directors can now assign volunteer roles",
      "Added program resources for the FLL release",
      "Added admin date creation",
    ],
    sections: [],
  },
  {
    version: new Version(21, 1, 6),
    intro: [
      "Shippers can now delete comments",
      "Shipping view can be filtered by name or company",
    ],
    sections: [],
  },
  {
    version: new Version(21, 1, 5),
    intro: [
      "Admins can now move teams between events if required",
      'Financiers and admins can create "override payments"',
    ],
    sections: [],
  },
  {
    version: new Version(21, 1, 4),
    intro: ["Shipper views are now live - kits are ready to head on out!"],
    sections: [],
  },
  {
    version: new Version(21, 1, 3),
    intro: [
      "Minor bugfixes, including an error which was popping up on all logins",
    ],
    sections: [],
  },
  {
    version: new Version(21, 1, 2),
    intro: ["Lots of little bugfixes, and some new features"],
    sections: [
      {
        title: "Bugs",
        changes: [
          "Tournament directors can now see names, emails and phone numbers as they should",
          "Removed (hopefully) outstanding balance entries with $0.00, and added email to this table",
          "Fixed issue with tournament director management",
        ],
      },
      {
        title: "Minor new features",
        changes: [
          "Users can now sign up their Explore and FTC teams to tournaments",
          "Users can now add or remove secondary coaches from their teams",
          "More CSV exports for tournament directors and admins",
          "Limits added on programs so we don't over-sell kits",
          "Admins can now transfer team ownership between users (carefully)",
        ],
      },
    ],
  },
  {
    version: new Version(21, 1, 1),
    intro: [
      "Admins can now see more information about teams and users",
      "The team limit is now configurable per user - contact the FIRST Australia team if you need to register more than 2 teams.",
      "The volunteer registration process has been updated for clarity.",
    ],
    sections: [],
  },
  {
    version: new Version(21, 1, 0),
    intro: ["Volunteer handling is now in place"],
    sections: [
      {
        title: "Volunteering",
        changes: [
          "Users can now sign up for events for which they want to volunteer.  The interface is a little raw, keep an eye out on future updates for user friendly UI enhancements",
          "Users and tournament directors can see volunteer registrations.",
        ],
      },
      {
        title: "Other",
        changes: ["Tournament directors can now be added and removed."],
      },
    ],
  },
  {
    version: new Version(21, 0, 5),
    intro: [
      "Users can now create their volunteer profiles",
      "Tournament directors can now see more details on their tournament page",
    ],
    sections: [],
  },
  {
    version: new Version(21, 0, 4),
    intro: [],
    sections: [
      {
        title: "Pre-registration",
        changes: [
          "Pre-registration is closed as of midnight on April 10th, 2021.",
          "There is now no longer a pre-registration discount for FLL Challenge, and there are new limits on the number of teams and kits that can be registered",
        ],
      },
      {
        title: "Admin",
        changes: [
          "Tournament directors can now easily view the list of teams attending their event.",
          "Added better handling for user discounts",
          'Updated the "Upcoming Events" section to include the tournament/s for which you\'re registered',
          'Admins can now "un-delete" deleted teams, on request.',
        ],
      },
    ],
  },
  {
    version: new Version(21, 0, 3),
    intro: [
      "We now support creation and management of tournaments, though adding your team to a tournament is still on its way.",
      "Also, minor UI updates and fixes.",
      "Phone numbers are now mandatory for registered users, so that we can contact you if needed.",
    ],
    sections: [],
  },
  {
    version: new Version(21, 0, 2),
    intro: [
      "We had a bug in the financial breakdown; this just affected the view, not the actual calculations, and has now been fixed.",
    ],
    sections: [],
  },
  {
    version: new Version(21, 0, 1),
    intro: [
      "The first round of updates are mostly minor, in response to initial pre-registrations",
    ],
    sections: [
      {
        title: "Changes",
        changes: [
          'The payment summary in the "Pay Now" screen added a little more information',
          'The "Pay Now" screen now tries to prevent you from navigating away while POs are still uploading',
          "Discounts for tournament directors have been added",
          "Greater control for administrators",
        ],
      },
    ],
  },
  {
    version: new Version(21, 0, 0),
    intro: [
      "This is the 2021 update release for the FIRST Australia registration and management system",
      "This initial release contains many structural updates over the 2020 system, but is currently limited to supporting pre-registrations for the 2021 seasons.",
    ],
    sections: [
      {
        title: "Authentication",
        changes: [
          "Previous issues with email confirmation and forgotten passwords have been addressed.",
          "We now support Google Signin to simplify the process for compatible users",
        ],
      },
      {
        title: "Profile",
        changes: [
          "Providing a given name and family name is now mandatory",
          "The 2020 profile system has been significantly simplified and replaced with an 'Address' system",
        ],
      },
      {
        title: "Team Registration",
        changes: [
          "You can now register multiple teams at once",
          "Additional coaches can be nominated, who can see the team in their dashboard but not change details",
          "We no longer require a billing address at this stage in the process",
          "Finer control over which teams need a kit shipped",
        ],
      },
      {
        title: "Finance",
        changes: [
          "Credit card payments are now better integrated, with close to real-time confirmation",
          "Instead of paying per program and team, we now combine all fees into one number for simplicity",
          "Credit card payment no longer requires re-entry of all your information",
          "To pay by invoice, a PDF Purchase Order is now required.",
        ],
      },
    ],
  },
];

export default releases;

export type VersionChangeObject = {
  version: Version;
  intro: string[];
  sections: {
    title: string;
    changes: string[];
  }[];
};
