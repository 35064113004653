type Labels = [string, string, string, string];
interface ILabels {
  desc: {
    [key: string]: string;
  };
  label: {
    [key: string]: Labels;
  };
}

const innovation: ILabels = {
  desc: {
    identify: "Team had a clearly defined problem that was well researched.",
    design:
      "Team generated innovative ideas independently before selecting and planning which one to develop.",
    create:
      "Team developed an original idea or built on an existing one with a prototype model/drawing to represent their solution.",
    iterate:
      "Team shared their ideas, collected feedback, and included improvements in their solution.",
    communicate:
      "Team shared a creative and effective presentation of their current solution and its impact on their users",
  },
  label: {
    identify_a: [
      "Problem not clearly defined",
      "Partially clear definition of the problem",
      "Clear definition of the problem",
      "",
    ],
    identify_b: [
      "Minimal research",
      "Partial research from more than one source",
      "Clear, detailed research from a variety of sources",
      "",
    ],
    design_a: [
      "Minimal evidence of an inclusive selection process",
      "Partial evidence of an inclusive selection process",
      "Clear evidence of an inclusive selection process",
      "",
    ],
    design_b: [
      "Minimal evidence of an effective plan",
      "Partial evidence of an effective plan",
      "Clear evidence of an effective plan",
      "",
    ],
    create_a: [
      "Minimal development of innovative solution",
      "Partial development of innovative solution",
      "Clear development of innovative solution",
      "",
    ],
    create_b: [
      "Unclear model/drawing of solution",
      "Simple model/drawing that helps to share the solution",
      "Detailed model/drawing that helps to share the solution",
      "",
    ],
    iterate_a: [
      "Minimal sharing of their solution",
      "Shared their solution with user OR professional",
      "Shared their solution with user AND professional",
      "",
    ],
    iterate_b: [
      "Minimal evidence of improvements in their solution",
      "Partial evidence of improvements in their solution",
      "Clear evidence of improvements in their solution",
      "",
    ],
    communicate_a: [
      "Presentation minimally engaging",
      "Presentation partially engaging",
      "Presentation engaging",
      "",
    ],
    communicate_b: [
      "Solution and its potential impact on others unclear",
      "Solution and its potential impact on others partially clear",
      "Solution and its potential impact on others clear",
      "",
    ],
  },
};
export default innovation;
