import { GCheckFields, GDef, IGuard, IUser } from "../api.types";

export interface DTOPaymentGet {
  _id: string;
  address: string;
  payer: string;
  created_by: string;
  reference: number;
  invoice_number?: string;
  credit_card: boolean;
  pdf_url?: string;
  pdf_verified?: boolean;
  admin_comment?: string;
  thumbnail?: string;
  payment_id?: string;
  amount: number;
  comment: string;
  timestamp: Date;
}

export interface DTOPaymentCreate {
  address: string;
  payer: IUser["_id"];
  invoice_number?: string;
  credit_card: boolean;
  pdf_url?: string;
  admin_comment?: string;

  thumbnail?: string;
  amount: number;
  comment: string;
}

export interface DTOPaymentUpdate {
  address?: string;
  // reference?: number;
  invoice_number?: string;
  payment_id?: string;
  credit_card?: boolean;
  pdf_url?: string;
  pdf_verified?: boolean;
  admin_comment?: string;

  thumbnail?: string;
  amount?: number;
  comment?: string;
}

export const GIsDTOPaymentGet: IGuard<DTOPaymentGet> = (x) =>
  GCheckFields<DTOPaymentGet>(x, [
    "_id",
    "address",
    "created_by",
    "payer",
    "reference",
    "credit_card",
    "amount",
    "comment",
    "timestamp",
  ]);

export const GIsDTOPaymentCreate: IGuard<DTOPaymentCreate> = (x) =>
  GCheckFields<DTOPaymentCreate>(x, [
    "address",
    "amount",
    "comment",
    "credit_card",
  ]);

export const GIsDTOPaymentUpdate: IGuard<DTOPaymentUpdate> = (x) =>
  GDef(x) as DTOPaymentUpdate;
