import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnalyticsTrack } from "../../../api/analytics";
import { UserRole } from "../../../common/backend.types";
import { addressGetMine } from "../../../store/address/address.action";
import { paymentGetMine } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetMine } from "../../../store/shipping/shipment.action";
import { teamGetMine } from "../../../store/teams/team.action";
import { volleyGetMine } from "../../../store/volley/volley.action";
import { validateAuth } from "../../../utils/auth.validate";
import ButtonLink from "../../atomic/ButtonLink";
import Icon from "../../atomic/Icon";
import Spinner from "../../atomic/Spinner";
import AdminTab from "./AdminTab";
import CoachTab from "./Coach/CoachTab";
import DirectorTab from "./DirectorTab";
import FinancierTab from "./FinancierTab";
import ShipperTab from "./ShipperTab";
import UpcomingEvents from "./UpcomingEvents";
import VolunteerTab from "./VolunteerTab";

type Tab = "coach" | "volunteer" | "director" | "shipper" | "finance" | "admin";

const DashboardView = ({
  auth,
  addresses,
  loading,
  addressGetMine,
  paymentGetMine,
  teamGetMine,
  volleyGetMine,
  shipmentGetMine,
}: Props) => {
  const [tab, setTab] = useState<Tab>("coach");

  useEffect(() => {
    if (validateAuth(auth)) {
      addressGetMine();
      paymentGetMine();
      volleyGetMine();
      shipmentGetMine();
      teamGetMine();
    }
  }, [auth]);

  useEffect(() => {
    AnalyticsTrack({ name: "View Dashboard Tab", data: { tab } });
  }, [tab]);

  const ToTab = (t: Tab): React.ReactNode => {
    switch (t) {
      case "coach":
        return <CoachTab />;
      case "volunteer":
        return <VolunteerTab />;
      case "director":
        return <DirectorTab />;
      case "shipper":
        return <ShipperTab />;
      case "finance":
        return <FinancierTab />;
      case "admin":
        return <AdminTab />;
      default:
        return <span>Not yet implemented</span>;
    }
  };

  return (
    <div id="dashboard">
      <UpcomingEvents />
      <div id="dashboard-profile">
        <h1>My profile</h1>
        <span>Basic details</span>
        {auth.user?.given_name && auth.user?.family_name ? (
          <Icon icon="check" className="text-success" />
        ) : (
          <Icon icon="times" className="text-warning" />
        )}
        <span>Volunteer profile</span>
        {auth.user?.volunteer_profile ? (
          <Icon icon="check" className="text-success" />
        ) : (
          <Icon icon="times" className="text-danger" />
        )}

        <span>Number of addresses</span>
        <span className={`text-${addresses.length ? "success" : "danger"}`}>
          {loading ? <Spinner variant="info" /> : addresses.length}
        </span>
        <ButtonLink to="/profile" className="row-span-2" variant={"primary"}>
          Update my profile
        </ButtonLink>
      </div>
      {validateAuth(auth) ? (
        <div className={`dashboard-tabs ${tab}`}>
          <div className="tabs">
            <div className="tab-coach" onClick={() => setTab("coach")}>
              Coach
            </div>
            <div className="tab-volunteer" onClick={() => setTab("volunteer")}>
              Volunteer
            </div>
            {auth.user.roles.includes(UserRole.Director) && (
              <div className="tab-director" onClick={() => setTab("director")}>
                Director
              </div>
            )}
            {auth.user.roles.includes(UserRole.Shipper) && (
              <div className="tab-shipper" onClick={() => setTab("shipper")}>
                Shipper
              </div>
            )}
            {auth.user.roles.includes(UserRole.Financier) && (
              <div className="tab-finance" onClick={() => setTab("finance")}>
                Finance
              </div>
            )}
            {auth.user.roles.includes(UserRole.Admin) && (
              <div className="tab-admin" onClick={() => setTab("admin")}>
                Admin
              </div>
            )}
          </div>
          <div className="tab-content">{ToTab(tab)}</div>
        </div>
      ) : (
        "Something went wrong"
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  addresses: state.address.mine,
  loading: state.address.loading,
});

const connector = connect(mapStateToProps, {
  addressGetMine,
  paymentGetMine,
  teamGetMine,
  volleyGetMine,
  shipmentGetMine,
});

type Props = ConnectedProps<typeof connector> & {};

export default connector(DashboardView);
