/// F. Westling, 2021-06-18

/// We don't want services to perform rapid-fire duplicate requests, and if new requests come in we want them to be overlapped.
/// Using a serviceManager allows you to set up gatekeeping to manage services sensibly by leveraging axios Cancel tokens and JS Promises

import axios, { CancelToken, CancelTokenSource } from "axios";
import { v4 as guid } from "uuid";
import logger from "../utils/logger";
export type ServiceFunction<Res> = (
  params: any,
  ct: CancelToken
) => Promise<Res>;

type Task = {
  /** Id of task */
  id: string;
  /** Name of service group */
  name: string;
  /** The promise to be performed */
  promise: Promise<any>;
  /** Stringified parameter object - if these change, cancel the old ones and only return the latest */
  param_hunter: string;
  /** Stringified parameter object - if these change, don't cancel the old ones  */
  param_gatherer: string;
  /** Cancel token source to manage cancellation of web requests */
  cts: CancelTokenSource;
  /** Flagged for deletion? */
  deleted?: boolean;
};

const SERVED_MSG = "Already served";

export default class ServiceManager {
  private tasks: Task[];
  private delay: number;

  /**
   * Create a new ServiceManager
   * @param delay ms to delay before clearing finished tasks - so, if you expect changes in the backend data, this number should be smaller than the expected period of change.
   */
  constructor(delay: number = 5000) {
    this.tasks = [];
    this.delay = delay;
  }

  /**
   *
   * @param func Asynchronous ServiceFunction to run
   * @param params Parameters to pass to func
   * @param name Name of service group; only compare tasks with the same name
   * @param param_hunter Params - if these change, cancel the old ones and only return the latest
   * @param param_gatherer Params - if these change, don't cancel the old ones
   * @returns the Promised result of "func"
   * @throws axios.isCancel error if task is cancelled
   * @throws ServiceManager.isServed error if task has already been served and doesn't need to run again.
   */
  run<T>(
    func: ServiceFunction<T>,
    params: any,
    name: string,
    param_hunter: any = {},
    param_gatherer: any = {}
  ): Promise<T> {
    const ph = JSON.stringify(param_hunter);
    const pg = JSON.stringify(param_gatherer);
    // Cancel and delete every task with a different "hunter"
    this.tasks
      .filter((t) => t.name === name && t.param_hunter !== ph)
      .forEach((task) => {
        task.cts.cancel("Cancelled due to more recent update");
        setTimeout(
          () => (this.tasks = this.tasks.filter((t) => t.id !== task.id)),
          100
        );
      });
    this.tasks = this.tasks.filter((t) => !t.deleted);

    // If there's an existing task with the same parameters, tell it to chill
    // Should it instead return the promise result?  That could lead to re-rendering...

    const task = this.tasks.find(
      (t) => t.param_gatherer === pg && t.param_hunter === ph
    );
    if (task) {
      logger.debug("Already served", "ServiceManager", false, {
        params,
        param_hunter,
      });
      return task.promise;
      // throw Error(SERVED_MSG);
    } else {
      logger.debug("Creating new", "ServiceManager", false, {
        params,
        param_hunter,
      });
    }

    // Else create a new task
    const id = guid();
    const cts = axios.CancelToken.source();

    const newTask: Task = {
      id,
      name,
      param_gatherer: pg,
      param_hunter: ph,
      cts,
      promise: this.runFunc(func, params, id, cts.token),
    };
    this.tasks.push(newTask);
    return newTask.promise;
  }

  private runFunc = async (
    func: ServiceFunction<any>,
    params: any,
    id: string,
    ct: CancelToken
  ) => {
    const ret = await func(params, ct);
    setTimeout(
      () => (this.tasks = this.tasks.filter((t) => t.id !== id)),
      this.delay
    );

    return ret;
  };

  static isServed = (err: any) => err?.message === SERVED_MSG;
}
