import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { UserRole } from "../../../common/backend.types";
import { addressGetAll } from "../../../store/address/address.action";
import { competeGetAll } from "../../../store/compete/compete.action";
import { exportUsers } from "../../../store/exports.action";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetAll } from "../../../store/shipping/shipment.action";
import { teamGetAll } from "../../../store/teams/team.action";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import {
  userAddRole,
  userRmRole,
  userGetAll,
  userUpdate,
} from "../../../store/users/user.action";
import { volleyGetAll } from "../../../store/volley/volley.action";
import logger from "../../../utils/logger";
import Button from "../../atomic/Button";
import CheckBox from "../../atomic/CheckBox";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import SubmitButton from "../../atomic/SubmitButton";
import UserView from "../../reports/UserView";

const AdminUsersView = ({
  userGetAll,
  userAddRole,
  userRmRole,
  userUpdate,
  exportUsers,
  competeGetAll,
  teamGetAll,
  volleyGetAll,
  tournamentGetAll,
  shipmentGetAll,
  paymentGetAll,
  addressGetAll,
  users,
  loading,
}: Props) => {
  const [filter, setFilter] = useState<string>("");
  const [viewUser, setViewUser] = useState<IUser | undefined>();
  const [editCredit, setEditCredit] = useState<IUser | undefined>();
  const [editLimit, setEditLimit] = useState<IUser | undefined>();
  const [credit, setCredit] = useState<number>(0);
  const [teamLimit, setTeamLimit] = useState<number>(2);

  useEffect(() => {
    userGetAll();
    competeGetAll();
    teamGetAll();
    volleyGetAll();
    tournamentGetAll();
    shipmentGetAll();
    paymentGetAll();
    addressGetAll();
  }, [
    userGetAll,
    competeGetAll,
    teamGetAll,
    volleyGetAll,
    tournamentGetAll,
    shipmentGetAll,
    paymentGetAll,
    addressGetAll,
  ]);

  const updateUserRole = (u: IUser, r: UserRole) => {
    if (!u.roles.includes(r)) userAddRole(u._id, r);
    else userRmRole(u._id, r);
  };

  const startEdit = (u: IUser) => {
    setEditCredit(u);
    setCredit(u.credit);
  };

  const startEditLimit = (u: IUser) => {
    setEditLimit(u);
    setTeamLimit(u.team_limit ?? 2);
  };

  const updateCredit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editCredit) {
      // console.log("NOP");
      return;
    } else {
      // console.log("POP");
      userUpdate(editCredit._id, { credit });
      logger.success("Credit updated!", "updateCredit", true, {
        user: editCredit,
        credit,
      });
      setEditCredit(undefined);
    }
  };

  const updateLimit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editLimit) {
      // console.log("NOP");
      return;
    } else {
      // console.log("POP");
      userUpdate(editLimit._id, { team_limit: teamLimit });
      logger.success("Limit updated!", "updateLimit", true, {
        user: editLimit,
        teamLimit,
      });
      setEditLimit(undefined);
    }
  };

  const handleExport = useCallback(() => exportUsers(), [exportUsers]);

  return loading ? (
    <Spinner variant="admin" />
  ) : (
    <div id="admin-user-view">
      <span className="d-flex">
        <h1 className="mr-auto">Users</h1>
        <Button onClick={handleExport}>Download</Button>
      </span>
      <input
        type="text"
        className="mb-1"
        placeholder="Filter..."
        value={filter}
        onChange={(e) => {
          e.preventDefault();
          setFilter(e.target.value);
        }}
      />
      {loading ? (
        <Spinner variant="admin" />
      ) : (
        <div className="users">
          <table className="table">
            <thead>
              <tr>
                <th>View</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Verified?</th>
                <th className="credit-column">Credit</th>
                <th className="credit-column">Team limit</th>
                <th>Finance</th>
                <th>Shipping</th>
                <th>Admin</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter(
                  (u) =>
                    u.given_name
                      ?.toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    u.family_name
                      ?.toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter.toLowerCase())
                )
                .map((u) => (
                  <tr key={u._id}>
                    <td>
                      <Button variant="primary" onClick={() => setViewUser(u)}>
                        <Icon icon="eye" />
                      </Button>
                    </td>
                    <td>
                      {u.given_name} {u.family_name}
                    </td>
                    <td>{u.email}</td>
                    <td>{u.phone}</td>
                    <td>
                      {u.email_verified ? (
                        <Icon icon="check" className="text-success" />
                      ) : (
                        <Icon icon="times" className="text-danger" />
                      )}
                    </td>
                    <td className="credit-column">
                      {editCredit && editCredit._id === u._id ? (
                        <form className="d-flex" onSubmit={updateCredit}>
                          <span>$</span>
                          <input
                            type="number"
                            value={credit}
                            min={0}
                            onChange={(e) => {
                              e.preventDefault();
                              const V = Number.parseFloat(e.target.value);
                              setCredit(V ? V : 0);
                            }}
                          />
                          <SubmitButton
                            value="Save"
                            variant="clear"
                            className="text-success"
                          />
                          <Button
                            variant="clear"
                            className="text-black"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditCredit(undefined);
                            }}
                          >
                            Cancel
                          </Button>
                        </form>
                      ) : (
                        <Button
                          variant="clear"
                          className="text-blue underlined"
                          onClick={() => startEdit(u)}
                        >
                          {/* Something here isn't working.... */}$
                          {u.credit ? u.credit.toFixed(2) : "0.00"}
                        </Button>
                      )}
                    </td>
                    <td className="credit-column">
                      {editLimit && editLimit._id === u._id ? (
                        <form className="d-flex" onSubmit={updateLimit}>
                          <input
                            type="number"
                            value={teamLimit}
                            min={0}
                            onChange={(e) => {
                              e.preventDefault();
                              const V = Number.parseFloat(e.target.value);
                              setTeamLimit(V ? V : 0);
                            }}
                          />
                          <SubmitButton
                            value="Save"
                            variant="clear"
                            className="text-success"
                          />
                          <Button
                            variant="clear"
                            className="text-black"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditLimit(undefined);
                            }}
                          >
                            Cancel
                          </Button>
                        </form>
                      ) : (
                        <Button
                          variant="clear"
                          className="text-blue underlined"
                          onClick={() => startEditLimit(u)}
                        >
                          {u.team_limit ?? 2}
                        </Button>
                      )}
                    </td>
                    <td>
                      <CheckBox
                        className="mx-auto"
                        checked={u.roles.includes(UserRole.Financier)}
                        toggle={() => updateUserRole(u, UserRole.Financier)}
                      />
                    </td>
                    <td>
                      <CheckBox
                        className="mx-auto"
                        checked={u.roles.includes(UserRole.Shipper)}
                        toggle={() => updateUserRole(u, UserRole.Shipper)}
                      />
                    </td>
                    <td>
                      <CheckBox
                        className="mx-auto"
                        checked={u.roles.includes(UserRole.Admin)}
                        toggle={() => updateUserRole(u, UserRole.Admin)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        open={viewUser !== undefined}
        onClose={() => setViewUser(undefined)}
      >
        {viewUser ? <UserView userId={viewUser._id} /> : <div></div>}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.all,
  loading:
    state.users.loading ||
    state.team.loading ||
    state.volley.loading ||
    state.tournament.loading ||
    state.shipment.loading ||
    state.address.loading ||
    state.payment.loading,
});

const connector = connect(mapStateToProps, {
  userGetAll,
  userAddRole,
  userRmRole,
  userUpdate,
  exportUsers,
  competeGetAll,
  teamGetAll,
  volleyGetAll,
  tournamentGetAll,
  shipmentGetAll,
  paymentGetAll,
  addressGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(AdminUsersView);
