import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ScoreAnswer } from "../../../api/api.types";
import { Compete } from "../../../store/compete/compete.types";
import { RootState } from "../../../store/root-reducer";
import {
  scoresheetCreate,
  scoresheetGetAll,
} from "../../../store/scoring/scoring.action";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import { Team, TeamPublic } from "../../../store/teams/team.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import logger from "../../../utils/logger";
import { SingleSelector } from "../../atomic/FaSelectors";
import { FaText } from "../../atomic/FaTexts";
import ScoreCalculator from "./forms/ScoreCalculator";
import { Game, Games } from "./games/types";

type LocalProps = {
  tournament: Tournament;
  teams: TeamPublic[];
  scoresheets: Scoresheet[];
  competes: Compete[];
  initTeam?: TeamPublic;
  initRound?: 1 | 2 | 3;
  onSuccess?: () => void;
  onTeamSet?: (teamSet: boolean) => void;
};

const CreateScoresheet = ({
  tournament,
  teams,
  scoresheets,
  competes,
  initTeam,
  initRound,
  scoresheetCreate,
  onSuccess = () => {},
  onTeamSet = () => {},
}: Props) => {
  const [game, setGame] = useState<Game>();
  const [team, setTeam] = useState<TeamPublic | undefined>();
  const [round, setRound] = useState<number>(1);
  const [latest, setLatest] = useState<Scoresheet | undefined>();

  useEffect(() => {
    setGame(Games.find((g) => g.season === tournament.season));
  }, [tournament.season]);

  useEffect(() => {
    if (initTeam) setTeam(teams.find((t) => t._id === initTeam._id));
  }, [initTeam]);
  useEffect(() => {
    if (initRound) setRound(initRound);
  }, [initRound]);

  const onPrint: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (!team) return;
    const t = document.title;
    if (team)
      document.title = `${team.team_number}-${team.team_name
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "_")}-round${round}`;
    else document.title = "scoresheet";
    window.print();
    document.title = t;
  };

  useEffect(() => {
    if (team && game) {
      const sheets = scoresheets
        .filter((sc) => sc.round === round)
        .map((s) => ({
          score: s,
          comps: competes.filter(
            (c) => c._id === s.compete_id && c.team_id === team._id
          ),
        }))
        .filter((s) => s.comps.length);
      setLatest(sheets[0]?.score);
    }
  }, [team, game, round]);

  const handleSubmit = async (
    answers: ScoreAnswer[],
    privateComment: string,
    publicComment: string,
    team_id: string,
    tournament_id: string,
    round: number
  ) => {
    const error = await scoresheetCreate({
      team_id,
      tournament_id,
      round,
      answers,
      private_comment: privateComment,
      public_comment: publicComment,
    });
    if (!error) {
      logger.success("Scoresheet created!", "CreateScoresheet", true);
      setTeam(undefined);
      setRound(1);
      onSuccess();
    } else {
      logger.warn(error, "CreateScoresheet", true);
    }
  };

  useEffect(() => {
    onTeamSet(!!team);
  }, [!!team]);

  const classes = useStyles();
  return (
    <div className={classes.createscoresheet}>
      <div className={classes.topbar}>
        <div className={classes.teamselector}>
          <SingleSelector<TeamPublic["_id"]>
            value={team?._id}
            label="Team"
            options={teams.map((t) => ({
              label: `${t.team_number}  ${t.team_name}`,
              value: t._id,
            }))}
            onSelect={(tid) => setTeam(teams.find((t) => t._id === tid))}
          />
        </div>
        <div className={classes.roundselector}>
          <TextField
            type="number"
            label="Round"
            variant="outlined"
            value={round}
            InputProps={{
              inputProps: { max: 3, min: 1 },
            }}
            onChange={(e) => setRound(Number(e.target.value))}
          />
        </div>
      </div>
      <div className={classes.body}>
        {team ? (
          game ? (
            <Fragment>
              <ScoreCalculator
                referee
                initData={latest?.answers}
                initPrivComment={latest?.private_comment}
                initPublComment={latest?.public_comment}
                game={game}
                onSubmit={(ans, priv, pub) =>
                  handleSubmit(ans, priv, pub, team._id, tournament._id, round)
                }
                onPrint={onPrint}
              />
            </Fragment>
          ) : (
            <FaText>Game not found</FaText>
          )
        ) : (
          <FaText>Please select a team</FaText>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    createscoresheet: {},
    topbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    teamselector: { width: 200 },
    roundselector: {
      width: 200,
      paddingTop: 8,
      display: "flex",
      justifyContent: "center",
    },
    body: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, {
  scoresheetCreate,
  scoresheetGetAll,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(CreateScoresheet);
