import React from "react";
import Icon from "./Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

interface Props {
  className?: string;
  checked: boolean;
  toggle: () => void;
  id?: string;
  label?: string;
}

const CheckBox = ({ className, label, checked, toggle, id }: Props) => {
  return (
    <FormControlLabel
      id={id}
      className={"no-select " + className}
      control={
        <Checkbox
          checked={checked}
          onChange={() => toggle()}
          name={label}
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default CheckBox;
