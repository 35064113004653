import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Box, { BoxProps } from "@material-ui/core/Box";

interface Props {
  open: boolean;
  onClose?: () => void;
  children: BoxProps["children"];
  classes?: {
    inner?: string;
    container?: string;
  };
  /** Always keep the children in the DOM. */
  keepMounted?: boolean;
}

/**
 * Wrapper for modals with our particular styles and properties
 */
const FaModal = ({ open, onClose, classes, keepMounted, children }: Props) => {
  const cl = useStyles();
  return (
    <Modal
      classes={{ root: { paperFullScreen: "prePrint printDialog" } }}
      className={[cl.Famodal, classes?.container ?? ""].join(" ")}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      keepMounted={keepMounted}
    >
      <Fade in={open}>
        <Box className={[cl.inner, classes?.inner ?? ""].join(" ")}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    Famodal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inner: {
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
      // border: "2px solid #000",
      borderRadius: 8,
      boxShadow: theme.shadows[5],
      maxHeight: "80vh",
      maxWidth: "80vw",
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("xs")]: {
        maxHeight: "100vh",
        maxWidth: "100vw",
        padding: theme.spacing(1, 2, 1),
      },

      "&:focus-visible": {
        outline: "none",
      },
    },
  })
);

export default FaModal;
