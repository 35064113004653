import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Route, Switch } from "react-router";
import { UserRole } from "../common/backend.types";
import { RootState } from "../store/root-reducer";
import Spinner from "./atomic/Spinner";
import AuthRoute from "./routing/AuthRoute";
import RoleRoute from "./routing/RoleRoute";
import VolRoute from "./routing/VolRoute";
import AdminTeamsView from "./views/Admin/AdminTeamsView";
import AdminUsersView from "./views/Admin/AdminUsersView";
import AdminView from "./views/Admin/AdminView";
import AdminVolunteersView from "./views/Admin/AdminVolunteersView";
import AllTournamentsView from "./views/Admin/AllTournamentsView";
import CreateTournamentView from "./views/Admin/CreateTournamentView";
import FinanceView from "./views/Coaches/Finance";
import MyTeamsView from "./views/Coaches/MyTeams";
import PayNowView from "./views/Coaches/PayNow";
import RegisterTeamsView from "./views/Coaches/RegisterTeams";
import ShippingView from "./views/Coaches/Shipping";
import DashboardView from "./views/Dashboard";
import MyTournamentsView from "./views/Directors/MyTournamentsView";
import FAQView from "./views/FAQ";
import FinancierView from "./views/Financier/FinancierView";
import HelpView from "./views/Help";
import JudgeAdvisorView from "./views/Judging/JudgeAdvisorView";
import JudgeView from "./views/Judging/JudgeView";
import RubricView from "./views/Judging/RubricEntryForm";
import Landing from "./views/Landing";
import ManageVolunteerView from "./views/ManageVolunteers/ManageVolunteerView";
import PageNotFound from "./views/PageNotFound";
import PaymentResponse from "./views/PaymentResponse";
import ProfileView from "./views/Profile";
import AllRankingsView from "./views/Scoring/AllRankingsView";
import Calculator from "./views/Scoring/Calculator";
import RankingsProjectorView from "./views/Scoring/RankingsProjectorView";
import RankingsView from "./views/Scoring/RankingsView";
import RefereeView from "./views/Scoring/RefereeView";
import ShipperView from "./views/Shipper/ShipperView";
import WhatsNewView from "./views/WhatsNew";

export type LocalRoute =
  | "/landing"
  | "/whats-new"
  | "/help"
  | "/faq"
  | "/"
  | "/profile"
  | "/register-teams"
  | "/my-teams"
  | "/finance"
  | "/shipping"
  | "/pay-now"
  | "/admin"
  | "/admin-users"
  | "/admin-volunteers"
  | "/admin-teams"
  | "/finance-view"
  | "/shipping-view"
  | "/payment-response"
  | "/all-tournaments"
  | "/my-tournaments"
  | "/create-tournament"
  | `/manage/${string}`
  | `/scoring`
  | `/scoring/${string}`
  | `/judging`
  | `/judging/${string}`
  | `/judging/rubrics/${string}`
  | `/calculator/${string}`
  | `/rankings/${string}`
  | `/rankings/projector/${string}`
  | `/referee/${string}`;

const Routes = ({ auth }: Props) => {
  return auth.loading ? (
    <Spinner variant={"primary"} />
  ) : (
    <Switch>
      <Route exact path="/landing" component={Landing} />
      <Route exact path="/whats-new" component={WhatsNewView} />
      <Route exact path="/help" component={HelpView} />
      <Route exact path="/faq" component={FAQView} />
      <AuthRoute exact path="/" component={DashboardView} />
      <AuthRoute exact path="/profile" component={ProfileView} />
      {/* <AuthRoute exact path="/register-teams" component={RegisterTeamsView} /> */}
      <AuthRoute exact path="/my-teams" component={MyTeamsView} />
      <AuthRoute exact path="/pay-now" component={PayNowView} />
      <AuthRoute exact path="/shipping" component={ShippingView} />
      <AuthRoute exact path="/finance" component={FinanceView} />
      <AuthRoute exact path="/payment-response" component={PaymentResponse} />
      <AuthRoute exact path="/manage/:id" component={ManageVolunteerView} />
      {/* Scoring for a particular tournament @todo: RoleRoute; only allow if user getsAccessToScoring*/}
      <AuthRoute exact path="/scoring/:id" component={PageNotFound} />
      {/* Generic scoring calculator */}
      <Route exact path="/scoring" component={PageNotFound} />
      <RoleRoute
        exact
        path="/finance-view"
        component={FinancierView}
        roles={[UserRole.Financier, UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/shipping-view"
        component={ShipperView}
        roles={[UserRole.Shipper, UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/admin"
        component={AdminView}
        roles={[UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/admin-teams"
        component={AdminTeamsView}
        roles={[UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/admin-users"
        component={AdminUsersView}
        roles={[UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/admin-volunteers"
        component={AdminVolunteersView}
        roles={[UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/all-tournaments"
        component={AllTournamentsView}
        roles={[UserRole.Admin]}
      />
      <RoleRoute
        exact
        path="/my-tournaments"
        component={MyTournamentsView}
        roles={[UserRole.Director]}
      />
      <RoleRoute
        exact
        path="/create-tournament"
        component={CreateTournamentView}
        roles={[UserRole.Admin]}
      />
      {/* Judging for a particular tournament*/}
      <VolRoute
        exact
        path="/judging/rubrics/:id"
        component={JudgeView}
        roles={[
          "Judge",
          "Judge (Core Values)",
          "Judge (Research Project)",
          "Judge (Robot Design)",
          "Judge Advisor",
          "Judge Assistant",
          "Tournament Director",
          "Firefighter",
        ]}
      />
      <VolRoute
        exact
        path="/judging/:id"
        component={JudgeAdvisorView}
        roles={[
          "Judge Advisor",
          "Judge Assistant",
          "Tournament Director",
          "Firefighter",
        ]}
      />
      <Route exact path="/calculator/:game" component={Calculator} />
      <VolRoute
        exact
        path="/referee/:id"
        component={RefereeView}
        roles={[
          "Referee",
          "Head Referee",
          "Scorekeeper",
          "Tournament Director",
          "Firefighter",
        ]}
      />
      <Route
        exact
        path="/rankings/projector/:tournament_id"
        component={RankingsProjectorView}
      />
      <Route exact path="/rankings/:tournament_id" component={RankingsView} />
      <Route exact path="/rankings" component={AllRankingsView} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector> & {};

export default connector(Routes);
