import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { RootState } from "../../../store/root-reducer";
import { teamDelete } from "../../../store/teams/team.action";
import { Team } from "../../../store/teams/team.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import {
  getUserCosts,
  getUserPayments,
} from "../../../store/users/user.action";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import ProgramLogo from "../../atomic/ProgramLogo";
import TeamNumber from "../../atomic/TeamNumber";

type LocalProps = {
  team?: Team;
  onTransfer?: () => void;
  onMoveTeam?: () => void;
};

const TournamentTeamRow = ({
  team,
  user,
  users,
  competes,
  tournaments,
  onTransfer = () => {},
  onMoveTeam = () => {},
  teamDelete,
  getUserCosts,
  getUserPayments,
}: Props) => {
  const [coaches, setCoaches] = useState<IUser[]>([]);
  const [tourns, setTourns] = useState<Tournament[]>([]);

  useEffect(() => {
    if (!team) return;
    setCoaches(
      team.coaches
        .map((c) => users.find((u) => u._id === c))
        .filter((u) => u) as IUser[]
    );
  }, [team, users]);

  useEffect(() => {
    if (!team) return;
    const comp = competes
      .filter((c) => c.team_id === team._id)
      .map((c) => c.tournament_id);
    setTourns(tournaments.filter((t) => comp.includes(t._id)));
  }, [team, competes, tournaments]);

  return !team ? (
    <tr></tr>
  ) : (
    <tr>
      <td>
        <ProgramLogo program={team.program} />
      </td>
      <td>
        <TeamNumber number={team.team_number} program={team.program} />
      </td>
      <td>{team.team_name}</td>
      <td>{team.affiliation}</td>
      <td>
        {tourns.map((c) => (
          <p className="m-0">{c.name}</p>
        ))}
      </td>
      <td>
        {coaches.map((c) => (
          <p className="m-0">
            {c.given_name
              ? `${c.given_name} ${c.family_name} (${c.email})`
              : c.email}
          </p>
        ))}
      </td>
      <td>
        <Moment format="LL">{team.timestamp}</Moment>
      </td>
      <td>
        {!team.post_registered ? (
          <Icon className="text-success" icon="check" />
        ) : (
          <Icon className="text-danger" icon="times" />
        )}
      </td>
      <td>
        {(user?.up ?? 0) <= (user?.down ?? 0) ? (
          <Icon className="text-success" icon="check" />
        ) : (
          <Icon className="text-danger" icon="times" />
        )}
      </td>
      <td>
        <Button variant="primary" onClick={() => onTransfer()}>
          <Icon icon="edit" />
        </Button>
      </td>
      <td>
        <Button variant="admin" onClick={() => onMoveTeam()}>
          <Icon icon="expand-arrows-alt" />
        </Button>
      </td>
      <td>
        <Button
          variant="danger"
          onClick={() => {
            if (window.confirm("Are you sure?")) teamDelete(team._id);
          }}
        >
          <Icon icon="trash-alt" />
        </Button>
      </td>
    </tr>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState, props: LocalProps) => ({
    user: selectUser(state, { userId: props.team?.user ?? "" }),
    users: state.users.all,
    competes: state.compete.all,
    tournaments: [...state.tournament.all, ...state.tournament.dummy],
  });
};

const connector = connect(makeMapStateToProps, {
  teamDelete,
  getUserCosts,
  getUserPayments,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(TournamentTeamRow);
