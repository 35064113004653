import { FIRSTProgram } from "./backend.types";

export type VolunteerRole =
  | "Head Referee"
  | "Referee"
  | "Field Reset"
  | "Scorekeeper"
  | "Timekeeper"
  | "Lead Queuer"
  | "Queuer"
  | "FTA (Field Technical Advisor)"
  | "FTAA (Field Technical Advisor Assistant"
  | "Lead Robot Inspector"
  | "Robot Inspector"
  | "CSA (Control Systems Advisor)"
  | "Judge"
  | "Judge (Robot Design)"
  | "Judge (Core Values)"
  | "Judge (Research Project)"
  | "Judge Advisor"
  | "Judge Assistant"
  | "Judge Check-in"
  | "Pit Admin"
  | "Pit Admin Assistant"
  | "Safety Glasses Supervisor"
  | "AV / Livestream"
  | "Emcee"
  | "Emcee Assistant"
  | "Game Announcer"
  | "Practice Field Attendant"
  | "Tournament Director"
  | "Firefighter"
  | "Assign me as needed";

export const RoleMap = {
  [FIRSTProgram.FLL_DISCOVER]: [
    "Queuer",
    "Judge",
    "Judge Advisor",
    "Judge Assistant",
    "Pit Admin",
    "Pit Admin Assistant",
    "AV / Livestream",
    "Emcee",
    "Tournament Director",
    "Firefighter",
    "Assign me as needed",
  ] as VolunteerRole[],
  [FIRSTProgram.FLL_EXPLORE]: [
    "Queuer",
    "Judge",
    "Judge Advisor",
    "Judge Assistant",
    "Pit Admin",
    "Pit Admin Assistant",
    "AV / Livestream",
    "Emcee",
    "Tournament Director",
    "Firefighter",
    "Assign me as needed",
  ] as VolunteerRole[],
  [FIRSTProgram.FLL_CHALLENGE]: [
    "Head Referee",
    "Referee",
    "Field Reset",
    "Scorekeeper",
    "Timekeeper",
    "Lead Queuer",
    "Queuer",
    "Judge",
    "Judge (Robot Design)",
    "Judge (Core Values)",
    "Judge (Research Project)",
    "Judge Advisor",
    "Judge Assistant",
    "Judge Check-in",
    "Pit Admin",
    "Pit Admin Assistant",
    "AV / Livestream",
    "Emcee",
    "Emcee Assistant",
    "Practice Field Attendant",
    "Tournament Director",
    "Firefighter",
    "Assign me as needed",
  ] as VolunteerRole[],
  [FIRSTProgram.FTC]: [
    "Head Referee",
    "Referee",
    "Field Reset",
    "Scorekeeper",
    "Timekeeper",
    "Lead Queuer",
    "Queuer",
    "FTA (Field Technical Advisor)",
    "FTAA (Field Technical Advisor Assistant)",
    "Lead Robot Inspector",
    "Robot Inspector",
    "CSA (Control Systems Advisor)",
    "Judge",
    "Judge Advisor",
    "Judge Assistant",
    "Judge Check-in",
    "Pit Admin",
    "Pit Admin Assistant",
    "Safety Glasses Supervisor",
    "AV / Livestream",
    "Emcee",
    "Emcee Assistant",
    "Game Announcer",
    "Practice Field Attendant",
    "Tournament Director",
    "Firefighter",
    "Assign me as needed",
  ] as VolunteerRole[],
  [FIRSTProgram.FRC]: [
    "Head Referee",
    "Referee",
    "Field Reset",
    "Scorekeeper",
    "Timekeeper",
    "Lead Queuer",
    "Queuer",
    "FTA (Field Technical Advisor)",
    "FTAA (Field Technical Advisor Assistant)",
    "Lead Robot Inspector",
    "Robot Inspector",
    "CSA (Control Systems Advisor)",
    "Judge",
    "Judge Advisor",
    "Judge Assistant",
    "Judge Check-in",
    "Pit Admin",
    "Pit Admin Assistant",
    "Safety Glasses Supervisor",
    "AV / Livestream",
    "Emcee",
    "Emcee Assistant",
    "Game Announcer",
    "Practice Field Attendant",
    "Tournament Director",
    "Firefighter",
    "Assign me as needed",
  ] as VolunteerRole[],
};

export const getsAccessToScoring = (x: VolunteerRole): boolean => {
  return (
    x === "Referee" ||
    x === "Head Referee" ||
    x === "Scorekeeper" ||
    x === "Firefighter" ||
    x === "Tournament Director"
  );
};

export const getsAccessToJudging = (x: VolunteerRole): boolean => {
  return (
    x === "Firefighter" ||
    x === "Tournament Director" ||
    (x.includes("Judge") && x !== "Judge Check-in")
  );
};
export const getsAccessToJudgeAdvisor = (x: VolunteerRole): boolean => {
  return (
    x === "Firefighter" ||
    x === "Tournament Director" ||
    x === "Judge Advisor" ||
    x === "Judge Assistant"
  );
};
