import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tournament } from "../../../../store/tournament/tournament.types";
import { RubricAnswer } from "../../../../store/rubrics/rubric.types";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store/root-reducer";
// import { rubricGetMine } from "../../../../store/rubrics/rubric.action";

interface LocalProps {
  tournament?: Tournament;
  onClickMove: () => void;
  onClickRubric: () => void;
}

const CoachTournamentButton = ({
  tournament,
  onClickMove,
  onClickRubric,
}: // rubricGetMine,
Props) => {
  // useEffect(() => {
  //   if (!!tournament) rubricGetMine(tournament._id);
  // }, [tournament?._id]);

  const classes = useStyles();
  return tournament ? (
    <div className={classes.coachtournamentbutton}>
      <span
        className="action-text text-blue"
        onClick={
          tournament?.released ? () => onClickRubric() : onClickMove
        }
      >
        {tournament.name} {tournament.released ? "[See rubric]" : ""}
      </span>
    </div>
  ) : (
    <div />
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    coachtournamentbutton: {},
  })
);

const mapStateToProps = (state: RootState) => ({
  rubrics: state.rubrics.mine,
});

const connector = connect(mapStateToProps, {
  // rubricGetMine,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(CoachTournamentButton);
