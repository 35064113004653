import {
  Volley,
  VolleyActionTypes,
  VolleyState,
  VOLLEY_CREATE,
  VOLLEY_DELETE,
  VOLLEY_GET_ALL,
  VOLLEY_GET_MINE,
  VOLLEY_UPDATE,
} from "./volley.types";

const initialState: VolleyState = {
  all: [],
  mine: [],
  loading: true,
};

const volleyReducer = function (
  state: VolleyState = initialState,
  action: VolleyActionTypes
): VolleyState {
  switch (action.type) {
    case VOLLEY_GET_ALL:
      return {
        ...state,
        all: action.payload.filter((x, i, a) => a.indexOf(x) === i),
        loading: false,
      };
    case VOLLEY_GET_MINE:
      return {
        ...state,
        mine: action.payload.filter((x, i, a) => a.indexOf(x) === i),
        loading: false,
      };

    case VOLLEY_CREATE:
      return {
        ...state,
        all: [...state.all, action.payload].filter(
          (x, i, a) => a.indexOf(x) === i
        ),
        // @todo change this to allow admins to create non-mine ones
        mine: [...state.mine, action.payload].filter(
          (x, i, a) => a.indexOf(x) === i
        ),
        loading: false,
      };
    case VOLLEY_UPDATE:
      const { id, data } = action.payload;
      return {
        ...state,
        all: state.all.map((t) => (t._id === id ? { ...t, ...data } : t)),
        mine: state.mine.map((t) => (t._id === id ? { ...t, ...data } : t)),
        loading: false,
      };
    case VOLLEY_DELETE:
      return {
        ...state,
        all: state.all.filter((t) => t._id !== action.payload),
        mine: state.mine.filter((t) => t._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};

export default volleyReducer;
