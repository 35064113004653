import { ITournament } from "../../api/api.types";

export type Tournament = ITournament;

export type TournamentState = {
  mine: Tournament[];
  all: Tournament[];
  dummy: Tournament[];
  loading: boolean;
};

/** Action definitions */
export const TOURNAMENT_GET_MINE = "TOURNAMENT_GET_MINE";
export const TOURNAMENT_GET_ALL = "TOURNAMENT_GET_ALL";
export const TOURNAMENT_CREATE = "TOURNAMENT_CREATE";
export const TOURNAMENT_UPDATE = "TOURNAMENT_UPDATE";
export const TOURNAMENT_DELETE = "TOURNAMENT_DELETE";
export const TOURNAMENT_CLEAR = "TOURNAMENT_CLEAR";

/** Action types */
export interface TournamentGetMineActionType {
  type: typeof TOURNAMENT_GET_MINE;
  payload: Tournament[];
}

export interface TournamentGetAllActionType {
  type: typeof TOURNAMENT_GET_ALL;
  payload: Tournament[];
}

export interface TournamentUpdateActionType {
  type: typeof TOURNAMENT_UPDATE;
  payload: {
    id: Tournament["_id"];
    data: Tournament;
  };
}

export interface TournamentCreateActionType {
  type: typeof TOURNAMENT_CREATE;
  payload: Tournament;
}

export interface TournamentDeleteActionType {
  type: typeof TOURNAMENT_DELETE;
  payload: Tournament["_id"];
}

export interface TournamentClearActionType {
  type: typeof TOURNAMENT_CLEAR;
  payload: null;
}

/** Final export */
export type TournamentActionTypes =
  | TournamentGetMineActionType
  | TournamentGetAllActionType
  | TournamentCreateActionType
  | TournamentUpdateActionType
  | TournamentDeleteActionType
  | TournamentClearActionType;
