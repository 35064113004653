import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router";
import { AnalyticsTrack } from "../../api/analytics";
import { addressGetMine } from "../../store/address/address.action";
import { Address } from "../../store/address/address.types";
import { RootState } from "../../store/root-reducer";
import { validateAuth } from "../../utils/auth.validate";
import { SingleSelector } from "../atomic/FaSelectors";
import Selector from "../atomic/Selector";
import Spinner from "../atomic/Spinner";
import AddressForm from "../forms/AddressForm";
import BasicDetailsForm from "../forms/BasicDetailsForm";
import VolunteerProfileForm from "../forms/VolunteerProfileForm";

const Profile = ({ auth, addressGetMine, addresses, loading }: Props) => {
  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "Profile",
      },
    });
  }, []);

  const [addr, setAddr] = useState<Address>();

  useEffect(() => {
    setAddr(addresses[0]);
  }, [addresses]);

  useEffect(() => {
    addressGetMine();
  }, [addressGetMine]);

  return validateAuth(auth) ? (
    <div id="profile-view">
      <h1>My Profile</h1>
      <div id="basics">
        <h2>Basic details</h2>
        <BasicDetailsForm />
      </div>
      <div id="addresses">
        <h2>Addresses</h2>
        <small className="text-primary">
          <strong>Note:</strong> If you are only volunteering for a tournament,
          you do not need to enter this information
        </small>
        {loading ? (
          <Spinner variant="info" />
        ) : addresses.length ? (
          <SingleSelector<Address | undefined>
            className="my-1"
            label="Select address..."
            value={addr}
            options={[
              { label: "Add new", value: undefined },
              ...addresses.map((a) => ({ label: a.label, value: a })),
            ]}
            onSelect={(a) => setAddr(a)}
          />
        ) : (
          <div></div>
        )}
        <AddressForm address={addr} />
      </div>
      <div id="volunteering">
        <h2>Volunteering</h2>
        <VolunteerProfileForm user={auth.user} />
      </div>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  addresses: state.address.mine,
  loading: state.address.loading,
});

const connector = connect(mapStateToProps, { addressGetMine });
type Props = ConnectedProps<typeof connector> & {};

export default connector(Profile);
