import api from "../../utils/api.instance";
import { GIsArray, IRubricAnswer } from "../api.types";
import {
  DTORubricAnswerGet,
  DTORubricAnswerCreate,
  GIsDTORubricAnswerGet,
  DTORubricAnswerBatchCreate,
} from "../dtos/rubric.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<IRubricAnswer[]> => {
  const a = (await api.get(`rubrics/mine`)).data;
  return GIsArray<DTORubricAnswerGet>(a).map((ad) => GIsDTORubricAnswerGet(ad));
};

const getAll = (tournament_id: string) =>
  Service.run(_getAll, { tournament_id }, "RUBRIC_GET_ALL");

const _getAll = async ({
  tournament_id,
}: {
  tournament_id: string;
}): Promise<IRubricAnswer[]> => {
  const a = (await api.get(`rubrics/${tournament_id}`)).data;
  return GIsArray<DTORubricAnswerGet>(a).map((ad) => GIsDTORubricAnswerGet(ad));
};

const create = async (data: DTORubricAnswerCreate): Promise<IRubricAnswer> => {
  const a = (await api.post(`rubrics`, data)).data;
  return GIsDTORubricAnswerGet(a);
};

const createBatch = async (
  data: DTORubricAnswerBatchCreate
): Promise<IRubricAnswer[]> => {
  const a = (await api.post(`rubrics/batch`, data)).data;
  return GIsArray<DTORubricAnswerGet>(a);
};

const remove = async (score_id: IRubricAnswer["_id"]): Promise<boolean> => {
  await api.delete(`rubrics/${score_id}`);
  return true;
};

const RubricAnswerService = {
  getMine,
  getAll,
  create,
  createBatch,
  remove,
};

export default RubricAnswerService;
