import { ITeam } from "../../api/api.types";
import {
  DTOTeamCreate,
  DTOTeamPublic,
  DTOTeamUpdate,
} from "../../api/dtos/team.dto";

/** Basic types */

export type Team = ITeam & {};
export type TeamPublic = DTOTeamPublic;
export type TeamUpdateData = DTOTeamUpdate & {
  coaches?: string[];
};

export type TeamState = {
  mine: Team[];
  coach: Team[];
  tournament: TeamPublic[];
  all: Team[];
  dummy: Team[];
  deleted_mine: Team[];
  deleted_all: Team[];
  loading: boolean;
};

/** Action definitions */
export const TEAM_GET_MINE = "TEAM_GET_MINE";
export const TEAM_GET_MINE_COACH = "TEAM_GET_MINE_COACH";
export const TEAM_GET_ALL = "TEAM_GET_ALL";
export const TEAM_GET_TOURN = "TEAM_GET_TOURN";
export const TEAM_CREATE = "TEAM_CREATE";
export const TEAM_UPDATE = "TEAM_UPDATE";
export const TEAM_DELETE = "TEAM_DELETE";
export const TEAM_UNDELETE = "TEAM_UNDELETE";
export const TEAM_CLEAR = "TEAM_CLEAR";

/** Action types */
export interface TeamGetMineActionType {
  type: typeof TEAM_GET_MINE;
  payload: Team[];
}
export interface TeamGetMineCoachActionType {
  type: typeof TEAM_GET_MINE_COACH;
  payload: Team[];
}

export interface TeamGetAllActionType {
  type: typeof TEAM_GET_ALL;
  payload: Team[];
}

export interface TeamGetTournActionType {
  type: typeof TEAM_GET_TOURN;
  payload: TeamPublic[];
}

export interface TeamUpdateActionType {
  type: typeof TEAM_UPDATE;
  payload: {
    id: Team["_id"];
    data: Team;
  };
}

export interface TeamCreateActionType {
  type: typeof TEAM_CREATE;
  payload: Team;
}

export interface TeamDeleteActionType {
  type: typeof TEAM_DELETE;
  payload: Team["_id"];
}

export interface TeamUndeleteActionType {
  type: typeof TEAM_UNDELETE;
  payload: Team["_id"];
}

export interface TeamClearActionType {
  type: typeof TEAM_CLEAR;
  payload: null;
}

/** Final export */
export type TeamActionTypes =
  | TeamGetMineActionType
  | TeamGetMineCoachActionType
  | TeamGetAllActionType
  | TeamGetTournActionType
  | TeamCreateActionType
  | TeamUpdateActionType
  | TeamDeleteActionType
  | TeamUndeleteActionType
  | TeamClearActionType;
