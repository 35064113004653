import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { Tournament } from "../../../store/tournament/tournament.types";
import {
  volleyUpdate,
  volleyDelete,
} from "../../../store/volley/volley.action";
import { BasicAssignRow } from "./BasicAssignRow";
import { VolUser } from "./ManageVolunteerView";

type LocalProps = {
  tournament: Tournament;
  volunteers: VolUser[];
};

const toArr = (L: number) => {
  let X = [];
  for (let i = 0; i < L; i++) X.push(i);
  return X;
};

const BasicAssignView = ({
  tournament,
  volunteers,
  volleyUpdate,
  volleyDelete,
}: Props) => {
  const classes = useStyles();
  return (
    <Table className={classes.basicassignview}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Age Range</TableCell>

          <TableCell>Preferences</TableCell>
          <TableCell>History</TableCell>
          <TableCell className={classes.cell}>Bump in?</TableCell>
          {toArr(tournament.n_days).map((d) => (
            <TableCell className={classes.cell} key={d}>
              Day {d + 1}
            </TableCell>
          ))}
          <TableCell className={classes.cell}>Bump out?</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Delete</TableCell>
        </TableRow>
      </TableHead>
      {volunteers.map((vol, key) => (
        <BasicAssignRow
          key={key}
          volunteer={vol}
          program={tournament.program}
          volleyUpdate={volleyUpdate}
          volleyDelete={volleyDelete}
        />
      ))}
    </Table>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    basicassignview: {
      zIndex: 2000,
      position: "relative",
    },
    cell: {
      textAlign: "center",
    },
  })
);

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { volleyUpdate, volleyDelete });

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(BasicAssignView);
