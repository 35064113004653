import React, { FocusEventHandler, Fragment, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/** A single option */
type Opt<T> = {
  label: string;
  value: T;
};

interface GenProps<T> {
  /** Label, to be visible even when options are selected */
  label?: React.ReactNode;
  /** Options in selector */
  options: Opt<T>[];
  /** Close dropdown on click */
  hold?: boolean;
  /** Allow undefined input */
  clearable?: boolean;
  /** Whether or not to include an input text element */
  searchable?: boolean;
  /** If equality can be determined quickly knowing T, do so  */
  compare?: (a: T, b?: T) => boolean;
  /** Icon with which to decorate the selector */
  Adornment?: typeof CheckBoxIcon;
  /** Width in pixels */
  width?: number;
  /** Classname for wrapper */
  className?: string;
  /** Handler for when the input is unfocused */
  onBlur?: FocusEventHandler<HTMLDivElement>;
  /** Handler for when the popper is opened */
  onOpen?: () => void;
  disabled?: boolean;
}

interface SingleProps<T> extends GenProps<T> {
  /** Current selected value */
  value?: T;
  /** Function to be run on change of selection */
  onSelect: (value: T | undefined) => void;
}

/** Our custom wrapper component for selecting a single value */
export function SingleSelector<T>({
  options,
  label,
  value,
  onSelect,
  hold = false,
  clearable = false,
  searchable = true,
  compare = (a, b) => a === b,
  Adornment,
  width,
  className,
  onBlur,
  disabled = false,
}: SingleProps<T>) {
  const classes = useStyles(width)();
  return (
    //searchable ? (
    <Autocomplete
      disabled={disabled}
      className={[classes.selector, className].join(" ")}
      options={options}
      getOptionLabel={(o) => o.label}
      value={options.find((o) => o.value === value) ?? null}
      onChange={(_, o) => {
        onSelect(o ? o.value : undefined);
      }}
      disableCloseOnSelect={hold}
      disableClearable={!clearable}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={!searchable}
          label={label}
          variant="outlined"
          InputProps={
            Adornment !== undefined
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <Fragment>
                      {/* // <InputAdornment position="start"> */}
                      <Adornment />
                      {params.InputProps.startAdornment}
                      {/* // </InputAdornment> */}
                    </Fragment>
                  ),
                }
              : params.InputProps
          }
        />
      )}
      getOptionSelected={(o) => compare(o.value, value)}
    />
  );
}

interface MultiProps<T> extends GenProps<T> {
  /** Current selected value */
  value: T[];
  /** Function to be run on change of selection */
  onSelect: (value: T[]) => void;
  /** Maximum number of tags to show */
  limitTags?: number;
  placeholder?: string;
  popupIcon?: React.ReactNode;
}

/** Our custom wrapper component for selecting multiple values */
export function MultiSelector<T>({
  options,
  label,
  value,
  onSelect,
  hold = true,
  clearable = false,
  searchable = true,
  limitTags = 1,
  compare = (a, b) => a === b,
  Adornment,
  popupIcon,
  width,
  className,
  onBlur,
  placeholder,
}: MultiProps<T>) {
  const [val, setVal] = useState<Opt<T>[]>([]);

  useEffect(() => {
    setVal(
      value
        .map((v) => options.find((o) => o.value === v))
        .filter((v) => v) as Opt<T>[]
    );
  }, [options, value]);

  const classes = useStyles(width)();
  return (
    //searchable ? (
    <Autocomplete
      multiple
      className={[classes.selector, className].join(" ")}
      options={options}
      disableListWrap
      popupIcon={popupIcon}
      getOptionLabel={(o) => o.label}
      renderOption={(option, { selected }) => (
        <Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </Fragment>
      )}
      value={val}
      onChange={(_, o, reason) => {
        // console.log({ o, reason });
        onSelect(o.map((opt) => opt.value));
      }}
      getOptionSelected={(opt, val) => compare(opt.value, val.value)}
      limitTags={limitTags}
      disableCloseOnSelect={hold}
      onBlur={onBlur}
      disableClearable={!clearable}
      renderInput={(params) => (
        <div>
          <div className="bv-inputlabel">{label}</div>
          <TextField
            {...params}
            disabled={!searchable}
            placeholder={placeholder}
            variant="outlined"
            InputProps={
              Adornment !== undefined
                ? {
                    ...params.InputProps,
                    startAdornment: (
                      <Fragment>
                        {/* // <InputAdornment position="start"> */}
                        <Adornment />
                        {params.InputProps.startAdornment}
                        {/* // </InputAdornment> */}
                      </Fragment>
                    ),
                  }
                : params.InputProps
            }
          />
        </div>
      )}
    />
  );
}

/** Our custom wrapper component for selecting multiple values */
export function CreatableMultiSelector({
  options,
  label,
  value,
  onSelect,
  hold = true,
  clearable = false,
  searchable = true,
  limitTags = 1,
  compare = (a, b) => a === b,
  Adornment,
  width,
  className,
  onBlur,
  onOpen,
  placeholder,
  popupIcon,
}: MultiProps<string>) {
  const [val, setVal] = useState<Opt<string>[]>([]);
  const filter = createFilterOptions<Opt<string>>();

  useEffect(() => {
    setVal(
      value
        .map(
          (v) => options.find((o) => o.value === v) ?? { label: v, value: v }
        )
        .filter((v) => v) as Opt<string>[]
    );
  }, [options, value]);

  const classes = useStyles(width)();
  return (
    <Autocomplete
      multiple
      disableListWrap
      className={[classes.selector, className].join(" ")}
      popupIcon={popupIcon}
      options={options}
      renderOption={(option, { selected }) => (
        <Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </Fragment>
      )}
      value={val}
      onBlur={onBlur}
      onOpen={onOpen}
      onChange={(_, o, reason) => {
        onSelect(o.map((opt) => (typeof opt === "string" ? opt : opt.value)));
      }}
      limitTags={limitTags}
      getOptionSelected={(opt, val) => compare(opt.value, val.value)}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Regular option
        return option.label;
      }}
      // onChange={(event, newValue) => {
      //   if (typeof newValue === 'string') {
      //     setValue({
      //       title: newValue,
      //     });
      //   } else if (newValue && newValue.inputValue) {
      //     // Create a new value from the user input
      //     setValue({
      //       title: newValue.inputValue,
      //     });
      //   } else {
      //     setValue(newValue);
      //   }
      // }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            value: params.inputValue,
            label: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      disableCloseOnSelect={hold}
      disableClearable={!clearable}
      // limitTags={limitTags}
      renderInput={(params) => (
        <div>
          <span className="bv-inputlabel">{label}</span>
          <TextField
            {...params}
            placeholder={placeholder}
            autoFocus
            disabled={!searchable}
            variant="outlined"
            InputProps={
              Adornment !== undefined
                ? {
                    ...params.InputProps,
                    startAdornment: (
                      <Fragment>
                        {/* // <InputAdornment position="start"> */}
                        <Adornment />
                        {params.InputProps.startAdornment}
                        {/* // </InputAdornment> */}
                      </Fragment>
                    ),
                  }
                : params.InputProps
            }
          />
        </div>
      )}
      forcePopupIcon
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
    />
  );
}

const useStyles = (width: number = 200) =>
  makeStyles((theme) =>
    createStyles({
      selector: {
        paddingTop: 8,
        // width: "100%",
        // height: 56,
        // maxWidth: width,
      },
      input: {
        flexWrap: "nowrap",
      },
    })
  );
