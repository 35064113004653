import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Event } from "../../../common/Event";
import { RootState } from "../../../store/root-reducer";
import Moment from "react-moment";
import { Compete } from "../../../store/compete/compete.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import ProgramLogo from "../../atomic/ProgramLogo";
import moment from "moment";
import { eventGetAll } from "../../../store/admin/admin.action";
import { competeGetAll } from "../../../store/compete/compete.action";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";

const generic: Event[] = [
  {
    date: new Date("2021-04-10"),
    name: "Pre-registration closes, payments must be finalised",
  },
  { date: new Date("2021-04-11"), name: "Season registration opens" },
  {
    date: new Date("2021-08-20"),
    overrideDateStr: "Mid to late August",
    name: "Kits arrive in Australia and start shipping",
  },
  {
    date: new Date("2021-08-14"),
    overrideDateStr: "August 2021",
    name: "2021 FLL FIRST Forward games released",
  },
];

const UpcomingEvents = ({
  competes,
  volleys,
  tournaments,
  teams,
  events,

  eventGetAll,
  competeGetAll,
  tournamentGetAll,
}: Props) => {
  const [all, setAll] = useState<Event[]>(generic);

  useEffect(() => {
    eventGetAll();
    competeGetAll();
    tournamentGetAll();
  }, []);

  useEffect(() => {
    const myComps = teams
      .map((t) => competes.find((comp) => comp.team_id === t._id))
      .filter((x) => x) as Compete[];
    const myTourns = myComps
      .map((comp) => tournaments.find((t) => comp.tournament_id === t._id))
      .filter((x, i, a) => x && a.indexOf(x) === i) as Tournament[];
    const myVol = volleys.map((v) => ({
      volley: v,
      tournament: tournaments.find((t) => v.tournament_id === t._id),
    }));
    setAll([
      ...generic,
      ...events.map((e) => ({
        date: new Date(e.date),
        name: e.title,
        program: e.program,
      })),
      ...myTourns.map((tourn) => ({
        date: tourn.start,
        name: tourn.name,
        program: tourn.program,
        role: "Coach",
      })),
      ...myVol
        .filter((v) => v.tournament)
        .map(({ volley, tournament }) => ({
          date: tournament!.start,
          name: tournament!.name,
          program: tournament!.program,
          role: volley.job ? volley.job.role : "Volunteer (TBA)",
        })),
    ]);
  }, [competes, tournaments, teams, events]);

  return (
    <div id="dashboard-schedule">
      <h1>Upcoming events</h1>
      <div className="wrapper">
        <table className="table">
          <thead>
            <tr>
              <th>Program</th>
              <th>Date</th>
              <th>Event</th>
              <th>Role</th>
              {/* <th>Link</th> */}
            </tr>
          </thead>
          <tbody className="striped">
            {all
              .filter((e) => e.date > new Date())
              .sort((a, b) => a.date.getTime() - b.date.getTime())
              .map((e, i) => (
                <tr key={i}>
                  <td>
                    {e.program ? (
                      <ProgramLogo program={e.program} />
                    ) : (
                      <span></span>
                    )}
                  </td>
                  <td>
                    {e.overrideDateStr ? (
                      e.overrideDateStr
                    ) : (
                      <Moment format="LL">{e.date}</Moment>
                    )}
                  </td>
                  <td>{e.name ?? ""}</td>
                  <td>{e.role}</td>
                  {/*<td>{e.link}</td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  competes: state.compete.all,
  teams: state.team.mine,
  volleys: state.volley.mine,
  tournaments: state.tournament.all,
  events: state.admin.events,
});

const connector = connect(mapStateToProps, {
  eventGetAll,
  competeGetAll,
  tournamentGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(UpcomingEvents);
