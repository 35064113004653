import React from "react";
import { ThemeColour } from "../../common/theme.types";

interface Props {
  className?: string;
  variant?: ThemeColour;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
}

const Button = ({
  className,
  variant = "primary",
  onClick,
  disabled,
  children,
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={`btn btn-${variant}${className ? " " + className : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
