import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../api/api.types";
import { RootState } from "../../store/root-reducer";
import { Team } from "../../store/teams/team.types";
import { FaButton } from "../atomic/FaButtons";
import TeamNumber from "../atomic/TeamNumber";
import AdvanceIcon from "@material-ui/icons/ArrowUpward";
import { Compete } from "../../store/compete/compete.types";
import {
  competeAdvance,
  competeDelete,
} from "../../store/compete/compete.action";
import logger from "../../utils/logger";
import { Tournament } from "../../store/tournament/tournament.types";
import Spinner from "../atomic/Spinner";
import { UserRole } from "../../common/backend.types";
import KickIcon from "@material-ui/icons/EventBusy";

const TournamentTeamRow = ({
  team,
  users,
  compete,
  user,
  next_id,
  competes,
  competeAdvance,
  competeDelete,
}: Props) => {
  const [coaches, setCoaches] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!team) return;
    setCoaches(
      team.coaches
        .map((c) => users.find((u) => u._id === c))
        .filter((u) => u) as IUser[]
    );
  }, [team, users]);

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    if (
      !window.confirm(
        "Are you sure?  The team must have qualified according to Champion's Award criteria."
      )
    )
      return setLoading(false);
    const success = await competeAdvance(compete._id);
    if (success) {
      logger.success("Team has been advanced", "TournamentTeamRow", true);
    }
    setLoading(false);
  };

  const handleKick = async () => {
    if (loading) return;
    setLoading(true);
    if (!window.confirm("Are you sure?")) return setLoading(false);
    const success = await competeDelete(compete._id);
    if (success) {
      logger.success("Team has been removed", "TournamentTeamRow", true);
    }
    setLoading(false);
  };

  return !team ? (
    <tr></tr>
  ) : (
    <tr>
      <td>
        <TeamNumber number={team.team_number} program={team.program} />
      </td>
      <td>{team.team_name}</td>
      <td>
        {coaches.map((c, i) => (
          <p className="m-0" key={i}>
            {c.given_name} {c.family_name}
          </p>
        ))}
      </td>
      <td>
        {coaches.map((c, i) => (
          <p className="m-0" key={i}>
            {c.email}
          </p>
        ))}
      </td>
      <td>
        {coaches.map((c, i) => (
          <p className="m-0" key={i}>
            {c.phone}
          </p>
        ))}
      </td>
      {user?.roles?.includes(UserRole.Admin) && (
        <td>
          <FaButton
            variant="contained"
            color="danger"
            onClick={handleKick}
            disabled={loading}
          >
            {loading ? "..." : <KickIcon />}
          </FaButton>
        </td>
      )}
      <td>
        {next_id &&
        !competes.find(
          (c) => c.team_id === team._id && c.tournament_id === next_id
        ) ? (
          <FaButton
            variant="contained"
            color="success"
            onClick={handleClick}
            disabled={loading}
          >
            {loading ? "..." : <AdvanceIcon />}
          </FaButton>
        ) : (
          <div />
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.all,
  competes: state.compete.all,
  user: state.auth.user,
});

const connector = connect(mapStateToProps, { competeAdvance, competeDelete });
type Props = ConnectedProps<typeof connector> & {
  team?: Team;
  compete: Compete;
  next_id?: Tournament["_id"];
};

export default connector(TournamentTeamRow);
