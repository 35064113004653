import { createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { IUser } from "../../../api/api.types";
import { FIRSTProgram } from "../../../common/backend.types";
import { RoleMap, VolunteerRole } from "../../../common/vol_roles";
import { FaButton } from "../../atomic/FaButtons";
import { SingleSelector } from "../../atomic/FaSelectors";
import Icon from "../../atomic/Icon";
import UserName from "../../atomic/UserName";
import { VolUser } from "./ManageVolunteerView";
import { DTOVolleyUpdate } from "../../../api/dtos/volley.dto";

interface Props {
  volunteer: VolUser;
  program: FIRSTProgram;
  volleyUpdate: (id: string, data: DTOVolleyUpdate) => Promise<boolean>;
  volleyDelete: (id: string) => Promise<boolean>;
}

export const BasicAssignRow = ({
  volunteer,
  program,
  volleyUpdate,
  volleyDelete,
}: Props) => {
  const [role, setRole] = useState<VolunteerRole | undefined>(
    volunteer.job?.role
  );

  useEffect(() => {
    setRole(volunteer.job?.role);
  }, [volunteer.job]);

  useEffect(() => {
    if (role !== volunteer.job?.role)
      volleyUpdate(volunteer._id, {
        job: role
          ? {
              program,
              role,
            }
          : undefined,
      });
  }, [role, volunteer.job]);
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell>
        {volunteer.email ? <UserName user={volunteer as IUser} /> : ""}
      </TableCell>
      <TableCell>{volunteer.volunteer_profile?.age_range ?? "?"}</TableCell>
      <TableCell>
        <ol className={classes.prefList}>
          {volunteer.volunteer_profile?.preferences
            .filter((p) => p.program === program)
            .map((pref, i) => (
              <li key={i}>{pref.role}</li>
            ))}
        </ol>
      </TableCell>
      <TableCell>
        <ul className={classes.histList}>
          {volunteer.volunteer_profile?.history
            .filter((p) => p.program === program)
            .map((pref, i) => (
              <li key={i}>{pref.role}</li>
            ))}
        </ul>
      </TableCell>
      {volunteer.days.map((day, i) => (
        <TableCell key={i} className={classes.cell}>
          {day ? (
            <Icon icon="check" className="text-success" />
          ) : (
            <Icon icon="times" className="text-danger" />
          )}
        </TableCell>
      ))}
      <TableCell>
        <SingleSelector<VolunteerRole>
          clearable
          className={classes.override}
          onSelect={setRole}
          options={RoleMap[program].map((p) => ({
            label: p,
            value: p,
          }))}
          value={role}
        />
      </TableCell>
      <TableCell>
        <FaButton
          color="danger"
          onClick={() =>
            window.confirm(
              "Are you sure?  You need to tell the volunteer that you are doing this."
            )
              ? volleyDelete(volunteer.volleyId)
              : {}
          }
        >
          <Icon icon="trash-alt" />
        </FaButton>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    basicassignrow: {},
    prefList: {},
    histList: {
      listStyle: "none",
      padding: 0,
    },
    cell: {
      textAlign: "center",
    },
    override: {
      zIndex: 2500,
    },
  })
);

export default BasicAssignRow;
