import {
  PaymentActionTypes,
  PaymentState,
  PAYMENT_CLEAR,
  PAYMENT_CREATE,
  PAYMENT_DELETE,
  PAYMENT_GET_ALL,
  PAYMENT_GET_MINE,
  PAYMENT_UPDATE,
} from "./payment.types";

const initialState: PaymentState = {
  mine: [],
  all: [],
  loading: true,
};

const paymentReducer = function (
  state: PaymentState = initialState,
  action: PaymentActionTypes
): PaymentState {
  switch (action.type) {
    case PAYMENT_GET_MINE:
      return {
        ...state,
        mine: action.payload,
        // all: [...action.payload, ...state.all].filter(
        //   (x, i, a) => a.findIndex((y) => y._id === x._id) === i
        // ),
        loading: false,
      };
    case PAYMENT_GET_ALL:
      return { ...state, all: action.payload, loading: false };
    case PAYMENT_CREATE:
      return {
        ...state,
        mine: [...state.mine, action.payload],
        all: [...state.all, action.payload],
        loading: false,
      };
    case PAYMENT_UPDATE:
      const p = action.payload;
      return {
        ...state,
        mine: state.mine.map((t) => (t._id === p._id ? p : t)),
        all: state.all.map((t) => (t._id === p._id ? p : t)),
        loading: false,
      };
    case PAYMENT_DELETE:
      return {
        ...state,
        mine: state.mine.filter((t) => t._id !== action.payload),
        all: state.all.filter((t) => t._id !== action.payload),
        loading: false,
      };
    case PAYMENT_CLEAR:
      return { ...state, mine: [], all: [] };
    default:
      return state;
  }
};

export default paymentReducer;
