import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { Address } from "../../../store/address/address.types";
import {
  paymentDelete,
  paymentUpdate,
} from "../../../store/payment/payment.action";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import AddressLabel from "../../atomic/AddressLabel";
import Button from "../../atomic/Button";
import CheckBox from "../../atomic/CheckBox";
import Icon from "../../atomic/Icon";
import UserName from "../../atomic/UserName";

interface LocalProps {
  users: IUser[];
  payments: Payment[];
  addresses: Address[];
  onEdit: (x: Payment) => void;
  filter?: string;
}

const Invoices = ({
  users,
  onEdit,
  payments,
  addresses,
  paymentUpdate,
  paymentDelete,
  filter,
}: Props) => {
  const togglePdfVerified = (p: Payment) => {
    paymentUpdate(p._id, { pdf_verified: !Boolean(p.pdf_verified) });
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Contact</th>
          <th>Amount</th>
          <th>Address</th>
          <th>Purchase order</th>
          <th>Verified?</th>
          <th>Invoice #</th>
          <th>Comments</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody className="striped">
        {payments
          .filter((p) => !p.credit_card && !p.admin_comment)
          .filter(
            (p) =>
              !filter ||
              users
                .find((u) => u._id === p.payer)
                ?.given_name?.toLowerCase()
                .includes(filter) ||
              users
                .find((u) => u._id === p.payer)
                ?.family_name?.toLowerCase()
                .includes(filter) ||
              addresses
                .find((u) => u._id === p.address)
                ?.company?.toLowerCase()
                .includes(filter) ||
              p.reference.toString().includes(filter)
          )
          .map((p) => (
            <tr key={p._id}>
              <td>{p.reference}</td>
              <td>
                <UserName user={users.find((u) => u._id === p.payer)} />
              </td>
              <td>${p.amount.toFixed(2)}</td>
              <td>
                <AddressLabel
                  address={addresses.find((a) => a._id === p.address)}
                />
              </td>
              <td>
                {p.pdf_url && (
                  <a
                    href={p.pdf_url}
                    target="_blank"
                    rel="noreferrer"
                    className={`btn btn-${p.thumbnail ? "clear" : "primary"}`}
                  >
                    {p.thumbnail ? (
                      <img src={p.thumbnail} alt="Purchase order thumbnail" />
                    ) : (
                      "View"
                    )}
                  </a>
                )}
              </td>
              <td>
                <CheckBox
                  checked={p.pdf_verified ?? false}
                  toggle={() => togglePdfVerified(p)}
                />
              </td>
              <td>{p.invoice_number}</td>
              <td>{p.comment}</td>
              <td>
                <Button
                  // TODO allow uploading of document here
                  onClick={() => onEdit(p)}
                  variant="clear"
                  className="text-success"
                >
                  <Icon icon="edit" />
                </Button>
              </td>
              <td>
                {
                  <Button
                    onClick={() => paymentDelete(p._id)}
                    variant="clear"
                    className="text-danger"
                  >
                    <Icon icon="trash-alt" />
                  </Button>
                }
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { paymentUpdate, paymentDelete });
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(Invoices);
