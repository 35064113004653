import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useParams,
  withRouter,
} from "react-router-dom";
import { UserRole } from "../../common/backend.types";
import { ThemeColour } from "../../common/theme.types";
import { VolunteerRole } from "../../common/vol_roles";
import { loadUser } from "../../store/auth/auth.action";
import { RootState } from "../../store/root-reducer";
import { tournamentGetAll } from "../../store/tournament/tournament.action";
import { Tournament } from "../../store/tournament/tournament.types";
import { volleyGetMine } from "../../store/volley/volley.action";
import { validateAuth } from "../../utils/auth.validate";
import logger, { trace } from "../../utils/logger";
import { FaHeader } from "../atomic/FaTexts";
import Spinner from "../atomic/Spinner";
import ProfileInterrupt from "../modals/ProfileInterrupt";
import { LocalRoute } from "../Routes";

const RoleRoute = ({
  component: Component,
  wrapper: Wrapper,
  auth,
  history,
  loadUser,
  roles,
  path,
  tournament: tourn,
  volley,
  tournamentGetAll,
  volleyGetMine,
  ...rest
}: Props) => {
  const tournament = rest.location.pathname.replace(/.*\//, "");
  const [isAllowed, setIsAllowed] = useState<boolean>();

  useEffect(() => {
    // console.log(`id: ${tournament}`);
    tournamentGetAll();
    volleyGetMine();
  }, []);

  useEffect(() => {
    const url = rest.location.pathname + rest.location.search;
    trace(auth, "ROUTE " + url);
    if (!validateAuth(auth)) localStorage.setItem("auth_callback", url);
    else {
      const ac = localStorage.getItem("auth_callback");
      if (ac && ac !== "undefined" /* && ac !== url*/) {
        localStorage.removeItem("auth_callback");
        logger.info("Redirecting to auth callback " + ac, "RoleRoute");
        history.push(ac);
      }
    }
  }, [auth, history, rest.location]);

  useEffect(() => {
    // console.log("A");
    if (!tournament) return setIsAllowed(true);
    // console.log("B");
    if (tourn.loading) return;
    // console.log("C");
    if (tourn.dummy.map((t) => t._id).includes(tournament))
      return setIsAllowed(true);
    // console.log("D");
    if (auth.loading) return;
    // console.log("E");
    if (validateAuth(auth, [UserRole.Admin])) return setIsAllowed(true);
    if (!validateAuth(auth)) return setIsAllowed(false);
    const checkTourn = tourn.all.find((t) => t._id === tournament);
    if (checkTourn && checkTourn.directors.includes(auth.user._id))
      return setIsAllowed(true);
    // console.log("F");
    if (volley.loading) return;
    const vol = volley.mine.find(
      (v) => v.tournament_id === tournament && v.user_id === auth.user?._id
    );
    // console.log("G");
    // console.log(vol);
    if (!vol || !vol.job) return setIsAllowed(false);
    // console.log("H");
    setIsAllowed(roles.includes(vol.job.role));
  }, [tournament, tourn, auth]);

  //   useEffect(() => {
  //     if (!auth.user) loadUser();
  //   }, [auth.user, loadUser]);

  return auth.loading || isAllowed === undefined ? (
    <Spinner variant={"primary"} />
  ) : !isAllowed || !validateAuth(auth) ? (
    <Redirect to={"/landing"} />
  ) : !auth.user.given_name ||
    !auth.user.family_name ||
    !auth.user.phone ||
    !auth.user.email_verified ? (
    <ProfileInterrupt />
  ) : Wrapper ? (
    <Route
      path={path as string}
      {...rest}
      render={(props) => (
        <Wrapper>
          {tournament === undefined ? (
            <FaHeader size="sm">Tournament not found</FaHeader>
          ) : (
            <Component
              {...props}
              route_tournament={[...tourn.all, ...tourn.dummy].find(
                (t) => t._id === tournament
              )}
            />
          )}
        </Wrapper>
      )}
    />
  ) : (
    <Route
      {...rest}
      path={path as string}
      render={(props) =>
        tournament === undefined ? (
          <FaHeader size="sm">Tournament not found</FaHeader>
        ) : (
          <Component
            {...props}
            route_tournament={[...tourn.all, ...tourn.dummy].find(
              (t) => t._id === tournament
            )}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  volley: state.volley,
  tournament: state.tournament,
});

const connector = connect(mapStateToProps, {
  loadUser,
  tournamentGetAll,
  volleyGetMine,
});

type Props = ConnectedProps<typeof connector> &
  RouteComponentProps<any> & {
    wrapper?: React.FunctionComponent;
    component: (props: any) => JSX.Element | null;
    exact?: boolean;
    path?: LocalRoute;
    roles: VolunteerRole[];
  };

export default connector(withRouter(RoleRoute));
