import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../../../store/root-reducer";
import TournamentForm from "../../forms/TournamentForm";
import { LocalRoute } from "../../Routes";

const CreateTournamentView = ({ history }: Props) => {
  return (
    <TournamentForm
      restricted={false}
      onSubmit={() => history.push("/all-tournaments" as LocalRoute)}
    />
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector> & RouteComponentProps<any> & {};

export default connector(withRouter(CreateTournamentView));
