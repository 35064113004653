import { GCheckFields, GDef, IGuard, IScoresheet } from "../api.types";

export interface DTOScoresheetCreate {
  /** What team is this? */
  team_id: string;
  /** What tournament is this */
  tournament_id: string;
  round: number;
  answers: {
    id: string;
    answer: string;
  }[];
  private_comment: string;
  public_comment: string;
}

export type DTOScoresheetGet = {
  _id: string;
  compete_id: string;
  user_id?: string;
  round: number;
  answers: {
    id: string;
    answer: string;
  }[];
  public_comment: string;
  private_comment: string;
  timestamp: Date;
};

export const GIsDTOScoresheetGet: IGuard<DTOScoresheetGet> = (x) => {
  return GCheckFields<DTOScoresheetGet>(x, [
    "_id",
    "compete_id",
    "round",
    "answers",
    "private_comment",
    "public_comment",
    "timestamp",
  ]);
};

export const ScoreGetDtoToScore = (x: DTOScoresheetGet): IScoresheet => x;
