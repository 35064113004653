export const ChallengeAwards = [
  "Robot Performance",
  "Champion's",
  "Core Values",
  "Innovation Project",
  "Robot Design",
  "Coach/Mentor",
  "Breakthrough",
  "Engineering Excellence",
  "Rising All-Star",
  "Motivate",
  //@todo
] as const;

export const FTCAwards = [
  "Inspire",
  //@todo
] as const;

export const Awards = [...ChallengeAwards, ...FTCAwards];

export type ChallengeAward = typeof ChallengeAwards[number];
export const isChallengeAward = (
  x: typeof Awards[number]
): x is ChallengeAward => ChallengeAwards.includes(x as any);

export type FTCAward = typeof FTCAwards[number];

export type Award = {
  award: typeof Awards[number];
  place: number;
};
