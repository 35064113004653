import { AppThunk } from "../app-thunk";
import {
  Address,
  AddressActionTypes,
  ADDRESS_UPDATE,
  ADDRESS_CLEAR,
  ADDRESS_GET_ALL,
  ADDRESS_GET_MINE,
  ADDRESS_DELETE,
  ADDRESS_CREATE,
} from "./address.types";
import AddressService from "../../api/services/address.service";
import logger from "../../utils/logger";
import { DTOAddressCreate, DTOAddressUpdate } from "../../api/dtos/address.dto";
import { AnalyticsTrack } from "../../api/analytics";

/**
 * Action types
 */

export const AddressGetMineAction = (data: Address[]): AddressActionTypes => ({
  type: ADDRESS_GET_MINE,
  payload: data,
});

export const AddressGetAllAction = (data: Address[]): AddressActionTypes => ({
  type: ADDRESS_GET_ALL,
  payload: data,
});

export const AddressCreateAction = (data: Address): AddressActionTypes => ({
  type: ADDRESS_CREATE,
  payload: data,
});

export const AddressUpdateAction = (data: Address): AddressActionTypes => ({
  type: ADDRESS_UPDATE,
  payload: data,
});

export const AddressDeleteAction = (
  data: Address["_id"]
): AddressActionTypes => ({
  type: ADDRESS_DELETE,
  payload: data,
});

export const AddressClearAction = (): AddressActionTypes => ({
  type: ADDRESS_CLEAR,
  payload: null,
});

/**
 * Actions
 */

export const addressGetMine = (): AppThunk => async (dispatch) => {
  try {
    const a = await AddressService.getMine();
    dispatch(AddressGetMineAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "address.action :: getMine", true);
  }
};

export const addressGetAll = (): AppThunk => async (dispatch) => {
  try {
    const a = await AddressService.getAll();
    dispatch(AddressGetAllAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "address.action :: getAll", true);
  }
};

export const addressCreate =
  (data: DTOAddressCreate): AppThunk =>
  async (dispatch) => {
    try {
      const a = await AddressService.create(data);
      dispatch(AddressCreateAction(a));
      AnalyticsTrack({
        name: "Created Address",
        data: {
          name: data.name,
          state: data.state,
        },
      });
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "address.action :: addressCreate",
        true
      );
    }
  };

export const addressUpdate =
  (aid: Address["_id"], data: DTOAddressUpdate): AppThunk =>
  async (dispatch) => {
    try {
      const a = await AddressService.update(aid, data);
      dispatch(AddressUpdateAction(a));
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "address.action :: addressUpdate",
        true
      );
    }
  };

export const addressDelete =
  (aid: Address["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await AddressService.remove(aid);
      dispatch(AddressDeleteAction(aid));
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "address.action :: addressDelete",
        true
      );
    }
  };
export const addressClear = (): AppThunk => (dispatch) => {
  dispatch(AddressClearAction());
};
