import api from "../../utils/api.instance";
import { GIsArray, IShipment, IUser } from "../api.types";
import {
  DTOShipmentCreate,
  DTOShipmentGet,
  DTOShipmentUpdate,
  GIsDTOShipmentGet,
} from "../dtos/shipment.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<IShipment[]> => {
  const a = (await api.get("shipment/mine")).data;
  return GIsArray<DTOShipmentGet>(a).map((ship) => GIsDTOShipmentGet(ship));
};

const getAll = () => Service.run(_getAll, {}, "SHIPMENT_GET_ALL");

const _getAll = async (): Promise<IShipment[]> => {
  const a = (await api.get("shipment")).data;
  return GIsArray<DTOShipmentGet>(a).map((ship) => GIsDTOShipmentGet(ship));
};

const create = async (data: DTOShipmentCreate): Promise<IShipment> => {
  return GIsDTOShipmentGet((await api.post(`shipment`, data)).data);
};

const update = async (
  id: IShipment["_id"],
  data: DTOShipmentUpdate
): Promise<IShipment> => {
  return GIsDTOShipmentGet((await api.put(`shipment/${id}`, data)).data);
};

const updateComment = async (
  id: IShipment["_id"],
  comment: IShipment["comment"]
): Promise<IShipment> => {
  return GIsDTOShipmentGet(
    (await api.put(`shipment/${id}/comment`, comment)).data
  );
};

const remove = async (id: IShipment["_id"]): Promise<boolean> => {
  await api.delete(`shipment/${id}`);
  return true;
};

const ShipmentService = {
  getMine,
  getAll,
  create,
  update,
  updateComment,
  remove,
};

export default ShipmentService;
