import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { addressGetAll } from "../../../store/address/address.action";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import { teamGetAll } from "../../../store/teams/team.action";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import UserName from "../../atomic/UserName";
import FinanceBreakdown from "../../reports/FinanceBreakdown";

type LocalProps = {
  userId: IUser["_id"];
};

const OutstandingView = ({
  userId,
  user,
  addressGetAll,
  teamGetAll,
  paymentGetAll,
}: Props) => {
  useEffect(() => {
    addressGetAll();
    teamGetAll();
    paymentGetAll();
  }, []);

  return !user ? (
    <div>Something went wrong...</div>
  ) : (
    <div>
      <h1>
        <UserName user={user} />
      </h1>

      <h2>Summary</h2>
      <div className="form">
        <strong>Teams:</strong> <span>${user.teamCost.toFixed(2)}</span>
        <strong>Kits:</strong> <span>${user.kitCost.toFixed(2)}</span>
        <strong>Shipping:</strong> <span>${user.shippingCost.toFixed(2)}</span>
        <strong>GST:</strong> <span>${((user.up * 0.1) / 1.1).toFixed(2)}</span>
        <strong>Discount:</strong>{" "}
        <span>${(user.credit ? user.credit : 0).toFixed(2)}</span>
        <strong>Total owed:</strong> <span>${user.up.toFixed(2)}</span>
        <strong>Already paid:</strong> <span>${user.down.toFixed(2)}</span>
        <strong className="mt-1">Total cost: </strong>
        <strong className="mt-1">${(user.up - user.down).toFixed(2)}</strong>
      </div>

      <FinanceBreakdown user={user} />
    </div>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState, props: LocalProps) => ({
    user: selectUser(state, props),
  });
};

const connector = connect(makeMapStateToProps, {
  addressGetAll,
  teamGetAll,
  paymentGetAll,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(OutstandingView);
