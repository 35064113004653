import { FIRSTProgram, FIRSTSeason } from "../../common/backend.types";
import api from "../../utils/api.instance";
import { GIsArray, ITournament } from "../api.types";
import {
  DTODummyTournamentCreate,
  DTOTournamentCreate,
  DTOTournamentGet,
  DTOTournamentUpdate,
  GIsDTOTournamentGet,
  TournamentFromDTOGet,
} from "../dtos/tournament.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<ITournament[]> => {
  const a = (await api.get("tournaments/mine")).data;
  return GIsArray<DTOTournamentGet>(a)
    .map((ad) => GIsDTOTournamentGet(ad))
    .map((t) => ({ ...TournamentFromDTOGet(t), start: new Date(t.start) }));
};

const getAll = () => Service.run(_getAll, {}, "TOURNAMENT_GET_ALL");

const _getAll = async (): Promise<ITournament[]> => {
  const a = (await api.get("tournaments")).data;
  return GIsArray<DTOTournamentGet>(a)
    .map((ad) => GIsDTOTournamentGet(ad))
    .map((t) => ({ ...TournamentFromDTOGet(t), start: new Date(t.start) }));
};

const create = async (data: DTOTournamentCreate): Promise<ITournament> => {
  const T = GIsDTOTournamentGet((await api.post(`tournaments`, data)).data);
  return { ...TournamentFromDTOGet(T), start: new Date(T.start) };
};
const createDummy = async (
  data: DTODummyTournamentCreate
): Promise<ITournament> => {
  const T = GIsDTOTournamentGet(
    (await api.post(`tournaments/dummy`, data)).data
  );
  return { ...TournamentFromDTOGet(T), start: new Date(T.start) };
};

const update = async (
  id: ITournament["_id"],
  data: DTOTournamentUpdate
): Promise<ITournament> => {
  const T = GIsDTOTournamentGet(
    (await api.put(`tournaments/${id}`, data)).data
  );
  return { ...TournamentFromDTOGet(T), start: new Date(T.start) };
};

const remove = async (id: ITournament["_id"]): Promise<boolean> => {
  await api.delete(`tournaments/${id}`);
  return true;
};

const checkNames = async (
  tournaments: {
    name: ITournament["name"];
    program: ITournament["program"];
    season: ITournament["season"];
  }[]
): Promise<ITournament["name"][]> =>
  GIsArray<string>(
    (await api.post("/tournaments/check-names", tournaments)).data
  );

const addDirector = async (
  tournament_id: ITournament["_id"],
  email: string
): Promise<ITournament> => {
  const a = (await api.put(`/tournaments/director/${tournament_id}`, { email }))
    .data;
  return TournamentFromDTOGet(GIsDTOTournamentGet(a));
};

const rmDirector = async (
  tournament_id: ITournament["_id"],
  email: string
): Promise<ITournament> => {
  const a = (
    await api.put(`/tournaments/director/rm/${tournament_id}`, {
      email,
    })
  ).data;
  return TournamentFromDTOGet(GIsDTOTournamentGet(a));
};

const addVolCo = async (
  tournament_id: ITournament["_id"],
  email: string
): Promise<ITournament> => {
  const a = (
    await api.put(`/tournaments/volunteer-coordinator/${tournament_id}`, {
      email,
    })
  ).data;
  return TournamentFromDTOGet(GIsDTOTournamentGet(a));
};

const rmVolCo = async (
  tournament_id: ITournament["_id"],
  email: string
): Promise<ITournament> => {
  const a = (
    await api.put(`/tournaments/volunteer-coordinator/rm/${tournament_id}`, {
      email,
    })
  ).data;
  return TournamentFromDTOGet(GIsDTOTournamentGet(a));
};

const TournamentService = {
  getMine,
  getAll,
  create,
  createDummy,
  update,
  remove,
  checkNames,
  addDirector,
  rmDirector,
  addVolCo,
  rmVolCo,
};

export default TournamentService;
