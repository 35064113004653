import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import logger from "../../utils/logger";
import Icon from "./Icon";
import { usePopper } from "react-popper";

interface Props {
  /** Content in clickable section */
  title?: React.ReactNode;
  /** Label for dropdown */
  label?: React.ReactNode;
  /** Class/prefix to be added to root container */
  className?: string;
  /** Show the dropdown carat */
  showCaret?: boolean;
  /** Clear background? */
  light?: boolean;
  /** Keep open on internal click? */
  hold?: boolean;
  /** Right-align content */
  right?: boolean;
  children: React.ReactNode;
}

function Dropdown({
  title,
  className,
  light = false,
  showCaret = false,
  label,
  right = false,
  hold = false,
  children,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [ID] = useState<string>(uuid());

  const inRef = useRef<HTMLInputElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  const { styles, attributes, update, state } = usePopper(
    btnRef.current,
    inRef.current,
    {
      strategy: "fixed",
      placement: right ? "bottom-end" : "bottom-start",
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            mainAxis: false, // true by default
          },
        },
      ],
    }
  );
  useEffect(() => {
    const handleClick = (e: MouseEvent, toggle: (b: boolean) => void) => {
      const h = e.target as HTMLElement;
      if (!container.current?.contains(h) && !inRef.current?.contains(h))
        toggle(false);
      // This isn't working... I can't even click on links
      // else if (!hold) toggle(false);
    };

    document.addEventListener("mousedown", (e) => handleClick(e, setOpen));
    return () => {
      document.removeEventListener("mousedown", (e) => handleClick(e, setOpen));
    };
  }, [setOpen]);

  useEffect(() => {
    // logger.success({ state }, "popper");
    if (open && update) update();
  }, [update, state, open]);

  return (
    <div
      ref={container}
      className={`dropdown${className ? " " + className : ""}${
        light ? " dropdown-light" : ""
      }`}
    >
      <div
        className="dropdown-clicker"
        ref={btnRef}
        id={ID}
        onClick={() => setOpen(!open)}
      >
        {label && <label htmlFor={ID}>{label}</label>}
        {title}
        {showCaret && (
          <Icon
            className="dropdown-caret"
            onClick={() => setOpen(!open)}
            icon={open ? "caret-up" : "caret-down"}
          />
        )}
      </div>
      <div
        className={`dropdown-content ${open ? "show" : "hide"}`}
        ref={inRef}
        style={styles.popper}
        aria-labelledby={ID}
        {...attributes.popper}
      >
        {children}
      </div>
    </div>
  );
}

export default Dropdown;
