import { Programs, Seasons, ShipmentContent } from "../../common/backend.types";
import { GCheckFields, GDef, GIsArray, IGuard, IUser } from "../api.types";

export interface DTOShipmentGet {
  _id: string;
  address: string;
  address_label: string;
  contents: ShipmentContent[];
  receiver: string;
  shipper: string;
  consignment_number: string;
  comment: string;
  timestamp: Date;
}

export interface DTOShipmentCreate {
  address: string;
  contents: ShipmentContent[];
  consignment_number: string;
  receiver: IUser["_id"];
  comment: string;
}

export interface DTOShipmentUpdate {
  contents?: ShipmentContent[];
  consignment_number?: string;
  comment?: string;
}

export const GIsDTOShipmentGet: IGuard<DTOShipmentGet> = (x) => {
  let d = GCheckFields<DTOShipmentGet>(x, [
    "_id",
    "address",
    "address_label",
    "contents",
    "receiver",
    "shipper",
    "consignment_number",
    "comment",
    "timestamp",
  ]);
  d.contents = GIsArray<ShipmentContent>(d.contents).map((c) =>
    GCheckFields<ShipmentContent>(c, ["count", "program", "season"])
  );
  for (let i = 0; i < d.contents.length; i++) {
    if (
      !Seasons.includes(d.contents[i].season) ||
      !Programs.includes(d.contents[i].program)
    )
      throw new TypeError("Invalid shipment contents");
  }
  return d;
};

export const GIsDTOShipmentCreate: IGuard<DTOShipmentCreate> = (x) => {
  let d = GCheckFields<DTOShipmentCreate>(x, [
    "address",
    "comment",
    "receiver",
    "consignment_number",
    "contents",
  ]);
  d.contents = GIsArray<ShipmentContent>(d.contents).map((c) =>
    GCheckFields<ShipmentContent>(c, ["count", "program", "season"])
  );
  for (let i = 0; i < d.contents.length; i++) {
    if (
      !Seasons.includes(d.contents[i].season) ||
      !Programs.includes(d.contents[i].program)
    )
      throw new TypeError("Invalid shipment contents");
  }
  return d;
};
export const GIsDTOShipmentUpdate: IGuard<DTOShipmentUpdate> = (x) => GDef(x);
