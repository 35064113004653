import React from "react";
import { VersionChangeObject } from "./releases";

interface Props {
  src: VersionChangeObject;
}

const VersionChanges = ({ src }: Props) => {
  return (
    <div className="version">
      <h1>{src.version.toString()}</h1>
      {src.intro.map((p, i) => (
        <p key={i}>{p}</p>
      ))}
      {src.sections.map((s, i) => (
        <div key={i} className="section">
          <h2>{s.title}</h2>
          <ul>
            {s.changes.map((c, idx) => (
              <li key={idx}>{c}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default VersionChanges;
