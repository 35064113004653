import { Attendance, VolParticipation } from "../../common/backend.types";
import {
  GCheckFields,
  GDef,
  IGuard,
  ITournament,
  IUser,
  IVolley,
} from "../api.types";

export interface DTOVolleyGet extends IVolley {}

export interface DTOVolleyUpdate {
  attendance?: Attendance;
  job?: VolParticipation;
}
export const GIsDTOVolleyGet: IGuard<DTOVolleyGet> = (x) =>
  GCheckFields<DTOVolleyGet>(GDef(x), [
    "user_id",
    "tournament_id",
    "attendance",
    "_id",
  ]);
