/**
 * Action types
 */

import { DTOScoresheetCreate } from "../../api/dtos/scoresheet.dto";
import ScoresheetService from "../../api/services/scoresheet.service";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import {
  Scoresheet,
  ScoresheetActionTypes,
  SCORE_CREATE,
  SCORE_DELETE,
  SCORE_GET_ALL,
  SCORE_GET_MINE,
} from "./scoring.types";

export const ScoresheetGetAllAction = (
  data: Scoresheet[]
): ScoresheetActionTypes => ({
  type: SCORE_GET_ALL,
  payload: data,
});
export const ScoresheetGetMineAction = (
  data: Scoresheet[]
): ScoresheetActionTypes => ({
  type: SCORE_GET_MINE,
  payload: data,
});

export const ScoresheetCreateAction = (
  data: Scoresheet
): ScoresheetActionTypes => ({
  type: SCORE_CREATE,
  payload: data,
});

export const ScoresheetDeleteAction = (
  id: Scoresheet["_id"]
): ScoresheetActionTypes => ({
  type: SCORE_DELETE,
  payload: id,
});

/**
 * Actions
 */

export const scoresheetGetAll =
  (tournament_id: string): AppThunk =>
  async (dispatch) => {
    try {
      const t = await ScoresheetService.getAll(tournament_id);
      dispatch(ScoresheetGetAllAction(t));
    } catch (error: any) {
      logger.logError(error, logger.warn, "scoresheet.action :: getAll", true);
    }
  };

export const scoresheetGetMine =
  (tournament_id: string): AppThunk =>
  async (dispatch) => {
    try {
      const t = await ScoresheetService.getMine(tournament_id);
      dispatch(ScoresheetGetMineAction(t));
    } catch (error: any) {
      logger.logError(error, logger.warn, "scoresheet.action :: getAll", false);
    }
  };

export const scoresheetCreate =
  (data: DTOScoresheetCreate): AppThunk<Promise<string>> =>
  async (dispatch, getState) => {
    try {
      const t = await ScoresheetService.create(data);
      dispatch(ScoresheetCreateAction(t));
      return "";
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "scoresheet.action :: scoresheetCreate",
        false
      );
      return error.message;
    }
  };

export const scoresheetDelete =
  (id: Scoresheet["_id"]): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      await ScoresheetService.remove(id);
      dispatch(ScoresheetDeleteAction(id));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "scoresheet.action :: scoresheetDelete",
        true
      );
      return false;
    }
  };
