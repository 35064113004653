import React, { Fragment } from "react";
import Moment from "react-moment";
import {
  getsAccessToJudgeAdvisor,
  getsAccessToJudging,
  getsAccessToScoring,
} from "../../common/vol_roles";
import { Tournament } from "../../store/tournament/tournament.types";
import { Volley } from "../../store/volley/volley.types";
import AddressLabel from "../atomic/AddressLabel";
import ButtonLink from "../atomic/ButtonLink";
import ProgramLogo from "../atomic/ProgramLogo";
import Spinner from "../atomic/Spinner";

interface Props {
  volley: Volley;
  tournament?: Tournament;
}

const TournamentVolleyRow = ({ volley, tournament }: Props) => {
  const myDays = [
    volley.days[0] ? "Bump in" : "",
    ...volley.days.slice(1, -1).map((b, i) => (b ? `Day ${i + 1}` : "")),
    volley.days[volley.days.length - 1] ? "Bump out" : "",
  ];

  return tournament ? (
    <tr>
      <td>
        <ProgramLogo program={tournament.program} />
      </td>
      <td>{tournament.name}</td>
      <td>
        <Moment format="LL">{tournament.start}</Moment>
      </td>
      <td>
        <div className="d-flex-col">
          <span>{tournament.street1}</span>
          <span>{tournament.street2}</span>
          <span>{tournament.street3}</span>
          <span>{tournament.suburb}</span>
          <span>
            {tournament.state} {tournament.postcode}
          </span>
        </div>
      </td>
      <td>{myDays.filter((d) => d).join(", ")}</td>
      <td>{volley.job?.role ?? "TBA"}</td>
      <td>
        {volley.job ? (
          <Fragment>
            {getsAccessToScoring(volley.job?.role) ? (
              <ButtonLink to={`/referee/${tournament._id}`}>Scoring</ButtonLink>
            ) : (
              <div />
            )}
            {getsAccessToJudging(volley.job?.role) ? (
              <ButtonLink to={`/judging/rubrics/${tournament._id}`}>
                Rubric entry
              </ButtonLink>
            ) : (
              <div />
            )}
            {getsAccessToJudgeAdvisor(volley.job?.role) ? (
              <ButtonLink to={`/judging/${tournament._id}`}>
                Judge advisor view
              </ButtonLink>
            ) : (
              <div />
            )}
          </Fragment>
        ) : (
          <div />
        )}
      </td>
    </tr>
  ) : (
    <tr></tr>
  );
};

export default TournamentVolleyRow;
