import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Award,
  ChallengeAward,
  ChallengeAwards,
  isChallengeAward,
} from "../../../common/awards";
import { competeUpdate } from "../../../store/compete/compete.action";
import { FaButton, FaIconButton } from "../../atomic/FaButtons";
import { SingleSelector } from "../../atomic/FaSelectors";

import DeleteIcon from "@material-ui/icons/Delete";

interface LocalProps {
  awards: Award[];
  onBlur: (award: Award[]) => void;
}

const AwardInput = ({ awards, onBlur }: LocalProps) => {
  const [tempAwards, setAwards] = useState<Partial<Award>[]>(awards);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    setAwards(awards);
  }, [awards]);

  // useEffect(() => {
  //   console.log(focused ? "Focused" : "Blurred");
  // }, [focused]);

  const checkFocus = useCallback(() => {
    return focused;
  }, [focused]);

  const savedCallback = useRef<any>();
  // Remember the latest callback.

  useEffect(() => {
    savedCallback.current = checkFocus;
  }, [checkFocus]);

  const handleFocusEvent = (focus: boolean) => {
    setFocused(focus);
    if (!focus)
      setTimeout(() => {
        if (!savedCallback.current())
          onBlur(tempAwards.filter((a) => a.award && a.place) as Award[]);
      }, 200);
  };

  const classes = useStyles();
  return (
    <div
      className={classes.awardinput}
      onFocus={() => handleFocusEvent(true)}
      onBlur={() => handleFocusEvent(false)}
    >
      {tempAwards.map((award, i) => (
        <div key={i} className={classes.awardrow}>
          <SingleSelector<ChallengeAward>
            options={ChallengeAwards.map((a) => ({
              label: a,
              value: a,
            }))}
            className={classes.selector}
            clearable
            value={
              award.award && isChallengeAward(award.award)
                ? award.award
                : undefined
            }
            onSelect={(a) =>
              a
                ? setAwards(
                    tempAwards.map((b, idx) =>
                      idx === i ? { award: a, place: b.place ?? 1 } : b
                    )
                  )
                : {}
            }
          />
          <SingleSelector<number>
            options={[
              {
                label: "1st",
                value: 1,
              },
              { label: "2nd", value: 2 },
              { label: "3rd", value: 1 },
            ]}
            className={classes.selector}
            clearable
            value={award.place}
            onSelect={(a) =>
              a
                ? setAwards(
                    tempAwards.map((b, idx) =>
                      idx === i ? { place: a ?? 1, award: b.award } : b
                    )
                  )
                : {}
            }
          />
          <FaIconButton
            rounded
            className={classes.delbtn}
            onClick={() => {
              setAwards(tempAwards.filter((a, idx) => i !== idx));
              onBlur(
                tempAwards
                  .filter((a, idx) => i !== idx)
                  .filter((a) => a.award && a.place) as Award[]
              );
            }}
            variant="contained"
            color="danger"
          >
            <DeleteIcon />
          </FaIconButton>
        </div>
      ))}
      <FaButton
        onClick={() =>
          setAwards([...tempAwards, { award: undefined, place: undefined }])
        }
        color="success"
      >
        Add
      </FaButton>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    awardinput: {
      width: 400,
      // ["&:focus-within"]: {
      //   backgroundColor: "blue",
      // },
    },
    selector: {
      width: 240,
    },
    awardrow: {
      display: "flex",
    },
    delbtn: {
      width: 48,
      height: 42,
      margin: "auto",
    },
  })
);

export default AwardInput;
