import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { LocalRoute } from "../Routes";

interface Props {
  children: React.ReactNode;
  to: LocalRoute;
  className?: string;
}

/** Simple wrapper for "Link" which enforces only valid routes are used */
function LocalLink({ to, className, children }: Props): ReactElement {
  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
}

export default LocalLink;
