import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RootState } from "../../../store/root-reducer";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import { IUser } from "../../../api/api.types";
import { Game, GameType } from "./games/types";
import { Team, TeamPublic } from "../../../store/teams/team.types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ScoresheetHistoryRow from "./ScoresheetHistoryRow";
import { Compete } from "../../../store/compete/compete.types";
import FaModal from "../../atomic/FaModal";
import ScoresheetView from "./ScoresheetView";
import { FaText } from "../../atomic/FaTexts";
import { Dialog } from "@material-ui/core";
import { DTOTeamPublic } from "../../../api/dtos/team.dto";

type LocalProps = {
  scoresheets: Scoresheet[];
  competes: Compete[];
  game?: GameType;
  onClick: (round: 1 | 2 | 3, team?: TeamPublic) => void;
};

export type ScoresheetWithTeam = Scoresheet & { team?: DTOTeamPublic };

const ScoresheetHistory = ({ scoresheets, competes, onClick, game }: Props) => {
  const [view, setView] = useState<ScoresheetWithTeam | undefined>();

  const classes = useStyles();
  return (
    <div className={classes.scoresheethistory}>
      <Table aria-label="simple table">
        <TableHead className={classes.headerRow}>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell align="center">Round 1</TableCell>
            <TableCell align="center">Round 2</TableCell>
            <TableCell align="center">Round 3</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {competes.map((c) => (
            <ScoresheetHistoryRow
              scoresheets={scoresheets.filter((s) => s.compete_id === c._id)}
              compete={c}
              score={game ? game.score : () => -1}
              onClickView={setView}
              onClickAdd={onClick}
            />
          ))}
        </TableBody>
      </Table>

      {game && (
        <Dialog
          open={Boolean(view)}
          onClose={() => setView(undefined)}
          maxWidth="lg"
          PaperProps={{ className: classes.dialog }}
        >
          {view ? (
            <ScoresheetView
              scoresheet={view}
              team_name={view.team?.team_name}
              game={game}
              referee
            />
          ) : (
            <div />
          )}
        </Dialog>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    scoresheethistory: {},
    headerRow: {
      background: theme.palette.grey[400],
    },
    dialog: {
      width: "100%",
      "@media print": {
        width: "100%",
        padding: 4,
      },
    },
  })
);

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(ScoresheetHistory);
