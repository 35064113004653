import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import RefreshIcon from "@material-ui/icons/Sync";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { competeGetAll } from "../../../store/compete/compete.action";
import { Compete } from "../../../store/compete/compete.types";
import { RootState } from "../../../store/root-reducer";
import {
  rubricCreate,
  rubricGetAll,
} from "../../../store/rubrics/rubric.action";
import { teamGetByTournament } from "../../../store/teams/team.action";
import { TeamPublic } from "../../../store/teams/team.types";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { Tournament } from "../../../store/tournament/tournament.types";
import { userGetTourn } from "../../../store/users/user.action";
import { FaIconButton } from "../../atomic/FaButtons";
import { FaHeader, FaText } from "../../atomic/FaTexts";
import Spinner from "../../atomic/Spinner";
import RubricEntryForm from "./RubricEntryForm";
import RubricTableRow from "./RubricTableRow";

type LocalProps = {
  route_tournament?: Tournament;
};

const JudgeView = ({
  rubrics,
  allTeams,
  tournaments,
  competes,
  users,
  loading,
  route_tournament,
  competeGetAll,
  rubricCreate,
  rubricGetAll,
  userGetTourn,
  tournamentGetAll,
  teamGetByTournament,
}: Props) => {
  useEffect(() => {
    competeGetAll();
    tournamentGetAll();
    if (!route_tournament) return;
    teamGetByTournament(route_tournament._id);
    rubricGetAll(route_tournament._id);
    userGetTourn(route_tournament._id);
  }, [route_tournament?._id]);

  const [teams, setTeams] = useState<TeamPublic[]>([]);

  // useEffect(() => {
  //   if (route_tournament)
  //     setTeams()
  // },[allTeams, competes, route_tournament])

  type View = { compete: Compete; team: TeamPublic };
  const [view, setView] = useState<View | undefined>();

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  useEffect(() => {
    setLastUpdate(new Date());
    setRefreshing(false);
  }, [rubrics]);

  /** debounce */
  const handleRefresh = () => {
    setRefreshing(true);
    if (route_tournament) rubricGetAll(route_tournament._id);
    competeGetAll();

    // setTimeout(() => setRefreshing(false), 10000);
  };

  const classes = useStyles();
  return loading ? (
    <Spinner variant="challenge" />
  ) : (
    <div className={classes.refereeview}>
      {!route_tournament ? (
        <FaText>Tournament not found</FaText>
      ) : (
        <Fragment>
          {/* @todo rankings */}
          {/* @todo fix modal */}
          <FaHeader size="md">
            {route_tournament.name}
            <FaIconButton
              onClick={handleRefresh}
              variant="contained"
              rounded
              className={classes.refreshBtn}
              color={refreshing ? "default" : "success"}
              disabled={refreshing}
            >
              <RefreshIcon />
            </FaIconButton>
          </FaHeader>
          <small>Last updated {moment(lastUpdate).format("HH:mm:ss")}</small>

          <FaHeader size="sm">Rubrics</FaHeader>
          <FaText>Click a row to edit rubric</FaText>
          <small>Note: Highlighted rows were scored by you</small>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team number</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">Core Values complete?</TableCell>
                <TableCell align="center">Project complete?</TableCell>
                <TableCell align="center">Robot Design complete?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTeams
                .map((t) => ({
                  team: t,
                  compete: competes.find(
                    (c) =>
                      c.team_id === t._id &&
                      c.tournament_id === route_tournament._id
                  ),
                }))
                .filter((t) => t.compete)
                .map(({ team, compete }) => (
                  <RubricTableRow
                    key={team._id}
                    onClick={() => setView({ team, compete: compete! })}
                    compete={compete!._id}
                    team={team}
                  />
                ))}
            </TableBody>
          </Table>
          {/* <RubricAnswerHistory
            rubrics={rubrics}
            game={Games.find((g) => g.season === route_tournament.season)}
          /> */}
          <Dialog
            open={!!view}
            onClose={() => {
              if (
                window.confirm(
                  "Are you sure? Any current changes will not be saved"
                )
              )
                setView(undefined);
            }}
            PaperProps={{ className: classes.dialog }}
          >
            {view ? (
              <RubricEntryForm
                team={view.team}
                compete={view.compete!}
                rubrics={rubrics.filter(
                  (r) => r.compete_id === view.compete!._id
                )}
              />
            ) : (
              <FaText>...</FaText>
            )}
          </Dialog>
        </Fragment>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    refereeview: {},
    topbar: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    teamselector: { width: 200 },
    body: {
      display: "flex",
      flexDirection: "column",
    },
    dialog: {
      padding: 16,
      minWidth: "80%",
      "@media print": {
        width: "100%",
        padding: 4,
      },
    },
    refreshBtn: {
      height: 32,
      width: 32,
      marginLeft: theme.spacing(1),
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  rubrics: state.rubrics.all,
  allTeams: [
    ...state.team.all,
    ...state.team.tournament,
    ...state.team.dummy,
  ].filter((x, i, a) => a.findIndex((y) => y._id === x._id) === i),
  tournaments: state.tournament.all,
  competes: state.compete.all,
  users: state.users.all,
  loading:
    state.team.loading || state.tournament.loading || state.compete.loading,
});

const connector = connect(mapStateToProps, {
  rubricCreate,
  rubricGetAll,
  userGetTourn,
  competeGetAll,
  tournamentGetAll,
  teamGetByTournament,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(JudgeView);
