import React, { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { Tournament } from "../../store/tournament/tournament.types";
import ButtonLink from "../atomic/ButtonLink";
import LocalLink from "../atomic/LocalLink";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import Spinner from "../atomic/Spinner";
import { Team } from "../../store/teams/team.types";
import TournamentTeamRow from "./TournamentTeamRow";
import { volleyCreate, volleyGetAll } from "../../store/volley/volley.action";
import TournamentVolleyDirectorRow from "./TournamentVolleyDirectorRow";
import Button from "../atomic/Button";
import Icon from "../atomic/Icon";
import {
  exportTournamentTeams,
  exportTournamentVolunteers,
} from "../../store/exports.action";
import {
  FIRSTProgram,
  FIRSTSeason,
  UserRole,
} from "../../common/backend.types";
import { LocalRoute } from "../Routes";
import { competeGetAll } from "../../store/compete/compete.action";
import { teamGetAll } from "../../store/teams/team.action";
import { userGetAll } from "../../store/users/user.action";
import { Token } from "../../api/dtos/token.dto";
import TokenService from "../../api/services/token.service";
import { FaButton } from "../atomic/FaButtons";

interface LocalProps {
  tournament: Tournament;
  admin?: boolean;
}

const sanitise = (x: string): string =>
  x
    .replaceAll(" ", "-")
    .replaceAll(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();

const TournamentReport = ({
  tournament,
  admin,
  user,
  volleys,
  users,
  competes,
  team,
  tournaments,
  loading,
  season,
  exportTournamentTeams,
  exportTournamentVolunteers,
  volleyGetAll,
  competeGetAll,
  teamGetAll,
  userGetAll,
}: Props) => {
  const [myTeams, setMyTeams] = useState<Team[]>([]);
  const tFilter = (t: Tournament): boolean =>
    t.season === season && t.program === FIRSTProgram.FLL_CHALLENGE;

  useEffect(() => {
    volleyGetAll();
    competeGetAll();
    teamGetAll();
    userGetAll();
  }, []);

  const [tokens, setTokens] = useState<Token[]>([]);
  const [trigger, setTrigger] = useState<boolean>(true);

  useEffect(() => {
    if (trigger)
      TokenService.getForTournament(tournament._id).then(setTokens).catch();
    setTrigger(false);
  }, [trigger]);

  const createToken = useCallback(() => {
    TokenService.create({
      name: "Default name",
      target: tournament._id,
    });
    setTrigger(true);
  }, [tournament._id]);

  const getLink = useCallback(
    (tourn: Tournament): LocalRoute => {
      const base = window.location.origin;
      const replicates = tournaments
        .filter(tFilter)
        .filter((t) => sanitise(t.name) === sanitise(tourn.name));
      return `/rankings/${
        replicates.length > 1 ? tourn._id : sanitise(tourn.name)
      }`;
    },
    [tournaments]
  );

  // useEffect(() => {
  //   competes.filter((c) => c.tournament_id === tournament._id);
  // }, [competes, tournament, team]);

  return (
    <div className="tournament-report">
      <div className="tab-grid-4 mt-2">
        {tournament.ship_kits && !tournament.ship_address ? (
          admin ? (
            <div className="col-span-4 mb-1">
              No shipping address nominated to receive their{" "}
              {tournament.ship_kits} kit{tournament.ship_kits === 1 ? "" : "s"}.
            </div>
          ) : (
            <div className="col-span-4 mb-1">
              <strong className="text-danger">Alert: </strong>
              You need to nominate a shipping address to receive your{" "}
              {tournament.ship_kits} kit{tournament.ship_kits === 1 ? "" : "s"}.
              Go to <LocalLink to="/my-tournaments">Tournaments</LocalLink> to
              edit.
            </div>
          )
        ) : (
          <span className="col-span-4"></span>
        )}

        <strong>Name</strong>
        <span>{tournament.name}</span>
        <strong>Date</strong>
        <span>
          <Moment format="LL">{tournament.start}</Moment>
          {tournament.n_days === 1 ? "" : " (" + tournament.n_days + " days)"}
        </span>

        <strong>Tokens</strong>
        {tokens.map((token, index) => (
          <div key={index}>
            <strong>Token {index + 1}:</strong>
            <span> {token.secret}</span>
          </div>
        ))}
        <FaButton onClick={createToken}>Create</FaButton>

        <strong>Location</strong>
        <div className="d-flex-col flex-left col-span-3">
          <span>{tournament.street1}</span>
          <span>{tournament.street2}</span>
          <span>{tournament.street3}</span>
          <span>{tournament.suburb}</span>
          <span>
            {tournament.state} {tournament.postcode}
          </span>
        </div>

        <strong>Teams registered</strong>
        {loading ? (
          <Spinner variant="challenge" />
        ) : (
          <span>
            {competes.filter((c) => c.tournament_id === tournament._id).length}{" "}
            / {tournament.cap}
          </span>
        )}
        <strong>Volunteers registered</strong>
        <span>
          {volleys.filter((v) => v.tournament_id === tournament._id).length}
        </span>
        <span>
          <ButtonLink to={`/manage/${tournament._id}`}>
            Manage volunteers
          </ButtonLink>
        </span>
      </div>
      <br />
      <div className="d-flex">
        <ButtonLink className="mr-1" to={getLink(tournament)}>
          Rankings
        </ButtonLink>
        <ButtonLink className="mr-1" to={`/judging/rubrics/${tournament._id}`}>
          Rubric entry
        </ButtonLink>
        <ButtonLink className="mr-1" to={`/judging/${tournament._id}`}>
          Judge advisor view
        </ButtonLink>
        <ButtonLink className="mr-1" to={`/referee/${tournament._id}`}>
          Scoring
        </ButtonLink>
      </div>
      <br />
      <strong>Advances to:</strong>
      <span className="pl-2">
        {tournaments.find((t) => t._id === tournament.next_tourn)?.name ??
          "None"}
      </span>

      <div className="team-list">
        <span className="d-flex">
          <h2>Teams</h2>
          <Button
            className="ml-auto"
            onClick={() => exportTournamentTeams(tournament)}
          >
            Download
          </Button>
        </span>
        <table className="table table-primary">
          <thead>
            <tr>
              <th>Team number</th>
              <th>Team name</th>
              <th>Coach names</th>
              <th>Coach emails</th>
              <th>Phone number</th>
              {user?.roles?.includes(UserRole.Admin) && <th>Kick</th>}
              <th>Advance</th>
            </tr>
          </thead>
          <tbody className="striped">
            {competes
              .filter((c) => c.tournament_id === tournament._id)
              .map((comp, i) => (
                <TournamentTeamRow
                  key={i}
                  team={(tournament.dummy ? team.dummy : team.all).find(
                    (t) => t._id === comp.team_id
                  )}
                  compete={comp}
                  next_id={tournament.next_tourn}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="volunteer-list">
        <span className="d-flex">
          <h2>Volunteers</h2>
          <Button
            className="ml-auto"
            onClick={() => exportTournamentVolunteers(tournament)}
          >
            Download
          </Button>
        </span>
        <table className="table table-volunteer">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Days</th>
            </tr>
          </thead>
          <tbody className="striped">
            {volleys
              .filter((v) => v.tournament_id === tournament._id)
              .map((v, i) => (
                <TournamentVolleyDirectorRow
                  key={i}
                  user={users.find((u) => u._id === v.user_id)}
                  volley={v}
                  tournament={tournament}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  competes: state.compete.all,
  team: state.team,
  volleys: state.volley.all,
  users: state.users.all,
  tournaments: state.tournament.all,
  loading: state.compete.loading,
  user: state.auth.user,
  season: state.settings.season,
});

const connector = connect(mapStateToProps, {
  exportTournamentTeams,
  exportTournamentVolunteers,
  volleyGetAll,
  competeGetAll,
  teamGetAll,
  userGetAll,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(TournamentReport);
