type Labels = [string, string, string, string];
interface ILabels {
  desc: {
    [key: string]: string;
  };
  label: {
    [key: string]: Labels;
  };
}

const corevalues: ILabels = {
  desc: {
    discovery: "Team explored new skills and ideas.",
    innovation: "Team used creativity and presistence to solve problems.",
    impact: "Team applied what they had learned to improve the world.",
    inclusion: "Team demonstrated respect and embraced their differences.",
    teamwork:
      "Team clearly showed they had worked as a team throughout their journey",
    fun: "Teams clearly had fun and celebrated what they have achieved",
  },
  label: {
    discovery: ["", "", "", ""],
    innovation: ["", "", "", ""],
    impact: ["", "", "", ""],
    inclusion: ["", "", "", ""],
    teamwork: ["", "", "", ""],
    fun: ["", "", "", ""],
  },
};
export default corevalues;
