import { State } from "../../common/backend.types";
import { GCheckFields, GDef, IGuard } from "../api.types";

export interface DTOAddressGet {
  _id: string;
  user: string;
  label: string;
  company: string;
  abn?: string;
  name: string;
  email: string;
  phone: string;
  street1: string;
  street2: string;
  street3: string;
  suburb: string;
  postcode: string;
  state: State;
  country: string;
}

export interface DTOAddressCreate {
  label: string;
  company: string;
  abn?: string;
  name: string;
  email: string;
  phone: string;
  street1: string;
  street2: string;
  street3: string;
  suburb: string;
  postcode: string;
  state: State;
  country: string;
}

export interface DTOAddressUpdate {
  label?: string;
  company?: string;
  abn?: string;
  name?: string;
  email?: string;
  phone?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  suburb?: string;
  postcode?: string;
  state?: State;
  country?: string;
}

export const GIsDTOAddressGet: IGuard<DTOAddressGet> = (x: any) =>
  GCheckFields<DTOAddressGet>(GDef(x), [
    "_id",
    "label",
    "company",
    "name",
    "email",
    "phone",
    "street1",
    "street2",
    "street3",
    "suburb",
    "postcode",
    "state",
    "country",
  ]);

export const GIsDTOAddressCreate: IGuard<DTOAddressCreate> = (x: any) =>
  GCheckFields<DTOAddressCreate>(GDef(x), [
    "label",
    "company",
    "name",
    "email",
    "phone",
    "street1",
    "street2",
    "street3",
    "suburb",
    "postcode",
    "state",
    "country",
  ]);

export const GIsDTOAddressUpdate: IGuard<DTOAddressUpdate> = (x: any) =>
  GDef(x) as DTOAddressUpdate;
