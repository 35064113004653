import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  FIRSTProgram,
  ProgramToClass,
  ProgramToLabel,
} from "../../../../common/backend.types";
import { getInventory } from "../../../../inventory/getInventory";
import { getMyCosts, getMyPayments } from "../../../../store/auth/auth.action";
import { competeGetAll } from "../../../../store/compete/compete.action";
import { Compete } from "../../../../store/compete/compete.types";
import { RootState } from "../../../../store/root-reducer";
import { rubricGetMine } from "../../../../store/rubrics/rubric.action";
import { RubricAnswer } from "../../../../store/rubrics/rubric.types";
import { teamGetMine } from "../../../../store/teams/team.action";
import { Team } from "../../../../store/teams/team.types";
import { tournamentGetAll } from "../../../../store/tournament/tournament.action";
import { Tournament } from "../../../../store/tournament/tournament.types";
import Button from "../../../atomic/Button";
import ButtonLink from "../../../atomic/ButtonLink";
import { FaHeader } from "../../../atomic/FaTexts";
import Icon from "../../../atomic/Icon";
import Modal from "../../../atomic/Modal";
import Spinner from "../../../atomic/Spinner";
import TeamNumber from "../../../atomic/TeamNumber";
import RegisterCompeteForm from "../../../forms/RegisterCompeteForm";
import RubricViewForm from "../../Judging/RubricViewForm";
import CoachTournamentButton from "./CoachTournamentButton";
import TeamResources from "./TeamResources";
import {
  selectCoachTeams,
  selectMyTeams,
} from "../../../../store/teams/team.selector";
import CopyTeamButton from "./CopyTeamButton";
import { makeUserWithMoneys } from "../../../../store/users/user.selector";

type CompeteExtra = Compete & {
  team?: Team;
  tournament?: Tournament;
};

const CoachTab = ({
  user,
  myTeams,
  coachTeams,
  loading,
  competes,
  payment,
  tournaments,
  rubrics,
  addresses,
  getMyCosts,
  getMyPayments,
  competeGetAll,
  tournamentGetAll,
  teamGetMine,
  rubricGetMine,
}: Props) => {
  const nKits = (p: FIRSTProgram) => {
    return myTeams
      .filter((t) => t.ship_kit && t.program !== FIRSTProgram.FTC)
      .filter((t) => t.program === p).length;
  };
  const [comps, setComps] = useState<CompeteExtra[]>([]);
  const [moveTeams, setMoveTeams] = useState<
    { teams: Team[]; program: FIRSTProgram } | undefined
  >();
  const [viewRubric, setViewRubric] = useState<{
    team: Team;
    compete?: Compete;
    rubrics: RubricAnswer[];
  }>();

  useEffect(() => {
    teamGetMine();
    competeGetAll();
    tournamentGetAll();
    rubricGetMine();
  }, []);

  useEffect(() => {
    setComps(
      competes
        .filter((c) => myTeams.map((t) => t._id).includes(c.team_id))
        .map((c) => ({
          ...c,
          team: myTeams.find((t) => t._id === c.team_id),
          tournament: tournaments.find((t) => t._id === c.tournament_id),
        }))
    );
  }, [competes]);

  const classes = useStyles();
  return !user ? (
    <div>Something went wrong</div>
  ) : (
    <div id="coach-tab">
      <div className="updates"></div>
      <div className="my-teams">
        <FaHeader size="sm">Updates</FaHeader>
        <div className="d-flex">
          <span className="mr-1">Outstanding payments:</span>
          <span className="mr-1">
            ${Math.max(user.up - user.down, 0).toFixed(2)}
          </span>
          <span className="mr-1">
            {user.up > user.down && (
              <div>
                <ButtonLink variant="primary" to="/pay-now">
                  Pay now!
                </ButtonLink>
                <small className="text-danger">
                  <strong>Note:</strong> Your registration is not finalised
                  until your balance is paid.{" "}
                  {new Date().getTime() < new Date("2021-04-10").getTime()
                    ? "If this is not complete before COB April 10th, your kits may not be guaranteed."
                    : ""}
                </small>
              </div>
            )}
          </span>
        </div>

        <div className="d-flex mt-1">
          <span className="mr-1">Tournaments:</span>
          <span className="mr-1">
            {comps.length} /{" "}
            {
              myTeams.filter((t) => t.program !== FIRSTProgram.FLL_DISCOVER)
                .length
            }
          </span>

          <span className="mr-1">
            {/* {comps.filter(
              (c) => c.tournament?.program === FIRSTProgram.FLL_EXPLORE
            ).length <
              myTeams.filter((t) => t.program === FIRSTProgram.FLL_EXPLORE)
                .length && (
              <Button
                variant="explore"
                onClick={() =>
                  setMoveTeams({
                    program: FIRSTProgram.FLL_EXPLORE,
                    teams: myTeams
                      .filter((t) => t.program === FIRSTProgram.FLL_EXPLORE)
                      .filter(
                        (t) => !comps.map((c) => c.team_id).includes(t._id)
                      ),
                  })
                }
              >
                Explore: Register now!
              </Button>
            )} */}
          </span>
          <span className="mr-1">
            {/* {comps.filter(
              (c) => c.tournament?.program === FIRSTProgram.FLL_CHALLENGE
            ).length <
              myTeams.filter((t) => t.program === FIRSTProgram.FLL_CHALLENGE)
                .length && (
              <div>
                <Button
                  variant="challenge"
                  onClick={() =>
                    setMoveTeams({
                      program: FIRSTProgram.FLL_CHALLENGE,
                      teams: myTeams
                        .filter((t) => t.program === FIRSTProgram.FLL_CHALLENGE)
                        .filter(
                          (t) => !comps.map((c) => c.team_id).includes(t._id)
                        ),
                    })
                  }
                >
                  Challenge: Register now!
                </Button>
                <small className="text-danger">
                  <strong>Note:</strong> You must register all Challenge teams
                  to tournaments.
                </small>
              </div>
            )} */}
          </span>
          <span className="mr-1">
            {/* {comps.filter((c) => c.tournament?.program === FIRSTProgram.FTC)
              .length <
              myTeams.filter((t) => t.program === FIRSTProgram.FTC).length && (
              <Button
                variant="ftc"
                onClick={() =>
                  setMoveTeams({
                    program: FIRSTProgram.FTC,
                    teams: myTeams
                      .filter((t) => t.program === FIRSTProgram.FTC)
                      .filter(
                        (t) => !comps.map((c) => c.team_id).includes(t._id)
                      ),
                  })
                }
              >
                FTC: Register now!
              </Button>
            )} */}
          </span>
        </div>
        <div className="d-flex mt-1">
          <span className="mr-1">Shipments:</span>
          <span className="ship-kits">
            0x shipped, {getInventory(myTeams).reduce((p, c) => p + c.count, 0)}
            x outstanding
          </span>
        </div>

        <div className="mt-1">
          <TeamResources />
        </div>

        <FaHeader size="sm">My teams</FaHeader>
        <p>Registration for this season is closed. See you next year!</p>
        {/* <ButtonLink to="/register-teams" className="mb-1" variant="success">
          <Icon icon="plus" className="mr-1" /> Register new team/s
        </ButtonLink> */}
        {/* <CopyTeamButton /> */}
        {loading ? (
          <Spinner variant="secondary" />
        ) : myTeams.length + coachTeams.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Program</th>
                <th>Number</th>
                <th>Name</th>
                <th>Tournaments</th>
              </tr>
            </thead>
            <tbody className={"ta-center"}>
              {myTeams.map((t) => (
                <tr key={t._id}>
                  <td className={"text-bold text-" + ProgramToClass(t.program)}>
                    {ProgramToLabel(t.program)}
                  </td>
                  <td>
                    <TeamNumber number={t.team_number} program={t.program} />
                  </td>
                  <td>{t.team_name}</td>
                  <td>
                    {competes
                      .filter((c) => c.team_id === t._id)
                      .filter(
                        (x, i, a) =>
                          a.findIndex(
                            (y) =>
                              y.team_id === x.team_id &&
                              y.tournament_id === x.tournament_id
                          ) === i
                      )
                      .map((c, i) => (
                        <CoachTournamentButton
                          key={i}
                          tournament={tournaments.find(
                            (tourn) => tourn._id === c.tournament_id
                          )}
                          onClickMove={() =>
                            setMoveTeams({ teams: [t], program: t.program })
                          }
                          onClickRubric={() => {
                            setViewRubric({
                              team: t,
                              compete: c,
                              rubrics: rubrics.filter(
                                (r) => r.compete_id === c._id
                              ),
                            });
                          }}
                        />
                      ))}
                  </td>
                </tr>
              ))}
              <tr className="line">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {coachTeams.map((t) => (
                <tr key={t._id}>
                  <td className={"text-bold text-" + ProgramToClass(t.program)}>
                    {ProgramToLabel(t.program)}
                  </td>
                  <td>
                    <TeamNumber number={t.team_number} program={t.program} />
                  </td>
                  <td>{t.team_name}</td>
                  <td>
                    {competes
                      .filter((c) => c.team_id === t._id)
                      .filter(
                        (x, i, a) =>
                          a.findIndex(
                            (y) =>
                              y.team_id === x.team_id &&
                              y.tournament_id === x.tournament_id
                          ) === i
                      )
                      .map((c, i) => (
                        <CoachTournamentButton
                          key={i}
                          tournament={tournaments.find(
                            (tourn) => tourn._id === c.tournament_id
                          )}
                          onClickMove={() =>
                            setMoveTeams({ teams: [t], program: t.program })
                          }
                          onClickRubric={() => {
                            setViewRubric({
                              team: t,
                              compete: c,
                              rubrics: rubrics.filter(
                                (r) => r.compete_id === c._id
                              ),
                            });
                          }}
                        />
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <span></span>
        )}
      </div>
      <Modal
        open={moveTeams !== undefined}
        onClose={() => setMoveTeams(undefined)}
      >
        {moveTeams ? (
          <RegisterCompeteForm
            teams={moveTeams.teams}
            program={moveTeams.program}
            onSubmit={() => setMoveTeams(undefined)}
          />
        ) : (
          "Something went wrong..."
        )}
      </Modal>
      <Dialog
        open={viewRubric !== undefined}
        onClose={() => setViewRubric(undefined)}
        PaperProps={{ className: classes.dialog }}
      >
        {viewRubric && viewRubric.compete ? (
          <RubricViewForm
            team={viewRubric.team}
            compete={viewRubric.compete}
            rubrics={viewRubric.rubrics}
          />
        ) : (
          "Something went wrong..."
        )}
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    refereeview: {},
    topbar: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    teamselector: { width: 200 },
    body: {
      display: "flex",
      flexDirection: "column",
    },
    dialog: {
      padding: 16,
      minWidth: "80%",
    },
    refreshBtn: {
      height: 32,
      width: 32,
      marginLeft: theme.spacing(1),
    },
  })
);

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState) => ({
    user: selectUser(state, { userId: "me" }),
    loading: state.team.loading,
    myTeams: selectMyTeams(state),
    coachTeams: selectCoachTeams(state),
    payment: state.payment,
    addresses: state.address.mine,
    tournaments: state.tournament.all,
    competes: state.compete.all,
    rubrics: state.rubrics.mine,
  });
};

const connector = connect(makeMapStateToProps, {
  getMyCosts,
  getMyPayments,
  competeGetAll,
  tournamentGetAll,
  teamGetMine,
  rubricGetMine,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(CoachTab);
