import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DTOUserUpdate } from "../../api/dtos/user.dto";
import { ThemeColour } from "../../common/theme.types";
import {
  loadUser,
  logout,
  resendEmailVerification,
  updateMyProfile,
} from "../../store/auth/auth.action";
import { RootState } from "../../store/root-reducer";
import useInterval from "../../utils/hooks/useInterval";
import logger from "../../utils/logger";
import Button from "../atomic/Button";
import Icon from "../atomic/Icon";
import Modal from "../atomic/Modal";
import SubmitButton from "../atomic/SubmitButton";
import BasicDetailsForm from "../forms/BasicDetailsForm";

type FormData = DTOUserUpdate;

const ProfileInterrupt = ({
  auth: { user },
  resendEmailVerification,
  updateMyProfile,
  logout,
  loadUser,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({
    given_name: user?.given_name,
    family_name: user?.family_name,
    phone: user?.phone,
  });

  // If not verified, check back in every minute.
  useInterval(() => {
    if (user && !user.email_verified) loadUser();
  }, 60000);

  useEffect(() => {
    resendEmailVerification();
  }, [resendEmailVerification]);

  return (
    <Modal open title="User profile actions">
      {(!user?.given_name || !user?.family_name || !user?.phone) && (
        <Fragment>
          We need just a little more information from you! Please fill in the
          below details.
          <BasicDetailsForm hidePassword />
        </Fragment>
      )}
      {!user?.email_verified && (
        <div>
          <h2>Email not verified</h2>
          <p>
            Please check your inbox; when you registered, you should have
            received an email to verify your email address{" "}
            {user ? "(" + user.email + ")" : ""}.
          </p>
          <p>
            To re-send the email verification, please{" "}
            <Button variant={"primary"} onClick={resendEmailVerification}>
              click here
            </Button>
          </p>
          <p>
            Once you've confirmed your email,{" "}
            <Button onClick={loadUser}>click here</Button>
          </p>
        </div>
      )}
      <br />
      <Button variant={"warn"} onClick={logout}>
        <Icon icon="sign-out-alt" /> Sign out
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {
  updateMyProfile,
  resendEmailVerification,
  logout,
  loadUser,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(ProfileInterrupt);
