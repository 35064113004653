import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { connect, ConnectedProps } from "react-redux";
import { IUser, IVolley } from "../../api/api.types";
import { ProgramToClass, ProgramToLabel } from "../../common/backend.types";
import { addressGetAll } from "../../store/address/address.action";
import { paymentGetAll } from "../../store/payment/payment.action";
import { RootState } from "../../store/root-reducer";
import { shipmentGetAll } from "../../store/shipping/shipment.action";
import { teamGetAll } from "../../store/teams/team.action";
import { tournamentGetAll } from "../../store/tournament/tournament.action";
import { Tournament } from "../../store/tournament/tournament.types";
import { makeUserWithMoneys } from "../../store/users/user.selector";
import { volleyGetAll } from "../../store/volley/volley.action";
import ProgramLogo from "../atomic/ProgramLogo";
import Spinner from "../atomic/Spinner";
import TeamNumber from "../atomic/TeamNumber";
import FinanceBreakdown from "./FinanceBreakdown";

type LocalProps = {
  userId: IUser["_id"];
};

type PopVol = IVolley & {
  popTourn?: Tournament;
};

const UserView = ({
  user,
  teams,
  volleys,
  tournaments,
  loading,
  teamGetAll,
  volleyGetAll,
  tournamentGetAll,
  shipmentGetAll,
  addressGetAll,
  paymentGetAll,
}: Props) => {
  const Teams = teams.filter((t) => t.user === (user?._id ?? ""));

  const [vols, setVols] = useState<PopVol[]>([]);

  useEffect(() => {
    teamGetAll();
    volleyGetAll();
    tournamentGetAll();
    // Needed for finance breakdown
    shipmentGetAll();
    addressGetAll();
    paymentGetAll();
  }, []);

  useEffect(() => {
    if (!user) return;
    setVols(
      volleys
        .filter((v) => v.user_id === user._id)
        .map((v) => ({
          ...v,
          popTourn: tournaments.find((t) => t._id === v.tournament_id),
        }))
    );
  }, [user, volleys, tournaments]);

  return !user ? (
    <div>Something went wrong!</div>
  ) : (
    <div>
      <h1>
        {user.given_name} {user.family_name}
      </h1>
      {loading ? (
        <Spinner variant="admin" />
      ) : Teams.length ? (
        <Fragment>
          <h2>Teams</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Program</th>
                <th>Number</th>
                <th>Name</th>
                <th>Tournaments</th>
              </tr>
            </thead>
            <tbody className={"ta-center"}>
              {Teams.map((t) => (
                <tr key={t._id}>
                  <td className={"text-bold text-" + ProgramToClass(t.program)}>
                    {t.season.toString().slice(0, 4)}{" "}
                    {ProgramToLabel(t.program)}
                  </td>
                  <td>
                    <TeamNumber number={t.team_number} program={t.program} />
                  </td>
                  <td>{t.team_name}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      ) : (
        <span>No teams registered</span>
      )}
      {user.volunteer_profile === undefined ? (
        <div />
      ) : (
        <Fragment>
          <h1>Volunteer profile</h1>
          <p>
            <strong>Age range: </strong>
            {user.volunteer_profile.age_range}
          </p>
          <p>
            <strong>Child protection number: </strong>
            {user.volunteer_profile.wwcc}
          </p>
          <p>
            <strong>Affiliation: </strong>
            {user.volunteer_profile.affiliation}
          </p>
          <p>
            <strong>Dietary restrictions: </strong>
            {user.volunteer_profile.dietary}
          </p>
          <p>
            <strong>Emergency contact: </strong>
            {user.volunteer_profile.emergency_name} (
            {user.volunteer_profile.emergency_phone})
          </p>
          <p>
            <strong>History: </strong>
            {user.volunteer_profile.history
              .map((v) => ProgramToLabel(v.program) + " " + v.role)
              .join(", ")}
          </p>
          <p>
            <strong>Preferences: </strong>
            {user.volunteer_profile.preferences
              .map((v) => ProgramToLabel(v.program) + " " + v.role)
              .join(", ")}
          </p>
        </Fragment>
      )}
      {vols.length === 0 ? (
        <div />
      ) : (
        <Fragment>
          <h1>Volunteering History</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Program</th>
                <th>Tournament name</th>
                <th>Date</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {vols
                .filter((v) => v.popTourn)
                .map((vol) => (
                  <tr key={vol._id}>
                    <td>
                      <ProgramLogo program={vol.popTourn!.program} />
                    </td>
                    <td>{vol.popTourn!.name}</td>
                    <td>
                      <Moment format="LL">{vol.popTourn!.start}</Moment>
                    </td>
                    <td>{vol.job ? vol.job.role : "TBD"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Fragment>
      )}

      <h2>Finances</h2>
      <FinanceBreakdown user={user} />
    </div>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState, props: LocalProps) => ({
    user: selectUser(state, props),
    teams: state.team.all,
    volleys: state.volley.all,
    tournaments: state.tournament.all,
    loading: state.team.loading,
  });
};

const connector = connect(makeMapStateToProps, {
  teamGetAll,
  volleyGetAll,
  tournamentGetAll,
  shipmentGetAll,
  paymentGetAll,
  addressGetAll,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(UserView);
