import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import { Tournament } from "../../store/tournament/tournament.types";
import {
  volleyCreate,
  volleyDelete,
  volleyToggleDay,
} from "../../store/volley/volley.action";
import { Volley } from "../../store/volley/volley.types";
import Button from "../atomic/Button";
import CheckBox from "../atomic/CheckBox";
import Spinner from "../atomic/Spinner";

type LocalProps = {
  volley?: Volley;
  tournament: Tournament;
};

const TournamentVolleyRegoRow = ({
  volley,
  tournament,
  volleyCreate,
  volleyToggleDay,
  volleyDelete,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreate = async () => {
    setLoading(true);
    await volleyCreate(tournament._id);
    setLoading(false);
  };

  return volley ? (
    <div className="d-flex">
      {volley.days.map((b, i) => (
        <div className="d-flex-col" key={i}>
          <span>
            &nbsp;
            {i === 0
              ? "Bump in"
              : i === volley.days.length - 1
              ? "Bump out"
              : `Day ${i}`}{" "}
            &nbsp;
          </span>
          <CheckBox
            className="mx-auto"
            checked={b}
            toggle={() => {
              volleyToggleDay(volley._id, i);
            }}
          />
        </div>
      ))}
      <div className="d-flex-col">
        <Button onClick={() => volleyDelete(volley._id)} variant="warn">
          Exit event
        </Button>
        <small>
          Note: If you have been assigned a role, or the tournament is locked,
          you must contact the tournament director to pull out of the event
        </small>
      </div>
    </div>
  ) : loading ? (
    <Spinner variant={"volunteer"} />
  ) : (
    <Button variant="success" onClick={handleCreate}>
      Register!
    </Button>
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, {
  volleyCreate,
  volleyToggleDay,
  volleyDelete,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(TournamentVolleyRegoRow);
