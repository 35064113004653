/**
 * Action types
 */

import {
  DTORubricAnswerBatchCreate,
  DTORubricAnswerCreate,
} from "../../api/dtos/rubric.dto";
import RubricAnswerService from "../../api/services/rubric.service";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import {
  RubricAnswer,
  RubricAnswerActionTypes,
  RUBRICS_CREATE,
  RUBRIC_CREATE,
  RUBRIC_DELETE,
  RUBRIC_GET_ALL,
  RUBRIC_GET_MINE,
} from "./rubric.types";

export const RubricAnswerGetAllAction = (
  data: RubricAnswer[]
): RubricAnswerActionTypes => ({
  type: RUBRIC_GET_ALL,
  payload: data,
});
export const RubricAnswerGetMineAction = (
  data: RubricAnswer[]
): RubricAnswerActionTypes => ({
  type: RUBRIC_GET_MINE,
  payload: data,
});

export const RubricAnswerCreateAction = (
  data: RubricAnswer
): RubricAnswerActionTypes => ({
  type: RUBRIC_CREATE,
  payload: data,
});

export const RubricAnswerBatchCreateAction = (
  data: RubricAnswer[]
): RubricAnswerActionTypes => ({
  type: RUBRICS_CREATE,
  payload: data,
});

export const RubricAnswerDeleteAction = (
  id: RubricAnswer["_id"]
): RubricAnswerActionTypes => ({
  type: RUBRIC_DELETE,
  payload: id,
});

/**
 * Actions
 */

export const rubricGetAll =
  (tournament_id: string): AppThunk =>
  async (dispatch) => {
    try {
      const t = await RubricAnswerService.getAll(tournament_id);
      dispatch(RubricAnswerGetAllAction(t));
    } catch (error: any) {
      logger.logError(error, logger.warn, "rubric.action :: getAll", true);
    }
  };

export const rubricGetMine = (): AppThunk => async (dispatch) => {
  try {
    const t = await RubricAnswerService.getMine();
    dispatch(RubricAnswerGetMineAction(t));
  } catch (error: any) {
    logger.logError(error, logger.warn, "rubric.action :: getAll", false);
  }
};

export const rubricCreate =
  (data: DTORubricAnswerCreate): AppThunk<Promise<string>> =>
  async (dispatch, getState) => {
    try {
      const t = await RubricAnswerService.create(data);
      dispatch(RubricAnswerCreateAction(t));
      return "";
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "rubric.action :: rubricCreate",
        false
      );
      return error.message;
    }
  };

export const rubricCreateBatch =
  (
    data: DTORubricAnswerBatchCreate
  ): AppThunk<Promise<RubricAnswer[] | false>> =>
  async (dispatch, getState) => {
    try {
      const t = await RubricAnswerService.createBatch(data);
      dispatch(RubricAnswerBatchCreateAction(t));
      return t;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "rubric.action :: rubricCreate",
        false
      );
      return false;
    }
  };

export const rubricDelete =
  (id: RubricAnswer["_id"]): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      await RubricAnswerService.remove(id);
      dispatch(RubricAnswerDeleteAction(id));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "rubric.action :: rubricDelete",
        true
      );
      return false;
    }
  };
