import React, { Fragment, useState } from "react";
import { IUser } from "../../api/api.types";
import { Payment } from "../../store/payment/payment.types";
import UserName from "../atomic/UserName";
import { paymentUpdate } from "../../store/payment/payment.action";
import SubmitButton from "../atomic/SubmitButton";
import Button from "../atomic/Button";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";

interface LocalProps {
  payment: Payment;
  users: IUser[];
  onCancel?: () => void;
  onSubmit: () => void;
}

const EditPayment = ({
  payment,
  users,
  onCancel,
  onSubmit,
  paymentUpdate,
}: Props) => {
  const { _id, payer, amount, credit_card, reference } = payment;

  const [comment, setComment] = useState<string>(payment.comment ?? "");
  const [inv, setInv] = useState<string>(payment.invoice_number ?? "");
  const [receipt, setReceipt] = useState<string>(payment.payment_id ?? "");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    paymentUpdate(_id, { comment, invoice_number: inv, payment_id: receipt });
    onSubmit();
  };

  return (
    <div>
      <h1 className="d-flex">
        <span className="mr-1">{reference}:</span>
        <UserName user={users.find((u) => u._id === payer)} />
      </h1>
      <h3>Amount: ${amount.toFixed(2)}</h3>
      <form className="form h-80" onSubmit={handleSubmit}>
        <label htmlFor="edit-payment-comment">Comment</label>
        <textarea
          name="edit-payment-comment"
          value={comment}
          rows={4}
          onChange={(e) => {
            e.preventDefault();
            setComment(e.target.value);
          }}
        />
        {credit_card ? (
          payment.payment_id ? (
            <Fragment>
              <label htmlFor="edit-payment-cc">Receipt number</label>
              <span>{payment.payment_id}</span>
            </Fragment>
          ) : (
            <Fragment>
              <label htmlFor="edit-payment-cc">Receipt number</label>
              <input
                type="text"
                name="edit-payment-cc"
                placeholder="e.g. WEBR00001"
                value={receipt}
                onChange={(e) => {
                  e.preventDefault();
                  setReceipt(e.target.value);
                }}
              />
            </Fragment>
          )
        ) : (
          <Fragment>
            <label htmlFor="edit-payment-inv">Invoice number</label>
            <input
              type="text"
              name="edit-payment-inv"
              value={inv}
              onChange={(e) => {
                e.preventDefault();
                setInv(e.target.value);
              }}
            />
          </Fragment>
        )}
        <div className="d-flex ml-auto col-span-2 mt-auto">
          <SubmitButton value="Save" variant="success" />
          {onCancel && (
            <Button variant="warn" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, { paymentUpdate });
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(EditPayment);
