import firebase from "firebase";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { v4 as uuid } from "uuid";
import PaymentService from "../../../api/services/payment.service";
import {
  costOfProgramKit,
  costOfProgramTeam,
  costOfShipping,
} from "../../../inventory/getCost";
import { addressGetMine } from "../../../store/address/address.action";
import { Address } from "../../../store/address/address.types";
import {
  getMyCosts,
  getMyPayments,
  getPayLink,
} from "../../../store/auth/auth.action";
import {
  paymentAddCreated,
  paymentCreate,
  paymentGetMine,
  paymentUpdate,
} from "../../../store/payment/payment.action";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetMine } from "../../../store/shipping/shipment.action";
import { teamGetMine } from "../../../store/teams/team.action";
import { selectMyTeams } from "../../../store/teams/team.selector";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import api from "../../../utils/api.instance";
import logger from "../../../utils/logger";
import uploadFirebase from "../../../utils/uploadFirebase";
import Button from "../../atomic/Button";
import { SingleSelector } from "../../atomic/FaSelectors";
import { FaText } from "../../atomic/FaTexts";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import Radio from "../../atomic/Radio";
import Selector from "../../atomic/Selector";
import Spinner from "../../atomic/Spinner";
import AddressForm from "../../forms/AddressForm";

const PayNow = ({
  user,
  teams,
  auth,
  payment,
  addresses,
  myCredit,
  paymentCreate,
  addressGetMine,
  teamGetMine,
  paymentGetMine,
  shipmentGetMine,
  getMyCosts,
  getMyPayments,
  paymentAddCreated,
  getPayLink,
  paymentUpdate,
  history,
  loading,
}: Props) => {
  const [pdfFile, setPdfFile] = useState<File>();
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [billing, setBilling] = useState<Address>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [ids] = useState<string[]>([uuid(), uuid()]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [method, setMethod] = useState<"credit_card" | "invoice">(
    "credit_card"
  );
  const [progress, setProgress] = useState<number>();
  const [thumb, setThumb] = useState<string>();
  const [finished, setFinished] = useState<boolean>(false);
  const [loadingCC, setLoadingCC] = useState<boolean>(false);

  useEffect(() => {
    addressGetMine();
    teamGetMine();
    paymentGetMine();
    shipmentGetMine();
  }, [addressGetMine, teamGetMine, paymentGetMine, shipmentGetMine]);

  useEffect(() => {
    if (!uploading && finished) history.push("/");
  }, [finished, uploading]);

  useEffect(() => {
    if (uploading) window.onbeforeunload = () => true;
    else window.onbeforeunload = null;
  }, [uploading]);

  useEffect(() => {
    const go = async () => {
      if (pdfFile) {
        var fileReader = new FileReader();

        fileReader.onload = async function () {
          if (!this.result) return;
          const thumbnails = (await generatePdfThumbnails(
            this.result,
            320
          )) as { page: number; thumbnail: string }[];
          if (thumbnails.length) {
            setThumb(thumbnails[0].thumbnail);
          }
        };
        //Step 3:Read the file as ArrayBuffer
        fileReader.readAsDataURL(pdfFile);
      }
    };
    go();
  }, [pdfFile]);

  const devPayCc = (p: Payment) => {
    api.get("payments/xetta/", {
      params: {
        msg: "Approved",
        Rego_id: p.reference,
        AmountPaid: p.amount,
        Company: addresses.find((a) => a._id === p.address)?.company,
        AuthResult: "38r29mi9093rm0",
        Receipt: "FakeDevReceiptNumber",
        success: true,
        Hash: "FakeDevReceiptNumber",
      },
    });
    setTimeout(() => {
      getMyPayments();
    }, 1000);
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(",");
    const M = arr[0].match(/:(.*?);/);
    if (!M) return null;
    var mime = M[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const submitInvoice = async () => {
    if (uploading) return;
    setUploading(true);
    if (!user) return alert("Something went wrong!");
    if (!pdfFile) return alert("Please upload a purchase order");
    if (!billing) return alert("Please provide a billing address");
    if (!billing.abn) return alert("Billing addresses must include an ABN");
    if (!auth.user) return alert("Something went wrong!");
    const p = await PaymentService.create({
      address: billing._id,
      payer: auth.user._id,
      credit_card: false,
      amount: Math.max(user.up - user.down, 0),
      comment: "",
    });
    logger.info({ p }, "submitInvoice :: reference created");
    const refName = p.reference + ".pdf";
    const thumbName = p.reference + ".png";
    paymentAddCreated(p);

    var T: string | undefined = undefined;

    if (thumb) {
      const thumbFile = dataURLtoFile(thumb, thumbName);
      if (thumbFile)
        await uploadFirebase({
          name: thumbName,
          location: "thumbnails",
          file: thumbFile,
          onProgress: (x) => setProgress(x / 2),
          onError: (e) => {
            logger.warn(
              "Upload failed: " + e.message,
              "submitInvoice :: uploadFirebase",
              true,
              { e }
            );
            history.push("/");
          },
          onFinish: async () => {
            const fURL = await firebase
              .storage()
              .ref("thumbnails")
              .child(thumbName)
              .getDownloadURL();

            logger.debug(
              { thumbName, fURL },
              "handleUploadSuccess :: thumbnail"
            );
            T = fURL;
          },
        });
    }

    await uploadFirebase({
      name: refName,
      location: "purchase_orders",
      file: pdfFile,
      onProgress: (x) => setProgress(thumb ? 50 + x / 2 : x),
      onError: (e) => {
        logger.warn(
          "Upload failed: " + e.message,
          "submitInvoice :: uploadFirebase",
          true,
          { e }
        );
        history.push("/");
      },
      onFinish: async () => {
        const fURL = await firebase
          .storage()
          .ref("purchase_orders")
          .child(refName)
          .getDownloadURL();

        logger.debug({ refName, fURL, T }, "handleUploadSuccess");
        paymentUpdate(p._id, { pdf_url: fURL, thumbnail: T });
        setUploading(false);
        setFinished(true);
      },
    });
  };

  const submitCreditCard = async () => {
    if (!user) return alert("Something went wrong");
    if (!billing) return alert("Please provide a billing address");
    if (!billing.abn) return alert("Billing addresses must include an ABN");
    if (!auth.user) return alert("Something went wrong!");
    setLoadingCC(true);
    const p = await PaymentService.create({
      address: billing._id,
      payer: auth.user._id,
      credit_card: true,
      amount: Math.max(user.up - user.down, 0),
      comment: "",
    });
    paymentAddCreated(p);

    logger.info({ p }, "submitCreditCard :: reference created");

    // if (
    //   window.location.hostname.includes("localhost") ||
    //   window.location.hostname.includes("firstaustralia-dev")
    // )
    //   devPayCc(p);
    // // else window.open(getPayLink(p));
    // else
    window.location.replace(getPayLink(p));
    // history.push("/");
  };

  return !user ? (
    <div>Something went wrong!</div>
  ) : (
    <div id="pay-now-view">
      <Prompt
        when={uploading}
        message="Are you sure you want to leave?  Your purchase order has not finished uploading."
      />

      <h1>Pay</h1>
      {user.offseasonCostExclGst <= 0 ? (
        <div />
      ) : (
        <p>
          <strong>Note:</strong> Since the system now covers multiple seasons,
          the amount owing has been recalculated to include both years in the
          same figures. However, your balance for 2021 should not have changed.
          So, please double check that these numbers are correct and{" "}
          <a href="mailto:wynonah.bush@mq.edu.au">contact us</a> if anything
          looks wrong. Do not make a payment if it looks incorrect.
        </p>
      )}
      <div className="form">
        <strong>Other seasons:</strong>
        <span>${user.offseasonCostExclGst.toFixed(2)}</span>
        <strong>Teams:</strong>{" "}
        <span>
          ${/* {user.teamCost.toFixed(2)} */}
          {teams
            .reduce(
              (p, c) =>
                p + costOfProgramTeam(c.program, c.season, !c.post_registered),
              0
            )
            .toFixed(2)}
        </span>
        <strong>Kits:</strong>{" "}
        <span>
          $
          {teams
            .filter((t) => t.ship_kit)
            .reduce(
              (p, c) =>
                p + costOfProgramKit(c.program, c.season, !c.post_registered),
              0
            )
            .toFixed(2)}
        </span>
        <strong>Shipping:</strong>{" "}
        <span>${(user?.shippingCost ?? 0).toFixed(2)}</span>
        <strong>Subtotal:</strong>
        <span>
          $
          {(
            user.currentSeasonExclGst +
            user.offseasonCostExclGst +
            user.shippingCost
          ).toFixed(2)}
        </span>
        <strong>GST:</strong>{" "}
        <span>
          $
          {(
            (user.currentSeasonExclGst +
              user.offseasonCostExclGst +
              user.shippingCost) *
            0.1
          ).toFixed(2)}
        </span>
        <strong className="">Total cost: </strong>
        <span className="text-danger">${user.up.toFixed(2)}</span>
        {auth.user?.credit ? (
          <Fragment>
            <strong>Discount:</strong>{" "}
            <span>${(auth.user ? auth.user.credit : 0).toFixed(2)}</span>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
        <strong>Already paid:</strong>{" "}
        <span className="text-success">
          - ${(user.down - (user.credit ?? myCredit)).toFixed(2)}
        </span>
        <strong className="mt-1">Total owing: </strong>
        <strong className="mt-1">
          ${Math.max(0, user.up - user.down).toFixed(2)}
        </strong>
      </div>
      <br />

      <div className="form">
        <label>Payment method?</label>
        <div className="payment-radios">
          <Radio
            checked={method === "credit_card"}
            onClick={() => setMethod("credit_card")}
            label="Credit Card"
          />
          <Radio
            checked={method === "invoice"}
            onClick={() => setMethod("invoice")}
            label="Invoice"
          />
        </div>
        <label>Billing address</label>
        <div className="">
          {loading ? (
            <Spinner variant="primary" />
          ) : (
            <SingleSelector<Address>
              label={"Billing address"}
              value={billing}
              options={addresses.map((a) => ({ value: a, label: a.label }))}
              onSelect={setBilling}
            />
          )}
          <Button
            variant="success"
            className="ml-auto"
            onClick={() => setModalOpen(true)}
          >
            <Icon icon="plus" className="mr-1" /> Add new
          </Button>
        </div>
        {billing && (
          <Fragment>
            <div></div>
            <div className="shipping-label">
              {billing.abn ? (
                <span></span>
              ) : (
                <span className="text-danger">
                  Billing addresses must provide an ABN
                </span>
              )}
              <span>{billing.company}</span>
              <span>{billing.street1}</span>
              {billing.street2 && <span>{billing.street2}</span>}
              {billing.street3 && <span>{billing.street3}</span>}
              <span>
                {billing.suburb} {billing.postcode}
              </span>
              <span>
                {billing.state} {billing.country}
              </span>
            </div>
          </Fragment>
        )}

        {method === "invoice" ? (
          <Fragment>
            <div />
            <span className="text-warn">
              Please do not pay by invoice unless absolutely necessary. Your
              registration will not be confirmed until we have manually reviewed
              your purchase order to ensure it is valid.
            </span>
            <div />
            <span>
              To secure your registration, we require a valid Purchase Order
              from your organisation with the exact figures listed above. This
              will be used to generate an invoice later during the season.
            </span>
            <label>Purchase order</label>
            <label className="btn btn-primary">
              <Icon icon="upload" className="mr-1" />
              {pdfFile ? pdfFile.name : "Upload a PDF"}
              <input
                hidden
                type="file"
                name="purchase-order"
                accept=".pdf"
                onChange={(e) =>
                  setPdfFile(e.target.files ? e.target.files[0] : undefined)
                }
              />
            </label>
            <div></div>
            {pdfFile && !billing ? (
              <FaText color="error">
                Remember to nominate a billing address above.
              </FaText>
            ) : (
              <div />
            )}
            {uploading ? (
              <Fragment>
                <Spinner variant="success" />
                <small className="col-span-2">
                  Uploading... please do not leave this page
                </small>
              </Fragment>
            ) : (
              <Button
                disabled={!pdfFile || !billing || uploading}
                variant="success"
                onClick={submitInvoice}
              >
                Submit
              </Button>
            )}
            <div className="col-span-2 d-flex">
              <div
                className={`progress bg-success w-${
                  progress ? Math.ceil(progress / 5) * 5 : 0
                }`}
              />
              <div
                className={`progress bg-grey w-${
                  progress ? 100 - Math.ceil(progress / 5) * 5 : 100
                }`}
              />
            </div>
            {thumb && (
              <div className="col-span-2">
                <img src={thumb} alt="Thumbnail" />
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <span>
              Once you finish payment, allow some time for the update.
            </span>
            {loadingCC ? (
              <Spinner variant="financier" />
            ) : (
              <Button
                disabled={billing === undefined}
                variant="success"
                onClick={submitCreditCard}
              >
                Pay
              </Button>
            )}
          </Fragment>
        )}
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddressForm onSubmit={() => setModalOpen(false)} />
      </Modal>
    </div>
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState) => ({
    user: selectUser(state, { userId: "me" }),
    auth: state.auth,
    teams: selectMyTeams(state),
    payment: state.payment,
    addresses: state.address.mine,
    loading: state.address.loading,
    myCredit: state.auth.user?.credit ?? 0,
  });
};

const connector = connect(makeMapStateToProps, {
  paymentCreate,
  addressGetMine,
  teamGetMine,
  paymentGetMine,
  shipmentGetMine,
  getMyCosts,
  getMyPayments,
  getPayLink,
  paymentAddCreated,
  paymentUpdate,
});
type Props = ConnectedProps<typeof connector> & RouteComponentProps<any> & {};

export default connector(withRouter(PayNow));
