import {
  Scoresheet,
  ScoresheetActionTypes,
  ScoresheetState,
  SCORE_CREATE,
  SCORE_DELETE,
  SCORE_GET_ALL,
  SCORE_GET_MINE,
  SCORE_UPDATE,
} from "./scoring.types";

const initialState: ScoresheetState = {
  all: [],
  mine: [],
  loading: true,
};

const scoresheetReducer = function (
  state: ScoresheetState = initialState,
  action: ScoresheetActionTypes
): ScoresheetState {
  switch (action.type) {
    case SCORE_GET_ALL:
      return {
        ...state,
        all: action.payload
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };
    case SCORE_GET_MINE:
      return {
        ...state,
        mine: action.payload
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };

    case SCORE_CREATE:
      return {
        ...state,
        all: [...state.all, action.payload]
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        // @todo change this to allow admins to create non-mine ones
        mine: [...state.mine, action.payload]
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };
    case SCORE_DELETE:
      return {
        ...state,
        all: state.all.filter((t) => t._id !== action.payload),
        mine: state.mine.filter((t) => t._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};

export default scoresheetReducer;
