import api from "../../utils/api.instance";
import { GIsArray, IScoresheet } from "../api.types";
import {
  DTOScoresheetGet,
  DTOScoresheetCreate,
  GIsDTOScoresheetGet,
} from "../dtos/scoresheet.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (tournament_id: string): Promise<IScoresheet[]> => {
  const a = (await api.get(`scoresheets/mine/${tournament_id}`)).data;
  return GIsArray<DTOScoresheetGet>(a).map((ad) => GIsDTOScoresheetGet(ad));
};

const getAll = (tournament_id: string) =>
  Service.run(_getAll, { tournament_id }, "SCORESHEET_GET_ALL");

const _getAll = async ({
  tournament_id,
}: {
  tournament_id: string;
}): Promise<IScoresheet[]> => {
  const a = (await api.get(`scoresheets/${tournament_id}`)).data;
  return GIsArray<DTOScoresheetGet>(a).map((ad) => GIsDTOScoresheetGet(ad));
};

const create = async (data: DTOScoresheetCreate): Promise<IScoresheet> => {
  const a = (await api.post(`scoresheets`, data)).data;
  return GIsDTOScoresheetGet(a);
};

const remove = async (score_id: IScoresheet["_id"]): Promise<boolean> => {
  await api.delete(`scoresheets/${score_id}`);
  return true;
};

const ScoresheetService = {
  getMine,
  getAll,
  create,
  remove,
};

export default ScoresheetService;
