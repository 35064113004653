import { ScoreAnswer } from "../../../../api/api.types";
import { FIRSTProgram, FIRSTSeason } from "../../../../common/backend.types";
import { Compete } from "../../../../store/compete/compete.types";
import CargoConnect from "./CargoConnect";
import CityShaper from "./CityShaper";
import RePlay from "./RePlay";
import SuperPowered from "./SuperPowered";

type ScoreType = "numeric" | "categorical";

interface IScore {
  id: string;
  label: string;
  labelShort: string;
  type: ScoreType;
  defaultValue: number | string;
}

export type NumericScore = IScore & {
  type: "numeric";
  min: number;
  max: number;
  defaultValue: number;
};

export type CategoricalScore = IScore & {
  type: "categorical";
  options: string[];
  defaultValue: string;
};

export type Score = NumericScore | CategoricalScore;

export type NumericScoreResult = NumericScore & {
  answer: string;
};

export type CategoricalScoreResult = CategoricalScore & {
  answer: string;
};

export type ScoreResult = NumericScoreResult | CategoricalScoreResult;

export type ScoreError = {
  id?: Score["id"];
  message: string;
};

export const GIsNumericScore = (m: Score): m is NumericScore =>
  m.type === "numeric";
export const GIsCategoricalScore = (m: Score): m is CategoricalScore =>
  m.type === "categorical";

export type ScoreSheet = {
  compete: Compete["_id"];
  round: number;
  answers: ScoreResult[];
};

export type Mission = {
  /** ID Prefix of all scores in this group */
  prefix: string;
  title: string;
  image?: string;
};

export type Game = {
  name: string;
  season: FIRSTSeason;
  program: FIRSTProgram.FLL_CHALLENGE;
  scores: Score[];
  missions: Mission[];
  answer: (res: ScoreAnswer[], q: string) => ScoreAnswer["answer"] | undefined;
  validate: (R: ScoreAnswer[]) => ScoreError[];
  score: (R: ScoreAnswer[]) => number;
};

export const Games = [SuperPowered, CargoConnect, RePlay, CityShaper] as const;

export type GameType = typeof Games[number];
