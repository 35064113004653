import React from "react";
import { IUser } from "../../api/api.types";
import { Tournament } from "../../store/tournament/tournament.types";
import { Volley } from "../../store/volley/volley.types";
import Icon from "../atomic/Icon";
import TournamentVolleyRegoRow from "../forms/TournamentVolleyRegoRow";

interface Props {
  user?: IUser;
  volley: Volley;
  tournament: Tournament;
}

const TournamentVolleyDirectorRow = ({ user, volley, tournament }: Props) => {
  return !user ? (
    <tr></tr>
  ) : (
    <tr>
      <td>
        {user.given_name} {user.family_name}
      </td>
      <td>{user.email}</td>
      <td>{user.phone}</td>
      <td>
        <div className="d-flex">
          {volley.days.map((b, i) => (
            <div className="d-flex-col" key={i}>
              <span>
                &nbsp;
                {i === 0
                  ? "Bump in"
                  : i === volley.days.length - 1
                  ? "Bump out"
                  : `Day ${i}`}{" "}
                &nbsp;
              </span>
              {b ? (
                <Icon icon="check" className="mx-auto text-success" />
              ) : (
                <Icon icon="times" className="mx-auto text-danger" />
              )}
            </div>
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TournamentVolleyDirectorRow;
