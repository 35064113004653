import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DTOTournamentCreate } from "../../api/dtos/tournament.dto";
import {
  Email,
  FIRSTProgram,
  isEmail,
  Programs,
  ProgramToLabel,
  State,
  States,
  TournamentType,
  TournamentTypes,
  UserRole,
} from "../../common/backend.types";
import { addressGetMine } from "../../store/address/address.action";
import { Address } from "../../store/address/address.types";
import { RootState } from "../../store/root-reducer";
import {
  tournamentCreate,
  tournamentGetAll,
  tournamentUpdate,
} from "../../store/tournament/tournament.action";
import { Tournament } from "../../store/tournament/tournament.types";
import { userGetAll } from "../../store/users/user.action";
import logger from "../../utils/logger";
import CheckBox from "../atomic/CheckBox";
import { SingleSelector } from "../atomic/FaSelectors";
import LocalLink from "../atomic/LocalLink";
import Selector from "../atomic/Selector";
import Spinner from "../atomic/Spinner";
import SubmitButton from "../atomic/SubmitButton";
import ChangeDirectorsForm from "./ChangeDirectorsForm";

type LocalProps = {
  data?: Tournament;
  onSubmit?: () => void;
  restricted: boolean;
};
const TournamentForm = ({
  data,
  auth,
  restricted,
  tournaments,
  loading,
  addresses,
  season,

  tournamentCreate,
  tournamentUpdate,
  users,
  onSubmit,
  addressGetMine,
  tournamentGetAll,
  userGetAll,
}: Props) => {
  const [formData, setFormData] = useState<DTOTournamentCreate>({
    name: data ? data.name : "",
    type: data ? data.type : "Open Qualifier",
    next_tourn: data ? data.next_tourn : "",
    program: data ? data.program : FIRSTProgram.FLL_CHALLENGE,
    start: data ? data.start : new Date("2021-10-01"),
    n_days: data ? data.n_days : 1,
    cap: data ? data.cap : 0,

    directors: [],
    volunteer_coordinators: [],

    remote: data ? data.remote : false,
    company: data ? data.company : "",
    street1: data ? data.street1 : "",
    street2: data ? data.street2 : "",
    street3: data ? data.street3 : "",
    postcode: data ? data.postcode : "",
    suburb: data ? data.suburb : "",
    state: data ? data.state : "NSW",
    ship_kits: data ? data.ship_kits : 4,

    ship_address: data && data.ship_address ? data.ship_address : undefined,
    locked: data ? data.locked : false,
    season: data ? data.season : season,
  });

  const [dateStr, setDateStr] = useState<string>(
    data ? moment(data.start).format("yyyy-MM-DD") : "2021-10-01"
  );

  useEffect(() => {
    addressGetMine();
    tournamentGetAll();
    userGetAll();
  }, []);

  useEffect(() => {
    if (!data) return;
    logger.debug({ directors: data.directors }, "TournamentForm");
    const dirs = data.directors
      .map((d) => users.find((u) => u._id === d)?.email)
      .filter((e) => e !== undefined) as Email[];
    const vcs = data.volunteer_coordinators
      .map((d) => users.find((u) => u._id === d)?.email)
      .filter((e) => e !== undefined) as Email[];
    logger.debug({ dirs, vcs }, "TournamentForm");
    setFormData({ ...formData, directors: dirs, volunteer_coordinators: vcs });
  }, [users, data]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      try {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      } catch (error: any) {
        logger.warn(error, "TournamentForm :: onChange");
      }
    },
    [formData]
  );

  const onChangeInt = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      try {
        setFormData({
          ...formData,
          [e.target.name]: Number.parseInt(e.target.value),
        });
      } catch (error: any) {
        logger.warn(error, "TournamentForm :: onChange", true);
      }
    },
    [formData]
  );

  const onChangeEmails = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const s: string = e.target.value.toLowerCase().replace(/ /g, "");
      setFormData({ ...formData, [e.target.name]: s.split(",") });
    },
    [formData]
  );

  const handleSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    try {
      // Check all the fields are valid
      logger.debug({ ...formData, dateStr }, "TournamentForm :: handleSubmit");
      var start: Date;
      try {
        start = new Date(dateStr);
      } catch (error: any) {
        return logger.warn(
          "Invalid date!",
          "TournamentForm :: handleSubmit",
          true
        );
      }

      if (new Date().getTime() > start.getTime())
        return logger.warn(
          "Start date must be in the future",
          "TournamentForm",
          true
        );

      if (!formData.directors)
        return logger.warn(
          "At least one director must be nominated",
          "TournamentForm",
          true
        );

      const badD = formData.directors.filter((d) => d && !isEmail(d));
      const badVC = formData.volunteer_coordinators.filter(
        (d) => d && !isEmail(d)
      );
      if (badD.length || badVC.length)
        return logger.warn(
          "Invalid emails detected: " + [...badD, ...badVC].join(", "),
          "TournamentForm",
          true,
          { badD, badVC }
        );
      data
        ? tournamentUpdate(data._id, { ...formData, start })
        : tournamentCreate({ ...formData, start });

      logger.success(
        `${formData.name} ${data ? "updated" : "created"} successfully!`,
        "TournamentForm",
        true
      );
      if (onSubmit) onSubmit();
    } catch (error: any) {
      logger.warn(error, "TournamentForm", true);
    }
  };

  return auth.loading ? (
    <Spinner variant="primary" />
  ) : (
    <div>
      <h1>{data ? `Edit ${data.name}` : "Create new tournament"}</h1>
      <form id="tournament-form" className="form mb-5" onSubmit={handleSubmit}>
        {!restricted ? (
          <Fragment>
            <label htmlFor="create-tourn-name">
              Tournament name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="name"
              id="create-tourn-name"
              value={formData.name}
              onChange={onChange}
              placeholder="Tournament name (must be unique)"
              autoComplete="off"
              required
            />
            {data ? (
              <span className="col-span-2"></span>
            ) : (
              <Fragment>
                <label className="d-flex" htmlFor="create-tourn-program">
                  Program<span className="text-danger">*</span>
                </label>
                <SingleSelector<FIRSTProgram>
                  options={Programs.map((p) => ({
                    label: ProgramToLabel(p),
                    value: p,
                  }))}
                  value={formData.program}
                  onSelect={(x) => {
                    if (x) setFormData({ ...formData, program: x });
                  }}
                />

                <label className="d-flex" htmlFor="create-tourn-type">
                  Type of tournament<span className="text-danger">*</span>
                </label>
                <SingleSelector<TournamentType>
                  options={TournamentTypes.map((t) => ({ label: t, value: t }))}
                  value={formData.type}
                  onSelect={(x) => {
                    if (x) setFormData({ ...formData, type: x });
                  }}
                />
              </Fragment>
            )}
            {auth?.user?.roles.includes(UserRole.Admin) ? (
              <Fragment>
                <label className="d-flex" htmlFor="create-tourn-next">
                  Advancing tournament:
                </label>
                {loading ? (
                  <Spinner variant="admin" />
                ) : tournaments.find(
                    (t) =>
                      t.program === formData.program &&
                      t.season === formData.season &&
                      t.type === "Championship"
                  ) ? (
                  <SingleSelector<Tournament["_id"]>
                    options={tournaments
                      .filter(
                        (t) =>
                          t.program === formData.program &&
                          t.season === formData.season &&
                          t.type === "Championship"
                      )
                      .map((t) => ({ label: t.name, value: t._id }))}
                    value={formData.next_tourn}
                    onSelect={(x) =>
                      setFormData({ ...formData, next_tourn: x })
                    }
                    clearable
                  />
                ) : (
                  <span className="text-warn">
                    No Championship events found
                  </span>
                )}
                <label htmlFor="create-tourn-start">
                  Start date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  required
                  name="dateStr"
                  id="create-tourn-start"
                  value={dateStr}
                  onChange={(e) => {
                    e.preventDefault();
                    setDateStr(e.target.value);
                  }}
                />
                <label htmlFor="create-tourn-ndays">
                  Length of tournament (in days)
                  <span className="text-danger">*</span>
                  <small className="text-warning">
                    Do not change this if any volunteers have signed up
                  </small>
                </label>
                <input
                  type="number"
                  min={1}
                  step={1}
                  max={4}
                  name="n_days"
                  id="create-tourn-ndays"
                  value={formData.n_days}
                  onChange={onChangeInt}
                />
              </Fragment>
            ) : (
              <span className="col-span-2"></span>
            )}
            <label htmlFor="create-tourn-cap">
              Max number of teams<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              min={0}
              step={1}
              max={120}
              name="cap"
              id="create-tourn-cap"
              value={formData.cap}
              onChange={onChangeInt}
            />

            {auth?.user?.roles.includes(UserRole.Admin) ? (
              <Fragment>
                <label htmlFor="create-tourn-ship_kits">
                  How many kits will they need? (You can change this later)
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  min={0}
                  step={1}
                  max={12}
                  name="ship_kits"
                  id="create-tourn-ship_kits"
                  value={formData.ship_kits}
                  onChange={onChangeInt}
                />
              </Fragment>
            ) : (
              <span className="col-span-2"></span>
            )}

            {data ? (
              <div className="col-span-2">
                <ChangeDirectorsForm tournament={data} />
              </div>
            ) : (
              <Fragment>
                <h3 className="col-span-2">Managers</h3>

                {/** @todo Autocomplete from list of users? */}
                <label htmlFor="create-tourn-directors">
                  Tournament directors<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="directors"
                  id="create-tourn-directors"
                  value={formData.directors.join(",")}
                  onChange={onChangeEmails}
                  placeholder="Comma-separated emails"
                  required
                />

                <label htmlFor="create-tourn-vcs">
                  Volunteer coordinator/s
                </label>
                <input
                  type="text"
                  name="volunteer_coordinators"
                  id="create-tourn-vcs"
                  value={formData.volunteer_coordinators.join(",")}
                  onChange={onChangeEmails}
                  placeholder="Comma-separated emails"
                />
              </Fragment>
            )}

            <h3 className="col-span-2">Location of event</h3>

            {auth?.user?.roles.includes(UserRole.Admin) ? (
              <Fragment>
                <label htmlFor="create-tourn-remote">Remote event?</label>
                <CheckBox
                  id="create-tourn-remote"
                  checked={formData.remote}
                  toggle={() =>
                    setFormData({ ...formData, remote: !formData.remote })
                  }
                />
              </Fragment>
            ) : (
              <span className="col-span-2"></span>
            )}

            <label htmlFor="create-tourn-company">
              Company<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="company"
              id="create-tourn-company"
              value={formData.company}
              onChange={onChange}
              required
              placeholder="Organisation in charge of tournament"
            />
            <label htmlFor="create-tourn-street1">
              Street address<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="street1"
              id="create-tourn-street1"
              value={formData.street1}
              onChange={onChange}
              required
            />
            <label htmlFor="create-tourn-street2"></label>
            <input
              type="text"
              name="street2"
              id="create-tourn-street2"
              value={formData.street2}
              onChange={onChange}
            />
            <label htmlFor="create-tourn-street3"></label>
            <input
              type="text"
              name="street3"
              id="create-tourn-street3"
              value={formData.street3}
              onChange={onChange}
            />
            <label htmlFor="create-tourn-postcode">
              Postcode<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="postcode"
              id="create-tourn-postcode"
              pattern="[0-9]{4}"
              title="Must be a four-digit number"
              value={formData.postcode}
              onChange={onChange}
              placeholder="xxxx"
              required
            />

            <label htmlFor="create-tourn-suburb">
              Suburb<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="suburb"
              id="create-tourn-suburb"
              value={formData.suburb}
              onChange={onChange}
              placeholder='e.g. "Macquarie Park"'
              required
            />

            <label className="d-flex" htmlFor="create-tourn-state">
              State<span className="text-danger">*</span>
            </label>
            <SingleSelector<State>
              options={States}
              value={formData.state}
              onSelect={(s) => {
                if (s) setFormData({ ...formData, state: s });
              }}
            />
            {data && data.directors.includes(auth?.user?._id ?? "NOOP") ? (
              loading ? (
                <Spinner variant="director" />
              ) : (
                <Fragment>
                  <label
                    className="d-flex"
                    htmlFor="create-tourn-shipping-address"
                  >
                    Shipping address
                    <br />
                    <small>
                      Go to <LocalLink to="/profile">My Profile</LocalLink> to
                      add new addresses
                    </small>
                  </label>
                  <SingleSelector<Address["_id"]>
                    options={addresses.map((a) => ({
                      label: a.label,
                      value: a._id,
                    }))}
                    value={formData.ship_address}
                    onSelect={(a) => {
                      if (a) setFormData({ ...formData, ship_address: a });
                    }}
                  />
                </Fragment>
              )
            ) : (
              <div />
            )}
          </Fragment>
        ) : data && data.directors.includes(auth?.user?._id ?? "NOOP") ? (
          loading ? (
            <Spinner variant="director" />
          ) : (
            <Fragment>
              <label className="d-flex" htmlFor="create-tourn-shipping-address">
                Shipping address
                <br />
                <small>
                  Go to <LocalLink to="/profile">My Profile</LocalLink> to add
                  new addresses
                </small>
              </label>
              <SingleSelector<Address["_id"]>
                options={addresses.map((a) => ({
                  label: a.label,
                  value: a._id,
                }))}
                value={formData.ship_address}
                onSelect={(a) => {
                  if (a) setFormData({ ...formData, ship_address: a });
                }}
              />
            </Fragment>
          )
        ) : (
          <div></div>
        )}

        <SubmitButton value="Save" variant="success" />
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tournaments: state.tournament.all,
  users: state.users.all,
  addresses: state.address.mine,
  auth: state.auth,
  season: state.settings.season,
  loading:
    state.tournament.loading || state.users.loading || state.address.loading,
});

const connector = connect(mapStateToProps, {
  tournamentCreate,
  tournamentUpdate,
  addressGetMine,
  tournamentGetAll,
  userGetAll,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(TournamentForm);
