import { FIRSTProgram, FIRSTSeason } from "../../common/backend.types";
import { Tournament } from "../../store/tournament/tournament.types";
import api from "../../utils/api.instance";
import { GIsArray, ITeam, IUser } from "../api.types";
import {
  DTOTeamCopy,
  DTOTeamCreate,
  DTOTeamDummyCreate,
  DTOTeamGet,
  DTOTeamPublic,
  DTOTeamUpdate,
  GIsDTOTeamGet,
  GIsDTOTeamPublic,
} from "../dtos/team.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<ITeam[]> => {
  const a = (await api.get("team/mine")).data;
  return GIsArray<DTOTeamGet>(a).map((ad) => GIsDTOTeamGet(ad));
};

const getTournament = async (
  tournament_id: Tournament["_id"]
): Promise<DTOTeamPublic[]> => {
  const a = (await api.get(`team/tournament/${tournament_id}`)).data;
  return GIsArray<DTOTeamPublic>(a).map((ad) => GIsDTOTeamPublic(ad));
};

const getAll = () => Service.run(_getAll, {}, "TEAM_GET_ALL");

const _getAll = async (): Promise<ITeam[]> => {
  const a = (await api.get("team")).data;
  return GIsArray<DTOTeamGet>(a).map((ad) => GIsDTOTeamGet(ad));
};

const create = async (data: DTOTeamCreate): Promise<ITeam> => {
  return GIsDTOTeamGet((await api.post(`team`, data)).data);
};
const copy = async (data: DTOTeamCopy): Promise<ITeam> => {
  return GIsDTOTeamGet((await api.post(`team/copy`, data)).data);
};
const createDummy = async (data: DTOTeamDummyCreate): Promise<ITeam> => {
  return GIsDTOTeamGet((await api.post(`team/dummy`, data)).data);
};

const update = async (
  id: ITeam["_id"],
  data: DTOTeamUpdate
): Promise<ITeam> => {
  return GIsDTOTeamGet((await api.put(`team/${id}`, data)).data);
};

const remove = async (id: ITeam["_id"]): Promise<boolean> => {
  await api.delete(`team/${id}`);
  return true;
};

const unremove = async (id: ITeam["_id"]): Promise<boolean> => {
  await api.put(`team/undelete/${id}`);
  return true;
};

const addCoach = async (
  tournament_id: ITeam["_id"],
  email: string
): Promise<ITeam> => {
  const a = (await api.put(`/team/coach/${tournament_id}`, { email })).data;
  return GIsDTOTeamGet(a);
};

const rmCoach = async (
  tournament_id: ITeam["_id"],
  email: string
): Promise<ITeam> => {
  const a = (
    await api.put(`/team/coach/rm/${tournament_id}`, {
      email,
    })
  ).data;
  return GIsDTOTeamGet(a);
};

const checkNames = async (
  teams: {
    name: ITeam["team_name"];
    program: ITeam["program"];
    season: ITeam["season"];
  }[]
): Promise<ITeam["team_name"][]> =>
  GIsArray<string>((await api.post("/team/check-names", teams)).data);

const transfer = async (
  team_id: ITeam["_id"],
  user_id: IUser["_id"]
): Promise<DTOTeamGet> =>
  GIsDTOTeamGet(
    (await api.put(`/team/transfer/${team_id}/to/${user_id}`)).data
  );

const checkKits = async (
  program: FIRSTProgram,
  season: FIRSTSeason
): Promise<number> => {
  const d = await api.get(`/team/max-kits/${season}/${program}`);
  return d.data;
};
const checkTeams = async (
  program: FIRSTProgram,
  season: FIRSTSeason
): Promise<number> => {
  const d = await api.get(`/team/max-teams/${season}/${program}`);
  return d.data;
};

const TeamService = {
  getMine,
  getAll,
  create,
  copy,
  createDummy,
  update,
  remove,
  unremove,
  addCoach,
  rmCoach,
  checkNames,
  transfer,
  getTournament,
  checkKits,
  checkTeams,
};

export default TeamService;
