import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnalyticsTrack } from "../../../api/analytics";
import { FIRSTProgram } from "../../../common/backend.types";
import { RootState } from "../../../store/root-reducer";
import { teamGetMine, teamDelete } from "../../../store/teams/team.action";
import {
  selectCoachTeams,
  selectMyTeams,
} from "../../../store/teams/team.selector";
import { Team } from "../../../store/teams/team.types";
import Button from "../../atomic/Button";
import ButtonLink from "../../atomic/ButtonLink";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import ProgramLogo from "../../atomic/ProgramLogo";
import Spinner from "../../atomic/Spinner";
import TeamNumber from "../../atomic/TeamNumber";
import EditTeamForm from "../../forms/EditTeam";

const MyTeamsView = ({
  myTeams,
  coachTeams,
  loading,
  teamGetMine,
  teamDelete,
}: Props) => {
  const [editTeam, setEditTeam] = useState<Team>();

  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "My Teams",
      },
    });
  }, []);

  const nKits = (p: FIRSTProgram) => {
    return myTeams.filter((t) => t.ship_kit).filter((t) => t.program === p)
      .length;
  };

  useEffect(() => {
    teamGetMine();
  }, [teamGetMine]);

  return (
    <div id="my-teams">
      <h2>My teams</h2>
      <ButtonLink to="/register-teams" className="mb-1" variant="success">
        <Icon icon="plus" className="mr-1" /> Register new team{" "}
      </ButtonLink>
      {loading ? (
        <Spinner variant="secondary" />
      ) : myTeams.length ? (
        <table className="table">
          <thead>
            <tr>
              <th>Program</th>
              <th>Number</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {myTeams.map((t) => (
              <tr key={t._id}>
                <td>
                  <ProgramLogo program={t.program} className="non-ta-left" />
                </td>
                <td>
                  <TeamNumber number={t.team_number} program={t.program} />
                </td>
                <td>{t.team_name}</td>
                <td>
                  <div className="d-flex j-space-evenly">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setEditTeam(t);
                        AnalyticsTrack({
                          name: "Edit Team",
                          data: {
                            program: t.program,
                            post_registered: t.post_registered,
                          },
                        });
                      }}
                    >
                      <Icon icon="edit" />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        teamDelete(t._id);
                        AnalyticsTrack({
                          name: "Delete Team",
                          data: {
                            program: t.program,
                            post_registered: t.post_registered,
                          },
                        });
                      }}
                    >
                      <Icon icon="times" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span></span>
      )}
      {coachTeams.length ? (
        <table className="table">
          <thead>
            <tr>
              <th>Program</th>
              <th>Number</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {coachTeams.map((t) => (
              <tr key={t._id}>
                <td>
                  <ProgramLogo program={t.program} className="non-ta-left" />
                </td>
                <td>
                  <TeamNumber number={t.team_number} program={t.program} />
                </td>
                <td>{t.team_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div />
      )}
      <Modal
        open={editTeam !== undefined}
        onClose={() => setEditTeam(undefined)}
      >
        {editTeam && (
          <EditTeamForm
            team={editTeam}
            onCancel={() => setEditTeam(undefined)}
            onSubmit={() => setEditTeam(undefined)}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.team.loading,
  myTeams: selectMyTeams(state),
  coachTeams: selectCoachTeams(state),
});

const connector = connect(mapStateToProps, { teamGetMine, teamDelete });
type Props = ConnectedProps<typeof connector> & {};

export default connector(MyTeamsView);
