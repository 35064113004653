import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnalyticsTrack } from "../../../api/analytics";
import {
  ProgramToLabel,
  SeasonLabel,
  ShipmentContent,
} from "../../../common/backend.types";
import { costOfShipping } from "../../../inventory/getCost";
import { diffShipped, getInventory } from "../../../inventory/getInventory";
import { addressGetMine } from "../../../store/address/address.action";
import { Address } from "../../../store/address/address.types";
import { RootState } from "../../../store/root-reducer";
import { shipmentGetMine } from "../../../store/shipping/shipment.action";
import { Shipment } from "../../../store/shipping/shipment.types";
import { teamGetMine } from "../../../store/teams/team.action";
import Button from "../../atomic/Button";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import AddressForm from "../../forms/AddressForm";

const ShippingView = ({
  teams,
  addresses,
  shipments,
  loading,
  addressGetMine,
  teamGetMine,
  shipmentGetMine,
}: Props) => {
  useEffect(() => {
    addressGetMine();
    teamGetMine();
    shipmentGetMine();
  }, []);

  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "My Shipping",
      },
    });
  }, []);

  const [addr, setAddr] = useState<Address | undefined>(undefined);

  const isUnShipped = (address: Address): boolean => {
    return getDiff(address, true)
      .map((d) => d.count > 0)
      .reduce((p, c) => c || p, false);
  };
  const isUnPrepped = (address: Address): boolean => {
    return getDiff(address, false)
      .map((d) => d.count > 0)
      .reduce((p, c) => c || p, false);
  };

  const getDiff = (address: Address, shippedOnly: boolean = false) => {
    const ships = shipments.filter(
      (s) =>
        s.address === address._id &&
        (shippedOnly ? Boolean(s.consignment_number) : true)
    );
    const diff = diffShipped(
      teams.filter((t) => t.ship_kit && t.shipping_address === address._id),
      ships
    );

    return diff;
  };

  const getUpcomingCost = (
    state: Address["state"],
    contents: ShipmentContent[]
  ) => {
    const n = contents.reduce((p, c) => p + c.count, 0);
    const multiplier = n === 0 ? 0 : Math.floor(n / 10) + 1;
    return costOfShipping(state) * multiplier ?? 0;
  };

  const getOldCost = (ship: Shipment) => {
    const label = JSON.parse(ship.address_label);
    const contentCount = ship.contents.reduce((p, c) => p + c.count, 0);
    const multiplier =
      contentCount == 0
        ? 0
        : new Date(ship.timestamp ?? 0)?.getFullYear() < 2022
        ? 1
        : Math.floor(contentCount / 10) + 1;
    return costOfShipping(label.state) * multiplier ?? 0;
  };

  return loading ? (
    <Spinner variant="primary" />
  ) : (
    <div id="coach-shipping-view">
      <h1>Upcoming</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Address</th>
            <th>Inventory</th>
            <th>Estimated Cost</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody className="striped">
          {addresses
            .filter((a) => isUnShipped(a))
            .map((a) => (
              <tr key={a._id}>
                <td>
                  <div className="d-flex-col">
                    <span>{a.company}</span>
                    <span>{a.street1}</span>
                    <span>{a.street2}</span>
                    <span>{a.street3}</span>
                    <span>
                      {a.suburb} {a.postcode}
                    </span>
                    <span>
                      {a.state} {a.country}
                    </span>
                  </div>
                </td>

                <td>
                  <div className="d-flex-col">
                    {getDiff(
                      a
                      // )getInventory(
                      //   teams.filter(
                      //     (t) => t.ship_kit && t.shipping_address === a._id
                      //   )
                    ).map((t, i) => (
                      <span key={i}>
                        {t.count} x {SeasonLabel(t.season)}{" "}
                        {ProgramToLabel(t.program)} kit
                        {t.count === 1 ? "" : "s"}
                      </span>
                    ))}
                  </div>
                </td>
                <td>${getUpcomingCost(a.state, getDiff(a)).toFixed(2)}</td>
                <td>
                  <Button
                    variant="primary"
                    className="mx-auto"
                    onClick={() => setAddr(a)}
                  >
                    Update address
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <h1>Shipped</h1>
      {shipments.length && (
        <table className="table">
          <thead>
            <tr>
              <th>Address</th>
              <th>Inventory</th>
              <th>Estimated cost</th>
              <th>Consignment number</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody className="striped">
            {shipments.map((ship) => (
              <tr key={ship._id}>
                <td>
                  <div className="d-flex-col">
                    {Object.values(
                      JSON.parse(ship.address_label) as {
                        [key: string]: string;
                      }
                    ).map((s: string, i) => (
                      <span key={i}>{s}</span>
                    ))}
                  </div>
                </td>

                <td>
                  <div className="d-flex-col">
                    {ship.contents.map((t, i) => (
                      <span key={i}>
                        {t.count} x {SeasonLabel(t.season)}{" "}
                        {ProgramToLabel(t.program)} kit
                        {t.count === 1 ? "" : "s"}
                      </span>
                    ))}
                  </div>
                </td>
                <td>${getOldCost(ship).toFixed(2)}</td>
                <td>
                  <a
                    href={`https://www.tnt.com/express/en_au/site/shipping-tools/tracking.html?searchType=con&cons=${ship.consignment_number}`}
                    target="_blank"
                  >
                    {ship.consignment_number}
                  </a>
                </td>
                <td>
                  <div className="d-flex-col">
                    {ship.comment.split(";").map((s, i) => (
                      <span key={i}>{s}</span>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Modal open={addr !== undefined} onClose={() => setAddr(undefined)}>
        <AddressForm address={addr} onSubmit={() => setAddr(undefined)} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  teams: state.team.mine,
  addresses: state.address.mine,
  shipments: state.shipment.mine,
  loading:
    state.team.loading || state.address.loading || state.shipment.loading,
});

const connector = connect(mapStateToProps, {
  addressGetMine,
  teamGetMine,
  shipmentGetMine,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(ShippingView);
