import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { addressGetAll } from "../../../store/address/address.action";
import { Address } from "../../../store/address/address.types";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import { teamGetAll } from "../../../store/teams/team.action";
import { userGetAll } from "../../../store/users/user.action";
import Button from "../../atomic/Button";
import CheckBox from "../../atomic/CheckBox";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import UserName from "../../atomic/UserName";
import EditPayment from "../../forms/EditPayment";
import Shipped from "./Shipped";
import ShipTeamList from "./ShipTeamList";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ShipTournamentList from "./ShipTournamentList";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { shipmentGetAll } from "../../../store/shipping/shipment.action";
import { AnalyticsTrack } from "../../../api/analytics";

interface TabPanelProps {
  children?: React.ReactNode;
  index: TabType;
  value: TabType;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type TabType = "teams" | "tournaments";

const ShipperView = ({
  users,
  addresses,
  payments,
  teams,
  tournaments,
  shipments,
  loading,
  userGetAll,
  paymentGetAll,
  teamGetAll,
  tournamentGetAll,
  shipmentGetAll,
  addressGetAll,
}: Props) => {
  const [showTeams, setShowTeams] = useState<boolean>(true);
  const [showTourn, setShowTourn] = useState<boolean>(true);
  const [showDone, setShowDone] = useState<boolean>(false);
  const [showUnpaid, setShowUnpaid] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [tab, setTab] = useState<TabType>("teams");

  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "Shipper",
      },
    });
  }, []);

  useEffect(() => {
    userGetAll();
    paymentGetAll();
    teamGetAll();
    tournamentGetAll();
    shipmentGetAll();
    addressGetAll();
  }, []);

  return (
    <div className="mb-5">
      <h1>Shipping</h1>
      <input
        type="text"
        className="mb-1"
        placeholder="Filter by name or company"
        value={filter}
        onChange={(e) => {
          e.preventDefault();
          setFilter(e.target.value);
        }}
      />
      <div className="d-flex">
        <label>Show shipped?</label>
        <CheckBox
          className="ml-2"
          checked={showDone}
          toggle={() => setShowDone(!showDone)}
        />
      </div>
      <div className="d-flex my-1">
        <label>Show unpaid?</label>
        <CheckBox
          className="ml-2"
          checked={showUnpaid}
          toggle={() => setShowUnpaid(!showUnpaid)}
        />
      </div>
      {/* <Tabs
        value={tab}
        onChange={(event, newTab: TabType) => setTab(newTab)}
        aria-label="simple tabs example"
      >
        <Tab label="Teams" value="teams" />
        <Tab label="Tournaments" value="tournaments" />
      </Tabs> */}

      {loading ? (
        <Spinner variant="shipper" />
      ) : (
        // <div className="payments mt-1">
        //   <TabPanel value={tab} index={"teams"}>
        <ShipTeamList
          shipments={shipments}
          payments={payments}
          users={users}
          addresses={addresses}
          teams={teams}
          tournaments={tournaments}
          filter={filter.toLowerCase()}
          showDone={showDone}
          showUnpaid={showUnpaid}
        />
        // </TabPanel>
        // {/* <TabPanel value={tab} index={"tournaments"}>
        //   <ShipTournamentList
        //     shipments={all}
        //     payments={payments}
        //     users={users}
        //     addresses={addresses}
        //     teams={team.all}
        //     tournaments={tournaments.all}
        //     filter={filter.toLowerCase()}
        //     showDone={showDone}
        //     showUnpaid={showUnpaid}
        //   />
        // </TabPanel> */}
        // </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.all,
  payments: state.payment.all,
  teams: state.team.all,
  tournaments: state.tournament.all,
  shipments: state.shipment.all,
  addresses: state.address.all,
  loading:
    state.users.loading ||
    state.payment.loading ||
    state.team.loading ||
    state.tournament.loading ||
    state.shipment.loading ||
    state.address.loading,
});

const connector = connect(mapStateToProps, {
  userGetAll,
  paymentGetAll,
  teamGetAll,
  tournamentGetAll,
  shipmentGetAll,
  addressGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(ShipperView);
