import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { ThemeColour } from "../../common/theme.types";
import { loadUser } from "../../store/auth/auth.action";
import { RootState } from "../../store/root-reducer";
import { validateAuth } from "../../utils/auth.validate";
import logger, { trace } from "../../utils/logger";
import Spinner from "../atomic/Spinner";
import ProfileInterrupt from "../modals/ProfileInterrupt";
import { LocalRoute } from "../Routes";

const AuthRoute = ({
  component: Component,
  wrapper: Wrapper,
  auth,
  history,
  loadUser,
  path,
  ...rest
}: Props) => {
  useEffect(() => {
    const url = rest.location.pathname + rest.location.search;
    trace(auth, "ROUTE " + url);
    if (!validateAuth(auth)) localStorage.setItem("auth_callback", url);
    else {
      const ac = localStorage.getItem("auth_callback");
      if (ac && ac !== "undefined" /* && ac !== url*/) {
        localStorage.removeItem("auth_callback");
        logger.info("Redirecting to auth callback " + ac, "AuthRoute");
        history.push(ac);
      }
    }
  }, [auth, history, rest.location]);

  //   useEffect(() => {
  //     if (!auth.user) loadUser();
  //   }, [auth.user, loadUser]);

  return auth.loading ? (
    <Spinner variant={"primary"} />
  ) : !validateAuth(auth) ? (
    <Redirect to={"/landing"} />
  ) : !auth.user.given_name ||
    !auth.user.family_name ||
    !auth.user.phone ||
    !auth.user.email_verified ? (
    <ProfileInterrupt />
  ) : Wrapper ? (
    <Route
      path={path as string}
      {...rest}
      render={(props) => (
        <Wrapper>
          <Component {...props} />
        </Wrapper>
      )}
    />
  ) : (
    <Route
      {...rest}
      path={path as string}
      render={(props) => <Component {...props} />}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, { loadUser });

type Props = ConnectedProps<typeof connector> &
  RouteComponentProps<any> & {
    wrapper?: React.FunctionComponent;
    component: (props: any) => JSX.Element | null;
    exact?: boolean;
    path?: LocalRoute;
  };

export default connector(withRouter(AuthRoute));
