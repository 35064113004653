import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FaHeader, FaParagraph } from "../../atomic/FaTexts";
import { RootState } from "../../../store/root-reducer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PublicEventRow from "./PublicEventRow";
import AdminDummyCreate from "./AdminDummyCreate";
import { eventGetAll } from "../../../store/admin/admin.action";

type LocalProps = {};

const AdminView = ({ events, eventGetAll }: Props) => {
  useEffect(() => {
    eventGetAll();
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.adminview}>
      <FaHeader size="md">Admin</FaHeader>
      <br />
      <FaHeader size="sm">Public events</FaHeader>
      <FaParagraph>
        Events added here will be listed on all users' dashboards under the
        "Upcoming Events" section.
      </FaParagraph>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={120}>Update?</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Program</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <PublicEventRow />
          {events.map((e, i) => (
            <PublicEventRow event={e} key={i} />
          ))}
        </TableBody>
      </Table>
      <br />
      {/* <AdminDummyCreate /> */}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    adminview: {},
  })
);

const mapStateToProps = (state: RootState) => ({
  events: state.admin.events,
});

const connector = connect(mapStateToProps, { eventGetAll });

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(AdminView);
