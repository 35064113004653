import { IUser } from "../../../api/api.types";
import { ChallengeAward, isChallengeAward } from "../../../common/awards";
import { Compete } from "../../../store/compete/compete.types";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import { TeamPublic } from "../../../store/teams/team.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import downloadCSV from "../../../utils/download_csv";
import { GameType } from "../Scoring/games/types";
import { JaTeamData } from "./JudgeAdvisorView";
import Rubrics, { QuestionId } from "./rubrics";

interface RowData {
  r1?: number;
  r2?: number;
  r3?: number;
}

// type JudgeRankData = {
//   [key: string]: any;
//   cv?: number;
//   ip?: number;
//   rd?: number;
//   cvr?: number;
//   rpr?: number;
//   rdr?: number;
// };

const UNDEFINED = -2000;
const MIN_SCORE = 5;

const max = (x: RowData, round: 1 | 2 | 3 = 1) => {
  let raw = [x.r1, x.r2, x.r3].filter((r) => r !== undefined) as number[];
  if (raw.length < round) return UNDEFINED;
  let m = Math.max(...raw);
  if (round === 1) return m;
  let idx = raw.indexOf(m);
  raw = raw.filter((r, i) => i !== idx);
  m = raw.length ? Math.max(...raw) : UNDEFINED;
  if (round === 2) return m;
  idx = raw.indexOf(m);
  raw = raw.filter((r, i) => i !== idx);
  return raw.length ? Math.max(...raw) : UNDEFINED;
};

const teamsort = (a: RowData, b: RowData) => {
  // First round
  let ma = max(a, 1);
  let mb = max(b, 1);
  if (ma !== mb) return mb - ma;
  // Second round
  ma = max(a, 2);
  mb = max(b, 2);
  if (ma !== mb) return mb - ma;
  // Final round
  return max(b, 3) - max(a, 3);
};

const calcTeams = (
  tournament: Tournament,
  game: GameType,
  allteams: TeamPublic[],
  competes: Compete[],
  scoresheets: Scoresheet[],
  rubrics: RubricAnswer[]
): JaTeamData[] => {
  const A = allteams
    .map((team) => ({
      team_number: team.team_number,
      team_name: team.team_name,
      team,
      compete: competes.find(
        (c) => c.team_id === team._id && c.tournament_id === tournament._id
      )!,
    }))
    .filter((t) => t.compete);
  const B = A.map((t) => ({
    ...t,
    team_number: t.team_number,
    team_name: t.team_name,
    lane: t.compete.judgePod ?? "0",
    r1s: scoresheets.find(
      (s) => s.compete_id === t.compete!._id && s.round === 1
    ),
    r2s: scoresheets.find(
      (s) => s.compete_id === t.compete!._id && s.round === 2
    ),
    r3s: scoresheets.find(
      (s) => s.compete_id === t.compete!._id && s.round === 3
    ),
    // Note these have to be sorted by timestamp to work
    cvr: rubrics
      .filter(
        (s) =>
          s.compete_id === t.compete!._id &&
          Rubrics.isCoreQuestion(s.question_id)
      )
      .filter(
        (x, i, a) => a.findIndex((y) => y.question_id === x.question_id) === i
      ),
    ipr: rubrics
      .filter(
        (s) =>
          s.compete_id === t.compete!._id &&
          Rubrics.isProjectQuestion(s.question_id)
      )
      .filter(
        (x, i, a) => a.findIndex((y) => y.question_id === x.question_id) === i
      ),
    rdr: rubrics
      .filter(
        (s) =>
          s.compete_id === t.compete!._id &&
          Rubrics.isRobotQuestion(s.question_id)
      )
      .filter(
        (x, i, a) => a.findIndex((y) => y.question_id === x.question_id) === i
      ),
  }));
  const C = B.map((t) => ({
    ...t,
    r1: t.r1s ? game.score(t.r1s.answers) : UNDEFINED,
    r2: t.r2s ? game.score(t.r2s.answers) : UNDEFINED,
    r3: t.r3s ? game.score(t.r3s.answers) : UNDEFINED,
    breakthrough: t.cvr.find((r) => r.question_id.includes("breakthrough")),
    motivate: t.cvr.find((r) => r.question_id.includes("motivate")),
    allstar: t.cvr.find((r) => r.question_id.includes("allstar")),
    cv: Rubrics.isCoreComplete(t.cvr.map((r) => r.question_id))
      ? t.cvr
          .filter(
            (r) =>
              !["cv:breakthrough", "cv:motivate", "allstar"].includes(
                r.question_id
              )
          )
          .reduce((p, c) => p + c.value, 0)
      : undefined,
    ip: Rubrics.isProjectComplete(t.ipr.map((r) => r.question_id))
      ? t.ipr.reduce((p, c) => p + c.value, 0)
      : undefined,
    rd: Rubrics.isRobotComplete(t.rdr.map((r) => r.question_id))
      ? t.rdr.reduce((p, c) => p + c.value, 0)
      : undefined,
  }));
  type X = typeof C[number];
  type Y = X & {
    robot_rank: number;
    max: number;
    cvrX?: number;
    rdrX?: number;
    iprX?: number;
    temp?: number;
  };
  const D = C.map((t) => ({
    ...t,
    max: max(t, 1),
  }))
    .sort(teamsort)
    .map((t, i) => ({
      ...t,
      robot_rank: i + 1,
    }))
    .sort((a, b) => (b.cv ?? UNDEFINED) - (a.cv ?? UNDEFINED))
    .reduce(
      (a, x, i) =>
        a.length
          ? [
              ...a,
              {
                ...x,
                cvrX:
                  i === 0
                    ? 1
                    : x.cv === a[i - 1]!.cv
                    ? a[i - 1].cvrX
                    : a[i - 1].cvrX! + (a[i - 1].temp ?? 1),
                temp:
                  i === 0
                    ? 1
                    : x.cv === a[i - 1]!.cv
                    ? (a[i - 1].temp ?? 0) + 1
                    : 1,
              },
            ]
          : [{ ...x, cvrX: 1, temp: 1 }],
      [] as Y[]
    )
    .sort((a, b) => (b.ip ?? UNDEFINED) - (a.ip ?? UNDEFINED))
    .reduce(
      (a, x, i) =>
        a.length
          ? [
              ...a,
              {
                ...x,
                iprX:
                  i === 0
                    ? 1
                    : x.ip === a[i - 1]!.ip
                    ? a[i - 1].iprX
                    : a[i - 1].iprX! + (a[i - 1].temp ?? 1),
                temp:
                  i === 0
                    ? 1
                    : x.ip === a[i - 1]!.ip
                    ? (a[i - 1].temp ?? 0) + 1
                    : 1,
              },
            ]
          : [{ ...x, iprX: 1, temp: 1 }],
      [] as Y[]
    )
    .sort((a, b) => (b.rd ?? UNDEFINED) - (a.rd ?? UNDEFINED))
    .reduce(
      (a, x, i) =>
        a.length
          ? [
              ...a,
              {
                ...x,
                rdrX:
                  i === 0
                    ? 1
                    : x.rd === a[i - 1]!.rd
                    ? a[i - 1].rdrX
                    : a[i - 1].rdrX! + (a[i - 1].temp ?? 1),
                temp:
                  i === 0
                    ? 1
                    : x.rd === a[i - 1]!.rd
                    ? (a[i - 1].temp ?? 1) + 1
                    : 1,
              },
            ]
          : [{ ...x, rdrX: 1, temp: 1 }],
      [] as Y[]
    );
  const E = D.map((t) => ({
    ...t,
    cve: t.cv ?? UNDEFINED,
    cv: t.cvrX ?? UNDEFINED,
    ipe: t.ip ?? UNDEFINED,
    ip: t.iprX ?? UNDEFINED,
    rde: t.rd ?? UNDEFINED,
    rd: t.rdrX ?? UNDEFINED,
    champ_score:
      (t.cvrX ?? 0) + (t.iprX ?? 0) + (t.rdrX ?? 0) + (t.robot_rank ?? 0),
  }))
    .sort((a, b) => a.champ_score - b.champ_score)
    .map((x, i) => ({ ...x, champ_rank: i + 1 }));
  const F = E.map((t) => ({
    ...t,
    round1: t.r1,
    round2: t.r2,
    round3: t.r3,
    breakthrough: t.breakthrough !== undefined && t.breakthrough.value > 1,
    motivate: t.motivate !== undefined && t.motivate.value > 1,
    allstar: t.allstar !== undefined && t.allstar.value > 1,
    awards:
      (t.compete.awards?.filter((a) => isChallengeAward(a.award)) as {
        award: ChallengeAward;
        place: number;
      }[]) ?? [],
    advance: false,
    adv_override: false,
    comments: "",
    // @todo: cv,rd,ip;  then,  breakthrough,motivate,allstar and ranking by cv,rd,ip, then
    // champ_score, then champ_rank.
  }));

  return F;
};

// ==== EXPORTS ==== //
const toName = (user?: IUser): string => {
  if (!user) return "Unknown";
  if (user.given_name && user.family_name)
    return `${user.given_name} ${user.family_name}`;
  else return user.email;
};

const exportTeams = (
  teams: JaTeamData[],
  tournament: Tournament,
  users: IUser[]
) => {
  const header = ["Team number", "Team name", "Coach", "Judge pod"];
  const data = teams
    .map((t) => [
      t.team_number.toString(),
      t.team_name,
      toName(users.find((u) => u._id === t.team.user)),
      t.lane.toString(),
    ])
    .map((row) =>
      row.map((cell) => (cell === UNDEFINED.toString() ? "" : cell))
    );
  downloadCSV(
    tournament.name.replace(/[^a-zA-Z0-9]/g, "-") + "_teams.csv",
    data,
    header
  );
};

const exportScores = (teams: JaTeamData[], tournament: Tournament) => {
  const header = [
    "Rank",
    "Team #",
    "Highest score",
    "R1",
    "GP1",
    "R2",
    "GP2",
    "R3",
    "GP3",
  ];
  const data = teams
    .map((t) => [
      t.robot_rank.toString(),
      t.team_number.toString(),
      t.max.toString(),
      t.round1.toString(),
      t.r1s?.answers
        .find((a) => a.id === "gp")
        ?.answer?.replaceAll(/[^0-9]/g, "") ?? "",
      t.round2.toString(),
      t.r2s?.answers
        .find((a) => a.id === "gp")
        ?.answer.replaceAll(/[^0-9]/g, "") ?? "",
      t.round3.toString(),
      t.r3s?.answers
        .find((a) => a.id === "gp")
        ?.answer.replaceAll(/[^0-9]/g, "") ?? "",
    ])
    .map((row) =>
      row.map((cell) => (cell === UNDEFINED.toString() ? "" : cell))
    );
  downloadCSV(
    tournament.name.replace(/[^a-zA-Z0-9]/g, "-") + "_scores.csv",
    data,
    header
  );
};

const exportCore = (teams: JaTeamData[], tournament: Tournament) => {
  const header = [
    "Team number",
    "Team name",
    "Discovery",
    "Innovation",
    "Impact",
    "Inclusion",
    "Teamwork",
    "Fun",
    "GP1",
    "GP2",
    "GP3",
    "Core values score",
    "Core values rank",
    "Breakthrough",
    "Rising All-Star",
    "Motivate",
  ];
  const data = teams
    .map((t) => [
      t.team_number.toString(),
      t.team_name.replaceAll(/,/g, "_"),
      t.cvr
        .find((r) => (r.question_id as QuestionId) === "cv:discovery")
        ?.value.toString() ?? "",
      t.cvr
        .find((r) => (r.question_id as QuestionId) === "cv:innovation")
        ?.value.toString() ?? "",
      t.cvr
        .find((r) => (r.question_id as QuestionId) === "cv:impact")
        ?.value.toString() ?? "",
      t.cvr
        .find((r) => (r.question_id as QuestionId) === "cv:inclusion")
        ?.value.toString() ?? "",
      t.cvr
        .find((r) => (r.question_id as QuestionId) === "cv:teamwork")
        ?.value.toString() ?? "",
      t.cvr
        .find((r) => (r.question_id as QuestionId) === "cv:fun")
        ?.value.toString() ?? "",
      t.r1s?.answers.find((a) => a.id === "gp")?.answer ?? "",
      t.r2s?.answers.find((a) => a.id === "gp")?.answer ?? "",
      t.r3s?.answers.find((a) => a.id === "gp")?.answer ?? "",
      t.cve.toString(),
      t.cv.toString(),
      t.breakthrough ? "true" : "",
      t.allstar ? "true" : "",
      t.motivate ? "true" : "",
    ])
    .map((row) =>
      row.map((cell) => (cell === UNDEFINED.toString() ? "" : cell))
    );
  downloadCSV(
    tournament.name.replace(/[^a-zA-Z0-9]/g, "-") + "_corevalues.csv",
    data,
    header
  );
};

const exportRobot = (teams: JaTeamData[], tournament: Tournament) => {
  const header = [
    "Team number",
    "Team name",
    "Identify strategy",
    "Identify building and coding",
    "Design workplan",
    "Design innovative features",
    "Create functionality",
    "Create code",
    "Iterate testing",
    "Iterate improve",
    "Communicate design process",
    "Communicate involvement",
    "Core values score",
    "Core values rank",
  ];
  const data = teams
    .map((t) => [
      t.team_number.toString(),
      t.team_name.replaceAll(/,/g, "_"),
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:identify_a")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:identify_b")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:design_a")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:design_b")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:create_a")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:create_b")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:iterate_a")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:iterate_b")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:communicate_a")
        ?.value.toString() ?? "",
      t.rdr
        .find((r) => (r.question_id as QuestionId) === "rd:communicate_b")
        ?.value.toString() ?? "",
      t.rde.toString(),
      t.rd.toString(),
    ])
    .map((row) =>
      row.map((cell) => (cell === UNDEFINED.toString() ? "" : cell))
    );
  downloadCSV(
    tournament.name.replace(/[^a-zA-Z0-9]/g, "-") + "_robot.csv",
    data,
    header
  );
};

const exportProject = (teams: JaTeamData[], tournament: Tournament) => {
  const header = [
    "Team number",
    "Team name",
    "Identify problem",
    "Identify research",
    "Design ideas",
    "Design planning",
    "Create solution",
    "Create model",
    "Iterate sharing",
    "Iterate improve",
    "Communicate engaging",
    "Communicate impact",
    "Core values score",
    "Core values rank",
  ];
  const data = teams
    .map((t) => [
      t.team_number.toString(),
      t.team_name.replaceAll(/,/g, "_"),
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:identify_a")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:identify_b")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:design_a")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:design_b")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:create_a")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:create_b")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:iterate_a")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:iterate_b")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:communicate_a")
        ?.value.toString() ?? "",
      t.ipr
        .find((r) => (r.question_id as QuestionId) === "rp:communicate_b")
        ?.value.toString() ?? "",
      t.ipe.toString(),
      t.ip.toString(),
    ])
    .map((row) =>
      row.map((cell) => (cell === UNDEFINED.toString() ? "" : cell))
    );
  downloadCSV(
    tournament.name.replace(/[^a-zA-Z0-9]/g, "-") + "_project.csv",
    data,
    header
  );
};

const judgeAdvisorCalcs = {
  calcTeams,
  exportTeams,
  exportScores,
  exportCore,
  exportRobot,
  exportProject,
};

export default judgeAdvisorCalcs;
