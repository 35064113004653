import { FIRSTProgram } from "../../common/backend.types";
import { GCheckFields, GDef, IGuard } from "../api.types";

export interface DTOEventGet {
  _id: string;
  date: Date;
  title: string;
  program?: FIRSTProgram;
  timestamp: Date;
}

export interface DTOEventCreate {
  date: Date;
  title: string;
  program?: FIRSTProgram;
}

export interface DTOEventUpdate {
  date?: Date;
  title?: string;
  program?: FIRSTProgram;
}

export const GIsDTOEventGet: IGuard<DTOEventGet> = (x: any) =>
  GCheckFields<DTOEventGet>(GDef(x), [
    "date",
    "title"
  ]);
