import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import NoIcon from "@material-ui/icons/Cancel";
import YesIcon from "@material-ui/icons/Check";
import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Award, ChallengeAwards } from "../../../common/awards";
import { FIRSTProgram } from "../../../common/backend.types";
import {
  competeGetAll,
  competeUpdate,
} from "../../../store/compete/compete.action";
import { Compete } from "../../../store/compete/compete.types";
import { RootState } from "../../../store/root-reducer";
import { rubricGetAll } from "../../../store/rubrics/rubric.action";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { scoresheetGetAll } from "../../../store/scoring/scoring.action";
import { Scoresheet } from "../../../store/scoring/scoring.types";
import {
  teamGetAll,
  teamGetByTournament,
} from "../../../store/teams/team.action";
import { TeamPublic } from "../../../store/teams/team.types";
import {
  tournamentGetAll,
  tournamentUpdate,
} from "../../../store/tournament/tournament.action";
import { userGetTourn } from "../../../store/users/user.action";
import CheckBox from "../../atomic/CheckBox";
import { SingleSelector } from "../../atomic/FaSelectors";
import TeamNumber from "../../atomic/TeamNumber";
import { JaTeamData } from "./JudgeAdvisorView";
import AwardInput from "./AwardInput";

const UNDEFINED = -2000;
const MIN_SCORE = 5;

type ViewRubric = {
  team: TeamPublic;
  compete: Compete;
  rubrics: RubricAnswer[];
};

type LocalProps = {
  row: JaTeamData;
  hidden?: boolean;
  onViewRubric: (data: ViewRubric) => void;
  onViewScoresheet: (data: Scoresheet) => void;
};

const JudgeAdvisorRow = ({
  hidden = false,
  row,
  rubrics,
  onViewRubric,
  onViewScoresheet,
  competeUpdate,
}: Props) => {
  const [tempLane, setTempLane] = useState<string>(row.lane);
  const [tempAwards, setTempAwards] = useState<Award[]>(row.awards);

  useEffect(() => {
    setTempLane(row.lane);
  }, [row.lane]);

  useEffect(() => {
    setTempAwards(row.awards);
  }, [row.awards]);

  const classes = useStyles();
  const labelId = `enhanced-table-checkbox-${row.team_number}`;
  return (
    <TableRow tabIndex={-1}>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <TeamNumber
          program={FIRSTProgram.FLL_CHALLENGE}
          number={row.team_number}
        />
      </TableCell>
      <TableCell align="right">{row.team_name}</TableCell>
      {hidden || (
        <Fragment>
          <TableCell align="right">
            <TextField
              value={tempLane}
              placeholder="Lane"
              onChange={(e) => setTempLane(e.target.value)}
              onBlur={() =>
                competeUpdate(row.compete._id, { judgePod: tempLane })
              }
            />
          </TableCell>
          <TableCell
            align="center"
            className={row.r1s ? classes.clickable : ""}
            onClick={() => (row.r1s ? onViewScoresheet(row.r1s) : {})}
          >
            {row.round1 === UNDEFINED ? "-" : row.round1}
          </TableCell>
          <TableCell
            align="center"
            className={row.r2s ? classes.clickable : ""}
            onClick={() => (row.r2s ? onViewScoresheet(row.r2s) : {})}
          >
            {row.round2 === UNDEFINED ? "-" : row.round2}
          </TableCell>
          <TableCell
            align="center"
            className={row.r3s ? classes.clickable : ""}
            onClick={() => (row.r3s ? onViewScoresheet(row.r3s) : {})}
          >
            {row.round3 === UNDEFINED ? "-" : row.round3}
          </TableCell>
          <TableCell align="center">
            {row.max === UNDEFINED ? "-" : row.max}
          </TableCell>
          <TableCell align="center">{row.robot_rank}</TableCell>
          <TableCell
            align="center"
            className={row.cve !== UNDEFINED ? classes.clickable : ""}
            onClick={() =>
              onViewRubric({
                team: row.team,
                compete: row.compete,
                rubrics: rubrics.filter(
                  (r) => r.compete_id === row.compete._id
                ),
              })
            }
          >
            {row.cv === UNDEFINED || row.cve === UNDEFINED ? "-" : row.cv}
          </TableCell>
          <TableCell
            align="center"
            className={row.ipe !== UNDEFINED ? classes.clickable : ""}
            onClick={() =>
              onViewRubric({
                team: row.team,
                compete: row.compete,
                rubrics: rubrics.filter(
                  (r) => r.compete_id === row.compete._id
                ),
              })
            }
          >
            {row.ip === UNDEFINED || row.ipe === UNDEFINED ? "-" : row.ip}
          </TableCell>
          <TableCell
            align="center"
            className={row.rde !== UNDEFINED ? classes.clickable : ""}
            onClick={() =>
              onViewRubric({
                team: row.team,
                compete: row.compete,
                rubrics: rubrics.filter(
                  (r) => r.compete_id === row.compete._id
                ),
              })
            }
          >
            {row.rd === UNDEFINED || row.rde === UNDEFINED ? "-" : row.rd}
          </TableCell>
        </Fragment>
      )}
      <TableCell align="center">
        {row.champ_score === UNDEFINED ? "-" : row.champ_score}
      </TableCell>
      <TableCell align="center">{row.champ_rank}</TableCell>
      <TableCell align="center">
        {row.breakthrough ? <YesIcon /> : <NoIcon />}
      </TableCell>
      <TableCell align="center">
        {row.allstar ? <YesIcon /> : <NoIcon />}
      </TableCell>
      <TableCell align="center">
        {row.motivate ? <YesIcon /> : <NoIcon />}
      </TableCell>
      <TableCell align="right">
        <AwardInput
          awards={tempAwards}
          onBlur={(a) => {
            competeUpdate(row.compete._id, {
              awards: a,
            });
          }}
        />
      </TableCell>
      {/* <TableCell align="right">
        <SingleSelector
          options={ChallengeAwards.map((a) => ({
            label: a,
            value: a,
          }))}
          className={classes.selector}
          clearable
          value={row.compete.awards[0]?.award}
          onSelect={(a) => {
            competeUpdate(row.compete._id, {
              awards: a
                ? [
                    {
                      award: a,
                      place: row.compete.awards[0]?.place ?? 1,
                    },
                  ]
                : [],
              attendance: "Attended",
            });
          }}
        />
      </TableCell> */}
      {/* <TableCell align="right">
        <SingleSelector
          disabled={!Boolean(row.compete.awards[0]?.award)}
          options={[1, 2, 3].map((a) => ({
            label: a.toString(),
            value: a,
          }))}
          className={classes.selector}
          value={row.compete.awards[0]?.place}
          clearable
          onSelect={(a) => {
            competeUpdate(row.compete._id, {
              awards: a
                ? [{ place: a, award: row.compete.awards[0]?.award }]
                : [],
              attendance: "Attended",
            });
          }}
        />
      </TableCell> */}
      {/* <TableCell align="right">
        <CheckBox checked={false} toggle={() => {}} />
      </TableCell>
      <TableCell align="right">
        <TextField />
      </TableCell> */}
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    judgeadvisorrow: { height: "100%" },
    paper: {
      width: "100%",
      height: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    clickable: {
      textDecoration: "underline",
      color: "blue",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    controls: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
    exportBtn: {
      marginRight: theme.spacing(1),
    },
    refreshBtn: {
      height: 32,
      width: 32,
      marginRight: theme.spacing(1),
    },
    selector: {
      width: 240,
    },
    label: {
      width: 80,
      margin: "auto 0",
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  rubrics: state.rubrics.all,
});

const connector = connect(mapStateToProps, {
  competeUpdate,
});

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(JudgeAdvisorRow);
