import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuid } from "uuid";
import {
  DTOAddressUpdate,
  GIsDTOAddressCreate,
} from "../../api/dtos/address.dto";
import { State, States } from "../../common/backend.types";
import {
  addressCreate,
  addressUpdate,
} from "../../store/address/address.action";
import { Address } from "../../store/address/address.types";
import { RootState } from "../../store/root-reducer";
import logger from "../../utils/logger";
import Button from "../atomic/Button";
import { SingleSelector } from "../atomic/FaSelectors";
import SubmitButton from "../atomic/SubmitButton";

type FormData = DTOAddressUpdate;

const AddressForm = ({
  address,
  auth: { user },
  addressUpdate,
  addressCreate,
  onSubmit,
}: Props) => {
  const [ids] = useState<
    [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ]
  >([
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
    uuid(),
  ]);

  const defaultState = {
    country: "Australia",
  };
  const [data, setData] = useState<FormData>(defaultState);

  useEffect(() => {
    if (!address) setData(defaultState);
    else setData({ ...address });
  }, [address]);

  const handleSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (!data.state)
      return logger.warn(
        "State is required",
        "AddressForm :: handleSubmit",
        true
      );
    try {
      if (address) {
        addressUpdate(address._id, data);
      } else {
        addressCreate(
          GIsDTOAddressCreate({ street2: "", street3: "", ...data })
        );
      }
      if (onSubmit) onSubmit();
    } catch (e: any) {
      logger.warn(e, "AddressForm :: handleSubmit", true);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setData((d) => ({ ...d, [e.target.name]: e.target.value }));
  };

  return (
    <form className="form address-form" onSubmit={handleSubmit}>
      <div className="d-flex-col align-start">
        <label htmlFor={ids[0]}>Label</label>
        <small>Internal use only; e.g. "My house"</small>
      </div>
      <input
        required
        autoComplete="none"
        type="text"
        name="label"
        id={ids[0]}
        value={data.label === undefined ? "" : data.label}
        onChange={(e) => onChange(e)}
      />
      {user ? (
        <Fragment>
          <span>Contact</span>
          <Button
            onClick={(e) => {
              e.preventDefault();

              setData({
                ...data,
                name: user.given_name + " " + user.family_name,
                email: user.email,
                phone: user.phone,
              });
            }}
          >
            I am the contact
          </Button>
        </Fragment>
      ) : (
        // Shouldn't ever happen but still...
        <span className="col-span-2">Contact</span>
      )}
      <label className="ml-1" htmlFor={ids[9]}>
        Name
      </label>
      <input
        required
        autoComplete="none"
        type="text"
        name="name"
        id={ids[9]}
        value={data.name === undefined ? "" : data.name}
        onChange={(e) => onChange(e)}
      />
      <label className="ml-1" htmlFor={ids[10]}>
        Email
      </label>
      <input
        required
        autoComplete="none"
        type="text"
        name="email"
        id={ids[10]}
        value={data.email === undefined ? "" : data.email}
        onChange={(e) => onChange(e)}
      />
      <label className="ml-1" htmlFor={ids[11]}>
        Phone
      </label>
      <PhoneInput
        value={(data.phone ?? "") as any}
        onChange={(e: any) => setData({ ...data, phone: e?.toString() })}
        defaultCountry="AU"
      />
      {/* <input
        required
        autoComplete="none"
        type="text"
        name="phone"
        id={ids[11]}
        placeholder="xxxx-xxx-xxx | xxxx xxxx"
        pattern="^([0-9]{4}[- ]?[0-9]{3}[- ]?[0-9]{3})|([0-9]{4} ?[0-9]{4})$"
        value={data.phone === undefined ? "" : data.phone}
        onChange={(e) => onChange(e)}
      /> */}
      <label htmlFor={ids[1]}>Company</label>
      <input
        required
        type="text"
        name="company"
        id={ids[1]}
        value={data.company === undefined ? "" : data.company}
        onChange={(e) => onChange(e)}
      />
      <label className="d-flex-col align-start" htmlFor={ids[2]}>
        ABN <small>This should be an 11 digit number</small>
      </label>
      <input
        required
        type="text"
        name="abn"
        id={ids[2]}
        maxLength={11}
        value={data.abn === undefined ? "" : data.abn}
        onChange={(e) => {
          e.persist();
          setData({
            ...data,
            abn: e.target.value.replace(/[^0-9]/g, ""),
          });
        }}
      />
      <label htmlFor={ids[3]}>Street Address</label>
      <input
        required
        type="text"
        name="street1"
        id={ids[3]}
        value={data.street1 === undefined ? "" : data.street1}
        onChange={(e) => onChange(e)}
      />
      <div></div>
      <input
        type="text"
        name="street2"
        id={ids[4]}
        value={data.street2 === undefined ? "" : data.street2}
        onChange={(e) => onChange(e)}
      />
      <div></div>
      <input
        type="text"
        name="street3"
        id={ids[5]}
        value={data.street3 === undefined ? "" : data.street3}
        onChange={(e) => onChange(e)}
      />
      <label htmlFor={ids[6]}>Suburb</label>
      <input
        required
        type="text"
        name="suburb"
        id={ids[6]}
        value={data.suburb === undefined ? "" : data.suburb}
        onChange={(e) => onChange(e)}
      />
      <label htmlFor={ids[7]}>Postcode</label>
      <input
        required
        type="text"
        name="postcode"
        id={ids[7]}
        pattern="[0-9]{4}"
        value={data.postcode === undefined ? "" : data.postcode}
        onChange={(e) => onChange(e)}
        title="Must be a four-digit number"
      />
      <label className="d-flex">State</label>
      <SingleSelector<State>
        options={States}
        value={data.state}
        onSelect={(v) => {
          if (v) setData({ ...data, state: v });
        }}
      />
      <span>Country</span>
      <span>Australia</span>
      <SubmitButton value={address ? "Update" : "Create"} />
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {
  addressUpdate,
  addressCreate,
});
type Props = ConnectedProps<typeof connector> & {
  address?: Address;
  onSubmit?: () => void;
};

export default connector(AddressForm);
