import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { ProgramToLabel, SeasonLabel } from "../../../common/backend.types";
import {
  compressManifest,
  diffManifest,
  diffShipped,
  diffShippedBoth,
  getInventory,
} from "../../../inventory/getInventory";
import { Address } from "../../../store/address/address.types";
import { Payment } from "../../../store/payment/payment.types";
import { RootState } from "../../../store/root-reducer";
import { shipmentCreate } from "../../../store/shipping/shipment.action";
import { Shipment } from "../../../store/shipping/shipment.types";
import { Team } from "../../../store/teams/team.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import { makeUserWithMoneys } from "../../../store/users/user.selector";
import logger from "../../../utils/logger";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import ShipmentEdit from "./ShipmentEdit";

interface LocalProps {
  userId: IUser["_id"];
  teams: Team[];
  payments: Payment[];
  addresses: Address[];
  tournaments: Tournament[];
  shipments: Shipment[];
  filter?: string;
  showDone?: boolean;
  showUnpaid?: boolean;
}

const ShipTeamRow = ({
  user,
  teams,
  payments,
  addresses,
  tournaments,
  shipments,
  filter,
  showDone,
  showUnpaid,
  shipmentCreate,
}: Props) => {
  const isUnShipped = (address: Address): boolean => {
    return getDiff(address, true)
      .map((d) => d.count > 0)
      .reduce((p, c) => c || p, false);
  };
  const isUnPrepped = (address: Address): boolean => {
    return getDiff(address, false)
      .map((d) => d.count > 0)
      .reduce((p, c) => c || p, false);
  };

  const getDiff = (address: Address, shippedOnly: boolean = false) => {
    const ships = shipments.filter(
      (s) =>
        s.address === address._id &&
        (shippedOnly ? Boolean(s.consignment_number) : true)
    );
    const diff = diffShippedBoth(
      tournaments.filter((t) => t.ship_kits && t.ship_address === address._id),
      teams.filter((t) => t.ship_kit && t.shipping_address === address._id),
      ships
    );

    // const diff = diffShipped(
    //   teams.filter((t) => t.ship_kit && t.shipping_address === address._id),
    //   ships
    // );

    return diff;
  };

  return !user ? (
    <tr>
      <td>Something went wrong...</td>
    </tr>
  ) : showUnpaid ||
    !(
      user.up - user.down > 0 && (user.up - user.down).toFixed(2.0) !== "0.00"
    ) ? (
    <Fragment>
      {addresses.map((addr) =>
        showDone || isUnShipped(addr) ? (
          <tr key={addr._id}>
            <td>
              <div className="d-flex-col">
                <span>{user.email}</span>
                <span>
                  {user.given_name} {user.family_name}
                </span>
                <span>{user.phone}</span>
              </div>
            </td>
            <td>
              <div className="d-flex-col">
                <span>{addr.company}</span>
                <span>{addr.street1}</span>
                <span>{addr.street2}</span>
                <span>{addr.street3}</span>
                <span>
                  {addr.suburb} {addr.postcode}
                </span>
                <span>
                  {addr.state} {addr.country}
                </span>
              </div>
            </td>
            <td>
              <div className="d-flex-col">
                {showDone &&
                  shipments.map((ship) => (
                    <span className="text-strike d-flex-col">
                      {ship.contents.map((s, i) => (
                        <span key={i}>
                          {s.count} x {SeasonLabel(s.season)}{" "}
                          {ProgramToLabel(s.program)} kit
                          {s.count === 1 ? "" : "s"}
                        </span>
                      ))}
                    </span>
                  ))}

                {getDiff(addr, true).map((t, i) => (
                  <span key={i}>
                    {t.count} x {SeasonLabel(t.season)}{" "}
                    {ProgramToLabel(t.program)} kit
                    {t.count === 1 ? "" : "s"}
                  </span>
                ))}
                {tournaments.filter((t) => t.ship_address === addr._id)
                  .length ? (
                  <i>{`Includes kits for tournaments: ${tournaments
                    .filter((t) => t.ship_address === addr._id)
                    .map(
                      (t) => t.name + " " + t.season.toString().substring(0, 4)
                    )
                    .join(", ")}`}</i>
                ) : (
                  ""
                )}
              </div>
            </td>
            <td>
              {user.up - user.down > 0 &&
              (user.up - user.down).toFixed(2.0) !== "0.00" ? (
                <Icon icon="times" className="text-danger" />
              ) : (
                <Icon icon="check" className="text-success" />
              )}
            </td>
            <td>
              {isUnShipped(addr) ? (
                <Icon icon="times" className="text-danger" />
              ) : (
                <Icon icon="check" className="text-success" />
              )}{" "}
            </td>
            <td>
              <div className="d-flex-col">
                {shipments
                  .filter((s) => s.address === addr._id)
                  .map((s) => (
                    <ShipmentEdit shipment={s} key={s._id} />
                  ))}
                {isUnPrepped(addr) && (
                  <Button
                    variant="success"
                    onClick={(e) => {
                      //   console.log(getDiff(addr));
                      shipmentCreate({
                        address: addr._id,
                        contents: getDiff(addr),
                        receiver: user._id,
                        consignment_number: "",
                        comment: "",
                      });
                    }}
                  >
                    Create
                  </Button>
                )}
              </div>
            </td>
          </tr>
        ) : (
          <tr />
        )
      )}
    </Fragment>
  ) : (
    <tr />
  );
};

const makeMapStateToProps = () => {
  const selectUser = makeUserWithMoneys();
  return (state: RootState, props: LocalProps) => ({
    user: selectUser(state, props),
  });
};

const connector = connect(makeMapStateToProps, { shipmentCreate });
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(ShipTeamRow);
