import api from "../../utils/api.instance";
import { GIsArray } from "../api.types";
import {
  DTOTokenCreate,
  DTOTokenGet,
  GIsDTOTokenGet,
  Token,
  TokenFromDTOGet,
} from "../dtos/token.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getForTournament = async (tournament_id: string): Promise<Token[]> => {
  const a = (await api.get(`tokens/${tournament_id}`)).data;
  return GIsArray<DTOTokenGet>(a)
    .map((ad) => GIsDTOTokenGet(ad))
    .map((t) => TokenFromDTOGet(t));
};

const create = async (data: DTOTokenCreate): Promise<Token> => {
  const T = GIsDTOTokenGet((await api.post(`tokens`, data)).data);
  return TokenFromDTOGet(T);
};

const remove = async (id: Token["_id"]): Promise<boolean> => {
  await api.delete(`tokens/${id}`);
  return true;
};

const TokenService = {
  getForTournament,
  create,
  remove,
};

export default TokenService;
