import {
  AgeRange,
  UserRole,
  VolParticipation,
  VolunteerProfile,
} from "../../common/backend.types";
import { GCheckFields, GDef, IGuard, IUser } from "../api.types";

export interface DTOVolunteerProfileGet extends VolunteerProfile {}

export interface DTOVolunteerProfileUpdate {
  wwcc?: string;
  age_range?: AgeRange;
  emergency_name?: string;
  emergency_phone?: string;
  history?: VolParticipation[];
  preferences?: VolParticipation[];
  affiliation?: string;
  dietary?: string;
}

export interface DTOUserGet extends IUser {}

export const GIsDTOUserGet: IGuard<DTOUserGet> = (x: any) =>
  GCheckFields<DTOUserGet>(GDef(x), ["_id", "email", "roles", "timestamp"]);

export interface DTOUserUpdate {
  given_name?: string;
  family_name?: string;
  phone?: string;
  credit?: number;
  team_limit?: number;
}

export const GIsDTOUserUpdate: IGuard<DTOUserUpdate> = (x) => GDef(x);

export interface DTOUserCheck {
  _id: IUser["_id"];
  providers?: IUser["providers"];
}

export const GIsDTOVolunteerProfileGet: IGuard<DTOVolunteerProfileGet> = (x) =>
  GCheckFields<DTOVolunteerProfileGet>(GDef(x), [
    "wwcc",
    "age_range",
    "emergency_name",
    "emergency_phone",
    "history",
    "preferences",
  ]);
