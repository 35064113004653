import React, { useEffect } from "react";
import { AnalyticsTrack } from "../../api/analytics";
import { RootState } from "../../store/root-reducer";
import logger from "../../utils/logger";
import { connect, ConnectedProps } from "react-redux";
import LocalLink from "../atomic/LocalLink";

interface LocalProps {}

const Help = ({ auth: { user } }: Props) => {
  useEffect(() => {
    logger.debug("Viewing help page");
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "Help",
      },
    });
  }, []);
  return (
    <div>
      <h2>How do I register my teams?</h2>
      <h3>Step 1: Sign up</h3>
      <p>
        First thing's first, create an account. You can use any valid email
        address you have access to, or sign in using a valid Google account
        (recommended)
      </p>
      <p>
        Once you enter the system, you will be asked to provide a full name and
        phone number.
      </p>
      <p>
        If you register using an email and password, you will also need to
        confirm your email address before you can log in. Check your inbox in
        the 5-10 minutes after you finish the signup process for a confirmation
        email.
      </p>

      <h3>Step 2: Register your teams</h3>
      <p>
        Press the button on the dashboard to start the registration process.
      </p>
      <p>
        Here, you can enter multiple teams at once, as long as each one is
        affiliated with a <i>FIRST</i> program and you nominate a team name.
        This name must be unique within the program.
      </p>
      <p>
        If you are registering any <i>FIRST</i> LEGO League Challenge teams, you
        can specify if you would like them to receive a kit or if you would like
        any of your teams to share. During pre-registration, at most 2 teams may
        share 1 kit, so if you register 3 teams, you will need to order at least
        2 kits.
      </p>
      <p>
        Provide at least one coach for the team; you will automatically be
        entered as one of the coaches, but you can add more if you have other
        contacts. These coaches will be able to see team detail but will have
        little edit access.
      </p>
      <p>
        Finally, provide a shipping address. You can do this from the
        register-teams view or from your profile page, and you can create as
        many addresses as you want. If you need kits to be sent to different
        addresses, you will need to go through the registration process multiple
        times. Please note that you are unable to pay by credit card if you are
        receiving more than 10 kits to any address.
      </p>
      <p>
        At the bottom right of the registration page, you will be shown a
        summary of the price for the teams you are registering.
      </p>

      <h3>Step 3: Pay for your registrations</h3>
      <p>
        The dashboard will show you how much you currently owe, and a detailed
        breakdown is shown in "My Finance" on the sidebar.
      </p>
      <p>
        On the "Pay Now" screen, you will again be presented with a breakdown of
        the costs you owe. There are two options for payment, described below;
        in each one, you will be required to enter a billing address. This can
        be the same or different to the shipping addresses covered in your
        registrations.
      </p>
      <p>
        <strong>Credit Card:</strong> This is the recommended payment option.
        Simply press the button to be taken to the Macquarie University payment
        gateway. Once you complete your transaction, you will be returned to
        this site and a receipt will be sent to your email address. A record of
        your payment should automatically be created in this system and your
        balance should go down to zero, but this can take time to update, so{" "}
        <span className="text-primary">
          do not go through the payment gateway again if it said you
          successfully completed your payment.
        </span>{" "}
      </p>
      <p>
        <strong>Invoice:</strong> If you are unable to pay by credit card, you
        can pay by invoice. However, as of 2021, you must{" "}
        <span className="text-primary">upload a valid purchase order</span> in
        order to request an invoice.
      </p>

      <h3>Step 4: That's it!</h3>
      <p>
        In coming weeks, the list of <i>FIRST</i> LEGO League Challenge events
        will be uploaded, and you can select an event to compete in. Otherwise,
        your preregistration is complete as long as your balance owing is listed
        as 0.
      </p>
      <p>
        Check out <LocalLink to="/faq">FAQs</LocalLink> if you have any further
        questions, or:
      </p>

      <h2>I want to report a problem</h2>
      <p>
        If you have a technical problem with the site, let us know at{" "}
        <a href="mailto:fred@firstaustralia.org">fred@firstaustralia.org</a>
      </p>
      <h2>Contact us</h2>
      <p>
        If you have any questions or concerns about the <i>FIRST</i> programs in
        Australia, contact{" "}
        <a href="mailto:wynonah.bush@mq.edu.au">wynonah.bush@mq.edu.au</a>
      </p>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(Help);
