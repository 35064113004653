import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Fab from "@material-ui/core/Fab";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import React, { Fragment, useCallback, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Compete } from "../../../store/compete/compete.types";
import { RootState } from "../../../store/root-reducer";
import { rubricCreateBatch } from "../../../store/rubrics/rubric.action";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { TeamPublic } from "../../../store/teams/team.types";
import logger from "../../../utils/logger";
import { FaHeader, FaText } from "../../atomic/FaTexts";
import Spinner from "../../atomic/Spinner";
import CoreValuesRubric from "./CoreValuesRubric";
import InnovationProjectRubric from "./InnovationProjectRubric";
import RobotDesignRubric from "./RobotDesignRubric";
import RubricCommentSection from "./RubricCommentSection";
import {
  CoreQuestion,
  GeneralQuestion,
  ProjectQuestion,
  QuestionId,
  RobotQuestion,
} from "./rubrics";

type LocalProps = {
  team: TeamPublic;
  compete: Compete;
  rubrics: RubricAnswer[];
};

type Score = { value?: 1 | 2 | 3 | 4; comment?: string };

/**
 * @todo add an alert on modal close, in case they try to close the modal while changes exist
 * also add progress bar to the save button
 * Editing isn't working at the moment -- make sure it only updates on answers once
 * It's super fucking slow, which isn't great?
 * Rule out the CV checkboxes from "isCoreComplete"
 * Add judge advisor bullshit
 * Bulk update - don't create one at a time?
 */

type ChangeData = {
  [property in
    | CoreQuestion
    | ProjectQuestion
    | RobotQuestion
    | GeneralQuestion]: Score;
};

const RubricEntryForm = ({
  team,
  compete,
  rubrics,
  rubricCreateBatch,
}: Props) => {
  const [changes, setChanges] = useState<Partial<ChangeData>>({});

  // Note: the "undefined" is to cover boolean situations
  const changeScore = useCallback(
    (id: QuestionId, score: Score | undefined) => {
      // console.log("UPDATE! " + id + " :: " + JSON.stringify(score));
      setChanges({ ...changes, [id]: score });
    },
    [changes]
  );

  const onPrint: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (!team) return;
    const t = document.title;
    if (team)
      document.title = `${team.team_number}-${team.team_name
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "_")}-rubric`;
    else document.title = "rubric";
    window.print();
    document.title = t;
  };

  // useEffect(() => {
  //   console.log("CHANGES HAVE ALSO CHANGED");
  // }, [changes]);

  const [changeProgress, setChangeProgress] = useState<number | undefined>();
  const syncChanges = useCallback(async () => {
    setChangeProgress(0);
    let i = 0;
    const arr = Object.entries(changes);
    const created = await rubricCreateBatch({
      tournament_id: compete.tournament_id,
      team_id: compete.team_id,
      answers: arr.map(([qid, score]) => ({
        question_id: qid,
        value: score?.value ?? 0,
        comment: score?.comment ?? "",
      })),
    });
    if (created === false) {
      logger.warn(
        `Cannot save changes.  The tournament may be locked.`,
        "RubricEntryForm",
        true
      );
      return;
    }

    if (created.length < arr.length) {
      logger.error(
        `${
          arr.length - created.length
        } updates failed! Please try again (and print the rubric to be safe)`,
        "RubricEntryForm",
        true
      );
    } else {
      logger.success(`Changes successfully saved!`, "RubricEntryForm", true);
      setChanges({});
    }
    setChangeProgress(undefined);
  }, [changes]);

  const classes = useStyles();
  return (
    <div className={classes.rubricview}>
      <FaHeader size="md">
        {team.team_number} {team.team_name}
      </FaHeader>
      <Accordion className={classes.cvSection}>
        <AccordionSummary
          className={classes.cvHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <FaText className={classes.bold}>Core Values</FaText>
        </AccordionSummary>
        <AccordionDetails>
          <CoreValuesRubric
            answers={rubrics
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .filter(
                (x, i, a) =>
                  a.findIndex((y) => y.question_id === x.question_id) === i
              )}
            update={changeScore}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.ipSection}>
        <AccordionSummary
          className={classes.ipHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <FaText className={classes.bold}>Innovation Project</FaText>
        </AccordionSummary>
        <AccordionDetails>
          <InnovationProjectRubric
            answers={rubrics
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .filter(
                (x, i, a) =>
                  a.findIndex((y) => y.question_id === x.question_id) === i
              )}
            update={changeScore}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.rdSection}>
        <AccordionSummary
          className={classes.rdHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <FaText className={classes.bold}>Robot Design</FaText>
        </AccordionSummary>
        <AccordionDetails>
          <RobotDesignRubric
            answers={rubrics
              .sort(
                (a, b) =>
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
              )
              .filter(
                (x, i, a) =>
                  a.findIndex((y) => y.question_id === x.question_id) === i
              )}
            update={changeScore}
          />
        </AccordionDetails>
      </Accordion>
      <RubricCommentSection
        cpos={
          rubrics.find((r) => r.question_id === "gn:positive_comment")
            ?.comment ?? ""
        }
        ccon={
          rubrics.find((r) => r.question_id === "gn:negative_comment")
            ?.comment ?? ""
        }
        cprv={
          rubrics.find((r) => r.question_id === "gn:private_comment")
            ?.comment ?? ""
        }
        cscr={
          rubrics.find((r) => r.question_id === "gn:award_script")?.comment ??
          ""
        }
        onChange={(q, v) =>
          changeScore(q, {
            value: 1,
            comment: v,
          })
        }
      />
      <Fab
        variant="extended"
        // size="small"
        color={"primary"}
        aria-label="save"
        className={classes.saveBubble}
        disabled={changeProgress !== undefined}
        onClick={() => syncChanges()}
      >
        {changeProgress === undefined ? (
          <Fragment>
            <SaveIcon />
            Save
          </Fragment>
        ) : (
          <Spinner variant="challenge" />
        )}
      </Fab>
      <Fab
        variant="extended"
        // size="small"
        color={"default"}
        aria-label="print"
        className={classes.printBubble}
        onClick={onPrint}
      >
        <PrintIcon />
        Print
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    rubricview: {},
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
      margin: theme.spacing(1, 0),
    },
    bold: { fontWeight: "bold" },
    cvSection: {
      border: `1px solid ${theme.palette.corevalues.main}`,
    },
    cvHeader: {
      backgroundColor: theme.palette.corevalues.dark,
    },
    ipSection: {
      border: `1px solid ${theme.palette.innovationproject.main}`,
    },
    ipHeader: {
      backgroundColor: theme.palette.innovationproject.dark,
    },
    rdSection: {
      border: `1px solid ${theme.palette.robotdesign.main}`,
    },
    rdHeader: {
      backgroundColor: theme.palette.robotdesign.dark,
    },
    saveBubble: {
      // position: "absolute",
      width: 120,
      justifyContent: "space-evenly",
      display: "flex",
      margin: theme.spacing(1),
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    printBubble: {
      // position: "absolute",
      width: 120,
      justifyContent: "space-evenly",
      display: "flex",
      margin: theme.spacing(1),
      position: "fixed",
      bottom: theme.spacing(10),
      right: theme.spacing(2),
    },
  })
);

const mapStateToProps = (state: RootState) => ({
  teams: [...state.team.all, ...state.team.dummy],
  competes: state.compete.all,
});

const connector = connect(mapStateToProps, { rubricCreateBatch });

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(RubricEntryForm);
