import React from "react";
import { IUser } from "../../api/api.types";

interface Props {
  user: IUser | undefined;
}

const UserName = ({ user }: Props) => (
  <div>
    {!user ? (
      <span className="text-danger">Unknown</span>
    ) : (
      <span>
        {user.given_name} {user.family_name}
      </span>
    )}
  </div>
);

export default UserName;
