import { AppThunk } from "../app-thunk";
import {
  Shipment,
  ShipmentActionTypes,
  SHIPMENT_UPDATE,
  SHIPMENT_CLEAR,
  SHIPMENT_GET_ALL,
  SHIPMENT_GET_MINE,
  SHIPMENT_DELETE,
  SHIPMENT_CREATE,
} from "./shipment.types";
import ShipmentService from "../../api/services/shipment.service";
import logger from "../../utils/logger";
import {
  DTOShipmentCreate,
  DTOShipmentGet,
  DTOShipmentUpdate,
} from "../../api/dtos/shipment.dto";

/**
 * Action types
 */

export const ShipmentGetMineAction = (
  data: Shipment[]
): ShipmentActionTypes => ({
  type: SHIPMENT_GET_MINE,
  payload: data,
});

export const ShipmentGetAllAction = (
  data: Shipment[]
): ShipmentActionTypes => ({
  type: SHIPMENT_GET_ALL,
  payload: data,
});

export const ShipmentCreateAction = (data: Shipment): ShipmentActionTypes => ({
  type: SHIPMENT_CREATE,
  payload: data,
});

export const ShipmentUpdateAction = (data: Shipment): ShipmentActionTypes => ({
  type: SHIPMENT_UPDATE,
  payload: data,
});

export const ShipmentDeleteAction = (
  data: Shipment["_id"]
): ShipmentActionTypes => ({
  type: SHIPMENT_DELETE,
  payload: data,
});

export const ShipmentClearAction = (): ShipmentActionTypes => ({
  type: SHIPMENT_CLEAR,
  payload: null,
});

/**
 * Actions
 */

export const shipmentGetMine = (): AppThunk => async (dispatch) => {
  try {
    const a = await ShipmentService.getMine();
    dispatch(ShipmentGetMineAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "shipment.action :: getMine", true);
  }
};

export const shipmentGetAll = (): AppThunk => async (dispatch) => {
  try {
    const a = await ShipmentService.getAll();
    dispatch(ShipmentGetAllAction(a));
  } catch (error: any) {
    logger.logError(error, logger.warn, "shipment.action :: getAll", true);
  }
};

export const shipmentCreate =
  (data: DTOShipmentCreate): AppThunk =>
  async (dispatch, getState) => {
    try {
      const a = await ShipmentService.create(data);
      dispatch(ShipmentCreateAction(a));
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "shipment.action :: shipmentCreate",
        true
      );
    }
  };

export const shipmentAddCreated =
  (data: DTOShipmentGet): AppThunk =>
  (dispatch) => {
    dispatch(ShipmentCreateAction(data));
  };

export const shipmentUpdate =
  (aid: Shipment["_id"], data: DTOShipmentUpdate): AppThunk =>
  async (dispatch) => {
    try {
      const a = await ShipmentService.update(aid, data);
      dispatch(ShipmentUpdateAction(a));
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "shipment.action :: shipmentUpdate",
        true
      );
    }
  };

export const shipmentDelete =
  (aid: Shipment["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await ShipmentService.remove(aid);
      dispatch(ShipmentDeleteAction(aid));
      logger.success("Shipment deleted", "shipmentDelete", true, { aid });
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "shipment.action :: shipmentDelete",
        true
      );
    }
  };

export const shipmentClear = (): AppThunk => (dispatch) => {
  dispatch(ShipmentClearAction());
};
