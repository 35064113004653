import { ShipmentActionTypes, ShipmentState, SHIPMENT_CLEAR, SHIPMENT_CREATE, SHIPMENT_DELETE, SHIPMENT_GET_ALL, SHIPMENT_GET_MINE, SHIPMENT_UPDATE } from "./shipment.types";


const initialState: ShipmentState = {
    mine: [],
    all: [],
    loading: true,
}

const shipmentReducer = function (
    state: ShipmentState = initialState,
    action: ShipmentActionTypes
): ShipmentState {
    switch (action.type) {
        case SHIPMENT_GET_MINE:
            return { ...state, mine: action.payload, loading: false };
          case SHIPMENT_GET_ALL:
            return { ...state, all: action.payload, loading: false };
          case SHIPMENT_CREATE:
            return {
              ...state,
              mine: [...state.mine, action.payload],
              all: [...state.all, action.payload],
              loading: false,
            };
          case SHIPMENT_UPDATE:
            const p = action.payload;
            return {
              ...state,
              mine: state.mine.map((t) => (t._id === p._id ? p : t)),
              all: state.all.map((t) => (t._id === p._id ? p : t)),
              loading: false,
            };
          case SHIPMENT_DELETE:
            return {
              ...state,
              mine: state.mine.filter((t) => t._id !== action.payload),
              all: state.all.filter((t) => t._id !== action.payload),
              loading: false,
            };
          case SHIPMENT_CLEAR:
            return { ...state, mine: [], all: [] };
          default:
            return state;
      
    }
}

export default shipmentReducer;