import React, { useState } from "react";
import Icon from "./Icon";
import { v4 as uuid } from "uuid";

interface Props {
  className?: string;
  checked: boolean;
  onClick: () => void;
  label: string;
}

const Radio = ({ className, checked, label, onClick }: Props) => {
  const [id] = useState<string>(uuid());

  return (
    <div
      onClick={onClick}
      className={`radio${className ? " " + className : ""}`}
    >
      <input
        type="radio"
        checked={checked}
        onClick={onClick}
        id={id}
        name="payment_method"
      />
      <label className="ml-1" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Radio;
