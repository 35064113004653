import api from "../../utils/api.instance";
import { GIsArray, IVolley, ITeam, ITournament, IUser } from "../api.types";
import {
  DTOVolleyGet,
  DTOVolleyUpdate,
  GIsDTOVolleyGet,
} from "../dtos/volley.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<IVolley[]> => {
  const a = (await api.get("volleys/mine")).data;
  return GIsArray<DTOVolleyGet>(a).map((ad) => GIsDTOVolleyGet(ad));
};

const getAll = () => Service.run(_getAll, {}, "VOLLEY_GET_ALL");

const _getAll = async (): Promise<IVolley[]> => {
  const a = (await api.get("volleys")).data;
  return GIsArray<DTOVolleyGet>(a).map((ad) => GIsDTOVolleyGet(ad));
};

const create = async (tournament: ITournament["_id"]): Promise<IVolley> => {
  const a = (await api.post(`volleys`, [tournament])).data;
  return GIsDTOVolleyGet(a[0]);
};

const createAdmin = async (
  tournament: ITournament["_id"],
  user: IUser["_id"][]
): Promise<IVolley> => {
  const a = (await api.post(`volleys/admin/${user}`, [tournament])).data;
  return GIsDTOVolleyGet(a[0]);
};

const update = async (
  volley_id: IVolley["_id"],
  data: DTOVolleyUpdate
): Promise<DTOVolleyGet> => {
  const a = (await api.put(`volleys/update/${volley_id}`, data)).data;
  return GIsDTOVolleyGet(a);
};

const toggleDay = async (
  volley_id: IVolley["_id"],
  day: number
): Promise<DTOVolleyGet> => {
  const a = (await api.put(`volleys/toggle/${volley_id}/day/${day}`)).data;
  return GIsDTOVolleyGet(a);
};

const remove = async (volley_id: IVolley["_id"]): Promise<boolean> => {
  await api.delete(`volleys/${volley_id}`);
  return true;
};

const VolleyService = {
  getMine,
  getAll,
  create,
  createAdmin,
  update,
  toggleDay,
  remove,
};

export default VolleyService;
