import firebase from "firebase";
import mixpanel from "mixpanel-browser";
import { Address } from "../store/address/address.types";
import logger from "../utils/logger";
import { AnalyticsEventType } from "./analytics.types";
import { IUser } from "./api.types";
import { DTOTeamCreate } from "./dtos/team.dto";

let analytics: firebase.analytics.Analytics;

export const AnalyticsInitialise = () => {
  // Initial analytics setups
  // Google Analytics (GA)
  analytics = firebase.analytics();
  // We use both mixpanel and GA for now, so we can see which is nicer to use
  if (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("test.firstaustralia.systems")
  )
    mixpanel.init("f027d59dfab15ea91bb4844bfcd01e4e", {
      cross_site_cookie: true,
      secure_cookie: true,
      debug: true, // turn off for production
    });
  // Dev project
  else
    mixpanel.init("28b400207c0408d85772739c632b241f", {
      cross_site_cookie: true,
      secure_cookie: true,
    }); // Prod project
};

export const AnalyticsTrack = (evt: AnalyticsEventType) => {
  // Mixpanel
  try {
    mixpanel.track(evt.name, { ...evt.data });
  } catch (error: any) {
    logger.debug(
      "Failed to log event",
      "analytics :: AnalyticsTrack // MP",
      false,
      {
        evt,
      }
    );
  }
};

/** On login, identify the user */
export const AnalyticsIdentifyUser = (u: IUser) => {
  try {
    mixpanel.identify(u._id);
    mixpanel.people.set({
      $email: u.email,
      signon_providers: u.providers,
      user_roles: u.roles,
    });
  } catch (error: any) {
    logger.debug(error, "Mixpanel identify!", false);
  }
};

export const AnalyticsReset = () => {
  mixpanel.reset();
};
