import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FIRSTProgram } from "../../common/backend.types";
import { addressGetMine } from "../../store/address/address.action";
import { Address } from "../../store/address/address.types";
import { RootState } from "../../store/root-reducer";
import { teamUpdate } from "../../store/teams/team.action";
import { selectMyTeams } from "../../store/teams/team.selector";
import { Team, TeamUpdateData } from "../../store/teams/team.types";
import logger from "../../utils/logger";
import Button from "../atomic/Button";
import CheckBox from "../atomic/CheckBox";
import { SingleSelector } from "../atomic/FaSelectors";
import Selector from "../atomic/Selector";
import Spinner from "../atomic/Spinner";
import ChangeCoachesForm from "./ChangeCoachesForm";

const EditTeamForm = ({
  team,
  addresses,
  loading,
  teams,
  onCancel,
  onSubmit,
  addressGetMine,
  teamUpdate,
}: Props) => {
  useEffect(() => {
    addressGetMine();
  }, [addressGetMine]);

  const [data, setData] = useState<TeamUpdateData>({
    affiliation: team.affiliation,
    rookie_year: team.rookie_year,
    ship_kit: team.ship_kit,
    shipping_address: team.shipping_address,
    team_name: team.team_name,
    team_number: team.team_number,
  });

  const handleSubmit = async () => {
    const success = await teamUpdate(team._id, data);
    if (success) {
      onSubmit();
      logger.success("Updated!", "handleSubmit", true);
    }
  };

  return loading ? (
    <Spinner variant="coach" />
  ) : (
    <div id="editTeamView">
      <h1>
        Edit {team.team_name} {team.team_number}
      </h1>
      <form className="form" onSubmit={handleSubmit}>
        <label>Team Number:</label>
        {team.program === FIRSTProgram.FTC ? (
          <input
            type="number"
            min={1}
            placeholder={"Team number"}
            value={data.team_number}
            onChange={(e) => {
              e.preventDefault();
              setData({ ...data, affiliation: e.target.value });
            }}
          />
        ) : (
          <span className="text-large">{team.team_number}</span>
        )}

        <label>Team Name:</label>
        <input
          type="text"
          className="w-100"
          placeholder="Team name; must be unique within the program"
          value={data.team_name}
          onChange={(e) => {
            e.preventDefault();
            setData({ ...data, team_name: e.target.value });
          }}
        />
        <label>Team Affiliation:</label>
        <input
          type="text"
          className="w-100"
          placeholder="Name of school, ''Community team'', etc"
          value={data.affiliation}
          onChange={(e) => {
            e.preventDefault();
            setData({ ...data, affiliation: e.target.value });
          }}
        />
        <label>Rookie Year:</label>
        <input
          type="number"
          min={1998}
          max={new Date().getFullYear()}
          placeholder={"Rookie Year"}
          value={data.rookie_year}
          onChange={(e) => {
            e.preventDefault();
            setData({ ...data, rookie_year: Number.parseInt(e.target.value) });
          }}
        />
        {team.program === FIRSTProgram.FLL_CHALLENGE && (
          <Fragment>
            <label>Ship kit?</label>
            <CheckBox
              className="m-auto"
              checked={data.ship_kit === undefined ? false : data.ship_kit}
              toggle={() => {
                const nKits = teams.filter(
                  (t) => t.program === FIRSTProgram.FLL_CHALLENGE && t.ship_kit
                ).length;
                const nNoKits = teams.filter(
                  (t) => t.program === FIRSTProgram.FLL_CHALLENGE && !t.ship_kit
                ).length;
                if (
                  team.program === FIRSTProgram.FLL_CHALLENGE &&
                  data.ship_kit &&
                  nNoKits - nKits >= -1
                )
                  alert("At most two Challenge teams may share a kit");
                else setData({ ...data, ship_kit: !data.ship_kit });
              }}
            />
          </Fragment>
        )}
        <label className="d-flex">Ship to:</label>
        {loading ? (
          <Spinner variant="primary" />
        ) : (
          <SingleSelector<Address>
            value={addresses.find((a) => a._id === data.shipping_address)}
            options={addresses.map((a) => ({ value: a, label: a.label }))}
            onSelect={(v) =>
              v ? setData({ ...data, shipping_address: v._id }) : {}
            }
          />
        )}
        <div className="col-span-2">
          <ChangeCoachesForm team={team} />
        </div>
      </form>
      <div className="footer">
        <Button variant="success" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="light" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  addresses: state.address.mine,
  teams: selectMyTeams(state),
  loading: state.address.loading || state.team.loading,
});

const connector = connect(mapStateToProps, { addressGetMine, teamUpdate });
type Props = ConnectedProps<typeof connector> & {
  team: Team;
  onCancel: () => void;
  onSubmit: () => void;
};

export default connector(EditTeamForm);
