import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { tournamentGetMine } from "../../../store/tournament/tournament.action";
import { selectMyTournaments } from "../../../store/tournament/tournament.selector";
import { Tournament } from "../../../store/tournament/tournament.types";
import { SingleSelector } from "../../atomic/FaSelectors";
import Selector from "../../atomic/Selector";
import Spinner from "../../atomic/Spinner";
import TournamentReport from "../../reports/TournamentReport";

const DirectorTab = ({ tournaments, loading, tournamentGetMine }: Props) => {
  const [tourn, setTourn] = useState<Tournament>();

  useEffect(() => {
    tournamentGetMine();
  }, []);

  useEffect(() => {
    if (!tourn && tournaments[0]) setTourn(tournaments[0]);
  }, [tourn, tournaments]);

  return loading ? (
    <Spinner variant="director" />
  ) : tournaments.length === 0 ? (
    <span>
      No tournaments found? Please contact{" "}
      <a href="mailto:fred@firstaustralia.org">fred@firstaustralia.org</a>
    </span>
  ) : tournaments.length > 1 ? (
    <div>
      <SingleSelector<Tournament>
        value={tourn}
        onSelect={(t) => {
          if (t) setTourn(t);
        }}
        options={tournaments.map((m) => ({ value: m, label: m.name }))}
      />
      {tourn ? (
        <TournamentReport tournament={tourn} />
      ) : (
        <span>Please select a tournament</span>
      )}
    </div>
  ) : (
    <div>
      <h1>{tournaments[0].name}</h1>
      {tourn ? (
        <TournamentReport tournament={tournaments[0]} />
      ) : (
        <span>Something went wrong...</span>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tournaments: selectMyTournaments(state),
  loading: state.tournament.loading,
});

const connector = connect(mapStateToProps, { tournamentGetMine });
type Props = ConnectedProps<typeof connector> & {};

export default connector(DirectorTab);
