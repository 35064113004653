import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import { v4 as uuid } from "uuid";
import Selector from "../atomic/Selector";
import { IUser } from "../../api/api.types";
import {
  userAddVolProfile,
  userUpdateVolProfile,
} from "../../store/users/user.action";
import { AgeRange } from "../../common/backend.types";
import { DTOVolunteerProfileUpdate } from "../../api/dtos/user.dto";
import SubmitButton from "../atomic/SubmitButton";
import Modal from "../atomic/Modal";
import Button from "../atomic/Button";
import VolunteerRoleList from "./VolunteerRoleList";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import logger from "../../utils/logger";
import { SingleSelector } from "../atomic/FaSelectors";

type FormData = DTOVolunteerProfileUpdate;

const VolunteerProfileForm = ({
  user,
  userAddVolProfile,
  userUpdateVolProfile,
}: Props) => {
  const [ids] = useState<[string, string, string, string]>([
    uuid(),
    uuid(),
    uuid(),
    uuid(),
  ]);

  const [showPrefModal, setShowPrefModal] = useState<boolean>(false);
  const [showHistModal, setShowHistModal] = useState<boolean>(false);

  const [formData, setFormData] = useState<FormData>({
    age_range: user.volunteer_profile?.age_range,
    wwcc: user.volunteer_profile?.wwcc,
    emergency_name: user.volunteer_profile?.emergency_name,
    emergency_phone: user.volunteer_profile?.emergency_phone,
    history: user.volunteer_profile?.history ?? [],
    preferences: user.volunteer_profile?.preferences ?? [],
    affiliation: user.volunteer_profile?.affiliation,
    dietary: user.volunteer_profile?.dietary,
  });

  const onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();

    if (user.volunteer_profile) userUpdateVolProfile(user._id, formData);
    else {
      if (!formData.age_range) return alert("Please specify your age range");
      if (!formData.wwcc)
        return alert("Please enter your WWCC number or application number");
      if (!formData.emergency_name)
        return alert("Please specify an emergency contact");
      if (!formData.emergency_phone)
        return alert("Please specify an emergency contact");
      if (!formData.emergency_phone)
        return alert("Please specify your affiliation (company, team, etc)");
      if (formData.preferences?.length === 0)
        return alert("Please specify your tournament preferences");
      if (
        formData.history?.length === 0 &&
        !window.confirm(
          "You didn't provide a volunteer history; are you sure you want to save?"
        )
      )
        return;
      userAddVolProfile(user._id, {
        age_range: formData.age_range!,
        emergency_name: formData.emergency_name!,
        emergency_phone: formData.emergency_phone,
        history: formData.history ?? [],
        preferences: formData.preferences ?? [],
        wwcc: formData.wwcc,
        dietary: formData.dietary,
        affiliation: formData.affiliation,
      });
    }
  };

  return false ? (
    <span>Coming soon!</span>
  ) : (
    <div>
      <form
        className="form volunteer-profile"
        onSubmit={onSubmit}
        autoComplete="off"
      >
        <label className="d-flex" htmlFor={ids[0]}>
          Age range (how old are you?)
        </label>
        <SingleSelector<AgeRange>
          value={formData.age_range}
          options={[
            { label: "< 14", value: "<14" },
            { label: "14-17", value: "14-17" },
            { label: "18-25", value: "18-25" },
            { label: "25+", value: ">25" },
          ]}
          onSelect={(v) => setFormData({ ...formData, age_range: v })}
        />
        <label htmlFor={ids[1]} className="col-span-2">
          Have you been cleared to work with children? (i.e. Working With
          Children Check, Blue Card or Youth Protection Clearance) If so, please
          enter the clearance number issued to you and identify the state or
          federal authority that issued it. If you have not received clearance,
          please enter "NA".{" "}
        </label>
        <div />
        <input
          type="text"
          autoComplete="one-time-code"
          id={ids[1]}
          value={formData.wwcc ?? ""}
          onChange={(e) => {
            e.persist();
            setFormData({ ...formData, wwcc: e.target.value });
          }}
        />
        <span className="col-span-2">Emergency Contact:</span>
        <label className="pl-1" htmlFor={ids[2]}>
          Name
        </label>
        <input
          type="text"
          autoComplete="name"
          id={ids[2]}
          value={formData.emergency_name ?? ""}
          onChange={(e) =>
            setFormData({ ...formData, emergency_name: e.target.value })
          }
        />
        <label className="pl-1" htmlFor={ids[3]}>
          Phone number
        </label>
        <PhoneInput
          autoComplete="tel"
          value={(formData.emergency_phone ?? "") as any}
          onChange={(e: any) =>
            setFormData({ ...formData, emergency_phone: e?.toString() })
          }
          defaultCountry="AU"
        />
        <label className="" htmlFor={ids[2]}>
          Affiliation/s
        </label>
        <input
          type="text"
          autoComplete="name"
          id={ids[2]}
          value={formData.affiliation ?? ""}
          onChange={(e) =>
            setFormData({ ...formData, affiliation: e.target.value })
          }
        />
        <label className="" htmlFor={ids[2]}>
          Dietary Restrictions
        </label>
        <input
          type="text"
          autoComplete="name"
          id={ids[2]}
          value={formData.dietary ?? ""}
          onChange={(e) =>
            setFormData({ ...formData, dietary: e.target.value })
          }
        />

        {/* <input
          type="text"
          id={ids[3]}
          value={formData.emergency_phone}
          placeholder="xxxx-xxx-xxx | xxxx xxxx"
          pattern="^([0-9]{4}[- ]?[0-9]{3}[- ]?[0-9]{3})|([0-9]{4} ?[0-9]{4})$"
          onChange={(e) =>
            setFormData({ ...formData, emergency_phone: e.target.value })
          }
        /> */}
        <label>Preferences</label>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setShowPrefModal(true);
          }}
        >
          Open
        </Button>
        {formData.preferences && formData.preferences.length >= 3 ? (
          <div className="col-span-2" />
        ) : (
          <small className="col-span-2 text-warn">
            You must provide at least 3 preferences
          </small>
        )}
        <label>History</label>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setShowHistModal(true);
          }}
        >
          Open
        </Button>

        <SubmitButton value={user.volunteer_profile ? "Update" : "Create"} />
      </form>
      <Modal open={showPrefModal} onClose={() => setShowPrefModal(false)}>
        <h1>Preferences</h1>
        <span>
          Please select at least your top 3 preferences for each program.
        </span>
        <Button
          variant="light"
          onClick={() => setFormData({ ...formData, preferences: [] })}
        >
          Clear
        </Button>
        {formData.preferences && (
          <VolunteerRoleList
            ordered
            checked={formData.preferences}
            toggleChecked={(p) => {
              formData
                .preferences!.map((p) => JSON.stringify(p))
                .includes(JSON.stringify(p))
                ? setFormData({
                    ...formData,
                    preferences: formData.preferences!.filter(
                      (h) => !(h.program === p.program && h.role === p.role)
                    ),
                  })
                : setFormData({
                    ...formData,
                    preferences: [...formData.preferences!, p],
                  });
            }}
          />
        )}
      </Modal>
      <Modal open={showHistModal} onClose={() => setShowHistModal(false)}>
        <h1>History</h1>
        <span>
          Please select each role you have fulfilled at a tournament, so we know
          what you can do!
        </span>
        <Button
          variant="light"
          onClick={() => setFormData({ ...formData, history: [] })}
        >
          Clear
        </Button>
        {formData.history && (
          <VolunteerRoleList
            checked={formData.history}
            toggleChecked={(p) => {
              formData
                .history!.map((p) => JSON.stringify(p))
                .includes(JSON.stringify(p))
                ? setFormData({
                    ...formData,
                    history: formData.history!.filter(
                      (h) => !(h.program === p.program && h.role === p.role)
                    ),
                  })
                : setFormData({
                    ...formData,
                    history: [...formData.history!, p],
                  });
            }}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, {
  userAddVolProfile,
  userUpdateVolProfile,
});
type Props = ConnectedProps<typeof connector> & { user: IUser };

export default connector(VolunteerProfileForm);
