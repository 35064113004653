import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../api/api.types";
import { RootState } from "../../store/root-reducer";
import {
  tournamentAddDirector,
  tournamentRmDirector,
} from "../../store/tournament/tournament.action";
import { Tournament } from "../../store/tournament/tournament.types";
import Button from "../atomic/Button";
import Icon from "../atomic/Icon";

const ChangeDirectorsForm = ({
  users,
  tournament,
  tournamentAddDirector,
  tournamentRmDirector,
}: Props) => {
  const [email, setEmail] = useState<string>("");

  const [dirs, setDirs] = useState<IUser["_id"][]>(tournament.directors);

  return (
    <div className="d-flex-col">
      <h3 className="mr-auto">Tournament directors</h3>
      <div className="d-flex mr-auto">
        <span className="w-100">Add director:</span>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "enter") return;
          }}
        />
        <Button
          variant="success"
          className="ml-1"
          onClick={async (e) => {
            e.preventDefault();
            if (!email) return;

            const id = await tournamentAddDirector(tournament._id, email);
            if (id) setDirs([...dirs, id]);
            setEmail("");
          }}
        >
          <Icon icon="plus" />
        </Button>
      </div>
      <br />
      {dirs.map((d, i, a) => {
        const u = users.all.find((u) => u._id === d);
        return !u ? (
          <div />
        ) : (
          <div className="d-flex mr-auto ml-1" key={i}>
            <span className="w-100">
              {u.given_name} {u.family_name} ({u.email})
            </span>
            {a.length > 1 && (
              <Button
                variant="danger"
                className="ml-1"
                onClick={async (e) => {
                  e.preventDefault();
                  if (await tournamentRmDirector(tournament._id, u.email))
                    setDirs(dirs.filter((d) => d !== u._id));
                }}
              >
                <Icon icon="times" />
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users,
});

const connector = connect(mapStateToProps, {
  tournamentAddDirector,
  tournamentRmDirector,
});
type Props = ConnectedProps<typeof connector> & {
  tournament: Tournament;
};

export default connector(ChangeDirectorsForm);
