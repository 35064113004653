import React, { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { competeGetAll } from "../../../store/compete/compete.action";
import { RootState } from "../../../store/root-reducer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tournament } from "../../../store/tournament/tournament.types";
import Moment from "react-moment";
import { FIRSTProgram, FIRSTSeason } from "../../../common/backend.types";

type LocalProps = {};

const sanitise = (x: string): string =>
  x
    .replaceAll(" ", "-")
    .replaceAll(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();

const AllRankingsView = ({
  tournamentGetAll,
  tournaments,
  season,
  loading,
}: Props) => {
  const classes = useStyles();
  const tFilter = (t: Tournament): boolean =>
    t.season === season && t.program === FIRSTProgram.FLL_CHALLENGE;

  useEffect(() => {
    tournamentGetAll();
  }, []);

  const getLink = useCallback(
    (tourn: Tournament): string => {
      const base = window.location.origin;
      const replicates = tournaments
        .filter(tFilter)
        .filter((t) => sanitise(t.name) === sanitise(tourn.name));
      return (
        base +
        `/rankings/${replicates.length > 1 ? tourn._id : sanitise(tourn.name)}`
      );
    },
    [tournaments]
  );

  const toLink = (s: string) => <a href={s}>{s}</a>;

  return (
    <div className={classes.allrankingsview}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tournament</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tournaments
            .sort(
              (a, b) =>
                new Date(b.start).getTime() - new Date(a.start).getTime()
            )
            .map((tourn) => (
              <TableRow key={tourn._id}>
                <TableCell>{tourn.name}</TableCell>
                <TableCell>
                  <Moment format="LL">{tourn.start}</Moment>
                </TableCell>
                <TableCell>{toLink(getLink(tourn))}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    allrankingsview: {},
  })
);

const mapStateToProps = (state: RootState) => ({
  tournaments: state.tournament.all,
  loading: state.tournament.loading,
  season: state.settings.season,
});

const connector = connect(mapStateToProps, { tournamentGetAll });

type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(AllRankingsView);
