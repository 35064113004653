import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FaParagraph, FaText } from "../../atomic/FaTexts";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBox from "../../atomic/CheckBox";
import { useState } from "react";
import NotCheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckedIcon from "@material-ui/icons/CheckBox";
import RubricQuestionRow from "./RubricQuestionRow";
import theme from "../../../styles/theme";
import robotdesign from "./labels/robotdesign";
import { QuestionId, RobotQuestion } from "./rubrics";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";

type Props = {
  answers: RubricAnswer[];
  update?: (question_id: QuestionId, score: Score | undefined) => void;
};

type Score = { value?: 1 | 2 | 3 | 4; comment?: string };

type RobotDesignData = {
  [property in RobotQuestion]: Score;
};

const RobotDesignRubric = ({ answers, update }: Props) => {
  const [data, setData] = useState<Partial<RobotDesignData>>({});

  useEffect(() => {
    setData({
      ...data,
      ...answers.reduce(
        (p, a) => ({
          ...p,
          [a.question_id]: { value: a.value, comment: a.comment },
        }),
        {}
      ),
    });
  }, []);

  const updateQuestion = (id: QuestionId, score: Score | undefined) => {
    if (update) update(id, score);
    setData({ ...data, [id]: score });
  };

  const classes = useStyles();
  return (
    <div className={classes.robotdesignrubric}>
      <FaText className={classes.bold}>Instructions</FaText>
      <FaParagraph variant="caption">
        Teams should communicate to the judges their achievement in each of the
        following criteria. This rubric should be filled out during the Robot
        Design explanation.
      </FaParagraph>
      <FaParagraph variant="body2" className={classes.bold}>
        Judges are required to tick one box on each separate line to indicate
        the level the team has achieved. If the team exceeds, please make a
        short comment in the Exceeds box.
      </FaParagraph>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.beginning}>
              <div className={classes.tablehead}>
                <FaText variant="button">Beginning</FaText>
                <FaText variant="caption">
                  Minimal examples observed across the team
                </FaText>
                <FaText variant="button" className={classes.headbottom}>
                  1
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.developing}>
              <div className={classes.tablehead}>
                <FaText variant="button">Developing</FaText>
                <FaText variant="caption">
                  Some examples observed across the team
                </FaText>
                <FaText variant="button" className={classes.headbottom}>
                  2
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.accomplished}>
              <div className={classes.tablehead}>
                <FaText variant="button">Accomplished</FaText>
                <FaText variant="caption">
                  Multiple examples observed across the team
                </FaText>
                <FaText variant="button" className={classes.headbottom}>
                  3
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.exceeds}>
              <div className={classes.tablehead}>
                <FaText variant="button">Exceeds</FaText>
                <FaText variant="button" className={classes.headbottom}>
                  4
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.comment}>
              <FaText variant="caption">Explain how team exceeds:</FaText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RubricQuestionRow
            value={[
              {
                ...data["rd:identify_a"],
                labels: robotdesign.label.identify_a,
              },
              {
                ...data["rd:identify_b"],
                labels: robotdesign.label.identify_b,
              },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v, i) =>
                    updateQuestion(
                      i === 0 ? "rd:identify_a" : "rd:identify_b",
                      v
                    )
            }
            color={theme.palette.robotdesign}
            label="Identify"
            description={robotdesign.desc.identify}
          />
          <RubricQuestionRow
            value={[
              { ...data["rd:design_a"], labels: robotdesign.label.design_a },
              { ...data["rd:design_b"], labels: robotdesign.label.design_b },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v, i) =>
                    updateQuestion(i === 0 ? "rd:design_a" : "rd:design_b", v)
            }
            color={theme.palette.robotdesign}
            label="Design"
            description={robotdesign.desc.design}
          />
          <RubricQuestionRow
            value={[
              { ...data["rd:create_a"], labels: robotdesign.label.create_a },
              { ...data["rd:create_b"], labels: robotdesign.label.create_b },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v, i) =>
                    updateQuestion(i === 0 ? "rd:create_a" : "rd:create_b", v)
            }
            color={theme.palette.robotdesign}
            label="Create"
            description={robotdesign.desc.create}
          />
          <RubricQuestionRow
            value={[
              { ...data["rd:iterate_a"], labels: robotdesign.label.iterate_a },
              { ...data["rd:iterate_b"], labels: robotdesign.label.iterate_b },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v, i) =>
                    updateQuestion(i === 0 ? "rd:iterate_a" : "rd:iterate_b", v)
            }
            color={theme.palette.robotdesign}
            label="Iterate"
            description={robotdesign.desc.iterate}
          />
          <RubricQuestionRow
            value={[
              {
                ...data["rd:communicate_a"],
                labels: robotdesign.label.communicate_a,
              },
              {
                ...data["rd:communicate_b"],
                labels: robotdesign.label.communicate_b,
              },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v, i) =>
                    updateQuestion(
                      i === 0 ? "rd:communicate_a" : "rd:communicate_b",
                      v
                    )
            }
            color={theme.palette.robotdesign}
            label="Communicate"
            description={robotdesign.desc.communicate}
          />
        </TableBody>
      </Table>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    robotdesignrubric: {},
    bold: {
      fontWeight: "bold",
    },
    clickable: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tablehead: {
      display: "flex",
      flexDirection: "column",
    },
    headbottom: {
      marginTop: "auto",
      marginLeft: "auto",
      marginRight: "auto",
    },
    beginning: {
      backgroundColor: theme.palette.robotdesign.light,
    },
    developing: {
      backgroundColor: theme.palette.robotdesign.main,
      // backgroundColor: "#FACFC8"
    },
    accomplished: {
      backgroundColor: "#8DC798",
    },
    exceeds: {
      backgroundColor: theme.palette.robotdesign.dark,
    },
    comment: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    labelrow: {
      columnSpan: "all",
      backgroundColor: theme.palette.robotdesign.main,
    },
  })
);

export default RobotDesignRubric;
