import React, { useState, useCallback, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DTOUserUpdate } from "../../api/dtos/user.dto";
import { updateMyProfile } from "../../store/auth/auth.action";
import { RootState } from "../../store/root-reducer";
import SubmitButton from "../atomic/SubmitButton";
import firebase from "firebase";
import logger from "../../utils/logger";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";

type FormData = DTOUserUpdate;

const BasicDetailsForm = ({
  auth: { user },
  hidePassword = false,
  updateMyProfile,
}: Props) => {
  const [formData, setFormData] = useState<FormData>({
    given_name: user?.given_name,
    family_name: user?.family_name,
    phone: user?.phone,
  });
  const [nPass, setNPass] = useState<string>("");

  const updatePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!nPass) return;
    var user = firebase.auth().currentUser;
    if (!user)
      return logger.warn("Something went wrong!", "updatePassword", true);
    user
      .updatePassword(nPass)
      .then(function () {
        return logger.success("Password updated.", "updatePassword", true);
      })
      .catch(function (error) {
        return logger.warn(
          "Unable to update password: " + error.message,
          "updatePassword",
          true,
          { error }
        );
      });
  };

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData, setFormData]
  );

  const onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    updateMyProfile(formData);
  };

  return (
    <div>
      <form className="form" id="profile-form" onSubmit={onSubmit}>
        <label htmlFor="profile-form-given">Given name:</label>
        <input
          required
          type="text"
          name="given_name"
          id="profile-form-given"
          value={formData.given_name}
          onChange={onChange}
        />
        <label htmlFor="profile-form-family">Family name:</label>
        <input
          required
          type="text"
          name="family_name"
          id="profile-form-family"
          value={formData.family_name}
          onChange={onChange}
        />
        <label htmlFor="profile-form-phone">Phone:</label>
        <PhoneInput
          value={(formData.phone ?? "") as any}
          onChange={(e: any) =>
            setFormData({ ...formData, phone: e?.toString() })
          }
          defaultCountry="AU"
        />
        {/* <input
          type="tel"
          name="phone"
          required
          id="profile-form-phone"
          placeholder="xxxx-xxx-xxx | xxxx xxxx"
          pattern="^([0-9]{4}[- ]?[0-9]{3}[- ]?[0-9]{3})|([0-9]{4} ?[0-9]{4})$"
          value={formData.phone}
          onChange={onChange}
          title="10 digits (mobile) or 8 digits (landline).  No country code required"
        /> */}
        <SubmitButton variant={"success"} value="Save" />
      </form>
      {hidePassword || (
        <Fragment>
          <h2>Update password</h2>
          <form className="form" onSubmit={updatePassword}>
            <label htmlFor="profile-form-pass">Update password:</label>
            <input
              required
              type="password"
              autoComplete="new-password"
              id="profile-form-pass"
              placeholder="Update password"
              value={nPass}
              onChange={(e) => {
                e.preventDefault();
                setNPass(e.target.value);
              }}
            />
            <SubmitButton value="Update" />
          </form>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, { updateMyProfile });
type Props = ConnectedProps<typeof connector> & {
  hidePassword?: boolean;
};

export default connector(BasicDetailsForm);
