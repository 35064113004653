import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router";
import { FIRSTSeason, SeasonLabel, Seasons } from "../../common/backend.types";
import logo from "../../img/FIRST-Regional-Australia-Rev-h.png";
import {
  linkToGoogle,
  linkToMicrosoft,
  loadUser,
  logout,
} from "../../store/auth/auth.action";
import { UserObject } from "../../store/auth/auth.types";
import { RootState } from "../../store/root-reducer";
import { setSeason } from "../../store/settings.slice";
import Dropdown from "../atomic/Dropdown";
import Icon from "../atomic/Icon";
import LocalLink from "../atomic/LocalLink";
import Selector from "../atomic/Selector";

const Navbar = ({
  logout,
  loadUser,
  season,
  setSeason,
  linkToGoogle,
  auth,
  title,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(true);
  const toDisplayName = (u: UserObject) => {
    if (!u) return "?";
    if (u.given_name) return `${u.given_name}`;
    return u.email.split("@")[0];
  };

  const history = useHistory();

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <nav className="navbar">
      <LocalLink to="/" className="navbar-brand">
        <img
          src={logo}
          width="auto"
          height="36px"
          className="mr-1"
          alt="FIRST Australia logo"
        />
      </LocalLink>
      <ul className="navbar-nav-desktop">
        <li className="nav-item dropdown ml-auto">
          <Selector<FIRSTSeason>
            options={Seasons.map((s) => ({ label: SeasonLabel(s), value: s }))}
            value={season}
            onSelect={(s) => {
              if (s) {
                setSeason(s);
              }
            }}
          />
          <div className="ml-1" />
          <Dropdown showCaret right title={"Help"}>
            <LocalLink to="/help" className="dropdown-item  nav-link">
              Help
            </LocalLink>
            <LocalLink to="/faq" className="dropdown-item  nav-link">
              FAQs
            </LocalLink>
            <LocalLink to="/whats-new" className="dropdown-item nav-link">
              What's new?
            </LocalLink>
            <hr className="" />
            <span className="dropdown-item disabled">
              {window.Version.toString()}
            </span>
          </Dropdown>
        </li>
        {auth.user && (
          <Fragment>
            <li className="nav-item dropdown ml-2">
              <Dropdown
                right
                showCaret
                label={
                  auth.user.picture &&
                  visible && (
                    <img
                      onError={() => setVisible(false)}
                      src={auth.user.picture}
                      width="auto"
                      height="100%"
                      className=""
                      alt="user logo"
                    />
                  )
                }
                title={<span className="name">{toDisplayName(auth.user)}</span>}
              >
                <LocalLink to="/profile" className="dropdown-item nav-link">
                  Profile
                </LocalLink>
                <span
                  className="dropdown-item text-danger d-flex"
                  onClick={logout}
                >
                  <Icon icon="sign-out-alt" className="mr-1" /> Sign out
                </span>
              </Dropdown>
            </li>
          </Fragment>
        )}
      </ul>
      <ul className="navbar-nav-mobile">
        <li className="nav-item dropdown ml-auto">
          <Dropdown title={<Icon icon="bars" />}>
            <span className="dropdown-item">Help</span>
            <span className="dropdown-item">FAQs</span>
            <span className="dropdown-item">What's new?</span>
            {auth.user && (
              <Fragment>
                <hr className="my-1" />
                <span className="dropdown-item">My profile</span>
                <span className="dropdown-item text-danger" onClick={logout}>
                  <Icon icon="sign-out-alt" /> Sign out
                </span>
              </Fragment>
            )}
          </Dropdown>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  season: state.settings.season,
});

const connector = connect(mapStateToProps, {
  loadUser,
  logout,
  setSeason,
  linkToGoogle,
  linkToMicrosoft,
});

type Props = ConnectedProps<typeof connector> & {
  title: string;
};

export default connector(Navbar);
