/** API types: */

import { string } from "fp-ts";
import { Award } from "../common/awards";
import {
  Contact,
  StreetAddress,
  FIRSTProgram,
  FIRSTSeason,
  ShipmentContent,
  UserRole,
  State,
  TournamentType,
  Attendance,
  VolunteerProfile,
  VolParticipation,
} from "../common/backend.types";
import { Score } from "../components/views/Scoring/games/types";

/** Anything that comes from MongoDB has these fields */
export type DBObject = { _id: string; timestamp?: Date };

/** A user in the system */
export interface IUser extends DBObject {
  email: string;
  roles: UserRole[];
  given_name?: string;
  family_name?: string;
  phone?: string;
  firebase_id?: string;
  providers?: FirebaseProvider[];
  email_verified: boolean;
  picture?: string;
  timestamp: Date;
  credit: number;
  team_limit: number;
  volunteer_profile?: VolunteerProfile;
  /** @todo */
  tracking_optout?: boolean;
}

/** Record of a single team able to compete in tournaments. */
export interface ITeam extends DBObject {
  timestamp: Date;
  team_name: string;
  team_number: number;
  rookie_year: number;
  program: FIRSTProgram;
  user: IUser["_id"];
  affiliation?: string;
  coaches: IUser["_id"][];
  season: FIRSTSeason;
  ship_kit: boolean;
  shipping_address: string;
  // invoice_address: string;
  // credit_card?: boolean;
  post_registered: boolean;
  deleted: boolean;
  dummy: boolean;
}

/** Record of a single shipment made by FIRST Australia */
export interface IShipment extends DBObject {
  address: IAddress["_id"];
  address_label: string;
  contents: ShipmentContent[];
  receiver: IUser["_id"];
  shipper: IUser["_id"];
  consignment_number: string;
  comment: string;
}

export interface IPayment extends DBObject {
  address: IBillingAddress["_id"];
  created_by: IUser["_id"];
  payer: IUser["_id"];
  reference: number;
  invoice_number?: string;
  credit_card: boolean;
  pdf_url?: string;
  pdf_verified?: boolean;
  admin_comment?: string;
  thumbnail?: string;
  payment_id?: string;
  amount: number;
  comment: string;
  timestamp: Date;
}

export interface IAddress extends DBObject, Contact, StreetAddress {
  user: IUser["_id"];
  label: string;
  company: string;
  abn?: string;
}

export interface IBillingAddress extends IAddress {
  abn: string;
}

export interface ITournament extends DBObject {
  // Basic info
  name: string;
  program: FIRSTProgram;
  season: FIRSTSeason;
  /** What date/time does the event start? */
  start: Date;
  /** How many days does the event run for? */
  n_days: number;
  /** Event type */
  type: TournamentType;
  /** To which event does this qualify you for? */
  next_tourn?: ITournament["_id"];
  /** How many teams are allowed in this event*/
  cap: number;

  // Location
  remote: boolean;
  company: string;
  street1: string;
  street2?: string;
  street3?: string;
  postcode: string;
  suburb: string;
  state: State;

  // Managers
  /** Almost full edit access - lock, raise cap, etc */
  directors: IUser["_id"][];
  /** Can only edit volunteers */
  volunteer_coordinators: IUser["_id"][];

  // Status
  /** No more changes - documents are printed! */
  locked: boolean;
  vol_locked: boolean;
  judge_locked: boolean;
  ship_address: IAddress["_id"];
  /** How many kits do they need shipped? */
  ship_kits: number;
  dummy?: boolean;
  /** Have judging rubrics been released? */
  released?: boolean;
}

export interface ICompete extends DBObject {
  team_id: ITeam["_id"];
  tournament_id: ITournament["_id"];
  attendance: Attendance;
  awards: Award[];
  judgePod?: string;
}
export interface IVolley extends DBObject {
  user_id: IUser["_id"];
  tournament_id: ITournament["_id"];
  attendance: Attendance;
  days: boolean[];
  job?: VolParticipation;
}

export interface IEvent extends DBObject {
  title: string;
  date: Date;
  program?: FIRSTProgram;
}

export type ScoreAnswer = { id: string; answer: string };

export interface IScoresheet extends DBObject {
  compete_id: string;
  user_id?: string;
  timestamp: Date;
  round: number;
  answers: ScoreAnswer[];
  public_comment: string;
  private_comment: string;
}

export interface IRubricAnswer extends DBObject {
  compete_id: string;
  user_id: string;
  timestamp: Date;
  question_id: string;
  value: number;
  comment: string;
}

/** Generic types */

/** The interface of all errors returned by API calls */
export interface ApiError extends Error {
  status: number;
  statusText: string;
  message: string;
  errors?: string[];
}

/** ApiError type guard */
export function isApiError(e: any): e is ApiError {
  return (
    e &&
    e.status &&
    typeof e.status === "number" &&
    e.statusText &&
    typeof e.statusText === "string" &&
    e.message &&
    typeof e.message === "string"
  );
}

export type FirebaseProvider =
  //   | "anonymous"
  | "password"
  //   | "facebook.com"
  //   | "github.com"
  //   | "twitter.com"
  //   | "custom"
  | "google.com";

// =================== Guard function templates =================== //

/**
 * Guard functions can be used to make sure external objects are in the right format
 *
 * Guard functions should only be implemented for incoming DTOs - those generated in the frontend should benefit from compile time type checking
 *
 * Make sure to throw a TypeError if the object isn't valid.
 *
 * @argument arg Object of unknown format
 * @throws a TypeError if the input cannot be a "T"
 * @returns arg cast as a "T"
 */

export interface IGuard<T> {
  (arg: any): T;
}

/**
 *  Basic guard function
 *  @argument x Any object to be checked
 *  @throws TypeError if x is undefined
 */
export const GDef: IGuard<any> = (x) => {
  if (x === undefined)
    throw new TypeError("Guarded variable cannot be undefined");
  return x;
};

/**
 *  Basic guard function which checks that all the provided fields are present
 *  @argument x Any object to be checked
 *  @argument fields All the fields you want checked
 *  @throws TypeError if any of the given fields are undefined
 *  @returns x as the specified type
 */
export function GCheckFields<T>(x: any, fields: (keyof T)[]): T {
  const errors = fields
    .map((f) => ((x as T)[f] === undefined ? f : ""))
    .filter((f) => f !== "");
  if (errors.length > 0)
    throw new TypeError(
      `Expected fields: ${errors.join(",")} in ${JSON.stringify(x)}`
    );
  return x as T;
}

export function GIsArray<T>(x: any): T[] {
  if (!Array.isArray(x))
    throw new TypeError(`Invalid type, expected array: ${JSON.stringify(x)}`);
  return x as T[];
}

export function GIsString(x: any): string {
  if (typeof x !== "string")
    throw new TypeError(`Invalid type, expected string: ${JSON.stringify(x)}`);
  return x as string;
}
