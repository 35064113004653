/** Wrapper components for Button elements,
 *  to make it easier to import and customise */

import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { createStyles, fade, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

type CustomColor = "danger" | "success";
type CustomColorUp = "Danger" | "Success";

export type FaButtonColor = ButtonProps["color"] | CustomColor;

interface BaseProps extends Omit<ButtonProps, "color"> {
  color?: FaButtonColor;
  tooltip?: string;
}

/**
 * Type check if the color is one of our own defined types or
 * one of the predefined types as part of material
 * @param color Color to be type checked
 * @returns True if color is one of the ButtonProps colors
 */
const isColor = (color: string | undefined): color is ButtonProps["color"] => {
  return (
    color === "inherit" ||
    color === "primary" ||
    color === "secondary" ||
    color === "default"
  );
};

type Variant = Exclude<ButtonProps["variant"], undefined>;
const toClassName = (
  color: CustomColor,
  variant: Variant = "text"
): `${Variant}${CustomColorUp}` => {
  switch (color) {
    case "danger":
      return (variant + "Danger") as `${Variant}${CustomColorUp}`;
    case "success":
      return (variant + "Success") as `${Variant}${CustomColorUp}`;
  }
};

// TODO: Declare forward refs for tooltips https://material-ui.com/components/tooltips/#custom-child-element
export const FaButton = ({
  color,
  tooltip,
  className,
  ...props
}: BaseProps) => {
  const classes = useStyles();
  const btn = (
    <Button
      color={isColor(color) ? color : undefined}
      className={
        !isColor(color)
          ? [classes[toClassName(color, props.variant)], className].join(" ")
          : className
      }
      {...props}
    />
  );

  return tooltip ? <Tooltip title={tooltip}>{btn}</Tooltip> : btn;
};

interface InternalLinkProps extends BaseProps {
  to: RouterLinkProps["to"];
  replace?: RouterLinkProps["replace"];
}

export const FaButtonLink = ({
  to,
  tooltip,
  replace = false,
  ...rest
}: InternalLinkProps) => {
  const btn = (
    <Button component={RouterLink} {...({ to } as any)} {...rest}>
      {rest.children}
    </Button>
  );

  return tooltip ? <Tooltip title={tooltip}>{btn}</Tooltip> : btn;
};

interface ExternalLinkProps extends BaseProps {
  to: string;
  replace?: boolean;
  download?: string;
}

export const FaButtonExtLink = ({
  to,
  replace = false,
  download,
  tooltip,
  ...rest
}: ExternalLinkProps) => {
  const btn = (
    <Button
      component={(props: {
        href: string;
        target?: string;
        download?: string;
        children: typeof rest.children;
      }) => <a {...props} />}
      {...({
        href: to,
        download,
        target: replace ? undefined : "_blank",
      } as any)}
      {...rest}
    >
      {rest.children}
    </Button>
  );
  return tooltip ? <Tooltip title={tooltip}>{btn}</Tooltip> : btn;
};

export const FaSubmitButton = ({
  children,
  color,
  className,
  tooltip,
  ...rest
}: BaseProps) => {
  const classes = useStyles();
  const btn = (
    <Button
      type="submit"
      color={isColor(color) ? color : undefined}
      className={
        !isColor(color)
          ? [classes[toClassName(color, rest.variant)], className].join(" ")
          : className
      }
      {...rest}
    >
      {children}
    </Button>
  );
  return tooltip ? <Tooltip title={tooltip}>{btn}</Tooltip> : btn;
};

interface IconButtonProps extends BaseProps {
  rounded?: boolean;
}

export const FaIconButton = ({
  rounded = false,
  className = "",
  color,
  tooltip,
  ...rest
}: IconButtonProps) => {
  const classes = useStyles();
  const btn = (
    <Button
      color={isColor(color) ? color : undefined}
      className={[
        className,
        rounded ? classes.roundIcon : classes.icon,
        !isColor(color) ? classes[toClassName(color, rest.variant)] : "",
      ].join(" ")}
      {...rest}
    />
  );
  return tooltip ? <Tooltip title={tooltip}>{btn}</Tooltip> : btn;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    Fabuttons: {},
    icon: {
      minWidth: 0,
      padding: 4,
    },
    roundIcon: {
      minWidth: 0,
      padding: 4,
      borderRadius: 20,
    },
    textDanger: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.04),
      },
    },
    containedDanger: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    outlinedDanger: {
      borderColor: fade(theme.palette.error.main, 0.5),
      borderWidth: 1,
      borderStyle: "solid",
      color: theme.palette.error.main,
      "&:hover": {
        borderColor: theme.palette.error.main,
      },
    },

    textSuccess: {
      color: theme.palette.success.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.success.main, 0.04),
      },
    },
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    outlinedSuccess: {
      borderColor: fade(theme.palette.success.main, 0.5),
      borderWidth: 1,
      borderStyle: "solid",
      color: theme.palette.success.main,
      "&:hover": {
        borderColor: theme.palette.success.main,
      },
    },
  })
);
