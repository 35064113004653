import { ICompete, ITeam, ITournament } from "../../api/api.types";
import { DTOCompeteUpdate } from "../../api/dtos/compete.dto";
import { Award } from "../../common/awards";
import { Attendance } from "../../common/backend.types";

/** Basic types */

export type Compete = ICompete & {};

export type EnrichedCompete = Compete & {
  team: ITeam;
  tournament: ITournament;
  attendance: Attendance;
  awards: Award[];
};

export type CompeteState = {
  all: Compete[];
  loading: boolean;
};

/** Action definitions */
export const COMPETE_GET_ALL = "COMPETE_GET_ALL";
export const COMPETE_CREATE = "COMPETE_CREATE";
export const COMPETE_UPDATE = "COMPETE_UPDATE";
export const COMPETE_DELETE = "COMPETE_DELETE";

/** Action types */
export interface CompeteGetAllActionType {
  type: typeof COMPETE_GET_ALL;
  payload: Compete[];
}

export interface CompeteUpdateActionType {
  type: typeof COMPETE_UPDATE;
  payload: {
    id: Compete["_id"];
    data: Compete;
  };
}

export interface CompeteCreateActionType {
  type: typeof COMPETE_CREATE;
  payload: Compete[];
}

export interface CompeteDeleteActionType {
  type: typeof COMPETE_DELETE;
  payload: Compete["_id"];
}

/** Final export */
export type CompeteActionTypes =
  | CompeteGetAllActionType
  | CompeteCreateActionType
  | CompeteUpdateActionType
  | CompeteDeleteActionType;
