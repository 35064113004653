import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

interface Props {
  /** Positive comment */
  cpos: string;
  /** Constructive comment */
  ccon: string;
  /** Private comment */
  cprv: string;
  /** Script comment */
  cscr: string;

  onChange: (
    question_id:
      | "gn:positive_comment"
      | "gn:negative_comment"
      | "gn:private_comment"
      | "gn:award_script",
    value: string
  ) => void;
}

const RubricCommentSection = ({ cpos, ccon, cprv, cscr, onChange }: Props) => {
  const classes = useStyles();
  const [commentPos, setCommentPos] = useState<string>("");
  const [commentConst, setCommentConst] = useState<string>("");
  const [commentPriv, setCommentPriv] = useState<string>("");
  const [commentScript, setCommentScript] = useState<string>("");

  useEffect(() => {
    setCommentPos(cpos);
  }, [cpos]);

  useEffect(() => {
    setCommentConst(ccon);
  }, [ccon]);

  useEffect(() => {
    setCommentPriv(cprv);
  }, [cprv]);

  useEffect(() => {
    setCommentScript(cscr);
  }, [cscr]);

  return (
    <div className={classes.rubriccommentsection}>
      <TextField
        className={classes.commentBox}
        multiline
        maxRows={4}
        label={'"Great job" comments'}
        value={commentPos}
        onChange={(e) => setCommentPos(e.target.value)}
        onBlur={() => onChange("gn:positive_comment", commentPos)}
      />
      <TextField
        className={classes.commentBox}
        multiline
        maxRows={4}
        label={'"Think about" comments'}
        value={commentConst}
        onChange={(e) => setCommentConst(e.target.value)}
        onBlur={() => onChange("gn:negative_comment", commentConst)}
      />
      <TextField
        className={classes.commentBox}
        multiline
        maxRows={4}
        label={"Judge-only private comments"}
        value={commentPriv}
        onChange={(e) => setCommentPriv(e.target.value)}
        onBlur={() => onChange("gn:private_comment", commentPriv)}
      />
      <TextField
        className={classes.commentBox}
        multiline
        maxRows={4}
        label={"Award script"}
        value={commentScript}
        onChange={(e) => setCommentScript(e.target.value)}
        onBlur={() => onChange("gn:award_script", commentScript)}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    rubriccommentsection: {
      display: "flex",
      flexDirection: "column",
    },
    commentBox: {
      marginTop: theme.spacing(2),
    },
  })
);

export default RubricCommentSection;
