import {
  RubricAnswer,
  RubricAnswerActionTypes,
  RubricState,
  RUBRICS_CREATE,
  RUBRIC_CREATE,
  RUBRIC_DELETE,
  RUBRIC_GET_ALL,
  RUBRIC_GET_MINE,
  RUBRIC_UPDATE,
} from "./rubric.types";

const initialState: RubricState = {
  all: [],
  mine: [],
  loading: true,
};

const rubricReducer = function (
  state: RubricState = initialState,
  action: RubricAnswerActionTypes
): RubricState {
  switch (action.type) {
    case RUBRIC_GET_ALL:
      return {
        ...state,
        all: action.payload
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };
    case RUBRIC_GET_MINE:
      return {
        ...state,
        mine: action.payload
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };

    case RUBRIC_CREATE:
      return {
        ...state,
        all: [...state.all, action.payload]
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        // @todo change this to allow admins to create non-mine ones
        mine: [...state.mine, action.payload]
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };
    case RUBRICS_CREATE:
      return {
        ...state,
        all: [...state.all, ...action.payload]
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        // @todo change this to allow admins to create non-mine ones
        mine: [...state.mine, ...action.payload]
          .filter((x, i, a) => a.indexOf(x) === i)
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          ),
        loading: false,
      };
    case RUBRIC_DELETE:
      return {
        ...state,
        all: state.all.filter((t) => t._id !== action.payload),
        mine: state.mine.filter((t) => t._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};

export default rubricReducer;
