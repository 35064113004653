import { combineReducers } from "redux";
import addressReducer from "./address/address.reducer";
import adminReducer from "./admin/admin.reducer";
import alertReducer from "./alert/alert.reducer";
import authReducer from "./auth/auth.reducer";
import competeReducer from "./compete/compete.reducer";
import paymentReducer from "./payment/payment.reducer";
import rubricReducer from "./rubrics/rubric.reducer";
import scoresheetReducer from "./scoring/scoring.reducer";
import settingsReducer from "./settings.slice";
import shipmentReducer from "./shipping/shipment.reducer";
import teamReducer from "./teams/team.reducer";
import tournamentReducer from "./tournament/tournament.reducer";
import userReducer from "./users/user.reducer";
import volleyReducer from "./volley/volley.reducer";

export const rootReducer = combineReducers({
  admin: adminReducer,
  alert: alertReducer,
  auth: authReducer,
  users: userReducer,
  address: addressReducer,
  team: teamReducer,
  payment: paymentReducer,
  shipment: shipmentReducer,
  scoring: scoresheetReducer,
  rubrics: rubricReducer,
  tournament: tournamentReducer,
  compete: competeReducer,
  volley: volleyReducer,
  settings: settingsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
