import { UserRole, VolunteerProfile } from "../../common/backend.types";
import { Tournament } from "../../store/tournament/tournament.types";
import api from "../../utils/api.instance";
import { GIsArray, IUser } from "../api.types";
import {
  DTOUserCheck,
  DTOUserGet,
  DTOUserUpdate,
  DTOVolunteerProfileUpdate,
  GIsDTOUserGet,
  GIsDTOVolunteerProfileGet,
} from "../dtos/user.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMe = async (): Promise<IUser> => {
  const d = GIsDTOUserGet((await api.get("auth")).data);
  return d as IUser;
};

const getAll = () => Service.run(_getAll, {}, "USER_GET_ALL");

const _getAll = async (): Promise<IUser[]> => {
  const r = (await api.get("user/all")).data;
  return GIsArray<DTOUserGet>(r).map((u) => GIsDTOUserGet(u));
};

const getTournament = async (
  tournament_id: Tournament["_id"]
): Promise<IUser[]> => {
  const r = (await api.get(`user/tournament/${tournament_id}`)).data;
  return GIsArray<DTOUserGet>(r).map((u) => GIsDTOUserGet(u));
};

const checkEmail = async (email: string): Promise<DTOUserCheck[]> => {
  return (await api.get(`user/email/check/${encodeURIComponent(email)}`)).data;
};

const update = async (
  uid: IUser["_id"],
  data: DTOUserUpdate
): Promise<IUser> => {
  return GIsDTOUserGet((await api.put(`user/${uid}`, data)).data);
};

const addRole = async (uid: IUser["_id"], role: UserRole): Promise<IUser> => {
  return GIsDTOUserGet((await api.put(`user/${uid}/role/${role}`)).data);
};

const rmRole = async (uid: IUser["_id"], role: UserRole): Promise<IUser> => {
  return GIsDTOUserGet((await api.delete(`user/${uid}/role/${role}`)).data);
};

const addVolunteerProfile = async (
  uid: IUser["_id"],
  data: VolunteerProfile
): Promise<IUser> => {
  return GIsDTOUserGet(
    (await api.post(`user/${uid}/volunteer-profile`, data)).data
  );
};

const updateVolunteerProfile = async (
  uid: IUser["_id"],
  data: DTOVolunteerProfileUpdate
): Promise<IUser> => {
  return GIsDTOUserGet(
    (await api.put(`user/${uid}/volunteer-profile`, data)).data
  );
};

const UserService = {
  getMe,
  getAll,
  getTournament,
  checkEmail,
  update,
  addRole,
  rmRole,
  addVolunteerProfile,
  updateVolunteerProfile,
};

export default UserService;
