import { AnalyticsIdentifyUser, AnalyticsReset } from "../../api/analytics";
import { IUser } from "../../api/api.types";
import { DTOUserUpdate } from "../../api/dtos/user.dto";
import UserService from "../../api/services/user.service";
import { FIRSTSeason } from "../../common/backend.types";
import firebase, {
  auth,
  googleProvider,
  microsoftProvider,
} from "../../firebase";
import api, { setAuthToken } from "../../utils/api.instance";
import logger from "../../utils/logger";
import { ADDRESS_CLEAR } from "../address/address.types";
import { AppThunk } from "../app-thunk";
import { Payment, PAYMENT_CLEAR } from "../payment/payment.types";
import { SHIPMENT_CLEAR } from "../shipping/shipment.types";
import { TEAM_CLEAR } from "../teams/team.types";
import { TOURNAMENT_CLEAR } from "../tournament/tournament.types";
import { getUserCosts, getUserPayments } from "../users/user.action";
import { USERS_CLEAR } from "../users/user.types";
import {
  AuthActionTypes,
  AUTH_ERROR,
  LOGOUT,
  SET_EMAIL_SENT,
  UPDATE_USER,
  USER_LOADED,
} from "./auth.types";

/**
 * Action types
 */

// This extra level of abstraction adds a bunch of extra code BUT also strong typing
export const LoadUserAction = (user: IUser, token: string): AuthActionTypes => {
  const { given_name, family_name, phone } = user;
  AnalyticsIdentifyUser(user);
  const data = {
    ...user,
    given_name: given_name ? given_name : "",
    family_name: family_name ? family_name : "",
    phone: phone ? phone : "",
  };
  return {
    type: USER_LOADED,
    payload: { user: data, token },
  };
};

export const SetUserSentAction = (sent: boolean): AuthActionTypes => {
  return {
    type: SET_EMAIL_SENT,
    payload: sent,
  };
};

export const UpdateUserAction = (user: IUser): AuthActionTypes => {
  const { given_name, family_name, phone } = user;
  const data = {
    ...user,
    given_name: given_name ? given_name : "",
    family_name: family_name ? family_name : "",
    phone: phone ? phone : "",
  };
  return {
    type: UPDATE_USER,
    payload: data,
  };
};

const AuthErrorAction = (e: Error): AuthActionTypes => {
  return {
    type: AUTH_ERROR,
    payload: e,
  };
};

const LogoutAction = (): AuthActionTypes => {
  return {
    type: LOGOUT,
    payload: "",
  };
};

/**
 * Actions
 */

export const loginWithGoogle = (): AppThunk => async (dispatch) => {
  logger.trace("Logging in...", "auth.action.ts :: loginWithGoogle");
  try {
    const res = await auth.signInWithPopup(googleProvider);
    // console.log(res);
    // Now fetch the user object from the backend
    dispatch(loadUser());
  } catch (error: any) {
    // TODO handle better
    logger.warn(
      error.response ? error.response.data.message : error.message,
      "auth.action.ts :: loginWithGoogle",
      true,
      {
        error,
      }
    );
    dispatch(AuthErrorAction(error));
  }
};

export const loginWithMicrosoft = (): AppThunk => async (dispatch) => {
  logger.trace("Logging in...", "auth.action.ts :: loginWithMicrosoft");
  try {
    const res = await auth.signInWithPopup(microsoftProvider);
    // console.log(res);
    // Now fetch the user object from the backend
    dispatch(loadUser());
  } catch (error: any) {
    logger.info(error.message, "auth.action.ts :: loginWithMicrosoft", true, {
      error,
    });
    dispatch(AuthErrorAction(error));
  }
};

export const linkToMicrosoft = (): AppThunk => async (dispatch) => {
  logger.trace("Linking to Microsoft", "auth.action.ts :: loginWithMicrosoft");
  try {
    const u = auth.currentUser;
    if (u) {
      const res = await u.linkWithPopup(microsoftProvider);
      logger.success(res, "auth.action.ts :: loginWithMicrosoft");
    } else throw new Error("Not logged in!");
  } catch (e: any) {
    logger.info(e.message, "auth.action.ts :: loginWithMicrosoft", true, {
      e,
    });
    dispatch(AuthErrorAction(e));
  }
};

export const linkToGoogle = (): AppThunk => async (dispatch) => {
  logger.trace("Linking to Google", "auth.action.ts :: loginWithGoogle");
  try {
    const u = auth.currentUser;
    if (u) {
      const res = await u.linkWithPopup(googleProvider);
      logger.success(res, "auth.action.ts :: loginWithGoogle");
    } else throw new Error("Not logged in!");
  } catch (e: any) {
    logger.info(e.message, "auth.action.ts :: loginWithGoogle", true, {
      e,
    });
    dispatch(AuthErrorAction(e));
  }
};

export const loginWithPassword =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    logger.trace("Logging in...", "auth.action.ts :: login");
    try {
      const res = await auth.signInWithEmailAndPassword(email, password);
      // console.log(res);
      // Now fetch the user object from the backend
      dispatch(loadUser());
    } catch (error: any) {
      logger.info(error.message, "auth.action.ts :: loginWithPassword", true, {
        error,
      });
      dispatch(AuthErrorAction(error));
    }
  };

export const registerPassword =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    logger.trace("Logging in...", "auth.action.ts :: login");
    try {
      const res = await auth.createUserWithEmailAndPassword(email, password);
      // console.log(res);

      // Now fetch the user object from the backend
      dispatch(loadUser());
    } catch (error: any) {
      //   if (error.code === "auth/email-already-in-use") {
      logger.info(error.message, "auth.action.ts :: loginWithPassword", true, {
        error,
      });
      dispatch(AuthErrorAction(error));
    }
  };

export const resendEmailVerification = (): AppThunk => async (dispatch) => {
  try {
    const u = firebase.auth().currentUser;
    if (!u)
      return logger.warn(
        "Not currently logged in",
        "auth/actions :: resendEmailVerification",
        true
      );
    if (!u.emailVerified) await u.sendEmailVerification();
    dispatch(SetUserSentAction(true));
    logger.success(
      "Email verification sent",
      "auth/actions :: resendEmailVerification",
      true
    );
  } catch (error: any) {
    logger.logError(
      error,
      logger.warn,
      "auth/actions :: resendEmailVerification",
      true
    );
  }
};

export const loadUser = (): AppThunk => async (dispatch) => {
  logger.trace("Started", "loadUser", false);
  try {
    let token: string = "";
    firebase.auth().onAuthStateChanged(
      async function (user) {
        logger.trace("Started", "loadUser :: onAuthStateChanged", false);

        if (user) {
          // if (!user.emailVerified) {
          //   logger.info("Sending email verification message");
          //   await user.sendEmailVerification();
          // }
          token = await user.getIdToken(/* forceRefresh */ true);
          // console.log(token);
          setAuthToken(token);
          try {
            const api_user: IUser = (await api.get("auth")).data;
            // const api_user = STATIC_GetAPIUser(user);
            dispatch(LoadUserAction(api_user, token));
          } catch (error: any) {
            logger.warn(
              error.response ? error.response.data.message : error.message,
              "auth.action.ts :: loadUser",
              true,
              {
                error,
              }
            );
            dispatch(AuthErrorAction(error));
          }
        } else {
          // dispatch(AuthErrorAction(new Error("FirebaseAuth failed")));
          logger.debug("Load user failed; not logged in with Firebase");
          dispatch(AuthErrorAction(new Error("Firebase auth failed")));
        }
      },
      (error) => {
        logger.warn(error, "loadUser", false);
        dispatch(AuthErrorAction(new Error(error.message)));
      }
    );
  } catch (error: any) {
    dispatch(AuthErrorAction(error));
  }
};

export const updateMyProfile =
  (data: DTOUserUpdate): AppThunk =>
  async (dispatch, getState) => {
    logger.trace("Updating user profile", "auth.action.ts :: logout");

    const auth = getState().auth;
    if (!auth.user || !auth.user._id)
      return logger.error(
        "Something went wrong: Not logged in!",
        "auth/actions :: updateMyProfile",
        true,
        { auth }
      );

    try {
      const updated_user = await UserService.update(auth.user._id, data);
      dispatch(UpdateUserAction(updated_user));
      AnalyticsReset();
    } catch (error: any) {
      logger.logError(error, logger.warn, "updateMyProfile", true);
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  logger.trace("Logging out...", "auth.action.ts :: logout");
  try {
    dispatch(LogoutAction());
    dispatch({ type: PAYMENT_CLEAR });
    dispatch({ type: TEAM_CLEAR });
    dispatch({ type: ADDRESS_CLEAR });
    dispatch({ type: SHIPMENT_CLEAR });
    dispatch({ type: TOURNAMENT_CLEAR });
    dispatch({ type: USERS_CLEAR });

    await firebase.auth().signOut();
    // Now fetch the user object from the backend
  } catch (error: any) {
    dispatch(AuthErrorAction(error));
  }
};

/**
 * How much do I owe in total?
 */
export const getMyCosts =
  (
    seasons: FIRSTSeason[] | null = null,
    incl_gst: boolean = true,
    apply_credit: boolean = true
  ): AppThunk<number> =>
  (dispatch, getState) => {
    const { auth } = getState();
    if (!auth.user) return 0;
    return dispatch(getUserCosts(auth.user, seasons, incl_gst, apply_credit));
  };

/**
 * How much have I paid in total?
 */
export const getMyPayments = (): AppThunk<number> => (dispatch, getState) => {
  const { auth } = getState();
  if (!auth.user) return 0;
  return dispatch(getUserPayments(auth.user));
  // const p = payment.mine.reduce(
  //   (p, c) =>
  //     p +
  //     ((!c.credit_card && c.pdf_url) || (c.credit_card && c.payment_id)
  //       ? c.amount
  //       : 0),
  //   0
  // );
  // return p;
};

// https://macquarie-test.onestopsecure.com/onestopweb/integratedpayments/tranadd??UDS_ACTION=DEFAULT&UDS_ACTION_DATA=FIRSTLEGO001&LEGOREGID=&FNAME=&SNAME=&EMAIL=&ABN=&ORGANISATION=&UNITAMOUNTINCTAX
export const getPayLink =
  (p: Payment): AppThunk<string> =>
  (dispatch, getState) => {
    const { address, auth } = getState();
    const a = address.mine.find((a) => a._id === p.address);
    const AMT = p.amount;

    if (!a || !auth.user) alert("Something went wrong!");
    else
      return window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("firstaustralia-dev") ||
        window.location.hostname.includes("first-australia-staging")
        ? `https://macquarie-dev.xetta.com/FIRSTAustralia/tranadd?UDS_ACTION=DEFAULT&UDS_ACTION_DATA=YFlTAElAWgpOU1RyQUJDcStTQnRKXDMbWkhCWwVAIEt5TFBQ&LEGOREGID=${p.reference}&FNAME=${auth.user.given_name}&SNAME=${auth.user.family_name}&EMAIL=${auth.user.email}&ABN=${a.abn}&ORGANISATION=${a.company}&UNITAMOUNTINCTAX=${AMT}`
        : `https://payments.mq.edu.au/onestopweb/WebIntegration/tran?UDS_ACTION=DEFAULT&UDS_ACTION_DATA=YFlTAElAWgpOU1RyQUJDcStTQnRKXDMbWkhCWwVAIEt5TFBQ&LEGOREGID=${p.reference}&FNAME=${auth.user.given_name}&SNAME=${auth.user.family_name}&EMAIL=${auth.user.email}&ABN=${a.abn}&ORGANISATION=${a.company}&UNITAMOUNTINCTAX=${AMT}`;
    // Xetta 1.1: https://payments.mq.edu.au/onestopweb/WebIntegration/tran?UDS_ACTION=DEFAULT&UDS_ACTION_DATA=YFlTAElAWgpOU1RyQUJDcStTQnRKXDMbWkhCWwVAIEt5TFBQ
    // Xetta 1.0: : `https://payments.mq.edu.au/onestopweb/WebIntegration/tran?UDS_ACTION=DEFAULT&UDS_ACTION_DATA=LEGO010&LEGOREGID=${p.reference}&FNAME=${auth.user.given_name}&SNAME=${auth.user.family_name}&EMAIL=${auth.user.email}&ABN=${a.abn}&ORGANISATION=${a.company}&UNITAMOUNTINCTAX=${AMT}`;

    // https://payments.mq.edu.au/onestopweb/WebIntegration/tran?UDS_ACTION=DEFAULT&UDS_ACTION_DATA=LEGO010&LEGOREGID=&FNAME=&SNAME=&EMAIL=&ABN=&ORGANISATION=&UNITAMOUNTINCTAX
    // return `https://macquarie-test.onestopsecure.com/onestopweb/integratedpayments/tranadd??UDS_ACTION=DEFAULT&UDS_ACTION_DATA=FIRSTLEGO001&LEGOREGID=${p.reference}&FNAME=${auth.user.given_name}&SNAME=${auth.user.family_name}&EMAIL=${auth.user.email}&ABN=${a.abn}&ORGANISATION=${a.company}&UNITAMOUNTINCTAX=${AMT}`;
    // `${window.location.origin}/api/payments/xetta?msg=Approved&Rego_id=${p.reference}&AmountPaid=${p.amount}&Company=${a?.company}&AuthResult=43n1u413ni&Receipt=FakeDevReceiptNumber&success=true&Hash=FakeDevReceiptNumber`;

    return "";
  };
