import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../api/api.types";
import { RootState } from "../../store/root-reducer";
import { teamTransfer } from "../../store/teams/team.action";
import { Team } from "../../store/teams/team.types";
import { userGetAll } from "../../store/users/user.action";
import { SingleSelector } from "../atomic/FaSelectors";
import Selector from "../atomic/Selector";
import SubmitButton from "../atomic/SubmitButton";

const TeamTransferForm = ({
  allUsers,
  team,
  onFinish = () => {},
  teamTransfer,
  userGetAll,
}: Props) => {
  const [curr, setCurr] = useState<IUser | undefined>();
  const [to, setTo] = useState<IUser | undefined>();

  useEffect(() => {
    userGetAll();
  }, []);

  useEffect(() => {
    setCurr(allUsers.find((u) => u._id === team.user));
  }, [allUsers, team.user]);

  const name = (user: IUser | undefined): string => {
    if (!user) return "Unknown";
    if (user.given_name && user.family_name)
      return user.given_name + " " + user.family_name;
    return user.email;
  };

  const onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();

    if (!to) return alert("Please select a user");
    if (
      !window.confirm(
        "Are you sure?  This could mess up any payments that have been made"
      )
    )
      return;
    teamTransfer(team._id, to._id);
    onFinish();
  };

  return (
    <form className="d-flex-col" onSubmit={onSubmit}>
      <h2>Transfer ownership of {team.team_name}</h2>
      <span className="text-warn">
        <strong>Note:</strong> If any payments have been made by the existing
        owner, they will stay with that owner, so the new owner will be asked to
        make more payments.
      </span>
      <span className="d-flex">Current owner: {name(curr)}</span>
      <SingleSelector<IUser>
        className="w-30"
        options={allUsers.map((u) => ({ value: u, label: name(u) }))}
        value={to}
        onSelect={setTo}
        searchable
        label="Select user"
        clearable
      />
      <SubmitButton value="Save" />
      <input
        type="cancel"
        value="Cancel"
        className="btn btn-warn"
        onClick={() => onFinish()}
      />
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  allUsers: state.users.all,
});

const connector = connect(mapStateToProps, { teamTransfer, userGetAll });
type Props = ConnectedProps<typeof connector> & {
  team: Team;
  onFinish?: () => void;
};

export default connector(TeamTransferForm);
