import { Award } from "../../common/awards";
import { Attendance } from "../../common/backend.types";
import {
  GCheckFields,
  GDef,
  ICompete,
  IGuard,
  ITeam,
  ITournament,
} from "../api.types";

export interface DTOCompeteGet extends ICompete {}

export interface DTOCompeteCreate {
  tournament_id: ITournament["_id"];
  team_ids: ITeam["_id"][];
}

export interface DTOCompeteUpdate {
  attendance?: Attendance;
  awards?: Award[];
  judgePod?: string;
}
export const GIsDTOCompeteGet: IGuard<DTOCompeteGet> = (x) =>
  GCheckFields<DTOCompeteGet>(GDef(x), [
    "team_id",
    "tournament_id",
    "attendance",
    "awards",
    "_id",
  ]);
