import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FaParagraph, FaText } from "../../atomic/FaTexts";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBox from "../../atomic/CheckBox";
import { useState } from "react";
import NotCheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckedIcon from "@material-ui/icons/CheckBox";
import RubricQuestionRow from "./RubricQuestionRow";
import theme from "../../../styles/theme";
import corevalues from "./labels/corevalues";
import { CoreQuestion, QuestionId } from "./rubrics";
import { RubricAnswer } from "../../../store/rubrics/rubric.types";
import { rubricCreate } from "../../../store/rubrics/rubric.action";

type Props = {
  answers: RubricAnswer[];
  update?: (question_id: QuestionId, score: Score | undefined) => void;
};

type Score = { value?: 1 | 2 | 3 | 4; comment?: string };

type CoreValuesData = {
  [property in CoreQuestion]: Score;
};

/** @todo make these game-specific?  Or, does judging change all at once? */
const CoreValuesRubric = ({ answers, update }: Props) => {
  const [data, setData] = useState<Partial<CoreValuesData>>({});

  useEffect(() => {
    setData({
      ...data,
      ...answers.reduce(
        (p, a) => ({
          ...p,
          [a.question_id]: { value: a.value, comment: a.comment },
        }),
        {}
      ),
    });
  }, []);

  const updateQuestion = (id: QuestionId, score: Score | undefined) => {
    if (update) update(id, score);
    setData({ ...data, [id]: score });
  };

  const classes = useStyles(update === undefined)();
  return (
    <div className={classes.corevaluesrubric}>
      <FaText className={classes.bold}>Instructions</FaText>
      <FaParagraph variant="caption">
        The Core Values should be the lens through which you watch the team's
        presentations. All team members should be demonstrating the Core Values
        in everything they do. This rubric should be used to record the Core
        Values observed throughout the judging session.
      </FaParagraph>
      <FaParagraph variant="body2">
        If the team is a candidate for one of these awards, please tick the
        appropriate box:
      </FaParagraph>
      <Table>
        <TableBody>
          <TableRow
            onClick={() =>
              update === undefined
                ? {}
                : updateQuestion(
                    "cv:breakthrough",
                    data["cv:breakthrough"]
                      ? undefined
                      : ({
                          value: 4,
                        } as Score)
                  )
            }
            className={classes.clickable}
          >
            <TableCell>
              <CheckBox
                checked={!!data["cv:breakthrough"]}
                toggle={() =>
                  update === undefined
                    ? {}
                    : updateQuestion(
                        "cv:breakthrough",
                        data["cv:breakthrough"]
                          ? undefined
                          : ({
                              value: 4,
                            } as Score)
                      )
                }
              />
            </TableCell>
            <TableCell>
              <FaText className={classes.bold}>Breakthrough Award</FaText>
            </TableCell>
            <TableCell>
              <FaText variant="body2">
                A team that made significant progress in their confidence and
                capability and who understand that what they discover is more
                important than what they win.
              </FaText>
            </TableCell>
          </TableRow>
          <TableRow
            onClick={() =>
              update === undefined
                ? {}
                : updateQuestion(
                    "cv:allstar",
                    data["cv:allstar"]
                      ? undefined
                      : ({
                          value: 4,
                        } as Score)
                  )
            }
            className={classes.clickable}
          >
            <TableCell>
              <CheckBox
                checked={!!data["cv:allstar"]}
                toggle={() =>
                  update === undefined
                    ? {}
                    : updateQuestion(
                        "cv:allstar",
                        data["cv:allstar"]
                          ? undefined
                          : ({
                              value: 4,
                            } as Score)
                      )
                }
              />
            </TableCell>
            <TableCell>
              <FaText className={classes.bold}>Rising All-star</FaText>
            </TableCell>
            <TableCell>
              <FaText variant="body2">
                A team that the judges notice and expect great things from in
                the future.
              </FaText>
            </TableCell>
          </TableRow>
          <TableRow
            onClick={() =>
              update === undefined
                ? {}
                : updateQuestion(
                    "cv:motivate",
                    data["cv:motivate"]
                      ? undefined
                      : ({
                          value: 4,
                        } as Score)
                  )
            }
            className={classes.clickable}
          >
            <TableCell>
              <CheckBox
                checked={!!data["cv:motivate"]}
                toggle={() =>
                  update === undefined
                    ? {}
                    : updateQuestion(
                        "cv:motivate",
                        data["cv:motivate"]
                          ? undefined
                          : ({
                              value: 4,
                            } as Score)
                      )
                }
              />
            </TableCell>
            <TableCell>
              <FaText className={classes.bold}>Motivate</FaText>
            </TableCell>
            <TableCell>
              <FaText variant="body2">
                A team that embraces the culture of FIRST LEGO League through
                team building, team spirit and displayed enthusiasm.
              </FaText>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.beginning}>
              <div className={classes.tablehead}>
                <FaText variant="button">Beginning</FaText>
                <FaText variant="caption">
                  Minimal examples observed across the team
                </FaText>
                <FaText variant="button" className={classes.headbottom}>
                  1
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.developing}>
              <div className={classes.tablehead}>
                <FaText variant="button">Developing</FaText>
                <FaText variant="caption">
                  Some examples observed across the team
                </FaText>
                <FaText variant="button" className={classes.headbottom}>
                  2
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.accomplished}>
              <div className={classes.tablehead}>
                <FaText variant="button">Accomplished</FaText>
                <FaText variant="caption">
                  Multiple examples observed across the team
                </FaText>
                <FaText variant="button" className={classes.headbottom}>
                  3
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.exceeds}>
              <div className={classes.tablehead}>
                <FaText variant="button">Exceeds</FaText>
                <FaText variant="button" className={classes.headbottom}>
                  4
                </FaText>
              </div>
            </TableCell>
            <TableCell className={classes.comment}>
              <FaText variant="caption">Explain how team exceeds:</FaText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RubricQuestionRow
            value={[
              { ...data["cv:discovery"], labels: corevalues.label.discovery },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v) => updateQuestion("cv:discovery", v)
            }
            color={theme.palette.corevalues}
            label="Discovery"
            description={corevalues.desc.discovery}
          />
          <RubricQuestionRow
            value={[
              { ...data["cv:innovation"], labels: corevalues.label.innovation },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v) => updateQuestion("cv:innovation", v)
            }
            color={theme.palette.corevalues}
            label="Innovation"
            description={corevalues.desc.innovation}
          />
          <RubricQuestionRow
            value={[{ ...data["cv:impact"], labels: corevalues.label.impact }]}
            onChange={
              update === undefined
                ? undefined
                : (v) => updateQuestion("cv:impact", v)
            }
            color={theme.palette.corevalues}
            label="Impact"
            description={corevalues.desc.impact}
          />
          <RubricQuestionRow
            value={[
              { ...data["cv:inclusion"], labels: corevalues.label.inclusion },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v) => updateQuestion("cv:inclusion", v)
            }
            color={theme.palette.corevalues}
            label="Inclusion"
            description={corevalues.desc.inclusion}
          />
          <RubricQuestionRow
            value={[
              { ...data["cv:teamwork"], labels: corevalues.label.teamwork },
            ]}
            onChange={
              update === undefined
                ? undefined
                : (v) => updateQuestion("cv:teamwork", v)
            }
            color={theme.palette.corevalues}
            label="Teamwork"
            description={corevalues.desc.teamwork}
          />
          <RubricQuestionRow
            value={[{ ...data["cv:fun"], labels: corevalues.label.fun }]}
            onChange={
              update === undefined
                ? undefined
                : (v) => updateQuestion("cv:fun", v)
            }
            color={theme.palette.corevalues}
            label="Fun"
            description={corevalues.desc.fun}
          />
        </TableBody>
      </Table>
    </div>
  );
};

const useStyles = (restricted: boolean) =>
  makeStyles((theme) =>
    createStyles({
      corevaluesrubric: {},
      bold: {
        fontWeight: "bold",
      },
      clickable: {
        cursor: restricted ? "default" : "pointer",
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
      tablehead: {
        display: "flex",
        flexDirection: "column",
      },
      headbottom: {
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
      },
      beginning: {
        backgroundColor: theme.palette.corevalues.light,
      },
      developing: {
        backgroundColor: theme.palette.corevalues.main,
        // backgroundColor: "#FACFC8"
      },
      accomplished: {
        backgroundColor: "#F5B0A8",
      },
      exceeds: {
        backgroundColor: theme.palette.corevalues.dark,
      },
      comment: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      labelrow: {
        columnSpan: "all",
        backgroundColor: theme.palette.corevalues.main,
      },
    })
  );

export default CoreValuesRubric;
