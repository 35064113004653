import { IUser } from "../api/api.types";
import { FIRSTProgram } from "../common/backend.types";
import {
  costOfProgramKit,
  costOfProgramTeam,
  costOfShipping,
} from "../inventory/getCost";
import { diffShipped } from "../inventory/getInventory";
import { Address } from "../store/address/address.types";
import { Payment } from "../store/payment/payment.types";
import { Shipment } from "../store/shipping/shipment.types";
import { Team } from "../store/teams/team.types";

type CostProps = {
  /** Who are we calculating costs for? */
  user: IUser;
  /** A list of all relevant addresses */
  addresses: Address[];
  /** A list of all relevant teams */
  teams: Team[];
  /** A list of all relevant shipments */
  shipments: Shipment[];
  /** Should GST be included? */
  incl_gst?: boolean;
  /** Should this user's credit be applied to the result? */
  apply_credit?: boolean;
};

export const computeTotalCost = ({
  user,
  addresses,
  teams,
  shipments,
  incl_gst = true,
  apply_credit = false,
}: CostProps): number => {
  const teamCost = computeTeamCost({ teams });
  const kitCost = computeKitCost({ teams: teams.filter((t) => t.ship_kit) });
  const shippingCost = computeShippingCost({ shipments, addresses, teams });

  const c = Math.max(
    teamCost + kitCost + shippingCost, // - (apply_credit ? user.credit : 0)
    0
  );
  // logger.info({ c }, "getUserCosts");
  return incl_gst ? c * 1.1 : c;
};

type PaymentProps = {
  user: IUser;
  payments: Payment[];
};

export const computePayments = ({ user, payments }: PaymentProps): number => {
  const p = payments.reduce(
    (p, c) =>
      p +
      ((!c.credit_card && c.pdf_url && (c.pdf_verified || c.invoice_number)) ||
      (c.credit_card && c.payment_id) ||
      c.admin_comment
        ? c.amount
        : 0),
    0
  );
  // logger.info({ p }, "getUserPayments");
  return p;
};

type ShippingCosts = {
  shipments: Shipment[];
  addresses: Address[];
  teams: Team[];
};

export const computeShippingCost = ({
  shipments,
  addresses,
  teams,
}: ShippingCosts): number => {
  let shippingCost = 0;

  const shipped = shipments.filter((s) => !!s.consignment_number);

  // Meta: Assume every shipment thus far was correctly done.
  // Note - if shipped before 2022, don't use the multiplier rule (and only add once?)
  // @todo: If they're a tournament director, don't count the tournament shipping?
  shipped.forEach((ship) => {
    const label = JSON.parse(ship.address_label);
    const contentCount = ship.contents.reduce((p, c) => p + c.count, 0);
    const multiplier =
      contentCount === 0
        ? 0
        : new Date(ship.timestamp ?? 0)?.getFullYear() < 2022
        ? 1
        : Math.floor(contentCount / 10) + 1;
    shippingCost += costOfShipping(label.state) * multiplier ?? 0;
  });

  addresses.forEach((address) => {
    // Count how many kits still need to be shipped
    const diff = diffShipped(
      teams.filter(
        (t) =>
          t.ship_kit &&
          t.program !== FIRSTProgram.FTC &&
          t.shipping_address === address._id &&
          !t.deleted
      ),
      shipped.filter((s) => s.address === address._id)
    );
    const numKitsUnshipped = diff.reduce((p, c) => p + c.count, 0);
    // Add state-based shipping cost per every 10 kits to every address
    const multiplier =
      numKitsUnshipped === 0 ? 0 : Math.floor(numKitsUnshipped / 10) + 1;
    shippingCost += costOfShipping(address.state) * multiplier;
  });
  return shippingCost;
};

type TeamKitCosts = {
  teams: Team[];
};

export const computeTeamCost = ({ teams }: TeamKitCosts): number => {
  return teams.reduce(
    (p, c) => p + costOfProgramTeam(c.program, c.season, !c.post_registered),
    0
  );
};

export const computeKitCost = ({ teams }: TeamKitCosts): number => {
  return teams.reduce(
    (p, c) => p + costOfProgramKit(c.program, c.season, !c.post_registered),
    0
  );
};
