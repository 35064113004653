import {
  Contact,
  FIRSTProgram,
  FIRSTSeason,
  Programs,
  Seasons,
} from "../../common/backend.types";
import { Team } from "../../store/teams/team.types";
import { GCheckFields, GDef, GIsArray, IAddress, IGuard } from "../api.types";

export interface DTOTeamGet {
  _id: string;
  timestamp: Date;
  team_name: string;
  team_number: number;
  rookie_year: number;
  program: FIRSTProgram;
  user: string;
  affiliation?: string;
  coaches: string[];
  season: FIRSTSeason;
  ship_kit: boolean;
  shipping_address: string;
  // invoice_address: string;
  credit_card: boolean;
  post_registered: boolean;
  deleted: boolean;
  dummy: boolean;
}

export interface DTOTeamGet {
  _id: string;
  timestamp: Date;
  team_name: string;
  team_number: number;
  rookie_year: number;
  program: FIRSTProgram;
  user: string;
  affiliation?: string;
  coaches: string[];
  season: FIRSTSeason;
  ship_kit: boolean;
  shipping_address: string;
  // invoice_address: string;
  credit_card: boolean;
  post_registered: boolean;
  deleted: boolean;
  dummy: boolean;
}

export interface DTOTeamPublic {
  _id: string;
  team_number: number;
  team_name: string;
  program: FIRSTProgram;
  user: string;
}

export const GIsDTOTeamPublic: IGuard<DTOTeamPublic> = (x) => {
  const T = GCheckFields<DTOTeamPublic>(GDef(x), [
    "_id",
    "team_name",
    "team_number",
    "program",
  ]);
  if (!Programs.includes(T.program)) throw new TypeError("Invalid fields");
  return T;
};

export const TeamFromDTOGet = (x: DTOTeamGet): Team => ({
  _id: x._id,
  user: x.user,
  team_number: x.team_number,
  team_name: x.team_name,
  coaches: x.coaches,
  timestamp: x.timestamp,
  program: x.program,
  rookie_year: x.rookie_year,
  affiliation: x.affiliation ? x.affiliation : "",
  season: x.season,
  ship_kit: x.ship_kit,
  shipping_address: x.shipping_address,
  // invoice_address: x.invoice_address,
  // credit_card: x.credit_card,
  post_registered: x.post_registered,
  deleted: x.deleted,
  dummy: x.dummy,
});

export interface DTOTeamDummyCreate {
  team_name: string;
  team_number?: number;
  program: FIRSTProgram;
  season: FIRSTSeason;
}

export interface DTOTeamCreate {
  team_name: string;
  team_number?: number;
  rookie_year?: number;
  program: FIRSTProgram;
  affiliation?: string;
  coaches: Contact[]; // On creation, these are turned into Users
  season: FIRSTSeason;
  ship_kit: boolean;
  shipping_address: IAddress["_id"];
  // invoice_address: IAddress["_id"];
  // credit_card: boolean;
  post_registered?: boolean;
}

export interface DTOTeamUpdate {
  team_name?: string;
  team_number?: number;
  rookie_year?: number;
  program?: FIRSTProgram;
  affiliation?: string;
  coaches?: Contact[]; // On creation, these are turned into Users
  season?: FIRSTSeason;
  ship_kit?: boolean;
  shipping_address?: IAddress["_id"];
  // invoice_address?: IAddress["_id"];
  credit_card?: boolean;
  post_registered?: boolean;
}

export const GIsDTOTeamGet: IGuard<DTOTeamGet> = (x) => {
  const T = GCheckFields<DTOTeamGet>(GDef(x), [
    "_id",
    "timestamp",
    "team_name",
    "team_number",
    "rookie_year",
    "program",
    "user",
    "coaches",
    "season",
    "ship_kit",
    "shipping_address",
    // "invoice_address",
    // "credit_card",
    // "post_registered",
  ]);
  if (!Programs.includes(T.program) || !Seasons.includes(T.season))
    throw new TypeError("Invalid fields");
  return T;
};

export const GIsDTOTeamCreate: IGuard<DTOTeamCreate> = (x) => {
  const T = GCheckFields<DTOTeamCreate>(GDef(x), [
    "team_name",
    "program",
    "coaches",
    "season",
    "ship_kit",
    "shipping_address",
    // "invoice_address",
    // "credit_card",
  ]);
  if (!Programs.includes(T.program) || !Seasons.includes(T.season))
    throw new TypeError("Invalid fields");
  GIsArray<Contact>(T.coaches).map((c) =>
    GCheckFields<Contact>(c, ["email", "phone", "name"])
  );
  return T;
};

export const GIsDTOTeamUpdate: IGuard<DTOTeamUpdate> = (x) => {
  const T = GDef(x);
  if (
    (T.program && !Programs.includes(T.program)) ||
    (T.season && !Seasons.includes(T.season))
  )
    throw new TypeError("Invalid fields");
  GIsArray<Contact>(T.coaches).map((c) =>
    GCheckFields<Contact>(c, ["email", "phone", "name"])
  );
  return T;
};

export interface DTOTeamCheck {
  program: FIRSTProgram;
  season: FIRSTSeason;
}

export interface DTOTeamCopy {
  team_id: string;
  season: FIRSTSeason;
  ship_kit: boolean;
  credit_card: boolean;
  post_registered?: boolean;
}
