import {
  DBObject,
  GCheckFields,
  GDef,
  IGuard,
  ITournament,
} from "../api.types";
export interface DTOTokenGet {
  _id: DBObject["_id"];
  name: string;
  secret: string;
  target: ITournament["_id"];
  timestamp: Date;
}
export type Token = DTOTokenGet;

export const TokenFromDTOGet = (x: DTOTokenGet): Token => ({
  _id: x._id,
  name: x.name,
  target: x.target,
  secret: x.secret,
  timestamp: x.timestamp,
});

export interface DTOTokenCreate {
  name: string;
  target: ITournament["_id"];
}

export const GIsDTOTokenGet: IGuard<DTOTokenGet> = (x) => {
  const T = GCheckFields<DTOTokenGet>(GDef(x), [
    "_id",
    "name",
    "target",
    "timestamp",
  ]);
  return T;
};
