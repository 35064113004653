import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import ButtonLink from "../../atomic/ButtonLink";
import Spinner from "../../atomic/Spinner";
import Moment from "react-moment";
import Button from "../../atomic/Button";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import CreateTournamentView from "./CreateTournamentView";
import { Tournament } from "../../../store/tournament/tournament.types";
import TournamentForm from "../../forms/TournamentForm";
import {
  tournamentDelete,
  tournamentGetAll,
  tournamentUpdate,
} from "../../../store/tournament/tournament.action";
import { ProgramToLabel } from "../../../common/backend.types";
import TournamentReport from "../../reports/TournamentReport";
import { exportTournaments } from "../../../store/exports.action";
import { FaHeader } from "../../atomic/FaTexts";
import { competeGetAll } from "../../../store/compete/compete.action";
import { selectAllTournaments } from "../../../store/tournament/tournament.selector";

const AllTournamentsView = ({
  tournamentDelete,
  tournamentUpdate,
  exportTournaments,
  tournamentGetAll,
  competeGetAll,
  tournaments,
  competes,
  loading,
}: Props) => {
  useEffect(() => {
    tournamentGetAll();
    competeGetAll();
  }, []);

  const [editTourn, setEditTourn] = useState<Tournament["_id"] | undefined>(
    undefined
  );
  const [viewTourn, setViewTourn] = useState<Tournament | undefined>(undefined);

  return (
    <div>
      <span className="d-flex">
        <h1 className="mr-auto">All tournaments</h1>
        <Button onClick={() => exportTournaments(false)}>Download</Button>
      </span>
      <ButtonLink variant="success" to="/create-tournament">
        Create new tournament
      </ButtonLink>
      <small>Remember to create Championship level events first</small>
      {loading ? (
        <Spinner variant="admin" className="mt-1" />
      ) : (
        <table className="table table-primary mt-1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Start date</th>
              <th>Program</th>
              <th>Teams</th>
              <th>Locked?</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody className="striped">
            {tournaments
              .sort(
                (a, b) =>
                  new Date(a.start ?? 0).getTime() -
                  new Date(b.start ?? 0).getTime()
              )
              .map((tourn) => (
                <tr key={tourn._id}>
                  <td className="d-flex-col flex-left">
                    <div className="d-flex j-space-between w-100">
                      <Button
                        variant="clear"
                        className="action-text text-black"
                        onClick={() => setViewTourn(tourn)}
                      >
                        {tourn.name}
                        {tourn.remote ? (
                          <Icon icon="laptop-house" className="ml-1" />
                        ) : (
                          <span></span>
                        )}
                      </Button>
                    </div>
                    <small className="mx-auto">{tourn.type}</small>
                  </td>
                  <td>
                    <Moment format="LL">{tourn.start}</Moment>
                  </td>
                  <td>{ProgramToLabel(tourn.program)}</td>
                  <td>
                    {loading ? (
                      <Spinner variant="admin" />
                    ) : (
                      competes.filter((c) => c.tournament_id === tourn._id)
                        .length
                    )}{" "}
                    / {tourn.cap}
                  </td>
                  <td>
                    <Button
                      onClick={() =>
                        tournamentUpdate(tourn._id, { locked: !tourn.locked })
                      }
                      variant={tourn.locked ? "danger" : "success"}
                    >
                      <Icon icon={tourn.locked ? "lock" : "unlock"} />
                      {/* <small>{tourn.locked ? "lock" : "unlock"}</small> */}
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => setEditTourn(tourn._id)}
                      variant="clear"
                      className="text-primary"
                    >
                      <Icon icon="edit" />
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => tournamentDelete(tourn._id)}
                      variant="clear"
                      className="text-danger"
                    >
                      <Icon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <Modal
        open={editTourn !== undefined}
        onClose={() => setEditTourn(undefined)}
      >
        {editTourn ? (
          <TournamentForm
            restricted={false}
            data={tournaments.find((t) => t._id === editTourn)}
            onSubmit={() => setEditTourn(undefined)}
          />
        ) : (
          <span></span>
        )}
      </Modal>
      <Modal
        open={viewTourn !== undefined}
        onClose={() => setViewTourn(undefined)}
      >
        {viewTourn ? (
          <TournamentReport admin tournament={viewTourn} />
        ) : (
          <span />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tournaments: selectAllTournaments(state),
  competes: state.compete.all,
  loading: state.compete.loading || state.tournament.loading,
});

const connector = connect(mapStateToProps, {
  tournamentDelete,
  tournamentUpdate,
  exportTournaments,
  tournamentGetAll,
  competeGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(AllTournamentsView);
