import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IUser } from "../../../api/api.types";
import { UserRole } from "../../../common/backend.types";
import { exportVols } from "../../../store/exports.action";
import { RootState } from "../../../store/root-reducer";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import {
  userAddRole,
  userRmRole,
  userGetAll,
  userUpdate,
} from "../../../store/users/user.action";
import { volleyGetAll } from "../../../store/volley/volley.action";
import logger from "../../../utils/logger";
import Button from "../../atomic/Button";
import CheckBox from "../../atomic/CheckBox";
import Icon from "../../atomic/Icon";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import SubmitButton from "../../atomic/SubmitButton";
import UserView from "../../reports/UserView";

const AdminVolunteersView = ({
  userGetAll,
  volleyGetAll,
  tournamentGetAll,
  exportVols,
  users,
  tournaments,
  volleys,
  loading,
}: Props) => {
  const [filter, setFilter] = useState<string>("");
  const [viewUser, setViewUser] = useState<IUser | undefined>();

  useEffect(() => {
    userGetAll();
    volleyGetAll();
    tournamentGetAll();
  }, []);

  const updateUserRole = (u: IUser, r: UserRole) => {
    if (!u.roles.includes(r)) userAddRole(u._id, r);
    else userRmRole(u._id, r);
  };

  const [downloading, setDownloading] = useState<boolean>(false);
  const handleDownload = useCallback(async () => {
    if (!downloading) {
      setDownloading(true);
      exportVols();
      setDownloading(false);
    }
  }, [downloading]);

  return (
    <div id="admin-user-view">
      <span className="d-flex">
        <h1 className="mr-auto">Volunteers</h1>
        <Button onClick={handleDownload}>
          {downloading ? <Spinner variant="admin" /> : "Download"}
        </Button>
      </span>
      <input
        type="text"
        className="mb-1"
        placeholder="Filter..."
        value={filter}
        onChange={(e) => {
          e.preventDefault();
          setFilter(e.target.value);
        }}
      />
      {loading ? (
        <Spinner variant="admin" />
      ) : (
        <div className="users">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Events</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter(
                  (u) =>
                    u.volunteer_profile &&
                    volleys.map((v) => v.user_id).includes(u._id)
                )
                .filter(
                  (u) =>
                    u.given_name
                      ?.toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    u.family_name
                      ?.toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter.toLowerCase())
                )
                .map((u) => (
                  <tr key={u._id}>
                    <td>
                      {u.given_name} {u.family_name}
                    </td>
                    <td>{u.email}</td>
                    <td>{u.phone}</td>
                    <td>
                      <div className="d-flex-col">
                        {volleys
                          .filter((v) => v.user_id === u._id)
                          .map((v) => ({
                            tourn: tournaments.find(
                              (t) => t._id == v.tournament_id
                            ),
                            role: v.job?.role ?? "TBA",
                            attendance: v.attendance,
                          }))
                          .filter((r) => r.tourn)
                          .map((r) => (
                            <span>
                              {r.tourn!.name} ({r.role}){/* ,{" "} */}
                              {/* {r.attendance ?? "Unknown"} */}
                            </span>
                          ))}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        open={viewUser !== undefined}
        onClose={() => setViewUser(undefined)}
      >
        {viewUser ? <UserView userId={viewUser._id} /> : <div></div>}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.all,
  volleys: state.volley.all,
  tournaments: state.tournament.all,
  loading:
    state.users.loading || state.volley.loading || state.tournament.loading,
});

const connector = connect(mapStateToProps, {
  userGetAll,
  volleyGetAll,
  tournamentGetAll,
  exportVols,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(AdminVolunteersView);
