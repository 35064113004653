import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FIRSTProgram } from "../../../common/backend.types";
import { paymentGetAll } from "../../../store/payment/payment.action";
import { RootState } from "../../../store/root-reducer";
import { teamGetAll } from "../../../store/teams/team.action";
import {
  selectAllTeams,
  selectDeletedTeams,
} from "../../../store/teams/team.selector";
import { userGetAll } from "../../../store/users/user.action";

const AdminTab = ({
  users,
  teams,
  payments,
  deleted_teams,
  userGetAll,
  teamGetAll,
  paymentGetAll,
}: Props) => {
  useEffect(() => {
    userGetAll();
    teamGetAll();
    paymentGetAll();
  }, []);

  return (
    <div>
      <h1>Admin</h1>
      <div className="tab-grid-4">
        <strong>Users</strong>
        <span className="col-span-3">{users.length}</span>
        <strong>Pre-registered teams:</strong>
        <span>{teams.filter((t) => !t.post_registered).length}</span>
        <strong>Post-registered teams:</strong>
        <span>{teams.filter((t) => t.post_registered).length}</span>
        <strong>Deleted teams:</strong>
        <span>{deleted_teams.length}</span>
        <hr className="col-span-4" />
        <strong>Teams</strong>
        <span>{teams.length}</span>
        <strong>Kits</strong>
        <span>{teams.filter((t) => t.ship_kit).length}</span>
        <span className="col-span-4">&nbsp;</span>
        <span className="text-challenge">Challenge</span>
        <span>
          {teams.filter((t) => t.program === FIRSTProgram.FLL_CHALLENGE).length}
        </span>
        <span className="text-challenge">Challenge</span>
        <span>
          {
            teams.filter(
              (t) => t.ship_kit && t.program === FIRSTProgram.FLL_CHALLENGE
            ).length
          }
        </span>
        <span className="text-explore">Explore</span>
        <span>
          {teams.filter((t) => t.program === FIRSTProgram.FLL_EXPLORE).length}
        </span>
        <span className="text-explore">Explore</span>
        <span>
          {
            teams.filter(
              (t) => t.ship_kit && t.program === FIRSTProgram.FLL_EXPLORE
            ).length
          }
        </span>
        <span className="text-discover">Discover</span>
        <span>
          {teams.filter((t) => t.program === FIRSTProgram.FLL_DISCOVER).length}
        </span>
        <span className="text-discover">Discover</span>
        <span>
          {
            teams.filter(
              (t) => t.ship_kit && t.program === FIRSTProgram.FLL_DISCOVER
            ).length
          }
        </span>
        <span className="text-ftc">FTC</span>
        <span>
          {teams.filter((t) => t.program === FIRSTProgram.FTC).length}
        </span>
        <span className="text-ftc">FTC</span>
        <span>
          {
            teams.filter((t) => t.ship_kit && t.program === FIRSTProgram.FTC)
              .length
          }
        </span>
        <hr className="col-span-4" />
        <strong>Payments:</strong>
        <span className="col-span-3">{payments.length}</span>
        <span className="col-span-4">&nbsp;</span>
        <span>Invoice</span>
        <span className="col-span-3">
          {payments.filter((p) => !p.credit_card).length}
        </span>
        <span>Credit Card</span>
        <span className="col-span-3">
          {payments.filter((p) => p.credit_card).length}
        </span>
        <span>Total paid</span>
        <span>
          $
          {payments
            .filter(
              (p) =>
                (p.credit_card && p.payment_id) ||
                (!p.credit_card && p.pdf_url) ||
                p.admin_comment
            )
            .reduce((p, c) => p + c.amount, 0)
            .toFixed(2)}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.users.all,
  teams: selectAllTeams(state),
  payments: state.payment.all,
  deleted_teams: selectDeletedTeams(state),
});

const connector = connect(mapStateToProps, {
  userGetAll,
  teamGetAll,
  paymentGetAll,
});
type Props = ConnectedProps<typeof connector> & {};

export default connector(AdminTab);
