import React, { Fragment, useEffect } from "react";
import Moment from "react-moment";
import { connect, ConnectedProps } from "react-redux";
import {
  FIRSTProgram,
  Programs,
  ProgramToLabel,
} from "../../common/backend.types";
import {
  costOfProgramKit,
  costOfProgramTeam,
  costOfShipping,
} from "../../inventory/getCost";
import { diffShipped } from "../../inventory/getInventory";
import { addressGetMine } from "../../store/address/address.action";
import { getMyCosts, getMyPayments } from "../../store/auth/auth.action";
import { paymentGetMine } from "../../store/payment/payment.action";
import { RootState } from "../../store/root-reducer";
import { shipmentGetMine } from "../../store/shipping/shipment.action";
import { teamGetMine } from "../../store/teams/team.action";
import { getUserCosts, getUserPayments } from "../../store/users/user.action";
import { UserWithMoneys } from "../../store/users/user.selector";
import AddressLabel from "../atomic/AddressLabel";
import Spinner from "../atomic/Spinner";

type LocalProps = {
  user: UserWithMoneys;
  isMine?: boolean;
};

const FinanceBreakdown = ({
  user,
  isMine = false,
  season,
  address,
  shipment,
  payment,
  team,
  loading,
  myCredit,
  addressGetMine,
  teamGetMine,
  paymentGetMine,
  shipmentGetMine,
}: Props) => {
  useEffect(() => {
    addressGetMine();
    teamGetMine();
    paymentGetMine();
    shipmentGetMine();
  }, [addressGetMine, teamGetMine, paymentGetMine, shipmentGetMine]);

  // Should do this with selectors
  const Teams = (
    !isMine ? team.all.filter((t) => t.user === user._id) : team.mine
  ).filter((t) => t.season === season);
  const Addresses = !isMine
    ? address.all.filter((t) => t.user === user._id)
    : address.mine;
  const Payments = !isMine
    ? payment.all.filter((t) => t.payer === user._id)
    : payment.mine;
  const Shipments = (
    !isMine
      ? shipment.all.filter((t) => t.receiver === user._id)
      : shipment.mine
  ).filter((s) => !!s.consignment_number);

  // console.log({ Shipments });

  return loading ? (
    <Spinner variant="financier" />
  ) : !user ? (
    <div>Something went wrong!</div>
  ) : (
    <div className="breakdown">
      <h2>Breakdown</h2>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            {Programs.map((p, i) => (
              <th key={i}>{ProgramToLabel(p)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="sidehead">Teams</td>
            {Programs.map((p, i) => (
              <td className="ta-center" key={i}>
                {p === FIRSTProgram.FLL_CHALLENGE ? (
                  <div className="d-flex-col">
                    {Teams.filter((t) => t.program === p && !t.post_registered)
                      .length ? (
                      <span>
                        {
                          Teams.filter(
                            (t) => t.program === p && !t.post_registered
                          ).length
                        }{" "}
                        x ${costOfProgramTeam(p, season, true)} (pre)
                      </span>
                    ) : (
                      ""
                    )}
                    {Teams.filter((t) => t.program === p && t.post_registered)
                      .length ? (
                      <span>
                        {
                          Teams.filter(
                            (t) => t.program === p && t.post_registered
                          ).length
                        }{" "}
                        x ${costOfProgramTeam(p, season)}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <span>
                    {Teams.filter((t) => t.program === p).length} x $
                    {costOfProgramTeam(p, season)}
                  </span>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td className="sidehead">Kits</td>
            {Programs.filter((p) => costOfProgramKit(p, season) > 0).map(
              (p, i) => (
                <td className="ta-center" key={i}>
                  {p === FIRSTProgram.FLL_CHALLENGE ? (
                    <div className="d-flex-col">
                      {Teams.filter(
                        (t) =>
                          t.ship_kit && t.program === p && !t.post_registered
                      ).length ? (
                        <span>
                          {
                            Teams.filter(
                              (t) =>
                                t.ship_kit &&
                                t.program === p &&
                                !t.post_registered
                            ).length
                          }{" "}
                          x ${costOfProgramKit(p, season, true)} (pre)
                        </span>
                      ) : (
                        ""
                      )}
                      {Teams.filter(
                        (t) =>
                          t.ship_kit &&
                          t.program !== FIRSTProgram.FTC &&
                          t.program === p &&
                          t.post_registered
                      ).length ? (
                        <span>
                          {
                            Teams.filter(
                              (t) =>
                                t.ship_kit &&
                                t.program !== FIRSTProgram.FTC &&
                                t.program === p &&
                                t.post_registered
                            ).length
                          }{" "}
                          x ${costOfProgramKit(p, season)}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <span>
                      {
                        Teams.filter(
                          (t) =>
                            t.ship_kit &&
                            t.program !== FIRSTProgram.FTC &&
                            t.program === p
                        ).length
                      }{" "}
                      x ${costOfProgramKit(p, season)}
                    </span>
                  )}
                </td>
              )
            )}
          </tr>
          <tr>
            <td className="sidehead">Total (teams + kits)</td>
            <td />
            <td />
            <td />
            <td />
            <td>${user.currentSeasonExclGst.toFixed(2)}</td>
          </tr>
          <tr className="divider">
            <td className="sidehead">This season</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              ${user.currentSeasonExclGst.toFixed(2)}
            </td>
          </tr>

          <tr>
            <td className="sidehead">Other seasons</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              ${user.offseasonCostExclGst.toFixed(2)}
            </td>
          </tr>

          <tr className="divider">
            <td className="sidehead">Shipping: previous shipments</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              $
              {Shipments.map((ship) => {
                const label = JSON.parse(ship.address_label);
                const contentCount = ship.contents.reduce(
                  (p, c) => p + c.count,
                  0
                );
                const multiplier =
                  contentCount === 0
                    ? 0
                    : new Date(ship.timestamp ?? 0)?.getFullYear() < 2022
                    ? 1
                    : Math.floor(contentCount / 10) + 1;
                return costOfShipping(label.state) * multiplier ?? 0;
              })
                .reduce((p, c) => p + c, 0)
                .toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="sidehead">Shipping: upcoming</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              $
              {Addresses.map((address) => {
                // Count how many kits still need to be shipped
                const diff = diffShipped(
                  Teams.filter(
                    (t) => t.ship_kit && t.shipping_address === address._id
                  ),
                  Shipments.filter((s) => s.address === address._id)
                );
                const numKitsUnshipped = diff.reduce((p, c) => p + c.count, 0);
                // Add state-based shipping cost per every 10 kits to every address
                const multiplier =
                  numKitsUnshipped == 0
                    ? 0
                    : Math.floor(numKitsUnshipped / 10) + 1;
                return costOfShipping(address.state) * multiplier;
              })
                .reduce((p, c) => p + c, 0)
                .toFixed(2)}
            </td>
          </tr>

          <tr>
            <td className="sidehead">Shipping total</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              ${(user?.shippingCost ?? 0).toFixed(2)}
            </td>
          </tr>
          <tr className="divider">
            <td className="sidehead">Subtotal</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              $
              {(
                user.currentSeasonExclGst +
                user.offseasonCostExclGst +
                user.shippingCost
              ).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="sidehead">GST</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              {" "}
              $
              {(
                (user.currentSeasonExclGst +
                  user.offseasonCostExclGst +
                  user.shippingCost) *
                0.1
              ).toFixed(2)}
            </td>
          </tr>

          <tr className="divider">
            <td className="sidehead">Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center"> $ {user.up.toFixed(2)}</td>
          </tr>
          {user && user.credit ? (
            <tr>
              <td className="sidehead">Discount</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="ta-center">-${user.credit.toFixed(2)}</td>
            </tr>
          ) : !user && myCredit ? (
            <tr>
              <td className="sidehead">Discount</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="ta-center">-${myCredit.toFixed(2)}</td>
            </tr>
          ) : (
            <tr>
              <td className="sidehead">Discount</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="ta-center">$0</td>
            </tr>
          )}

          <tr>
            <td className="sidehead">Paid</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              {" "}
              - ${(user.down - (user.credit ?? myCredit)).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="sidehead">Balance</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="ta-center">
              <strong>${Math.max(0, user.up - user.down).toFixed(2)}</strong>
            </td>
          </tr>
          <tr className="divider">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      {Payments.filter((p) => !p.credit_card && !p.admin_comment).length ? (
        <Fragment>
          <h2>Invoice Payments</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Address</th>
                <th>Purchase order</th>
                <th>Invoice #</th>
                {/* You can't view comments about your own stuff */}
                {user && <th>Comments</th>}
              </tr>
            </thead>
            <tbody className="striped">
              {Payments.filter((p) => !p.credit_card).map((p) => (
                <tr key={p._id}>
                  <td>{p.reference}</td>

                  <td>${p.amount.toFixed(2)}</td>
                  <td>
                    {
                      <AddressLabel
                        address={Addresses.find((a) => a._id === p.address)}
                      />
                    }
                  </td>
                  <td>
                    {p.pdf_url && (
                      <a
                        href={p.pdf_url}
                        target="_blank"
                        rel="noreferrer"
                        className={`btn btn-${
                          p.thumbnail ? "clear" : "primary"
                        }`}
                      >
                        {p.thumbnail ? (
                          <img
                            src={p.thumbnail}
                            alt="Purchase order thumbnail"
                          />
                        ) : (
                          "View"
                        )}
                      </a>
                    )}
                  </td>
                  <td></td>
                  {user && <td>{p.comment}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      ) : (
        <span></span>
      )}
      {Payments.filter((p) => p.credit_card).length ? (
        <Fragment>
          <h2>Credit Card Payments</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Address</th>
                <th>Receipt number</th>
                <th>Date</th>
                <th>Invoice #</th>
                {/* You can't view comments about your own stuff */}
                {user && <th>Comments</th>}
              </tr>
            </thead>
            <tbody className="striped">
              {Payments.filter((p) => p.credit_card).map((p) => (
                <tr key={p._id}>
                  <td>{p.reference}</td>
                  <td>${p.amount.toFixed(2)}</td>
                  <td>
                    {
                      <AddressLabel
                        address={Addresses.find((a) => a._id === p.address)}
                      />
                    }
                  </td>
                  <td>{p.payment_id ? p.payment_id : <span>Unpaid</span>}</td>
                  <td>
                    <Moment format="LL">{p.timestamp}</Moment>
                  </td>
                  <td></td>
                  {user && <td>{p.comment}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      ) : (
        <span></span>
      )}
      {Payments.filter((p) => p.admin_comment).length ? (
        <Fragment>
          <h2>Admin Override Payments</h2>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Admin Comment</th>
                {/* You can't view comments about your own stuff */}
                {user && <th>Comments</th>}
              </tr>
            </thead>
            <tbody className="striped">
              {Payments.filter((p) => p.admin_comment).map((p) => (
                <tr key={p._id}>
                  <td>{p.reference}</td>
                  <td>${p.amount.toFixed(2)}</td>
                  <td>{p.admin_comment}</td>
                  {user && <td>{p.comment}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      ) : (
        <span></span>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  address: state.address,
  payment: state.payment,
  shipment: state.shipment,
  team: state.team,
  myCredit: state.auth.user?.credit ?? 0,
  season: state.settings.season,
  loading: state.team.loading || state.address.loading || state.payment.loading,
});

const connector = connect(mapStateToProps, {
  getMyCosts,
  getUserCosts,
  getMyPayments,
  getUserPayments,
  addressGetMine,
  teamGetMine,
  paymentGetMine,
  shipmentGetMine,
});
type Props = ConnectedProps<typeof connector> & LocalProps;

export default connector(FinanceBreakdown);
