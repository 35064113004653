import {
  Team,
  TeamActionTypes,
  TeamState,
  TEAM_CLEAR,
  TEAM_CREATE,
  TEAM_DELETE,
  TEAM_GET_ALL,
  TEAM_GET_MINE,
  TEAM_GET_MINE_COACH,
  TEAM_UPDATE,
  TEAM_UNDELETE,
  TEAM_GET_TOURN,
} from "./team.types";

const initialState: TeamState = {
  mine: [],
  coach: [],
  tournament: [],
  all: [],
  dummy: [],
  deleted_mine: [],
  deleted_all: [],
  loading: true,
};

const teamReducer = function (
  state: TeamState = initialState,
  action: TeamActionTypes
): TeamState {
  switch (action.type) {
    case TEAM_GET_MINE:
      return {
        ...state,
        mine: action.payload.filter((t) => !t.deleted && !t.dummy),
        deleted_mine: action.payload.filter((t) => t.deleted),
        loading: false,
      };
    case TEAM_GET_MINE_COACH:
      return {
        ...state,
        coach: action.payload.filter((t) => !t.deleted && !t.dummy),
        deleted_mine: action.payload.filter((t) => t.deleted),
        loading: false,
      };
    case TEAM_GET_ALL:
      return {
        ...state,
        all: action.payload.filter((t) => !t.deleted && !t.dummy),
        deleted_all: action.payload.filter((t) => t.deleted && !t.dummy),
        dummy: action.payload.filter((t) => t.dummy && !t.deleted),
        loading: false,
      };
    case TEAM_GET_TOURN:
      return {
        ...state,
        tournament: action.payload,
        loading: false,
      };
    case TEAM_CREATE:
      if (action.payload.dummy) {
        return {
          ...state,
          dummy: [...state.dummy, action.payload],
          loading: false,
        };
      } else {
        return {
          ...state,
          mine: [...state.mine, action.payload],
          all: [...state.all, action.payload],
          loading: false,
        };
      }
    case TEAM_UPDATE:
      const { id, data } = action.payload;
      return {
        ...state,
        mine: state.mine.map((t) => (t._id === id ? { ...t, ...data } : t)),
        all: state.all.map((t) => (t._id === id ? { ...t, ...data } : t)),
        dummy: state.dummy.map((t) => (t._id === id ? { ...t, ...data } : t)),
        loading: false,
      };
    case TEAM_DELETE:
      return {
        ...state,
        deleted_mine: [
          ...state.deleted_mine,
          state.mine.find((t) => t._id === action.payload),
        ].filter((t) => t !== undefined) as Team[],
        deleted_all: [
          ...state.deleted_all,
          state.all.find((t) => t._id === action.payload),
        ].filter((t) => t !== undefined) as Team[],
        mine: state.mine.filter((t) => t._id !== action.payload),
        all: state.all.filter((t) => t._id !== action.payload),
        dummy: state.dummy.filter((t) => t._id !== action.payload),
        loading: false,
      };
    case TEAM_UNDELETE:
      return {
        ...state,
        mine: [
          ...state.mine,
          state.deleted_mine.find((t) => t._id === action.payload),
        ].filter((t) => t !== undefined) as Team[],
        all: [
          ...state.all,
          state.deleted_all.find((t) => t._id === action.payload),
        ].filter((t) => t !== undefined) as Team[],
        deleted_mine: state.deleted_mine.filter(
          (t) => t._id !== action.payload
        ),
        deleted_all: state.deleted_all.filter((t) => t._id !== action.payload),
        loading: false,
      };
    case TEAM_CLEAR:
      return { ...state, mine: [], all: [], coach: [], dummy: [] };
    default:
      return state;
  }
};

export default teamReducer;
