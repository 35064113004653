import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { tournamentGetAll } from "../../../store/tournament/tournament.action";
import { selectAllTournaments } from "../../../store/tournament/tournament.selector";
import { volleyGetMine } from "../../../store/volley/volley.action";
import Button from "../../atomic/Button";
import LocalLink from "../../atomic/LocalLink";
import Modal from "../../atomic/Modal";
import Spinner from "../../atomic/Spinner";
import RegisterVolleyForm from "../../forms/RegisterVolleyForm";
import TournamentVolleyRow from "../../reports/TournamentVolleyRow";

const VolunteerTab = ({ auth, volleys, tournaments, loading }: Props) => {
  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);

  useEffect(() => {
    volleyGetMine();
    tournamentGetAll();
  }, []);

  return (
    <div>
      <h1>Volunteer</h1>
      {!auth.user?.volunteer_profile ? (
        <p>
          Please provide a{" "}
          <LocalLink to="/profile">volunteer profile</LocalLink> before
          registering for any events.{" "}
        </p>
      ) : loading ? (
        <Spinner variant="volunteer" />
      ) : (
        <Fragment>
          <Button
            variant="volunteer"
            onClick={() => setShowRegisterModal(true)}
          >
            Update your registrations!
          </Button>
          <h3>My events</h3>
          {volleys.length === 0 ? (
            <span>No events yet! Press the above button to sign up</span>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Program</th>
                  <th>Tournament</th>
                  <th>Start date</th>
                  <th>Location</th>
                  <th>Days</th>
                  <th>Role</th>
                  <th>Links?</th>
                </tr>
              </thead>
              <tbody>
                {volleys.map((v) => (
                  <TournamentVolleyRow
                    key={v._id}
                    volley={v}
                    tournament={tournaments.find(
                      (t) => t._id === v.tournament_id
                    )}
                  />
                ))}
              </tbody>
            </table>
          )}
          <Modal
            open={showRegisterModal}
            onClose={() => setShowRegisterModal(false)}
          >
            <RegisterVolleyForm />
          </Modal>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  volleys: state.volley.mine,
  tournaments: selectAllTournaments(state),
  loading: state.tournament.loading || state.volley.loading,
});

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector> & {};

export default connector(VolunteerTab);
