import { VolunteerRole } from "./vol_roles";

export enum UserRole {
  Admin,
  Financier,
  Shipper,
  Director,
  User,
}

export type FirebaseData = {
  picture?: string;
  email_verified?: boolean;
  provider?: string;
};

export type Contact = {
  name: string;
  phone: string;
  email: string;
};
export type Email = string;
export const isEmail = (email: string): email is Email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export type StreetAddress = {
  street1: string;
  street2: string;
  street3: string;
  suburb: string;
  postcode: string;
  state: State;
  country: string;
};

export type Company = {
  name: string;
  abn: number;
};

export interface Address {
  street1: string;
  street2?: string;
  street3?: string;
  suburb: string;
  postcode: number;
  country: string;
}
export const TournamentTypes = [
  "Open Qualifier",
  "Closed Qualifier",
  "Championship",
  "Scrimmage",
] as const;
/**
 * Open Qualifier: Qualifies you for an Australian Championship event, any team can enter; teams can only be in one qualifier at once<br/>
 * Closed Qualifier: Qualifies you for an Australian Championship event, only admins can add teams; teams can only be in one qualifier at once<br/>
 * Championship: Teams can only be added by qualification or admin <br/>
 * Scrimmage: Scrimmages are just for fun, anyone can enter or leave and no one qualifies.  Teams can also be in multiple.
 */
export type TournamentType = typeof TournamentTypes[number];

export const Attendances = [
  "Unknown",
  "Cancelled",
  "No show",
  "Attended",
] as const;
export type Attendance = typeof Attendances[number];

export type State = "NSW" | "ACT" | "VIC" | "SA" | "QLD" | "WA" | "NT" | "TAS";
export const States: { label: string; value: State }[] = [
  {
    label: "New South Wales",
    value: "NSW",
  },
  {
    label: "Australian Capital Territory",
    value: "ACT",
  },
  {
    label: "Victoria",
    value: "VIC",
  },
  {
    label: "Queensland",
    value: "QLD",
  },
  {
    label: "South Australia",
    value: "SA",
  },
  {
    label: "Western Australia",
    value: "WA",
  },
  {
    label: "Northern Territory",
    value: "NT",
  },
  {
    label: "Tasmania",
    value: "TAS",
  },
];

export enum FIRSTProgram {
  FLL_CHALLENGE = "FLL_CHALLENGE",
  FLL_EXPLORE = "FLL_EXPLORE",
  FLL_DISCOVER = "FLL_DISCOVER",
  FTC = "FIRST_TECH_CHALLENGE",
  FRC = "FIRST_ROBOTICS_COMPETITION",
}
export const Programs = [
  FIRSTProgram.FLL_DISCOVER,
  FIRSTProgram.FLL_EXPLORE,
  FIRSTProgram.FLL_CHALLENGE,
  FIRSTProgram.FTC,
  FIRSTProgram.FRC,
];

export const ProgramToLabel = (
  s: FIRSTProgram,
  full: boolean = false
): string => {
  switch (s) {
    case FIRSTProgram.FLL_CHALLENGE:
      return (full ? "FIRST LEGO League " : "") + "Challenge";
    case FIRSTProgram.FLL_DISCOVER:
      return (full ? "FIRST LEGO League " : "") + "Discover";
    case FIRSTProgram.FLL_EXPLORE:
      return (full ? "FIRST LEGO League " : "") + "Explore";
    case FIRSTProgram.FTC:
      return "FTC";
    case FIRSTProgram.FRC:
      return "FRC";
  }
};

export const ProgramToClass = (s: FIRSTProgram): string => {
  switch (s) {
    case FIRSTProgram.FLL_CHALLENGE:
      return "challenge";
    case FIRSTProgram.FLL_DISCOVER:
      return "discover";
    case FIRSTProgram.FLL_EXPLORE:
      return "explore";
    case FIRSTProgram.FTC:
      return "ftc";
    case FIRSTProgram.FRC:
      return "frc";
  }
};

export enum FIRSTSeason {
  FirstLaunch = 20192020,
  PlayMakers = 20202021,
  FirstForward = 20212022,
  Energize = 20222023,
  NewSeason = 20232024,
}
export const Seasons = [
  // FIRSTSeason.PlayMakers,
  FIRSTSeason.FirstForward,
  FIRSTSeason.Energize,
  FIRSTSeason.NewSeason,
];

export const SeasonLabel = (s: FIRSTSeason): string => {
  switch (s) {
    case FIRSTSeason.FirstLaunch:
      return "2019 FIRST Launch";
    case FIRSTSeason.PlayMakers:
      return "2020 PlayMakers";
    case FIRSTSeason.FirstForward:
      return "2021 FIRST Forward";
    case FIRSTSeason.Energize:
      return "2022 Energize";
    case FIRSTSeason.NewSeason:
      return "2023 New Season";
    default:
      return "Unknown Season";
  }
};

export type ShipmentContent = {
  program: FIRSTProgram;
  season: FIRSTSeason;
  count: number;
};

export type AgeRange = "<14" | "14-17" | "18-25" | ">25";

export type VolParticipation = {
  program: FIRSTProgram;
  role: VolunteerRole;
};

export interface VolunteerProfile {
  wwcc: string;
  age_range: AgeRange;
  emergency_name: string;
  emergency_phone: string;
  history: VolParticipation[];
  preferences: VolParticipation[];
  affiliation?: string;
  dietary?: string;
}

export const SeasonEndDate = (
  season: FIRSTSeason,
  program: FIRSTProgram = FIRSTProgram.FLL_CHALLENGE
): Date => {
  if (program === FIRSTProgram.FLL_CHALLENGE) {
    switch (season) {
      case FIRSTSeason.FirstLaunch:
        return new Date("2019-12-14");
      case FIRSTSeason.PlayMakers:
        return new Date("2020-12-14");
      case FIRSTSeason.FirstForward:
        return new Date("2021-12-14");
      case FIRSTSeason.Energize:
        return new Date("2022-08-01");
      case FIRSTSeason.NewSeason:
        return new Date("2023-08-01");
    }
  } else {
    switch (season) {
      case FIRSTSeason.FirstLaunch:
        return new Date("2019-12-14");
      case FIRSTSeason.PlayMakers:
        return new Date("2020-12-14");
      case FIRSTSeason.FirstForward:
        return new Date("2021-12-14");
      case FIRSTSeason.Energize:
        return new Date("2022-12-14");
      case FIRSTSeason.NewSeason:
        return new Date("2023-12-14");
    }
  }
};
