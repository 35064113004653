const Colours = {
  primary: "#0066b3",
  secondary: "#ed1c24",
  challenge: "#ed1c24",
  explore: "#00a651",
  discover: "#662d91",
  ftc: "#f57f26",
  light: "#d8e4fb",
  dark: "#231f20",
  danger: "#b50900",
  warn: "#ff9900",
  success: "#28a745",
  black: "#000",
  white: "#fff",
  gray: "#c7c6c7",
  grey: "#c7c6c7",
  lightGray: "#eee",
  lightGrey: "#eee",
  coach: "#ed1c24",
  volunteer: "#0066b3",
  director: "#00a651",
  shipper: "#ff6600",
  //TNT orange lol
  financier: "#a6192e",
  /// MQ red
  admin: "#662d91",
  blue: "#0051ff",
  corevalues: "#fad9d3",
  innovationproject: "#d2daef",
  robotdesign: "#d7e9d7",
};

export default Colours;
