import React from "react";
import { Address } from "../../store/address/address.types";

interface Props {
  address: Address | undefined;
}

const AddressLabel = ({ address }: Props) =>
  address === undefined ? (
    <span className="text-danger">UNKNOWN</span>
  ) : (
    <div className="d-flex-col">
      <span>{address.company}</span>
      <span>{address.street1}</span>
      <span>{address.street2}</span>
      <span>{address.street3}</span>
      <span>
        {address.suburb} {address.postcode}
      </span>
      <span>
        {address.state} {address.country}
      </span>
    </div>
  );
export default AddressLabel;
