import { IUser } from "../../api/api.types";
import {
  DTOUserUpdate,
  DTOVolunteerProfileUpdate,
} from "../../api/dtos/user.dto";
import UserService from "../../api/services/user.service";
import {
  FIRSTSeason,
  UserRole,
  VolunteerProfile,
} from "../../common/backend.types";
import { computePayments, computeTotalCost } from "../../utils/getCost";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import { UpdateUserAction } from "../auth/auth.action";
import { Tournament } from "../tournament/tournament.types";
import {
  UsersActionTypes,
  USERS_CLEAR,
  USERS_GET_ALL,
  USERS_UPDATE,
} from "./user.types";

/**
 * Action types
 */

export const UserGetAllAction = (data: IUser[]): UsersActionTypes => ({
  type: USERS_GET_ALL,
  payload: data,
});

export const UserUpdateAction = (data: IUser): UsersActionTypes => ({
  type: USERS_UPDATE,
  payload: data,
});

export const UserClearAction = (): UsersActionTypes => ({
  type: USERS_CLEAR,
  payload: null,
});

/**
 * Actions
 */

export const userGetAll = (): AppThunk => async (dispatch) => {
  try {
    const a = await UserService.getAll();
    dispatch(
      UserGetAllAction(
        a.map((u) => ({ ...u, credit: u.credit ? u.credit : 0 }))
      )
    );
  } catch (error: any) {
    logger.logError(error, logger.warn, "user.action :: getAll", true);
  }
};

export const userGetTourn =
  (tournament_id: Tournament["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      const a = await UserService.getTournament(tournament_id);
      dispatch(
        UserGetAllAction(
          a.map((u) => ({ ...u, credit: u.credit ? u.credit : 0 }))
        )
      );
    } catch (error: any) {
      logger.logError(error, logger.warn, "user.action :: getAll", true);
    }
  };

export const userUpdate =
  (uid: IUser["_id"], data: DTOUserUpdate): AppThunk =>
  async (dispatch, getState) => {
    try {
      const a = await UserService.update(uid, data);
      dispatch(UserUpdateAction(a));
      if (getState().auth.user?._id === uid) dispatch(UpdateUserAction(a));
    } catch (error: any) {
      logger.logError(error, logger.warn, "user.action :: userUpdate", true);
    }
  };

export const userAddRole =
  (uid: IUser["_id"], role: UserRole): AppThunk =>
  async (dispatch, getState) => {
    try {
      const u = await UserService.addRole(uid, role);
      dispatch(UserUpdateAction(u));
      if (getState().auth.user?._id === uid) dispatch(UpdateUserAction(u));

      // If updating yourself, should also update auth.
    } catch (error: any) {
      logger.logError(error, logger.warn, "user.action :: userAddRole", true);
    }
  };

export const userRmRole =
  (uid: IUser["_id"], role: UserRole): AppThunk =>
  async (dispatch, getState) => {
    try {
      const u = await UserService.rmRole(uid, role);
      dispatch(UserUpdateAction(u));
      // If updating yourself, should also update auth.
      if (getState().auth.user?._id === uid) dispatch(UpdateUserAction(u));
    } catch (error: any) {
      logger.logError(error, logger.warn, "user.action :: userRmRole", true);
    }
  };

export const userAddVolProfile =
  (uid: IUser["_id"], data: VolunteerProfile): AppThunk =>
  async (dispatch, getState) => {
    try {
      const u = await UserService.addVolunteerProfile(uid, data);
      dispatch(UserUpdateAction(u));
      logger.success(
        "Created volunteer profile!",
        "user.action :: userAddVolProfile",
        true
      );
      // If updating yourself, should also update auth.
      if (getState().auth.user?._id === uid) dispatch(UpdateUserAction(u));
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "user.action :: userAddVolProfile",
        true
      );
    }
  };

export const userUpdateVolProfile =
  (uid: IUser["_id"], data: DTOVolunteerProfileUpdate): AppThunk =>
  async (dispatch, getState) => {
    try {
      const u = await UserService.updateVolunteerProfile(uid, data);
      dispatch(UserUpdateAction(u));
      logger.success(
        "Updated volunteer profile!",
        "user.action :: userUpdateVolProfile",
        true
      );
      // If updating yourself, should also update auth.
      if (getState().auth.user?._id === uid) dispatch(UpdateUserAction(u));
    } catch (error: any) {
      logger.logError(error, logger.warn, "user.action :: userAddRole", true);
    }
  };

export const userClear = (): AppThunk => (dispatch) => {
  dispatch(UserClearAction());
};

/**
 * How much in the negative is this user?
 * @param seasons If null, check all seasons.  If a list, check only those seasons
 */
export const getUserCosts =
  (
    user: IUser,
    seasons: FIRSTSeason[] | null = null,
    incl_gst: boolean = true,
    apply_credit: boolean = true
  ): AppThunk<number> =>
  (dispatch, getState) => {
    const { team, address, shipment } = getState();
    return computeTotalCost({
      user,
      addresses: address.all.filter((a) => a.user === user._id),
      teams:
        seasons === null
          ? team.all.filter((t) => t.user === user._id)
          : team.all
              .filter((t) => t.user === user._id && !t.deleted)
              .filter((t) => seasons.includes(t.season)),
      shipments: shipment.all.filter((s) => s.receiver === user._id),
      incl_gst,
      apply_credit,
    });
  };

/**
 * How much in the positive is this user?
 */
export const getUserPayments =
  (user: IUser): AppThunk<number> =>
  (dispatch, getState) => {
    const { payment } = getState();
    return computePayments({
      user,
      payments: payment.all.filter((p) => p.payer === user._id),
    });
  };
