/**
 * Action types
 */

import { IUser } from "../../api/api.types";
import {
  DTODummyTournamentCreate,
  DTOTournamentCreate,
  DTOTournamentUpdate,
} from "../../api/dtos/tournament.dto";
import TournamentService from "../../api/services/tournament.service";
import { getCost } from "../../inventory/getCost";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import {
  Tournament,
  TournamentActionTypes,
  TOURNAMENT_CREATE,
  TOURNAMENT_DELETE,
  TOURNAMENT_GET_ALL,
  TOURNAMENT_GET_MINE,
  TOURNAMENT_UPDATE,
} from "./tournament.types";

export const TournamentGetMineAction = (
  data: Tournament[]
): TournamentActionTypes => ({
  type: TOURNAMENT_GET_MINE,
  payload: data,
});

export const TournamentGetAllAction = (
  data: Tournament[]
): TournamentActionTypes => ({
  type: TOURNAMENT_GET_ALL,
  payload: data,
});

export const TournamentUpdateAction = (
  id: Tournament["_id"],
  data: Tournament
): TournamentActionTypes => ({
  type: TOURNAMENT_UPDATE,
  payload: { id, data },
});

export const TournamentCreateAction = (
  data: Tournament
): TournamentActionTypes => ({
  type: TOURNAMENT_CREATE,
  payload: data,
});

export const TournamentDeleteAction = (
  id: Tournament["_id"]
): TournamentActionTypes => ({
  type: TOURNAMENT_DELETE,
  payload: id,
});

/**
 * Actions
 */

export const tournamentGetMine = (): AppThunk => async (dispatch, getState) => {
  try {
    const t = await TournamentService.getMine();
    const { auth } = getState();
    const U = auth.user; // Shouldn't have to do this, linter :-/
    if (U) {
      dispatch(TournamentGetMineAction(t));
    }
  } catch (error: any) {
    logger.logError(error, logger.warn, "tournament.action :: getMine", true);
  }
};

export const tournamentGetAll = (): AppThunk => async (dispatch) => {
  try {
    const t = await TournamentService.getAll();
    dispatch(TournamentGetAllAction(t));
  } catch (error: any) {
    logger.logError(error, logger.warn, "tournament.action :: getAll", true);
  }
};

export const tournamentCreate =
  (data: DTOTournamentCreate): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = await TournamentService.create(data);
      dispatch(TournamentCreateAction(t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentCreate",
        true
      );
      return false;
    }
  };
export const tournamentDummyCreate =
  (data: DTODummyTournamentCreate): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    try {
      const t = await TournamentService.createDummy(data);
      dispatch(TournamentCreateAction(t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentCreate",
        true
      );
      return false;
    }
  };

export const tournamentUpdate =
  (
    id: Tournament["_id"],
    data: DTOTournamentUpdate
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = await TournamentService.update(id, data);
      dispatch(TournamentUpdateAction(id, t));
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentUpdate",
        true
      );
      return false;
    }
  };

export const tournamentDelete =
  (id: Tournament["_id"]): AppThunk =>
  async (dispatch) => {
    try {
      await TournamentService.remove(id);
      dispatch(TournamentDeleteAction(id));
      logger.success("Tournament deleted", "tournamentDelete", true);
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentDelete",
        true
      );
    }
  };

export const tournamentAddDirector =
  (
    id: Tournament["_id"],
    email: string
  ): AppThunk<Promise<IUser["_id"] | null>> =>
  async (dispatch) => {
    try {
      const t = await TournamentService.addDirector(id, email);
      dispatch(TournamentUpdateAction(id, t));
      logger.success(`${email} added as a director`, "tournament.action", true);
      return t.directors[t.directors.length - 1];
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentAddDirector",
        true
      );
      return null;
    }
  };
export const tournamentRmDirector =
  (id: Tournament["_id"], email: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const t = await TournamentService.rmDirector(id, email);
      dispatch(TournamentUpdateAction(id, t));
      logger.success(
        `${email} removed as a director`,
        "tournament.action",
        true
      );
      return true;
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentRmDirector",
        true
      );
      return false;
    }
  };

export const tournamentAddVolCo =
  (id: Tournament["_id"], email: string): AppThunk =>
  async (dispatch) => {
    try {
      const t = await TournamentService.addVolCo(id, email);
      dispatch(TournamentUpdateAction(id, t));
      logger.success(
        `${email} added as a volunteer coordinator`,
        "tournament.action",
        true
      );
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentAddVolCo",
        true
      );
    }
  };
export const tournamentRmVolCo =
  (id: Tournament["_id"], email: string): AppThunk =>
  async (dispatch) => {
    try {
      const t = await TournamentService.rmVolCo(id, email);
      dispatch(TournamentUpdateAction(id, t));
      logger.success(
        `${email} removed as a volunteer coordinator`,
        "tournament.action",
        true
      );
    } catch (error: any) {
      logger.logError(
        error,
        logger.warn,
        "tournament.action :: tournamentRmVolCo",
        true
      );
    }
  };

export const myTournamentsCost = (): AppThunk => async (dispatch, getState) => {
  const { tournament, auth } = getState();
  return getCost(tournament.mine, auth.user?.credit ? auth.user.credit : 0);
};
