const downloadCSV = (filename: string, rows: string[][], header?: string[]) => {
  let file = new Blob(
    header
      ? [[header.join(","), ...rows.map((row) => row.join(","))].join("\n")]
      : [rows.map((row) => row.join(",")).join("\n")],
    {
      type: "application/csv",
    }
  );
  // if (window.navigator.msSaveOrOpenBlob)
  //   // IE10+
  //   window.navigator.msSaveOrOpenBlob(file, filename);
  // else {
  // Others
  var a = document.createElement("a"),
    url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  // a.dispatchEvent(
  //   new MouseEvent("click", {
  //     bubbles: true,
  //     cancelable: true,
  //     view: window,
  //   })
  // );
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
  // }
};

export default downloadCSV;
