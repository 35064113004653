import React from "react";
import {
  FIRSTProgram,
  ProgramToClass,
  ProgramToLabel,
} from "../../common/backend.types";

import discover from "../../img/programs/discover.png";
import explore from "../../img/programs/explore.png";
import challenge from "../../img/programs/challenge.png";
import ftc from "../../img/programs/ftc.png";
import frc from "../../img/programs/frc.png";

interface Props {
  program: FIRSTProgram;
  className?: string;
  full?: boolean;
}

const ProgramLogo = ({ program, className, full = false }: Props) => {
  const source = (p: FIRSTProgram) => {
    switch (p) {
      case FIRSTProgram.FLL_CHALLENGE:
        return challenge;
      case FIRSTProgram.FLL_DISCOVER:
        return discover;
      case FIRSTProgram.FLL_EXPLORE:
        return explore;
      case FIRSTProgram.FTC:
        return ftc;
      case FIRSTProgram.FRC:
        return frc;
    }
  };

  return (
    <div className={`program-logo ${className}`}>
      {/* <img
        src={source(program)}
        alt={program + " logo"}
        height={60}
      /> */}
      <span className={"text-bold text-" + ProgramToClass(program)}>
        {ProgramToLabel(program, full)}
      </span>
    </div>
  );
};

export default ProgramLogo;
