import {
  Tournament,
  TournamentActionTypes,
  TournamentState,
  TOURNAMENT_CLEAR,
  TOURNAMENT_CREATE,
  TOURNAMENT_DELETE,
  TOURNAMENT_GET_ALL,
  TOURNAMENT_GET_MINE,
  TOURNAMENT_UPDATE,
} from "./tournament.types";

const initialState: TournamentState = {
  mine: [],
  all: [],
  dummy: [],
  loading: true,
};

const tournamentReducer = function (
  state: TournamentState = initialState,
  action: TournamentActionTypes
): TournamentState {
  switch (action.type) {
    case TOURNAMENT_GET_MINE:
      return {
        ...state,
        mine: action.payload,
        loading: false,
      };
    case TOURNAMENT_GET_ALL:
      return {
        ...state,
        all: action.payload.filter((t) => !t.dummy),
        dummy: action.payload.filter((t) => t.dummy),
        loading: false,
      };
    case TOURNAMENT_CREATE:
      return {
        ...state,
        ...(action.payload.dummy
          ? { dummy: [...state.dummy, action.payload] }
          : { all: [...state.all, action.payload] }),
        loading: false,
      };
    case TOURNAMENT_UPDATE:
      const { id, data } = action.payload;
      return {
        ...state,
        mine: state.mine.map((t) => (t._id === id ? { ...t, ...data } : t)),
        all: state.all.map((t) => (t._id === id ? { ...t, ...data } : t)),
        dummy: state.dummy.map((t) => (t._id === id ? { ...t, ...data } : t)),

        loading: false,
      };
    case TOURNAMENT_DELETE:
      return {
        ...state,
        mine: state.mine.filter((t) => t._id !== action.payload),
        all: state.all.filter((t) => t._id !== action.payload),
        dummy: state.dummy.filter((t) => t._id !== action.payload),
        loading: false,
      };
    case TOURNAMENT_CLEAR:
      return { ...state, mine: [], all: [], dummy: [] };
    default:
      return state;
  }
};

export default tournamentReducer;
