import React from "react";
import { ThemeColour } from "../../common/theme.types";

interface Props {
  className?: string;
  variant?: ThemeColour;
  value: string;
}

const SubmitButton = ({ className, variant = "primary", value }: Props) => {
  return (
    <input
      type="submit"
      className={`btn btn-${variant}${className ? " " + className : ""}`}
      value={value}
    />
  );
};

export default SubmitButton;
