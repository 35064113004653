import {RootState} from '../root-reducer';
import {createSelector} from 'reselect';

/// Selector functions
const selectTournamentMine = (state:RootState) => ({tournaments: state.tournament.mine, season: state.settings.season})
const selectTournamentAll = (state:RootState) => ({tournaments: state.tournament.all, season: state.settings.season})

/// 

export const selectMyTournaments = createSelector(selectTournamentMine, s=>s.tournaments.filter(t=>t.season === s.season));
export const selectAllTournaments = createSelector(selectTournamentAll, s=>s.tournaments.filter(t=>t.season === s.season));
