import api from "../../utils/api.instance";
import { GIsArray, IEvent, IUser } from "../api.types";
import {
  DTOEventCreate,
  DTOEventGet,
  DTOEventUpdate,
  GIsDTOEventGet,
} from "../dtos/admin.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getAll = () => Service.run(_getAll, {}, "EVENT_GET_ALL");

const _getAll = async (): Promise<IEvent[]> => {
  const a = (await api.get("admin/events")).data;
  return GIsArray<DTOEventGet>(a).map((ad) => GIsDTOEventGet(ad));
};

const create = async (data: DTOEventCreate): Promise<IEvent> => {
  return GIsDTOEventGet((await api.post(`admin/events`, data)).data);
};

const update = async (
  id: IEvent["_id"],
  data: DTOEventUpdate
): Promise<IEvent> => {
  return GIsDTOEventGet((await api.put(`admin/events/${id}`, data)).data);
};

const remove = async (id: IEvent["_id"]): Promise<boolean> => {
  await api.delete(`admin/events/${id}`);
  return true;
};

export const EventService = {
  getAll,
  create,
  update,
  remove,
};
