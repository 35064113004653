import api from "../../utils/api.instance";
import { GIsArray, IPayment, IUser } from "../api.types";
import {
  DTOPaymentCreate,
  DTOPaymentGet,
  DTOPaymentUpdate,
  GIsDTOPaymentGet,
} from "../dtos/payment.dto";
import ServiceManager from "../service-manager";

const Service = new ServiceManager(60000);

const getMine = async (): Promise<IPayment[]> => {
  const a = (await api.get("payments/mine")).data;
  return GIsArray<DTOPaymentGet>(a).map((ship) => GIsDTOPaymentGet(ship));
};

const getAll = () => Service.run(_getAll, {}, "PAYMENT_GET_ALL");

const _getAll = async (): Promise<IPayment[]> => {
  const a = (await api.get("payments")).data;
  return GIsArray<DTOPaymentGet>(a).map((ship) => GIsDTOPaymentGet(ship));
};

const create = async (data: DTOPaymentCreate): Promise<IPayment> => {
  return GIsDTOPaymentGet((await api.post(`payments`, data)).data);
};

const update = async (
  id: IPayment["_id"],
  data: DTOPaymentUpdate
): Promise<IPayment> => {
  return GIsDTOPaymentGet((await api.put(`payments/${id}`, data)).data);
};

const updateComment = async (
  id: IPayment["_id"],
  comment: IPayment["comment"]
): Promise<IPayment> => {
  return GIsDTOPaymentGet(
    (await api.put(`payments/${id}/comment`, comment)).data
  );
};

const remove = async (id: IPayment["_id"]): Promise<boolean> => {
  await api.delete(`payments/${id}`);
  return true;
};

const PaymentService = {
  getMine,
  getAll,
  create,
  update,
  updateComment,
  remove,
};

export default PaymentService;
