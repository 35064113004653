import React, { Fragment, useState, useEffect } from "react";
import Moment from "react-moment";
import { connect, ConnectedProps } from "react-redux";
import { FIRSTProgram } from "../../common/backend.types";
import {
  competeCreate,
  competeDelete,
  competeGetAll,
  competeMove,
} from "../../store/compete/compete.action";
import { RootState } from "../../store/root-reducer";
import { Team } from "../../store/teams/team.types";
import { tournamentGetAll } from "../../store/tournament/tournament.action";
import { selectAllTournaments } from "../../store/tournament/tournament.selector";
import { Tournament } from "../../store/tournament/tournament.types";
import Button from "../atomic/Button";
import CheckBox from "../atomic/CheckBox";
import Icon from "../atomic/Icon";
import Spinner from "../atomic/Spinner";
import TeamNumber from "../atomic/TeamNumber";

const RegisterCompeteForm = ({
  program,
  dummy,
  admin,
  tournaments,
  dummyTournaments,
  competes,
  teams,
  loading,
  onSubmit,
  competeGetAll,
  competeCreate,
  competeMove,
  competeDelete,
  tournamentGetAll,
}: Props) => {
  const [sel, setSel] = useState<Team[]>(teams);

  useEffect(() => {
    tournamentGetAll();
    competeGetAll();
  }, []);

  useEffect(() => {
    setSel(teams);
  }, [teams]);

  const handleSubmit = async (tournament_id: Tournament["_id"]) => {
    var success: boolean = false;
    if (sel.length === 1 && competes.find((c) => c.team_id === sel[0]._id)) {
      const comp = competes.find((t) => t.team_id === sel[0]._id);
      if (comp) {
        if (comp.tournament_id === tournament_id)
          success = await competeDelete(comp._id, admin);
        else success = await competeMove(comp._id, tournament_id, admin);
      }
    } else if (sel.length > 0) {
      success = await competeCreate(
        {
          tournament_id,
          team_ids: sel.map((t) => t._id),
        },
        admin
      );
    } else alert("You must select some of your teams");

    if (success) onSubmit();
  };

  return loading ? (
    <Spinner variant="challenge" />
  ) : (
    <div id="register-form">
      <h1>Register for a tournament</h1>
      <div className="teams">
        {teams.length === 1 ? (
          <span className="team">
            <TeamNumber
              number={teams[0].team_number}
              program={teams[0].program}
              className="mr-1"
            />{" "}
            {teams[0].team_name}
          </span>
        ) : (
          teams.map((t, i) => (
            <span key={i} className="team">
              <CheckBox
                id={"checkbox_" + t._id}
                checked={sel.map((s) => s._id).includes(t._id)}
                toggle={() =>
                  setSel(
                    sel.map((s) => s._id).includes(t._id)
                      ? sel.filter((s) => s._id !== t._id)
                      : [...sel, t]
                  )
                }
              />
              <label htmlFor={"checkbox_" + t._id}>
                <TeamNumber number={t.team_number} program={t.program} />{" "}
                {t.team_name}
              </label>
            </span>
          ))
        )}
      </div>

      <table className="table table-primary mt-1">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start date</th>
            <th>Location</th>
            <th>Teams</th>
            <th>Choose</th>
          </tr>
        </thead>
        <tbody className="striped">
          {(dummy ? dummyTournaments : tournaments)
            .filter((t) => t.program === program && t.type === "Open Qualifier")
            .sort((a, b) => a.start.getTime() - b.start.getTime())
            .map((tourn) => (
              <tr key={tourn._id}>
                <td className="d-flex-col flex-left">
                  <div className="d-flex j-space-between w-100">
                    {tourn.name}
                  </div>
                  {/* <small>{tourn.type}</small> */}
                </td>
                <td>
                  <Moment format="LL">{tourn.start}</Moment>
                  {tourn.n_days > 1 ? `(${tourn.n_days} days)` : ""}
                </td>
                <td>
                  {tourn.remote ? (
                    <span className="d-flex place-content-center">
                      Remote
                      <Icon icon="laptop-house" className="mx-1" />
                    </span>
                  ) : (
                    <div className="d-flex-col">
                      <span>{tourn.company}</span>
                      <span>{tourn.street1}</span>
                      <span>{tourn.street2}</span>
                      <span>{tourn.street3}</span>
                      <span>{tourn.suburb}</span>
                      <span>
                        {tourn.state} {tourn.postcode}
                      </span>
                    </div>
                  )}
                </td>

                <td>
                  {competes.filter((c) => c.tournament_id === tourn._id).length}{" "}
                  / {tourn.cap}
                </td>
                <td>
                  {sel.length === 1 &&
                  competes.find((c) => c.team_id === sel[0]._id) ? (
                    competes.find(
                      (c) =>
                        c.tournament_id === tourn._id &&
                        c.team_id === sel[0]._id
                    ) ? (
                      <Button
                        variant="warn"
                        disabled={!admin && tourn.locked}
                        onClick={() => handleSubmit(tourn._id)}
                      >
                        {tourn.locked && <Icon icon="lock" />} Leave
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        disabled={!admin && tourn.locked}
                        onClick={() => handleSubmit(tourn._id)}
                      >
                        {tourn.locked && <Icon icon="lock" />} Move to
                      </Button>
                    )
                  ) : (
                    <Button
                      disabled={!admin && tourn.locked}
                      onClick={() => handleSubmit(tourn._id)}
                      variant={
                        tourn.locked ||
                        competes.filter((c) => c.tournament_id === tourn._id)
                          .length +
                          sel.length >
                          tourn.cap
                          ? "danger"
                          : "success"
                      }
                    >
                      {tourn.locked ? (
                        <Icon icon="lock" />
                      ) : competes.filter((c) => c.tournament_id === tourn._id)
                          .length +
                          sel.length >
                        tourn.cap ? (
                        "Full"
                      ) : (
                        "Register!"
                      )}
                      {/* <small>{tourn.locked ? "lock" : "unlock"}</small> */}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  tournaments: selectAllTournaments(state),
  dummyTournaments: state.tournament.dummy,
  competes: state.compete.all,
  loading: state.tournament.loading || state.compete.loading,
});

const connector = connect(mapStateToProps, {
  competeCreate,
  competeMove,
  competeDelete,
  competeGetAll,
  tournamentGetAll,
});
type Props = ConnectedProps<typeof connector> & {
  onSubmit: () => void;
  program: FIRSTProgram;
  teams: Team[];
  admin?: boolean;
  dummy?: boolean;
};

export default connector(RegisterCompeteForm);
