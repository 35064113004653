import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router";
import { RootState } from "../../store/root-reducer";
import LocalLink from "../atomic/LocalLink";

const PaymentResponse = ({ auth: { user } }: Props) => {
  return (
    <div id="pay-now-view">
      <h1>Payment received</h1>
      <p>Thank you for your registration!</p>
      <p>
        A receipt should be sent to your nominated email address
        {user ? `(${user.email})` : ""}
      </p>
      <LocalLink to="/" className="btn btn-success">
        Return to dashboard
      </LocalLink>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector> & {};

export default connector(PaymentResponse);
