import React, { useEffect, useCallback } from "react";
import logger from "../../utils/logger";
import Icon from "./Icon";

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
  onClose?: () => void;
  open: boolean;
}

/** Represents a Modal with customisable content */
const Modal = ({ children, title, className, onClose, open }: Props) => {
  const close = () => {
    if (onClose) onClose();
  };

  const handleKeypress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") close();
    },
    [open, close]
  );

  useEffect(() => {
    if (open && onClose) document.addEventListener("keydown", handleKeypress);
    else document.removeEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  }, [open, close]);

  return (
    <div
      className={`modal-wrapper${open ? " open" : " closed"}`}
      onClick={close}
    >
      <div
        className={`modal${className ? " " + className : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        {onClose && (
          <Icon className="modal-close-btn m-1" icon="times" onClick={close} />
        )}
        {title && <div className="modal-title">{title}</div>}
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
