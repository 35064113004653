import React, { useEffect } from "react";
import { useState } from "react";
import { IUser } from "../../../api/api.types";
import { FIRSTSeason } from "../../../common/backend.types";
import { Address } from "../../../store/address/address.types";
import { Payment } from "../../../store/payment/payment.types";
import { Shipment } from "../../../store/shipping/shipment.types";
import { Team } from "../../../store/teams/team.types";
import { Tournament } from "../../../store/tournament/tournament.types";
import ShipTeamRow from "./ShipTeamRow";

interface Props {
  users: IUser[];
  teams: Team[];
  tournaments: Tournament[];
  payments: Payment[];
  addresses: Address[];
  shipments: Shipment[];
  filter?: string;
  showDone?: boolean;
  showUnpaid?: boolean;
}

const ShipTeamList = ({
  users,
  teams,
  tournaments,
  shipments,
  payments,
  addresses,
  filter,
  showDone,
  showUnpaid,
}: Props) => {
  const [U, setU] = useState<IUser[]>([]);

  /** Sort addresses by the earliest team registered for that address */
  useEffect(() => {
    const T = teams
      .filter((t) => t.ship_kit)
      .sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );
    setU(
      (
        T.map((t) => t.user)
          .filter((x, i, a) => a.indexOf(x) === i)
          .map((u) => users.find((user) => user._id === u))
          .filter((u) => u) as IUser[]
      ).filter(
        (s) =>
          !filter ||
          s.given_name?.toLowerCase().includes(filter) ||
          s.family_name?.toLowerCase().includes(filter) ||
          addresses.find(
            (a) => a.user === s._id && a.company?.toLowerCase().includes(filter)
          )
      )
    );
  }, [teams, filter]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Contact</th>
          <th>Address</th>
          <th>To ship</th>
          <th>Paid?</th>
          <th>Finished?</th>
          <th>Shipments</th>
        </tr>
      </thead>
      <tbody className="striped">
        {U.map((u) => (
          <ShipTeamRow
            key={u._id}
            userId={u._id}
            teams={teams.filter((t) => t.user === u._id)}
            tournaments={tournaments.filter(
              (t) =>
                t.season !== FIRSTSeason.Energize &&
                t.ship_kits &&
                t.ship_address &&
                addresses
                  .filter((a) => a.user === u._id)
                  .map((a) => a._id)
                  .includes(t.ship_address)
            )}
            payments={payments.filter((p) => p.payer === u._id)}
            addresses={addresses.filter((a) => a.user === u._id)}
            shipments={shipments.filter((s) => s.receiver === u._id)}
            filter={filter}
            showDone={showDone}
            showUnpaid={showUnpaid}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ShipTeamList;
