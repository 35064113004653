import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect } from "react";
import { AnalyticsTrack } from "../../api/analytics";

interface Props {}

const FAQ = (props: Props) => {
  useEffect(() => {
    AnalyticsTrack({
      name: "View Page",
      data: {
        page: "FAQ",
      },
    });
  }, []);

  return (
    <div className="mb-4">
      <h1>Frequently Asked Questions</h1>
      <small>
        Note: this information is just for guidance and is not legally binding.
        For more involved concerns, please contact{" "}
        <a href="mailto:wynonah.bush@mq.edu.au">wynonah.bush@mq.edu.au</a>
      </small>
      <h2>System</h2>
      <h4>
        I don't understand how to do something / Something seems broken / This
        is dumb, why is it set up this way?
      </h4>
      <p>
        The first step is to check through this page - it might answer the
        question you have. Otherwise, if you have any questions or issues with
        the system, you can contact{" "}
        <a href="mailto:fred@firstaustralia.org">fred@firstaustralia.org</a>. We
        only ask that you keep in mind this is a volunteer-powered organisation,
        and we're often overworked, so please be polite and patient.
      </p>

      <h2>Accounts</h2>
      <h4>Why can't I sign in with my FIRST account?</h4>
      <p>
        If you registered as a user in this system in 2021, you should still be
        able to sign in with the same details. If you haven't created a FIRST
        Australia account since 2021, you will need to create a new user, as we
        moved to a new system in February 2021. This system is also separate to
        the firstinspires.org system, so the account you use there (for
        instance, to register or volunteer for FRC) will not work here.
      </p>

      <h4>I haven't received my confirmation email!</h4>
      <p>
        The confirmation email is sent through the popular Firebase
        authentication system, so it should be recognised as safe by most spam
        filters. However, checking spam (either from your inbox or via your
        organisation settings) is always the first step here, as the email
        should arrive within minutes of signing up. If this continues to be a
        problem, we highly recommend trying the new Google Sign-in button if you
        have access to a Google account.
      </p>

      {/* <h2>Pre-registration</h2>
      <h4>Why is registration so early this year?</h4>
      <p>
        In order to better cater for everyone during the season, we want to get
        an early understanding of how many kits and tournaments will be needed.
        The "pre-registration" process enables that.
      </p>
      <h4>
        Why should I pre-register? I would rather wait until the kits are ready
        to ship.
      </h4>
      <p>
        Of course there is no requirement to pre-register, and we will still
        endeavour to support teams who would prefer to wait. However, due to the
        difficulty in predicting the number of these teams, we provide several
        benefits to pre-registering teams.
      </p>
      <p>
        The main benefit is the ability to register as many teams and kits as
        you want. Once the season fully starts, the number of teams and kits per
        organisation will be severely limited to ensure everyone gets a chance
        to play, but if you register during the pre-registration period, you can
        register as many teams as needed to ensure all your students are
        included. There is also a discounted rate for registration in the
        pre-registration period.
      </p>

      <h4>When does the pre-registration period end?</h4>
      <p>
        Pre-registration ends on April 10th, and all teams who have registered
        and paid by that date will be guaranteed kits for the season.
      </p> */}

      <h4>When will my kits be shipped?</h4>
      <p>
        Kits will be shipped to pre-registered teams as soon as they arrive from
        LEGO, which is typically around <strong>June/July</strong>. By
        registering early, you guarantee that you'll be in the first batch of
        kits sent out.
      </p>

      <h2>Tournaments</h2>
      <h4>How do I register my teams for tournaments?</h4>
      <p>
        The tournament registration process has not yet been set up as the list
        of events is still being finalised.
      </p>

      <h4>
        I don't want to pay unless I can be sure to put my teams in a tournament
      </h4>
      <p>
        <i>FIRST</i> Australia will ensure all teams who pre-register will have
        access to an event; if there are not enough physical events available in
        your area for any reason, we will be hosting enough remote events (using
        the model we have successfully implemented since 2020) that all teams
        will get the chance to compete in a qualifying event and earn their way
        to one of the National Championships.
      </p>

      <h4>What happens if I don't pay before April 10th?</h4>
      <p>
        If your balance is not fully settled by April 10th, we can not guarantee
        that you can receive all the kits that you ordered. <i>FIRST</i>{" "}
        Australia may need to delete some of your registrations to bring you in
        line with the full-season registration limits, and you can no longer
        make use of the pre-registration discount.
      </p>

      <h2>Payment</h2>
      <h4>How is shipping calculated?</h4>
      <p>
        For any shipment, we estimate the price based on the state of delivery.
        These numbers are aggregated averages from years of shipping kits from
        our warehouse in Sydney:
      </p>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>State</strong>
            </TableCell>
            <TableCell>NSW</TableCell>
            <TableCell>VIC</TableCell>
            <TableCell>ACT</TableCell>
            <TableCell>NT</TableCell>
            <TableCell>QLD</TableCell>
            <TableCell>SA</TableCell>
            <TableCell>WA</TableCell>
            <TableCell>TAS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Cost</strong>
            </TableCell>
            <TableCell>$20</TableCell>
            <TableCell>$20</TableCell>
            <TableCell>$20</TableCell>
            <TableCell>$55</TableCell>
            <TableCell>$40</TableCell>
            <TableCell>$30</TableCell>
            <TableCell>$45</TableCell>
            <TableCell>$30</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <p>
        If we are shipping more than 10 kits to any single address, we add
        another unit of cost for every 10 kits to compensate for shipping prices
        increasing with larger loads.
      </p>

      <p>
        Note that this is only an estimate. If the price ends up significantly
        higher than this (usually because TNT had to attempt redelivery several
        times), we may contact you to provide additional payments.
      </p>

      <h4>
        I paid by credit card but the system is still saying I owe money; why is
        this?
      </h4>
      <p>
        This year, we are using a new system for payment integration which
        includes real-time updating, so once you are returned from the Macquarie
        University Payment Gateway, your balance should be updated. However,
        since this is a new system there may be some kinks to work out; the
        update may take several minutes, or in rare cases, several days.{" "}
      </p>
      <p>
        However, if the payment gateway told you your payment went through
        successfully, you <strong>do not</strong> need to try again, as this
        will lead to you being charged twice. You should receive an email from
        Macquarie University with a receipt for your payment (check spam if you
        have not seen it), and if you received this email your payment has been
        processed and your balance is squared away.
      </p>

      <h4>Why do I have to upload a purchase order to pay by invoice?</h4>
      <p>
        In the past, users have disputed the amount they have to pay when the
        final invoice is issued. To prevent any disputes on both sides, we are
        now requiring users to generate and upload a Purchase Order (PO) before
        they request a payment by invoice. For your convenience, we assume your
        balance is paid once a PO is uploaded, but if the PO is not valid we
        will erase if from the system and remind you to pay.
      </p>

      <h4>I realised I registered too many teams; can I get a refund?</h4>
      <p>
        As is clearly stated in the Terms and Conditions on the registration
        page, we may not offer refunds for change-of-mind registrations. This is
        because the number of registrations influences the number of kits we
        order and events we run, and we have to pay for each of them. As a
        non-profit organisation, we can not afford significant over-ordering.
      </p>
      <p>
        However, if you delete a team after paying for that team, the amount you
        paid can be put towards a new registration later; so, if you delete one
        of your Challenge teams, you may be able to add one or more Explore or
        Discover teams without needing to pay again.
      </p>

      <h2>Team management</h2>
      <h4>How do I update my teams?</h4>
      <p>
        In the sidebar, click on "My Teams" to see and edit your team
        information.
      </p>
      <h4>I accidentally deleted one of my teams! What do I do?</h4>
      <p>
        Please contact{" "}
        <a href="mailto:fred@firstaustralia.org">fred@firstaustralia.org</a>;
        team information is never deleted from the system, only flagged for
        removal, so we can easily restore it.
      </p>
      <h4>Where do I enter the names of the students on the team?</h4>
      <p>We do not currently collect this information.</p>

      <h2>Volunteering</h2>
      <h4>How do I sign up to volunteer at an event?</h4>
      <p>
        From your dashboard, switch to the "Volunteer" tab. You will need to
        fill in your volunteer profile (this involves capturing more data than
        regular users need, since we need to know who you are), and once the
        profile has been created, you can select the events you want to
        volunteer for.
      </p>

      <h4>
        I volunteered for an event which I now can't make it to. How do I cancel
        my volunteering?
      </h4>
      <p>
        To cancel volunteering, please contact{" "}
        <a href="mailto:wynonah.bush@mq.edu.au">Wynonah Bush</a>.
      </p>
      <p>
        The reason we don't allow users to cancel themselves is because
        tournaments take a lot of work to prepare, and tournament directors need
        to know who they have available. By making sure the process for removing
        volunteers is extremely deliberate, we can avoid surprises (like a key
        volunteer not showing up on the day) and can recruit new volunteers to
        replace you as quickly as possible.
      </p>
    </div>
  );
};

export default FAQ;
