const areas = [
  "core_values",
  "robot_design",
  "research_project",
  "general",
] as const;
type Area = typeof areas[number];

// type QMap = { [Property in Area]: readonly string[] };
const questions = {
  core_values: [
    "breakthrough",
    "allstar",
    "motivate",
    "discovery",
    "innovation",
    "impact",
    "inclusion",
    "teamwork",
    "fun",
  ] as const,
  research_project: [
    "identify_a",
    "identify_b",
    "design_a",
    "design_b",
    "create_a",
    "create_b",
    "iterate_a",
    "iterate_b",
    "communicate_a",
    "communicate_b",
  ] as const,
  robot_design: [
    "identify_a",
    "identify_b",
    "design_a",
    "design_b",
    "create_a",
    "create_b",
    "iterate_a",
    "iterate_b",
    "communicate_a",
    "communicate_b",
  ] as const,
  general: [
    "positive_comment",
    "negative_comment",
    "private_comment",
    "award_script",
  ] as const,
};

const coreQuestions = questions.core_values.map((s) => `cv:${s}` as const);
export type CoreQuestion = typeof coreQuestions[number];
const projectQuestions = questions.research_project.map(
  (s) => `rp:${s}` as const
);
export type ProjectQuestion = typeof projectQuestions[number];
const robotQuestions = questions.robot_design.map((s) => `rd:${s}` as const);
export type RobotQuestion = typeof robotQuestions[number];
const generalQuestions = questions.general.map((s) => `gn:${s}` as const);
export type GeneralQuestion = typeof generalQuestions[number];

export type QuestionId =
  | CoreQuestion
  | ProjectQuestion
  | RobotQuestion
  | GeneralQuestion;

const isCoreQuestion = (q: string): q is CoreQuestion =>
  q.startsWith("cv:") &&
  questions.core_values.includes(q.replace("cv:", "") as any);
const isProjectQuestion = (q: string): q is ProjectQuestion =>
  q.startsWith("rp:") &&
  questions.research_project.includes(q.replace("rp:", "") as any);
const isRobotQuestion = (q: string): q is RobotQuestion =>
  q.startsWith("rd:") &&
  questions.robot_design.includes(q.replace("rd:", "") as any);
const isGeneralQuestion = (q: string): q is GeneralQuestion =>
  q.startsWith("gn:") &&
  questions.general.includes(q.replace("gn:", "") as any);

// For now, we check if each category is "complete" by checking the number unique IDs - in theory
const isCoreComplete = (ids: string[]): boolean =>
  ids
    .filter((q) => isCoreQuestion(q))
    .filter((x, i, a) => a.indexOf(x) === i)
    .filter(
      (x) => !["cv:breakthrough", "cv:allstar", "cv:motivate"].includes(x)
    ).length ===
  questions.core_values.length - 3;
const isProjectComplete = (ids: string[]): boolean =>
  ids
    .filter((q) => isProjectQuestion(q))
    .filter((x, i, a) => a.indexOf(x) === i).length ===
  questions.research_project.length;
const isRobotComplete = (ids: string[]): boolean =>
  ids.filter((q) => isRobotQuestion(q)).filter((x, i, a) => a.indexOf(x) === i)
    .length === questions.robot_design.length;

const Rubrics = {
  isCoreQuestion,
  isProjectQuestion,
  isRobotQuestion,
  isGeneralQuestion,
  isCoreComplete,
  isProjectComplete,
  isRobotComplete,
};

export default Rubrics;
